import { Conversation, Message } from "@twilio/conversations";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { addConversationParticipant } from "./useAddConversationParticipant.hook";
import { formatError } from "utils/strings";
import { useTwilioClient } from "./useTwilioClient.hook";
import { ConversationType } from "models/Conversations/BarnardConversation.types";
import { ReactQueryKeys } from "constants/keys";
import { addMessage, generateEmptyConversationMessages } from "./useConversationMessages.utils";
import { InfiniteMessagesData } from "./useConversationMessages.hook";
import { useCurrentUser } from "features/authentication/useCurrentUser";

export const LOCAL_MESSAGE_PREFIX = "LOCAL_MESSAGE";
export const sendMessage = (conversation?: Conversation) => async (message: string) => {
  if (!conversation) {
    throw new Error("Conversation not found");
  }
  return conversation.prepareMessage().setBody(message).build().send();
};

export const useSendMessageByConversationSid = (
  conversationSid: string,
  conversationType: ConversationType
) => {
  const { data: twilioClient } = useTwilioClient(conversationType);
  const [conversation, setConversation] = useState<Conversation>();
  const queryClient = useQueryClient();
  const queryKey = [ReactQueryKeys.MESSAGES, conversationSid];
  const { data: currentUser } = useCurrentUser();

  useEffect(() => {
    (async () => {
      if (twilioClient && conversationSid) {
        // We expect to catch an error here if the user is not yet part of the conversation,
        // but it should be silent
        const conversationResponse = await twilioClient
          ?.getConversationBySid(conversationSid)
          .catch();
        if (conversationResponse) setConversation(conversationResponse);
      }
    })();
  }, [conversationSid, twilioClient]);

  const send = async (message: string) => {
    if (conversation) {
      return sendMessage(conversation)(message);
    } else {
      await addConversationParticipant(conversationSid);
      const conversationResponse = await twilioClient
        ?.getConversationBySid(conversationSid)
        .catch((e) => console.error(formatError(e)));

      if (!conversationResponse) throw new Error("Error joining conversation");
      setConversation(conversationResponse);
      return sendMessage(conversationResponse)(message);
    }
  };
  return useMutation(send, {
    onError: (error) => {
      queryClient.invalidateQueries(queryKey);
      //TODO: improve UX when error occurs
      console.error("Error sending message", error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onMutate: async (message) => {
      const now = Date.now();
      const sid = `${LOCAL_MESSAGE_PREFIX}_${now}`;
      const author = `${currentUser?.id}@capable-chat.com`;

      // Many any to patch here as we just need to create a temp message without all the data
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const newMessage = new Message(
        -1,
        {
          text: message,
          author,
          sid,
          timestamp: now,
          dateUpdated: now,
        } as any,
        conversation as any,
        {} as any,
        {} as any,
        {} as any
      );
      /* eslint-enable @typescript-eslint/no-explicit-any */

      const previousCache =
        queryClient.getQueryData<InfiniteMessagesData>(queryKey) ??
        generateEmptyConversationMessages();

      queryClient.setQueryData<InfiniteMessagesData>(queryKey, (currentCache) =>
        addMessage(newMessage, currentCache)
      );

      return { previousCache };
    },
  });
};

export const useSendMessageByConversation = (conversation: Conversation) => {
  return useMutation(sendMessage(conversation), {});
};
