/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The InsurancePoliciesInsurancePolicyPayerIdentificationAttributes model module.
 * @module model/InsurancePoliciesInsurancePolicyPayerIdentificationAttributes
 * @version v0.3
 */
export class InsurancePoliciesInsurancePolicyPayerIdentificationAttributes {
  /**
   * Constructs a new <code>InsurancePoliciesInsurancePolicyPayerIdentificationAttributes</code>.
   * @alias module:model/InsurancePoliciesInsurancePolicyPayerIdentificationAttributes
   * @class
   * @param payerId {String} The Unique number associated with insurance companies policies' cards.Sometimes reffed to as the plan number
   * @param payerName {String} The name of the insurance company on the policy card
   */
  constructor(payerId, payerName) {
    this.payerId = payerId;
    this.payerName = payerName;
  }

  /**
   * Constructs a <code>InsurancePoliciesInsurancePolicyPayerIdentificationAttributes</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InsurancePoliciesInsurancePolicyPayerIdentificationAttributes} obj Optional instance to populate.
   * @return {module:model/InsurancePoliciesInsurancePolicyPayerIdentificationAttributes} The populated <code>InsurancePoliciesInsurancePolicyPayerIdentificationAttributes</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InsurancePoliciesInsurancePolicyPayerIdentificationAttributes();
      if (data.hasOwnProperty("payer_id"))
        obj.payerId = ApiClient.convertToType(data["payer_id"], "String");
      if (data.hasOwnProperty("payer_name"))
        obj.payerName = ApiClient.convertToType(data["payer_name"], "String");
    }
    return obj;
  }
}

/**
 * The Unique number associated with insurance companies policies' cards.Sometimes reffed to as the plan number
 * @member {String} payerId
 */
InsurancePoliciesInsurancePolicyPayerIdentificationAttributes.prototype.payerId = undefined;

/**
 * The name of the insurance company on the policy card
 * @member {String} payerName
 */
InsurancePoliciesInsurancePolicyPayerIdentificationAttributes.prototype.payerName = undefined;
