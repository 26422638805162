import { useQuery } from "@tanstack/react-query";
import { ReactQueryKeys } from "constants/keys";
import { getAllPagesBody } from "hooks/getAllPagesBody";
import { API_Type } from "hooks/listAll";
import { User } from "models/User/User.types";
import { getParticipantsByAuthor } from "./useConversationsParticipants.utils";

const getPractitionersAndTenantAdminsData = async () => {
  const practitioners = await getAllPagesBody<User>(API_Type.Practitioner);
  const tenantAdmin = await getAllPagesBody<User>(API_Type.TenantAdmin);

  return getParticipantsByAuthor([...practitioners, ...tenantAdmin]);
};

export const usePractitionerAndTenantAdminsData = () => {
  const query = useQuery([ReactQueryKeys.PRACTITIONERS_AND_TENANT_ADMINS_DATA], () =>
    getPractitionersAndTenantAdminsData()
  );

  return {
    ...query,
  };
};
