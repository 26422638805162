import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import React from "react";

import { colors } from "../../styles/colors";
import { theme } from "../../styles/theme";

const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-autoHeight": {
      display: "flex",
      flexDirection: "column-reverse",
      backgroundColor: "white",
      border: "none",
    },
    "& .MuiDataGrid-main": {
      border: "1px solid #e0e0e0",
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "none",
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      borderTop: `1px solid ${colors.lightGrey4}`,
      cursor: "default",
      "& .MuiDataGrid-columnHeader:focus": {
        outline: "none",
      },
    },
    "& .MuiTablePagination-root": {
      paddingRight: theme.spacing(1),
    },
    "& .MuiDataGrid-footerContainer": {
      cursor: "default",
      backgroundColor: "#f4f4f4",
    },
    "& .MuiTablePagination-actions .MuiButtonBase-root": {
      padding: 0,
    },
    "& .MuiTablePagination-actions .MuiButtonBase-root .MuiSvgIcon-root": {
      width: "25px",
      height: "25px",
    },
    "& .MuiDataGrid-row": {
      border: "none",
      "&:nth-child(2n-1)": { backgroundColor: "#f4f4f4" },
      "&:nth-child(2n-1):hover": { backgroundColor: "#efefef" },
    },
  },
});

const InlinePaginatedTable = ({
  rows,
  columns,
  onPageChange,
  rowCount,
  onRowClick,
  rowId,
}: {
  rows: any;
  columns: any;
  onPageChange: any;
  rowCount: number;
  onRowClick?: any;
  rowId?: any;
}) => {
  const classes = useStyles();

  return (
    <DataGrid
      className={classes.root}
      rows={rows}
      columns={columns}
      hideFooterSelectedRowCount
      pageSize={10}
      rowHeight={72}
      headerHeight={70}
      autoHeight
      disableColumnMenu
      onPageChange={onPageChange}
      paginationMode="server"
      rowCount={rowCount}
      getRowId={rowId}
      onRowClick={onRowClick}
      sx={{
        [`& .${gridClasses.row}`]: onRowClick && {
          ":hover": { cursor: "pointer" },
        },
      }}
    />
  );
};

export default InlinePaginatedTable;
