import { BreakpointContext } from "contexts/breakpoint";
import { colors } from "styles/colors";
import { ModalProps } from "./Modal.types";
import { OutlinedButton, ContainedButton } from "../Buttons";
import { theme } from "styles/theme";
import { Box, Typography, CircularProgress, IconButton, Dialog } from "@mui/material";
import React, { useContext } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";

const Modal = (props: ModalProps): JSX.Element => {
  const { isMobileView } = useContext(BreakpointContext);
  const {
    onClose,
    onPrimaryButtonClick,
    primaryButtonText,
    primaryActionLoading,
    onSecondaryButtonClick = onClose,
    secondaryButtonText = "Cancel",
    open,
    title,
    subtitle,
    children,
    disabled,
    disableCloseOnFocusOut = false,
    disableCloseButton = false,
    footerChild = <></>,
  } = props;

  return (
    <Dialog
      onClose={disableCloseOnFocusOut ? undefined : onClose}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <Box sx={{ padding: (theme) => theme.spacing(3) }}>
        <Box sx={{ margin: (theme) => theme.spacing(3, 2) }}>
          <Typography variant="h3">{title}</Typography>
          {subtitle && (
            <Typography variant="subtitle1" sx={{ marginTop: theme.spacing(1) }}>
              {subtitle}
            </Typography>
          )}
          {!disableCloseButton && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: (theme) => theme.spacing(1),
                top: (theme) => theme.spacing(1),
                color: (theme) => theme.palette.text.primary,
              }}
            >
              <CloseIcon sx={{ width: "20px", height: "20px" }} />
            </IconButton>
          )}
        </Box>
        <Box
          sx={{
            margin: (theme) => theme.spacing(2),
          }}
        >
          {children}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>{footerChild}</Box>
          {primaryButtonText && onPrimaryButtonClick && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                margin: (theme) => theme.spacing(3),
              }}
            >
              <OutlinedButton
                onClick={onSecondaryButtonClick}
                // disabled={disabled} // mac did you need the cancel button to be disabled on encounters? otherwise this shouldnt be here. disabling for pal demo
                style={{
                  minWidth: isMobileView ? "85px" : "142px",
                  padding: "6.4px 12px",
                  height: "36px",
                  borderColor: colors.lightGrey2,
                  color: theme.palette.primary.main,
                  // @ts-ignore
                  "&:hover": {
                    borderColor: "#40404F",
                    backgroundColor: "#fff",
                  },
                }}
              >
                {secondaryButtonText}
              </OutlinedButton>
              {primaryActionLoading ? (
                <LoadingButton
                  loading
                  variant="outlined"
                  disabled
                  style={{
                    marginLeft: "12px",
                    padding: "6.4px 12px",
                    minWidth: "142px",
                    height: "36px",
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                    textTransform: "none",
                  }}
                  loadingIndicator={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CircularProgress
                        sx={{ display: "inline-block", color: theme.palette.primary.main }}
                        size={16}
                      />
                      <span style={{ paddingLeft: "0.5rem" }}>Processing...</span>
                    </Box>
                  }
                />
              ) : (
                <ContainedButton
                  onClick={onPrimaryButtonClick}
                  disabled={disabled}
                  style={{
                    marginLeft: "12px",
                    padding: "6.4px 12px",
                    minWidth: "142px",
                    height: "36px",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.background.paper,
                  }}
                >
                  {primaryButtonText}
                </ContainedButton>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default Modal;
