import { Box } from "@mui/system";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import React, { useContext, useState } from "react";
import Typography from "@mui/material/Typography";

import { BASE_API_PATH } from "../api/wrapper";
import { BreakpointContext } from "../contexts/breakpoint";
import { Alert, Card } from "@mui/material";
import { colors } from "../styles/colors";
import { ContainedButton } from "../components/styled/Buttons";
import { formatError } from "../utils/strings";
import { theme } from "../styles/theme";
import InfoAlertContainer from "../layout/InfoAlertContainer";

const ForgotUrl = function (): JSX.Element {
  const { isMobileView } = useContext(BreakpointContext);
  const [inFos, setInfos] = useState<{
    status: "success" | "info" | "warning" | "error";
    message: string;
    timeout?: number;
  }>();

  const [email, setEmail] = useState("");

  const onClickHandler = (): void => {
    const re =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const isEmailValid = re.test(email.toLowerCase());
    if (!isEmailValid) {
      setInfos({ status: "error", message: "Invalid email address", timeout: 3000 });
      return;
    }

    axios // this specific API endpoint is not available in the wrapper
      .post(`${BASE_API_PATH}/api_credentials/send_portal_url_reminder`, {
        api_credential: {
          email,
          base_url: process.env.REACT_APP_BASE_PATH,
        },
      })
      .then(() => {
        setInfos({ status: "info", message: "Email sent!", timeout: 3000 });
      })
      .catch((e) => {
        setInfos({ status: "error", message: formatError(e) });
      });
  };

  const formControlStyles = {
    margin: (theme) => theme.spacing(1, 0),
    width: "100%",
  };

  return (
    <Box
      sx={{
        backgroundColor: colors.lightGrey1,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Card
        sx={{
          width: isMobileView ? "100%" : "500px",
          marginTop: "100px",
          padding: theme.spacing(5),
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        {" "}
        <InfoAlertContainer displayLimit={3} />
        {inFos && (
          <Alert sx={{ marginY: 1 }} severity={inFos.status}>
            {inFos.message}
          </Alert>
        )}
        <Typography variant="h1" marginBottom={theme.spacing(2)}>
          Enter your email address below to receive a portal URL for your organization.
        </Typography>
        <FormControl variant="standard" sx={formControlStyles}>
          <InputLabel htmlFor="name">Email</InputLabel>
          <Input
            id="name"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </FormControl>
        <ContainedButton onClick={onClickHandler}>Send</ContainedButton>
      </Card>
    </Box>
  );
};

export default ForgotUrl;
