import { Integrations } from "@sentry/tracing";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import React, { useEffect, createContext } from "react";

import { ReactChildren } from "../types/react";

export const MonitoringContext = createContext({});

export const MonitoringProvider = ({ children }: ReactChildren): JSX.Element => {
  useEffect(() => {
    Sentry.init({
      dsn: "https://aaa8d10fac3f4840bf58ed60f8189769@o851356.ingest.sentry.io/6058621",
      integrations: [
        new Integrations.BrowserTracing(),
        new Sentry.Replay({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      tracesSampleRate: 1.0,
      environment: process.env.REACT_APP_ENV || "local",
      release: process.env.REACT_APP_KARIKO_VERSION || process.env.npm_package_version,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
    });

    datadogRum.init({
      applicationId: "97b90c33-9df2-4c54-a58d-4b4a8a87b4ee",
      clientToken: "pub0c4f710f26787b0916458785022c118c",
      site: "datadoghq.com",
      service: "kariko",
      env: process.env.REACT_APP_ENV || "local",
      version: process.env.REACT_APP_KARIKO_VERSION || process.env.npm_package_version,
      sampleRate: 100,
      // @ts-ignore
      sessionReplaySampleRate: Number(process.env.REACT_APP_DATADOG_SESSION_REPLAY_SAMPLING || 0),
      trackInteractions: true,
      trackFrustrations: true,
      trackResources: true,
      trackLongTasks: true,
      allowedTracingOrigins: [/https:\/\/.*\.capablehealth\.com/],
    });

    if (Number(process.env.REACT_APP_DATADOG_SESSION_REPLAY_SAMPLING || 0) > 0) {
      datadogRum.startSessionReplayRecording();
    }
  }, []);

  return <MonitoringContext.Provider value={{}}>{children}</MonitoringContext.Provider>;
};
