/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { InsuranceClaimsInsuranceClaimAddressAttributes } from "./InsuranceClaimsInsuranceClaimAddressAttributes";
import { InsuranceClaimsidInsuranceClaimInsuranceClaimLinesAttributes } from "./InsuranceClaimsidInsuranceClaimInsuranceClaimLinesAttributes";

/**
 * The InsuranceClaimsidInsuranceClaim model module.
 * @module model/InsuranceClaimsidInsuranceClaim
 * @version v0.3
 */
export class InsuranceClaimsidInsuranceClaim {
  /**
   * Constructs a new <code>InsuranceClaimsidInsuranceClaim</code>.
   * @alias module:model/InsuranceClaimsidInsuranceClaim
   * @class
   * @param id {String} Specify a insurance claim ID only when you want to update an existing insurance claim
   */
  constructor(id) {
    this.id = id;
  }

  /**
   * Constructs a <code>InsuranceClaimsidInsuranceClaim</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InsuranceClaimsidInsuranceClaim} obj Optional instance to populate.
   * @return {module:model/InsuranceClaimsidInsuranceClaim} The populated <code>InsuranceClaimsidInsuranceClaim</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InsuranceClaimsidInsuranceClaim();
      if (data.hasOwnProperty("id")) obj.id = ApiClient.convertToType(data["id"], "String");
      if (data.hasOwnProperty("place_of_service"))
        obj.placeOfService = ApiClient.convertToType(data["place_of_service"], "String");
      if (data.hasOwnProperty("address_attributes"))
        obj.addressAttributes = InsuranceClaimsInsuranceClaimAddressAttributes.constructFromObject(
          data["address_attributes"]
        );
      if (data.hasOwnProperty("insurance_claim_lines_attributes"))
        obj.insuranceClaimLinesAttributes = ApiClient.convertToType(
          data["insurance_claim_lines_attributes"],
          [InsuranceClaimsidInsuranceClaimInsuranceClaimLinesAttributes]
        );
    }
    return obj;
  }
}

/**
 * Specify a insurance claim ID only when you want to update an existing insurance claim
 * @member {String} id
 */
InsuranceClaimsidInsuranceClaim.prototype.id = undefined;

/**
 * Allowed values for the <code>placeOfService</code> property.
 * @enum {String}
 * @readonly
 */
InsuranceClaimsidInsuranceClaim.PlaceOfServiceEnum = {
  /**
   * value: "Pharmacy"
   * @const
   */
  pharmacy: "Pharmacy",

  /**
   * value: "Telehealth Provided Other than in Patient's Home"
   * @const
   */
  telehealthProvidedOtherThanInPatientsHome: "Telehealth Provided Other than in Patient's Home",

  /**
   * value: "School"
   * @const
   */
  school: "School",

  /**
   * value: "Homeless Shelter"
   * @const
   */
  homelessShelter: "Homeless Shelter",

  /**
   * value: "Indian Health Service Free-standing Facility"
   * @const
   */
  indianHealthServiceFreeStandingFacility: "Indian Health Service Free-standing Facility",

  /**
   * value: "Indian Health Service Provider-based Facility"
   * @const
   */
  indianHealthServiceProviderBasedFacility: "Indian Health Service Provider-based Facility",

  /**
   * value: "Tribal 638 Free-standing Facility"
   * @const
   */
  tribal638FreeStandingFacility: "Tribal 638 Free-standing Facility",

  /**
   * value: "Tribal 638 Provider-based Facility"
   * @const
   */
  tribal638ProviderBasedFacility: "Tribal 638 Provider-based Facility",

  /**
   * value: "Prison/Correction Facility"
   * @const
   */
  prisonCorrectionFacility: "Prison/Correction Facility",

  /**
   * value: "Telehealth Provided in Patient's Home"
   * @const
   */
  telehealthProvidedInPatientsHome: "Telehealth Provided in Patient's Home",

  /**
   * value: "Office"
   * @const
   */
  office: "Office",

  /**
   * value: "Home"
   * @const
   */
  home: "Home",

  /**
   * value: "Assisted Living Facility"
   * @const
   */
  assistedLivingFacility: "Assisted Living Facility",

  /**
   * value: "Group Home"
   * @const
   */
  groupHome: "Group Home",

  /**
   * value: "Mobile Unit"
   * @const
   */
  mobileUnit: "Mobile Unit",

  /**
   * value: "Temporary Lodging"
   * @const
   */
  temporaryLodging: "Temporary Lodging",

  /**
   * value: "Walk-in Retail Health Clinic"
   * @const
   */
  walkInRetailHealthClinic: "Walk-in Retail Health Clinic",

  /**
   * value: "Place of Employment-Worksite"
   * @const
   */
  placeOfEmploymentWorksite: "Place of Employment-Worksite",

  /**
   * value: "Off Campus-Outpatient Hospital"
   * @const
   */
  offCampusOutpatientHospital: "Off Campus-Outpatient Hospital",

  /**
   * value: "Urgent Care Facility"
   * @const
   */
  urgentCareFacility: "Urgent Care Facility",

  /**
   * value: "Inpatient Hospital"
   * @const
   */
  inpatientHospital: "Inpatient Hospital",

  /**
   * value: "On Campus-Outpatient Hospital"
   * @const
   */
  onCampusOutpatientHospital: "On Campus-Outpatient Hospital",

  /**
   * value: "Emergency Room-Hospital"
   * @const
   */
  emergencyRoomHospital: "Emergency Room-Hospital",

  /**
   * value: "Ambulatory Surgical Center"
   * @const
   */
  ambulatorySurgicalCenter: "Ambulatory Surgical Center",

  /**
   * value: "Birthing Center"
   * @const
   */
  birthingCenter: "Birthing Center",

  /**
   * value: "Military Treatment Facility"
   * @const
   */
  militaryTreatmentFacility: "Military Treatment Facility",

  /**
   * value: "Skilled Nursing Facility"
   * @const
   */
  skilledNursingFacility: "Skilled Nursing Facility",

  /**
   * value: "Nursing Facility"
   * @const
   */
  nursingFacility: "Nursing Facility",

  /**
   * value: "Custodial Care Facility"
   * @const
   */
  custodialCareFacility: "Custodial Care Facility",

  /**
   * value: "Hospice"
   * @const
   */
  hospice: "Hospice",

  /**
   * value: "Ambulance-Land"
   * @const
   */
  ambulanceLand: "Ambulance-Land",

  /**
   * value: "Ambulance-Air or Water"
   * @const
   */
  ambulanceAirOrWater: "Ambulance-Air or Water",

  /**
   * value: "Independent Clinic"
   * @const
   */
  independentClinic: "Independent Clinic",

  /**
   * value: "Federally Qualified Health Center"
   * @const
   */
  federallyQualifiedHealthCenter: "Federally Qualified Health Center",

  /**
   * value: "Inpatient Psychiatric Facility"
   * @const
   */
  inpatientPsychiatricFacility: "Inpatient Psychiatric Facility",

  /**
   * value: "Psychiatric Facility-Partial Hospitalization"
   * @const
   */
  psychiatricFacilityPartialHospitalization: "Psychiatric Facility-Partial Hospitalization",

  /**
   * value: "Community Mental Health Center"
   * @const
   */
  communityMentalHealthCenter: "Community Mental Health Center",

  /**
   * value: "Intermediate Care Facility/Individuals with Intellectual Disabilities"
   * @const
   */
  intermediateCareFacilityIndividualsWithIntellectualDisabilities:
    "Intermediate Care Facility/Individuals with Intellectual Disabilities",

  /**
   * value: "Residential Substance Abuse Treatment Facility"
   * @const
   */
  residentialSubstanceAbuseTreatmentFacility: "Residential Substance Abuse Treatment Facility",

  /**
   * value: "Psychiatric Residential Treatment Center"
   * @const
   */
  psychiatricResidentialTreatmentCenter: "Psychiatric Residential Treatment Center",

  /**
   * value: "Non-residential Substance Abuse Treatment Facility"
   * @const
   */
  nonResidentialSubstanceAbuseTreatmentFacility:
    "Non-residential Substance Abuse Treatment Facility",

  /**
   * value: "Non-residential Opioid Treatment Facility"
   * @const
   */
  nonResidentialOpioidTreatmentFacility: "Non-residential Opioid Treatment Facility",

  /**
   * value: "Mass Immunization Center"
   * @const
   */
  massImmunizationCenter: "Mass Immunization Center",

  /**
   * value: "Comprehensive Inpatient Rehabilitation Facility"
   * @const
   */
  comprehensiveInpatientRehabilitationFacility: "Comprehensive Inpatient Rehabilitation Facility",

  /**
   * value: "Comprehensive Outpatient Rehabilitation Facility"
   * @const
   */
  comprehensiveOutpatientRehabilitationFacility: "Comprehensive Outpatient Rehabilitation Facility",

  /**
   * value: "End-Stage Renal Disease Treatment"
   * @const
   */
  endStageRenalDiseaseTreatment: "End-Stage Renal Disease Treatment",

  /**
   * value: "Public Health Clinic"
   * @const
   */
  publicHealthClinic: "Public Health Clinic",

  /**
   * value: "Rural Health Clinic"
   * @const
   */
  ruralHealthClinic: "Rural Health Clinic",

  /**
   * value: "Independent Laboratory"
   * @const
   */
  independentLaboratory: "Independent Laboratory",

  /**
   * value: "Other Place of Service"
   * @const
   */
  otherPlaceOfService: "Other Place of Service",
};
/**
 * Place of service code
 * @member {module:model/InsuranceClaimsidInsuranceClaim.PlaceOfServiceEnum} placeOfService
 */
InsuranceClaimsidInsuranceClaim.prototype.placeOfService = undefined;

/**
 * @member {module:model/InsuranceClaimsInsuranceClaimAddressAttributes} addressAttributes
 */
InsuranceClaimsidInsuranceClaim.prototype.addressAttributes = undefined;

/**
 * @member {Array.<module:model/InsuranceClaimsidInsuranceClaimInsuranceClaimLinesAttributes>} insuranceClaimLinesAttributes
 */
InsuranceClaimsidInsuranceClaim.prototype.insuranceClaimLinesAttributes = undefined;
