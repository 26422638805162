/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { IntegrationsacuityAcuityIntegration } from "./IntegrationsacuityAcuityIntegration";

/**
 * The IntegrationsCandidBody model module.
 * @module model/IntegrationsCandidBody
 * @version v0.3
 */
export class IntegrationsCandidBody {
  /**
   * Constructs a new <code>IntegrationsCandidBody</code>.
   * @alias module:model/IntegrationsCandidBody
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>IntegrationsCandidBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/IntegrationsCandidBody} obj Optional instance to populate.
   * @return {module:model/IntegrationsCandidBody} The populated <code>IntegrationsCandidBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new IntegrationsCandidBody();
      if (data.hasOwnProperty("candid_integration"))
        obj.candidIntegration = IntegrationsacuityAcuityIntegration.constructFromObject(
          data["candid_integration"]
        );
    }
    return obj;
  }
}

/**
 * @member {module:model/IntegrationsacuityAcuityIntegration} candidIntegration
 */
IntegrationsCandidBody.prototype.candidIntegration = undefined;
