import React from "react";

import { SignIn } from "features/authentication/SignIn";
import { useAuthState } from "features/authentication/useAuthState";
import { useCurrentUser } from "features/authentication/useCurrentUser";
import { Box } from "@mui/material";
import LottieLoader from "react-lottie-loader";
import LottieData from "./components/styled/loading.json";
import { UserError } from "features/authentication/UserError";

const WithCurrentUser = ({ children }: { children: JSX.Element }) => {
  const { data: currentUser, isLoading, isError } = useCurrentUser();
  if (isError) {
    return <UserError />;
  }

  if (isLoading || !currentUser) {
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoader animationData={LottieData} />
      </Box>
    );
  }

  return children;
};

function AppAuthenticator({ children }: { children: JSX.Element }): JSX.Element {
  const { isSignedIn } = useAuthState();

  return isSignedIn ? (
    <WithCurrentUser>{children}</WithCurrentUser>
  ) : (
    <>
      <SignIn />
    </>
  );
}

export default AppAuthenticator;
