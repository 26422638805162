import { useQuery } from "@tanstack/react-query";

import { ApiParams } from "types/apiTypes";
import { ReactQueryKeys } from "constants/keys";
import { Tag } from "../models/Tag";
import api from "api/";

export const fetchTags = async (params: ApiParams = {}) => {
  const response = await api.client.Tag.list(params);

  if (!response.ok) {
    const error = `Error retrieving Tags ${response.error}`;
    console.error(error);
    throw new Error(error);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return response as { body: Tag[]; headers: any };
};

export const useTags = (params?: ApiParams) => {
  return useQuery([ReactQueryKeys.TAGS, params], () => fetchTags(params), {
    keepPreviousData: true,
  });
};
