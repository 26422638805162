/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The MedicationOrdersMedicationOrder model module.
 * @module model/MedicationOrdersMedicationOrder
 * @version v0.3
 */
export class MedicationOrdersMedicationOrder {
  /**
   * Constructs a new <code>MedicationOrdersMedicationOrder</code>.
   * @alias module:model/MedicationOrdersMedicationOrder
   * @class
   * @param patientId {String}
   */
  constructor(patientId) {
    this.patientId = patientId;
  }

  /**
   * Constructs a <code>MedicationOrdersMedicationOrder</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MedicationOrdersMedicationOrder} obj Optional instance to populate.
   * @return {module:model/MedicationOrdersMedicationOrder} The populated <code>MedicationOrdersMedicationOrder</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MedicationOrdersMedicationOrder();
      if (data.hasOwnProperty("place_order"))
        obj.placeOrder = ApiClient.convertToType(data["place_order"], "Boolean");
      if (data.hasOwnProperty("carrier"))
        obj.carrier = ApiClient.convertToType(data["carrier"], "String");
      if (data.hasOwnProperty("patient_id"))
        obj.patientId = ApiClient.convertToType(data["patient_id"], "String");
      if (data.hasOwnProperty("patient_medication_ids"))
        obj.patientMedicationIds = ApiClient.convertToType(data["patient_medication_ids"], [
          "String",
        ]);
      if (data.hasOwnProperty("product_ids"))
        obj.productIds = ApiClient.convertToType(data["product_ids"], ["String"]);
      if (data.hasOwnProperty("submission_id"))
        obj.submissionId = ApiClient.convertToType(data["submission_id"], "String");
    }
    return obj;
  }
}

/**
 * If `place_order` is true, the order will be placed right away.  If not, the order will be `draft`, or `processing` (if a case need to be created first) and will need to be confirmed later on by a subsequent call. If `true` while the order contains first time prescribed medications (through product_ids parameter), an error will be raised, as a case will need to be created and approved first.A patient token is allowed to create an order, but not to place it.
 * @member {Boolean} placeOrder
 * @default false
 */
MedicationOrdersMedicationOrder.prototype.placeOrder = false;

/**
 * Allowed values for the <code>carrier</code> property.
 * @enum {String}
 * @readonly
 */
MedicationOrdersMedicationOrder.CarrierEnum = {
  /**
   * value: "USPS"
   * @const
   */
  USPS: "USPS",

  /**
   * value: "UPS"
   * @const
   */
  UPS: "UPS",

  /**
   * value: "FedEx"
   * @const
   */
  fedEx: "FedEx",

  /**
   * value: "DHL"
   * @const
   */
  DHL: "DHL",
};
/**
 * @member {module:model/MedicationOrdersMedicationOrder.CarrierEnum} carrier
 * @default 'USPS'
 */
MedicationOrdersMedicationOrder.prototype.carrier = "USPS";

/**
 * @member {String} patientId
 */
MedicationOrdersMedicationOrder.prototype.patientId = undefined;

/**
 * @member {Array.<String>} patientMedicationIds
 */
MedicationOrdersMedicationOrder.prototype.patientMedicationIds = undefined;

/**
 * @member {Array.<String>} productIds
 */
MedicationOrdersMedicationOrder.prototype.productIds = undefined;

/**
 * When you create a medication order with non-OTC medications, a case will be created to ask for appropriated prescriptions. If you want to link questionnaire responses from a patient to the medication case, pass a Capable Health submission ID. The responses will appear in the MDI clinician portal.
 * @member {String} submissionId
 */
MedicationOrdersMedicationOrder.prototype.submissionId = undefined;
