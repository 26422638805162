import {
  EXTERNAL_PRODUCT_PROVIDERS,
  EXTERNAL_PRODUCT_TYPES,
  PROVIDER_PRODUCT_TYPE,
} from "../constants/products";

export const getProductType = (product) => {
  if (
    product.provider_name === EXTERNAL_PRODUCT_PROVIDERS.MDI &&
    product.provider_product_type === PROVIDER_PRODUCT_TYPE.COMPOUND
  ) {
    return EXTERNAL_PRODUCT_TYPES.MDI_COMPOUND;
  }
  if (
    product.provider_name === EXTERNAL_PRODUCT_PROVIDERS.MDI &&
    product.provider_product_type === PROVIDER_PRODUCT_TYPE.MEDICATION
  ) {
    return EXTERNAL_PRODUCT_TYPES.MDI_MEDICATION;
  }
  if (
    product.provider_name === EXTERNAL_PRODUCT_PROVIDERS.BUTTERFLY_LABS &&
    product.provider_product_type === PROVIDER_PRODUCT_TYPE.LAB_TEST
  ) {
    return EXTERNAL_PRODUCT_TYPES.BUTTERFLY_LABS_LAB_TEST;
  }
  if (
    product.provider_name === EXTERNAL_PRODUCT_PROVIDERS.IMAWARE &&
    product.provider_product_type === PROVIDER_PRODUCT_TYPE.LAB_TEST
  ) {
    return EXTERNAL_PRODUCT_TYPES.IMAWARE_LAB_TEST;
  }
  if (
    product.provider_name === EXTERNAL_PRODUCT_PROVIDERS.ASH_WELLNESS &&
    product.provider_product_type === PROVIDER_PRODUCT_TYPE.LAB_TEST
  ) {
    return EXTERNAL_PRODUCT_TYPES.ASH_WELLNESS_LAB_TEST;
  }
  if (
    product.provider_name === EXTERNAL_PRODUCT_PROVIDERS.CUREXA &&
    product.provider_product_type === PROVIDER_PRODUCT_TYPE.OTC_MEDICATION
  ) {
    return EXTERNAL_PRODUCT_TYPES.CUREXA_OTC;
  }
  if (
    product.provider_name === EXTERNAL_PRODUCT_PROVIDERS.MDI &&
    product.provider_product_type === PROVIDER_PRODUCT_TYPE.ENCOUNTER
  ) {
    return EXTERNAL_PRODUCT_TYPES.MDI_ENCOUNTER;
  }
  if (
    product.provider_name === EXTERNAL_PRODUCT_PROVIDERS.ACUITY &&
    product.provider_product_type === PROVIDER_PRODUCT_TYPE.APPOINTMENT
  ) {
    return EXTERNAL_PRODUCT_TYPES.ACUITY_APPOINTMENT;
  }
  return "";
};

export const getExternalProductOptions = (
  mdiMedicationOptions,
  mdiCompoundOptions,
  labTestOptions,
  hasCurexaIntegration,
  hasAshWellnessIntegration,
  hasAcuityIntegration
) => {
  const options = [{ label: "None", value: "" }];
  if (mdiCompoundOptions && Boolean(mdiCompoundOptions.length)) {
    options.push({ label: "MDI Compound", value: EXTERNAL_PRODUCT_TYPES.MDI_COMPOUND });
  }
  if (mdiMedicationOptions && Boolean(mdiMedicationOptions.length)) {
    options.push({ label: "MDI Medication", value: EXTERNAL_PRODUCT_TYPES.MDI_MEDICATION });
  }
  if (
    labTestOptions &&
    Boolean(labTestOptions.length) &&
    labTestOptions.find((o) => o.provider === EXTERNAL_PRODUCT_PROVIDERS.BUTTERFLY_LABS)
  ) {
    options.push({
      label: "ButterflyLabs Lab Test",
      value: EXTERNAL_PRODUCT_TYPES.BUTTERFLY_LABS_LAB_TEST,
    });
  }
  if (
    labTestOptions &&
    Boolean(labTestOptions.length) &&
    labTestOptions.find((o) => o.provider === EXTERNAL_PRODUCT_PROVIDERS.IMAWARE)
  ) {
    options.push({
      label: "Imaware Lab Test",
      value: EXTERNAL_PRODUCT_TYPES.IMAWARE_LAB_TEST,
    });
  }
  // TODO: Remove hard coded option and uncomment once lab test are available via api call to ash
  // if (
  //   labTestOptions &&
  //   Boolean(labTestOptions.length) &&
  //   labTestOptions.find((o) => o.provider === EXTERNAL_PRODUCT_PROVIDERS.ASH_WELLNESS)
  // ) {
  //   options.push({
  //     label: "Ash Wellness Lab Test",
  //     value: EXTERNAL_PRODUCT_TYPES.ASH_WELLNESS_LAB_TEST,
  //   });
  // }
  if (hasAshWellnessIntegration) {
    options.push({
      label: "Ash Wellness Lab Test",
      value: EXTERNAL_PRODUCT_TYPES.ASH_WELLNESS_LAB_TEST,
    });
  }
  if (hasAcuityIntegration) {
    options.push({
      label: "Acuity Appointment",
      value: EXTERNAL_PRODUCT_TYPES.ACUITY_APPOINTMENT,
    });
  }
  if (hasCurexaIntegration) {
    options.push({
      label: "Curexa OTC",
      value: EXTERNAL_PRODUCT_TYPES.CUREXA_OTC,
    });
  }
  options.push({
    label: "Consult service",
    value: "mdi_encounter",
  });

  return options;
};

export const convertRetailPriceToRetailPriceCents = (retailPrice: string): number | null => {
  if (retailPrice === undefined || retailPrice === "") {
    return null;
  } else {
    return Math.round(Number.parseFloat(retailPrice) * 100);
  }
};

export const convertRetailPriceCentsToRetailPrice = (retailCents: string) => {
  if (retailCents) {
    const parsedCents = Number.parseInt(retailCents) / 100;
    return parsedCents.toFixed(2);
  }
  return null;
};

export const convertRetailPricetoRetailPriceCents = (retailPrice: string) => {
  const chargeAmountInCents = (parseFloat(retailPrice) * 100).toFixed(2);
  return parseInt(chargeAmountInCents);
};
