// Generated by https://quicktype.io

export const ConversationTypes = {
  SMS: "sms",
  CHAT: "chat",
} as const;

export type ConversationType = (typeof ConversationTypes)[keyof typeof ConversationTypes];

export interface BarnardConversation {
  id: string;
  tenant_id: string;
  status: string;
  conversation_type: ConversationType;
  twilio_sid: string;
  created_at: string;
  updated_at: string;
  user_ids: string[];
  url: string;
}
