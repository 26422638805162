/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { LeadsLead } from "./LeadsLead";

/**
 * The LeadsBody model module.
 * @module model/LeadsBody
 * @version v0.3
 */
export class LeadsBody {
  /**
   * Constructs a new <code>LeadsBody</code>.
   * @alias module:model/LeadsBody
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>LeadsBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/LeadsBody} obj Optional instance to populate.
   * @return {module:model/LeadsBody} The populated <code>LeadsBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new LeadsBody();
      if (data.hasOwnProperty("lead")) obj.lead = LeadsLead.constructFromObject(data["lead"]);
    }
    return obj;
  }
}

/**
 * @member {module:model/LeadsLead} lead
 */
LeadsBody.prototype.lead = undefined;
