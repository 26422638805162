/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The IntegrationsphotonHealthPhotonHealthIntegration1 model module.
 * @module model/IntegrationsphotonHealthPhotonHealthIntegration1
 * @version v0.3
 */
export class IntegrationsphotonHealthPhotonHealthIntegration1 {
  /**
   * Constructs a new <code>IntegrationsphotonHealthPhotonHealthIntegration1</code>.
   * @alias module:model/IntegrationsphotonHealthPhotonHealthIntegration1
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>IntegrationsphotonHealthPhotonHealthIntegration1</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/IntegrationsphotonHealthPhotonHealthIntegration1} obj Optional instance to populate.
   * @return {module:model/IntegrationsphotonHealthPhotonHealthIntegration1} The populated <code>IntegrationsphotonHealthPhotonHealthIntegration1</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new IntegrationsphotonHealthPhotonHealthIntegration1();
      if (data.hasOwnProperty("m2m_client_id"))
        obj.m2mClientId = ApiClient.convertToType(data["m2m_client_id"], "String");
      if (data.hasOwnProperty("m2m_client_secret"))
        obj.m2mClientSecret = ApiClient.convertToType(data["m2m_client_secret"], "String");
      if (data.hasOwnProperty("spa_client_id"))
        obj.spaClientId = ApiClient.convertToType(data["spa_client_id"], "String");
      if (data.hasOwnProperty("spa_client_secret"))
        obj.spaClientSecret = ApiClient.convertToType(data["spa_client_secret"], "String");
      if (data.hasOwnProperty("organization_id"))
        obj.organizationId = ApiClient.convertToType(data["organization_id"], "String");
      if (data.hasOwnProperty("enabled"))
        obj.enabled = ApiClient.convertToType(data["enabled"], "Boolean");
    }
    return obj;
  }
}

/**
 * @member {String} m2mClientId
 */
IntegrationsphotonHealthPhotonHealthIntegration1.prototype.m2mClientId = undefined;

/**
 * @member {String} m2mClientSecret
 */
IntegrationsphotonHealthPhotonHealthIntegration1.prototype.m2mClientSecret = undefined;

/**
 * @member {String} spaClientId
 */
IntegrationsphotonHealthPhotonHealthIntegration1.prototype.spaClientId = undefined;

/**
 * @member {String} spaClientSecret
 */
IntegrationsphotonHealthPhotonHealthIntegration1.prototype.spaClientSecret = undefined;

/**
 * @member {String} organizationId
 */
IntegrationsphotonHealthPhotonHealthIntegration1.prototype.organizationId = undefined;

/**
 * @member {Boolean} enabled
 */
IntegrationsphotonHealthPhotonHealthIntegration1.prototype.enabled = undefined;
