import { Paper, Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React from "react";

import Section from "../Section";
import TextFieldInput from "../../styled/text-field-input/TextFieldInput";

interface NPIFormBody {
  id: string;
  number: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  address_id: string;
}

const NPIFormSection = ({
  npi,
  setNPI,
}: {
  npi: NPIFormBody;
  setNPI: (value) => void;
}): JSX.Element => {
  const handleOnChange = (field: string, value: string) => {
    setNPI((npi) => ({ ...npi, [field]: value }));
  };

  return (
    <Section title="Credentials" style={{ width: "100%" }}>
      <Paper
        sx={{
          padding: (theme) => theme.spacing(4),
          borderRadius: (theme) => theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              textAlign: "left",
              margin: (theme) => theme.spacing(1.5),
              gap: (theme) => theme.spacing(3),
            }}
          >
            <TextFieldInput
              label="NPI number"
              value={npi.number}
              onChange={(value) => {
                handleOnChange("number", value);
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              textAlign: "left",
              margin: (theme) => theme.spacing(1.5),
              gap: (theme) => theme.spacing(3),
            }}
          >
            <FormControl sx={{ width: "100%" }}>
              <TextFieldInput
                label="Rendering NPI address"
                value={npi.line1}
                onChange={(value) => {
                  handleOnChange("line1", value);
                }}
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <TextFieldInput
                label="Apt, Building, Floor (Optional)"
                value={npi.line2}
                onChange={(value) => {
                  handleOnChange("line2", value);
                }}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              textAlign: "left",
              margin: (theme) => theme.spacing(1.5),
              gap: (theme) => theme.spacing(3),
            }}
          >
            <FormControl sx={{ width: "100%" }}>
              <TextFieldInput
                label="City"
                value={npi.city}
                onChange={(value) => {
                  handleOnChange("city", value);
                }}
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <TextFieldInput
                label="State"
                value={npi.state}
                onChange={(value) => {
                  handleOnChange("state", value);
                }}
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <TextFieldInput
                label="Zip Code"
                value={npi.zip}
                onChange={(value) => {
                  handleOnChange("zip", value);
                }}
              />
            </FormControl>
          </Box>
        </Box>
      </Paper>
    </Section>
  );
};

export default NPIFormSection;
