/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { InsurancePoliciesInsurancePolicyPayerIdentificationAttributes } from "./InsurancePoliciesInsurancePolicyPayerIdentificationAttributes";
import { InsurancePoliciesidInsurancePolicySubscriberAttributes } from "./InsurancePoliciesidInsurancePolicySubscriberAttributes";

/**
 * The InsurancePoliciesidInsurancePolicy model module.
 * @module model/InsurancePoliciesidInsurancePolicy
 * @version v0.3
 */
export class InsurancePoliciesidInsurancePolicy {
  /**
   * Constructs a new <code>InsurancePoliciesidInsurancePolicy</code>.
   * @alias module:model/InsurancePoliciesidInsurancePolicy
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>InsurancePoliciesidInsurancePolicy</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InsurancePoliciesidInsurancePolicy} obj Optional instance to populate.
   * @return {module:model/InsurancePoliciesidInsurancePolicy} The populated <code>InsurancePoliciesidInsurancePolicy</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InsurancePoliciesidInsurancePolicy();
      if (data.hasOwnProperty("patient_number"))
        obj.patientNumber = ApiClient.convertToType(data["patient_number"], "String");
      if (data.hasOwnProperty("subscriber_attributes"))
        obj.subscriberAttributes =
          InsurancePoliciesidInsurancePolicySubscriberAttributes.constructFromObject(
            data["subscriber_attributes"]
          );
      if (data.hasOwnProperty("group_number"))
        obj.groupNumber = ApiClient.convertToType(data["group_number"], "String");
      if (data.hasOwnProperty("rx_bin"))
        obj.rxBin = ApiClient.convertToType(data["rx_bin"], "String");
      if (data.hasOwnProperty("rx_pcn"))
        obj.rxPcn = ApiClient.convertToType(data["rx_pcn"], "String");
      if (data.hasOwnProperty("plan_name"))
        obj.planName = ApiClient.convertToType(data["plan_name"], "String");
      if (data.hasOwnProperty("plan_type"))
        obj.planType = ApiClient.convertToType(data["plan_type"], "String");
      if (data.hasOwnProperty("active"))
        obj.active = ApiClient.convertToType(data["active"], "Boolean");
      if (data.hasOwnProperty("enabled"))
        obj.enabled = ApiClient.convertToType(data["enabled"], "Boolean");
      if (data.hasOwnProperty("coordination_of_benefits"))
        obj.coordinationOfBenefits = ApiClient.convertToType(
          data["coordination_of_benefits"],
          "String"
        );
      if (data.hasOwnProperty("payer_identification_attributes"))
        obj.payerIdentificationAttributes =
          InsurancePoliciesInsurancePolicyPayerIdentificationAttributes.constructFromObject(
            data["payer_identification_attributes"]
          );
    }
    return obj;
  }
}

/**
 * Sometimes referred to as ID, this is the number used byinsurance companies to identify the patient
 * @member {String} patientNumber
 */
InsurancePoliciesidInsurancePolicy.prototype.patientNumber = undefined;

/**
 * @member {module:model/InsurancePoliciesidInsurancePolicySubscriberAttributes} subscriberAttributes
 */
InsurancePoliciesidInsurancePolicy.prototype.subscriberAttributes = undefined;

/**
 * An identifier used by insurance company to identify the employer or sponsor of a policy
 * @member {String} groupNumber
 */
InsurancePoliciesidInsurancePolicy.prototype.groupNumber = undefined;

/**
 * six-digit number used by insurance companies to process electronic pharmacy clams
 * @member {String} rxBin
 */
InsurancePoliciesidInsurancePolicy.prototype.rxBin = undefined;

/**
 * a secondary identifier used to route pharmacy claims
 * @member {String} rxPcn
 */
InsurancePoliciesidInsurancePolicy.prototype.rxPcn = undefined;

/**
 * The name of the plan on the patient insurance card, sometimes referred to as plan type
 * @member {String} planName
 */
InsurancePoliciesidInsurancePolicy.prototype.planName = undefined;

/**
 * The id of the plan on the patient insurance card, sometimes referred
 * @member {String} planType
 */
InsurancePoliciesidInsurancePolicy.prototype.planType = undefined;

/**
 * A flag representing if this is an active policy, defaults to true
 * @member {Boolean} active
 */
InsurancePoliciesidInsurancePolicy.prototype.active = undefined;

/**
 * A flag representing if this is an enabled policy, defaults to true
 * @member {Boolean} enabled
 */
InsurancePoliciesidInsurancePolicy.prototype.enabled = undefined;

/**
 * Allowed values for the <code>coordinationOfBenefits</code> property.
 * @enum {String}
 * @readonly
 */
InsurancePoliciesidInsurancePolicy.CoordinationOfBenefitsEnum = {
  /**
   * value: "primary"
   * @const
   */
  primary: "primary",

  /**
   * value: "secondary"
   * @const
   */
  secondary: "secondary",
};
/**
 * the coordination of benefits flag. Only one of each is allowed across all active policies
 * @member {module:model/InsurancePoliciesidInsurancePolicy.CoordinationOfBenefitsEnum} coordinationOfBenefits
 */
InsurancePoliciesidInsurancePolicy.prototype.coordinationOfBenefits = undefined;

/**
 * @member {module:model/InsurancePoliciesInsurancePolicyPayerIdentificationAttributes} payerIdentificationAttributes
 */
InsurancePoliciesidInsurancePolicy.prototype.payerIdentificationAttributes = undefined;
