/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The IntegrationsshopifyShopifyIntegration model module.
 * @module model/IntegrationsshopifyShopifyIntegration
 * @version v0.3
 */
export class IntegrationsshopifyShopifyIntegration {
  /**
   * Constructs a new <code>IntegrationsshopifyShopifyIntegration</code>.
   * @alias module:model/IntegrationsshopifyShopifyIntegration
   * @class
   * @param credentialsClientSecret {String}
   * @param credentialsClientToken {String}
   * @param apiUrl {String}
   */
  constructor(credentialsClientSecret, credentialsClientToken, apiUrl) {
    this.credentialsClientSecret = credentialsClientSecret;
    this.credentialsClientToken = credentialsClientToken;
    this.apiUrl = apiUrl;
  }

  /**
   * Constructs a <code>IntegrationsshopifyShopifyIntegration</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/IntegrationsshopifyShopifyIntegration} obj Optional instance to populate.
   * @return {module:model/IntegrationsshopifyShopifyIntegration} The populated <code>IntegrationsshopifyShopifyIntegration</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new IntegrationsshopifyShopifyIntegration();
      if (data.hasOwnProperty("credentials_client_secret"))
        obj.credentialsClientSecret = ApiClient.convertToType(
          data["credentials_client_secret"],
          "String"
        );
      if (data.hasOwnProperty("credentials_client_token"))
        obj.credentialsClientToken = ApiClient.convertToType(
          data["credentials_client_token"],
          "String"
        );
      if (data.hasOwnProperty("api_url"))
        obj.apiUrl = ApiClient.convertToType(data["api_url"], "String");
      if (data.hasOwnProperty("enabled"))
        obj.enabled = ApiClient.convertToType(data["enabled"], "Boolean");
    }
    return obj;
  }
}

/**
 * @member {String} credentialsClientSecret
 */
IntegrationsshopifyShopifyIntegration.prototype.credentialsClientSecret = undefined;

/**
 * @member {String} credentialsClientToken
 */
IntegrationsshopifyShopifyIntegration.prototype.credentialsClientToken = undefined;

/**
 * @member {String} apiUrl
 */
IntegrationsshopifyShopifyIntegration.prototype.apiUrl = undefined;

/**
 * @member {Boolean} enabled
 * @default true
 */
IntegrationsshopifyShopifyIntegration.prototype.enabled = true;
