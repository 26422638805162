import Box from "@mui/material/Box";
import React from "react";
import Modal from "components/styled/modal/Modal";

import { Patient, PatientRelatedPerson } from "models/Patient/Patient.types";

const AddPractitionerModal = ({
  onClose,
  open,
  patient,
  relationship,
  onChange,
}: {
  patient: Patient;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  open: boolean;
  relationship: PatientRelatedPerson;
  onChange(relationship): void;
}): JSX.Element => {
  const practitionerName = `${relationship.related_person.first_name} ${relationship.related_person.last_name}`;
  const patientName = `${patient.first_name} ${patient.last_name}`;

  return (
    <Modal
      onClose={onClose}
      open={open}
      title="Unassign practitioner"
      primaryButtonText={"Yes, unassign"}
      onPrimaryButtonClick={() => onChange(relationship)}
      onSecondaryButtonClick={onClose}
    >
      <Box
        sx={{ marginBottom: (theme) => theme.spacing(5), paddingY: (theme) => theme.spacing(0, 2) }}
      >
        Are you sure you want to <b>unassign {practitionerName}</b> from {patientName}’s Care Team?
      </Box>
    </Modal>
  );
};

export default AddPractitionerModal;
