import { useLDClient } from "launchdarkly-react-client-sdk";
import Backdrop from "@mui/material/Backdrop";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuRounded from "@mui/icons-material/MenuRounded";
import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";

import { AuthContext } from "../../contexts/auth";
import { CapableLogoShort } from "../../components/CapableIcons";
import { colors } from "../../styles/colors";
import { MenuItem } from "@mui/material";
import { StyledCloseButton } from "../../components/styled/Buttons";
import { theme } from "../../styles/theme";
import Avatar from "../../components/styled/avatar/Avatar";
import SideNavigation from "./SideNavigation";
import { useCurrentUser } from "features/authentication/useCurrentUser";
import { AddInfoContext } from "contexts/info";
import { formatError } from "utils/strings";

const TopNavigation = ({
  isMobileView,
  style,
}: {
  isMobileView: boolean;
  style: React.CSSProperties;
}): JSX.Element => {
  const { data: currentUser, isLoading } = useCurrentUser();

  const { isLdUserIdentified, signOut } = useContext(AuthContext);
  const addInfoAlert = useContext(AddInfoContext);
  const ldClient = useLDClient();
  const [showCapableLogo, setShowCapableLogo] = useState(false);

  const logOut = async () => {
    signOut().catch((e) => {
      addInfoAlert({ status: "error", message: formatError(e) });
    });
  };

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let linkToProfile = "";
  let linkToProfileLabel = "";
  switch (currentUser?.userType) {
    case "Practitioner":
      linkToProfile = `/team/practitioner/${currentUser.id}`;
      linkToProfileLabel = "Edit profile";
      break;
    case "TenantAdmin":
      linkToProfile = `/team/admin/${currentUser.id}`;
      linkToProfileLabel = "Go to profile";
      break;
  }

  useEffect(() => {
    if (ldClient && isLdUserIdentified) {
      const showLogo = ldClient.variation("show-capable-logo", false);
      setShowCapableLogo(showLogo);
    }
  }, [isLdUserIdentified, ldClient]);

  if (isLoading) {
    return <></>;
  }
  return (
    <Box
      sx={{
        padding: theme.spacing(2),
        backgroundColor: colors.white,
        position: "fixed",
        top: 0,
        zIndex: (theme) => theme.zIndex.appBar,
        ...style,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "nowrap",
          width: "100%",
        }}
      >
        <span>
          {isMobileView ? (
            <>
              <span style={{ display: "flex", alignItems: "center" }}>
                <Button
                  sx={{
                    alignItems: "start",
                    minWidth: "1rem",
                    color: colors.darkGrey,
                    paddingTop: "5px",
                  }}
                  onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
                >
                  <MenuRounded height="1.2rem" />
                </Button>
                {showCapableLogo && (
                  <span style={{ margin: "0px 1rem" }} data-sentry-unmask>
                    <CapableLogoShort height="2rem" />
                  </span>
                )}
              </span>

              <Backdrop
                sx={{
                  backgroundColor: `${colors.darkBlue}E6`,
                  color: colors.white,
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                  justifyContent: "left",
                }}
                open={isMobileNavOpen}
              >
                <SideNavigation width={"85%"} isMobileView />
                <Typography align="center" variant="h1">
                  <StyledCloseButton onClick={() => setIsMobileNavOpen(false)}>
                    <CloseIcon />
                  </StyledCloseButton>
                </Typography>
              </Backdrop>
            </>
          ) : null}
        </span>

        <span style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={handleClick}
            sx={{ padding: 0, ":hover": { backgroundColor: colors.spring } }}
          >
            <Avatar user={currentUser} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
            sx={{ border: `1px solid ${colors.lightGrey4}`, marginTop: theme.spacing(1) }}
            data-sentry-unmask
          >
            <MenuItem onClick={logOut} sx={{ ":hover": { backgroundColor: colors.spring } }}>
              Sign Out
            </MenuItem>
            {linkToProfile && (
              <MenuItem sx={{ ":hover": { backgroundColor: colors.spring } }}>
                <Link
                  to={linkToProfile}
                  sx={{
                    color: (theme) => theme.palette.text.primary,
                    textDecoration: "none",
                    ":hover": { fontWeight: "bold" },
                  }}
                  component={RouterLink}
                >
                  {linkToProfileLabel}
                </Link>
              </MenuItem>
            )}
          </Menu>
        </span>
      </Box>
    </Box>
  );
};

export default TopNavigation;
