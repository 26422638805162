import { Alert, Collapse, Snackbar } from "@mui/material";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import React, { useContext, useState } from "react";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";

import { statusColors, statusTextColors } from "../styles/colors";
import { Info, RemoveInfoContext } from "../contexts/info";

interface InfoAlertBannerProps extends Info {
  id: string;
}

const InfoAlertBanner = ({
  id,
  status,
  message,
  isToast = false,
  enableAutoHide = true,
}: InfoAlertBannerProps): JSX.Element => {
  const removeInfoAlert = useContext(RemoveInfoContext);
  const [open, setOpen] = useState(true);

  const getAlert = () => {
    return (
      <Alert
        severity={status}
        iconMapping={{
          success: <CheckCircleOutline sx={{ color: statusTextColors[status] }} />,
          info: <InfoOutlined sx={{ color: statusTextColors[status] }} />,
          warning: <WarningAmberOutlined sx={{ color: statusTextColors[status] }} />,
          error: <ErrorOutline sx={{ color: statusTextColors[status] }} />,
        }}
        onClose={() => setOpen(false)}
        sx={{
          margin: (theme) => theme.spacing(0, 0, 2, 0),
          opacity: ".95",
          borderRadius: (theme) => theme.spacing(1),
          backgroundColor: statusColors[status],
          color: statusTextColors[status],
        }}
      >
        {message}
      </Alert>
    );
  };

  return isToast ? (
    <Snackbar
      open={open}
      autoHideDuration={enableAutoHide ? 6000 : null}
      onClose={() => removeInfoAlert(id)}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      {getAlert()}
    </Snackbar>
  ) : (
    <Collapse in={open} timeout={300} onExited={() => removeInfoAlert(id)}>
      {getAlert()}
    </Collapse>
  );
};

export default InfoAlertBanner;
