import { CapableClient } from "@capable-health/client";
import Auth from "@capable-health/capable-auth-sdk";

export const BASE_API_PATH: string =
  process.env["REACT_APP_API_PATH"] || "https://api.dev.capablehealth.com";

export const capableClient = new CapableClient({
  TOKEN: () => Auth.user.getAccessToken(),
  BASE: BASE_API_PATH,
});
