/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { InsuranceClaimsInsuranceClaim } from "./InsuranceClaimsInsuranceClaim";

/**
 * The InsuranceClaimsBody model module.
 * @module model/InsuranceClaimsBody
 * @version v0.3
 */
export class InsuranceClaimsBody {
  /**
   * Constructs a new <code>InsuranceClaimsBody</code>.
   * @alias module:model/InsuranceClaimsBody
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>InsuranceClaimsBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InsuranceClaimsBody} obj Optional instance to populate.
   * @return {module:model/InsuranceClaimsBody} The populated <code>InsuranceClaimsBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InsuranceClaimsBody();
      if (data.hasOwnProperty("insurance_claim"))
        obj.insuranceClaim = InsuranceClaimsInsuranceClaim.constructFromObject(
          data["insurance_claim"]
        );
    }
    return obj;
  }
}

/**
 * @member {module:model/InsuranceClaimsInsuranceClaim} insuranceClaim
 */
InsuranceClaimsBody.prototype.insuranceClaim = undefined;
