import { Patient, Phone } from "models/Patient/Patient.types";
import { capitalizeFirstOnly } from "utils/strings";

export interface PatientRelatedPerson {
  archived_at: string;
  created_at: string;
  id: string;
  patient: Patient;
  related_person: RelatedPerson;
  related_person_id: string;
  related_person_type: string;
  relationship_type: string;
  updated_at: string;
  url: string;
}

export interface RelatedPerson {
  age: number;
  birth_date: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  middle_name: string | null;
  phone_number: Phone;
  type: string;
}

export const RelationshipTypes = [
  "Emergency Contact",
  "Employer",
  "Next-of-Kin",
  "Other",
  "Unknown",
  "family member",
  "child",
  "adopted child",
  "adopted daughter",
  "adopted son",
  "foster child",
  "foster daughter",
  "foster son",
  "daughter",
  "natural daughter",
  "stepdaughter",
  "natural child",
  "natural son",
  "son",
  "stepson",
  "step child",
  "extended family member",
  "aunt",
  "maternal aunt",
  "paternal aunt",
  "cousin",
  "maternal cousin",
  "paternal cousin",
  "great grandparent",
  "great grandfather",
  "maternal great-grandfather",
  "paternal great-grandfather",
  "great grandmother",
  "maternal great-grandmother",
  "paternal great-grandmother",
  "maternal great-grandparent",
  "paternal great-grandparent",
  "grandchild",
  "granddaughter",
  "grandson",
  "grandparent",
  "grandfather",
  "maternal grandfather",
  "paternal grandfather",
  "grandmother",
  "maternal grandmother",
  "paternal grandmother",
  "maternal grandparent",
  "paternal grandparent",
  "inlaw",
  "child-in-law",
  "daughter in-law",
  "son in-law",
  "parent in-law",
  "father-in-law",
  "mother-in-law",
  "sibling in-law",
  "brother-in-law",
  "sister-in-law",
  "niece/nephew",
  "nephew",
  "niece",
  "uncle",
  "maternal uncle",
  "paternal uncle",
  "parent",
  "adoptive parent",
  "adoptive father",
  "adoptive mother",
  "father",
  "foster father",
  "natural father",
  "natural father of fetus",
  "stepfather",
  "mother",
  "gestational mother",
  "foster mother",
  "natural mother",
  "natural mother of fetus",
  "stepmother",
  "natural parent",
  "foster parent",
  "step parent",
  "sibling",
  "brother",
  "half-brother",
  "natural brother",
  "twin brother",
  "fraternal twin brother",
  "identical twin brother",
  "stepbrother",
  "half-sibling",
  "half-sister",
  "natural sibling",
  "natural sister",
  "twin sister",
  "fraternal twin sister",
  "identical twin sister",
  "twin",
  "fraternal twin",
  "identical twin",
  "sister",
  "stepsister",
  "step sibling",
  "significant other",
  "domestic partner",
  "former spouse",
  "spouse",
  "husband",
  "wife",
  "unrelated friend",
  "neighbor",
  "self",
  "Roommate",
];

export const RelatedPersonOptions = RelationshipTypes.map((type) => ({
  value: type,
  label: capitalizeFirstOnly(type),
}));
