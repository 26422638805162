import { Box } from "@mui/system";
import React, { useContext } from "react";

import InfoAlertBanner from "../components/InfoAlertBanner";
import { InfoContext } from "../contexts/info";

const InfoAlertContainer = ({ displayLimit }: { displayLimit?: number }): JSX.Element => {
  const infoAlerts = useContext(InfoContext);

  const infoBanners = infoAlerts
    .slice(displayLimit ? -displayLimit : 0)
    .reverse()
    .map((infoAlert) => {
      const id = infoAlert.id;
      const info = infoAlert.info;
      return (
        <InfoAlertBanner
          key={id}
          id={id}
          status={info.status}
          message={info.message}
          isToast={info?.isToast}
          enableAutoHide={info?.enableAutoHide}
        />
      );
    });

  return (
    infoAlerts && (
      <Box
        sx={{
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {infoBanners}
      </Box>
    )
  );
};

export default InfoAlertContainer;
