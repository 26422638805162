import {
  administrativeGenderOptions,
  desigationOptions,
  planTypeOptions,
  relationshipToPatientOptions,
} from "components/forms/constants/details";
import { Box } from "@mui/material";
import { fetchPayerNames } from "components/patients/profile/insurance/formData/usePayerNameOptions.hooks";
import { FormDataType, FormInputTypes, FormSchemaTypes } from "components/styled/form/Form.types";
import { FrontInsuranceIcon, BackInsuranceIcon } from "components/CapableIcons";

export const getSubscriberForm = ({
  first_name = "",
  last_name = "",
  birth_date = "",
  administrative_gender = {},
  line1 = "",
  line2 = "",
  city = "",
  state = "",
  zip = "",
  relationshipToPatient = {},
}): FormDataType[] => {
  return [
    {
      name: "firstName",
      defaultValue: first_name,
      schema: FormSchemaTypes.Field("First name is required."),
      component: FormInputTypes.Field,
      breakpoints: {
        xs: 12,
        sm: 6,
      },
      props: {
        label: "Subscriber first name",
      },
    },
    {
      name: "lastName",
      defaultValue: last_name,
      schema: FormSchemaTypes.Field("Last name is required"),
      component: FormInputTypes.Field,
      breakpoints: {
        xs: 12,
        sm: 6,
      },
      props: {
        label: "Subscriber last name",
      },
    },
    {
      name: "birthDate",
      defaultValue: birth_date,
      schema: FormSchemaTypes.DatePicker(),
      component: FormInputTypes.DatePicker,
      breakpoints: {
        xs: 12,
        sm: 6,
      },
      props: {
        label: "Date of birth",
      },
    },
    {
      name: "gender",
      defaultValue: administrative_gender,
      schema: FormSchemaTypes.SearchableSelect("You must provide a gender"),
      component: FormInputTypes.SearchableSelect,
      breakpoints: {
        xs: 12,
        sm: 6,
      },
      props: {
        label: "Gender",
        inputID: "gender",
        options: administrativeGenderOptions,
      },
    },
    {
      name: "line1",
      defaultValue: line1,
      schema: FormSchemaTypes.Field("Street address is required."),
      component: FormInputTypes.Field,
      breakpoints: {
        xs: 12,
        sm: 6,
      },
      props: {
        label: "Street address",
      },
    },
    {
      name: "line2",
      defaultValue: line2,
      schema: FormSchemaTypes.Optional,
      component: FormInputTypes.Field,
      breakpoints: {
        xs: 12,
        sm: 6,
      },
      props: {
        label: "Apt, building, flood (optional)",
      },
    },
    {
      name: "city",
      defaultValue: city,
      schema: FormSchemaTypes.Field("City is required."),
      component: FormInputTypes.Field,
      breakpoints: {
        xs: 12,
        sm: 4,
      },
      props: {
        label: "City",
      },
    },
    {
      name: "state",
      defaultValue: state,
      schema: FormSchemaTypes.Field("State is required."),
      component: FormInputTypes.Field,
      breakpoints: {
        xs: 12,
        sm: 4,
      },
      props: {
        label: "State",
      },
    },
    {
      name: "zip",
      defaultValue: zip,
      schema: FormSchemaTypes.Field("Zip is required."),
      component: FormInputTypes.Field,
      breakpoints: {
        xs: 12,
        sm: 4,
      },
      props: {
        label: "Zip",
      },
    },
    {
      name: "relationshipToPatient",
      defaultValue: relationshipToPatient,
      schema: FormSchemaTypes.SearchableSelect("You must provide a relationship to patient"),
      component: FormInputTypes.SearchableSelect,
      breakpoints: {
        xs: 12,
        sm: 6,
      },
      props: {
        label: "Relationship to patient",
        inputID: "relationshipToPatient",
        options: relationshipToPatientOptions,
      },
    },
  ];
};

export const getPolicyForm = ({
  rx_bin = "",
  rx_pcn = "",
  payer_identification = {
    payer_name: "",
    payer_id: "",
  },
  plan_name = "",
  plan_type = {},
  coordination_of_benefits = {},
  group_number = "",
  patient_number = "",
  insurance_card_front_url = "",
  insurance_card_back_url = "",
}): FormDataType[] => {
  return [
    {
      name: "payerInfo",
      defaultValue: payer_identification.payer_id
        ? {
            value: payer_identification.payer_id,
            label: payer_identification.payer_name,
          }
        : null,
      schema: FormSchemaTypes.AsyncSearchSelect("Payer name is required."),
      component: FormInputTypes.AsyncSearchSelect,
      breakpoints: {
        xs: 12,
        sm: 12,
      },
      props: {
        field: "Payer name",
        apiHook: fetchPayerNames,
      },
    },
    {
      name: "planName",
      defaultValue: plan_name,
      schema: FormSchemaTypes.Field("Plan name is required."),
      component: FormInputTypes.Field,
      breakpoints: {
        xs: 12,
        sm: 6,
      },
      props: {
        label: "Plan name",
      },
    },
    {
      name: "planType",
      defaultValue: plan_type,
      schema: FormSchemaTypes.SearchableSelect("Plan type is required"),
      component: FormInputTypes.SearchableSelect,
      breakpoints: {
        xs: 12,
        sm: 6,
      },
      props: {
        label: "Plan type",
        options: planTypeOptions,
      },
    },
    {
      name: "groupNumber",
      defaultValue: group_number,
      schema: FormSchemaTypes.Field("Group number is required."),
      component: FormInputTypes.Field,
      breakpoints: {
        xs: 12,
        sm: 6,
      },
      props: {
        label: "Group Number",
      },
    },
    {
      name: "patientNumber",
      defaultValue: patient_number,
      schema: FormSchemaTypes.Field("Member Id is required"),
      component: FormInputTypes.Field,
      breakpoints: {
        xs: 12,
        sm: 6,
      },
      props: {
        label: "Member ID",
      },
    },
    {
      name: "rxBin",
      defaultValue: rx_bin,
      schema: FormSchemaTypes.Optional,
      component: FormInputTypes.Field,
      breakpoints: {
        xs: 12,
        sm: 6,
      },
      props: {
        label: "Rx bin",
      },
    },
    {
      name: "rxPcn",
      defaultValue: rx_pcn,
      schema: FormSchemaTypes.Optional,
      component: FormInputTypes.Field,
      breakpoints: {
        xs: 12,
        sm: 6,
      },
      props: {
        label: "Rx pcn",
      },
    },
    {
      name: "designation",
      defaultValue: coordination_of_benefits,
      schema: FormSchemaTypes.SearchableSelect("Designation is required."),
      component: FormInputTypes.SearchableSelect,
      breakpoints: {
        xs: 12,
        sm: 12,
      },
      props: {
        label: "Designation",
        options: desigationOptions,
      },
    },
    {
      name: "frontImage",
      defaultValue: insurance_card_front_url,
      schema: FormSchemaTypes.Optional,
      component: FormInputTypes.ImageUpload,
      breakpoints: {
        xs: 12,
        sm: 6,
      },
      props: {
        dropTextTitle: "Front of insurance card",
        dropTextBody: "Drag file here or click to select file",
        dropTextFoot: "Supported file types: JPG, JPEG, PNG",
        icon: <FrontInsuranceIcon />,
      },
    },
    {
      name: "backImage",
      defaultValue: insurance_card_back_url,
      schema: FormSchemaTypes.Optional,
      component: FormInputTypes.ImageUpload,
      breakpoints: {
        xs: 12,
        sm: 6,
      },
      props: {
        dropTextTitle: "Back of insurance card",
        dropTextBody: "Drag file here or click to select file",
        dropTextFoot: "Supported file types: JPG, JPEG, PNG",
        icon: <BackInsuranceIcon />,
      },
    },
  ];
};
