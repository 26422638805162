import { Alert, Box, Card } from "@mui/material";
import { CapableLogoLong } from "components/CapableIcons";
import { AuthContext } from "contexts/auth";
import { BreakpointContext } from "contexts/breakpoint";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { colors } from "styles/colors";
import { theme } from "styles/theme";

export const UserError = () => {
  const { isMobileView } = useContext(BreakpointContext);
  const { signOut } = useContext(AuthContext);

  useEffect(() => {
    const timeout = setTimeout(() => {
      signOut();
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  });
  return (
    <Box
      sx={{
        backgroundColor: colors.lightGrey1,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Card
        sx={{
          width: isMobileView ? "100%" : "500px",
          marginTop: "100px",
          padding: theme.spacing(5),
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        <Box sx={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
          <CapableLogoLong />
        </Box>
        <Alert sx={{ marginTop: 2 }} severity="error" variant="standard">
          This user is not allowed, you will be redirected to{" "}
          <Link to="/" onClick={() => signOut()}>
            Sign in page
          </Link>
        </Alert>
      </Card>
    </Box>
  );
};
