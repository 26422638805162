import {
  PermissionsViews,
  PermissionsViewsName,
  PermissionsViewsPermission,
} from "./PermissionsViews.type";

export const userCanView =
  (permissionsViews: PermissionsViews) =>
  ({
    permissionsViewsName,
    permissionsViewsPermission,
  }: {
    permissionsViewsName: PermissionsViewsName | PermissionsViewsName[];
    permissionsViewsPermission: PermissionsViewsPermission;
  }) => {
    if (typeof permissionsViewsName === "string") {
      return permissionsViews.includes(`${permissionsViewsName}.${permissionsViewsPermission}`);
    }

    return permissionsViews
      .flatMap((permissionsView) =>
        permissionsViewsName.map(
          (name) => `${name}.${permissionsViewsPermission}` === permissionsView
        )
      )
      .includes(true);
  };
