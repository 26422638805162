import React from "react";
import { Box } from "@mui/material";
import LottieLoader from "react-lottie-loader";
import LottieData from "./styled/loading.json";

export const FullScreenLoader = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LottieLoader animationData={LottieData} />
    </Box>
  );
};
