import React, { useState, useEffect, useContext, useRef } from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Dropzone, { DropzoneRef } from "react-dropzone";

import { AddInfoContext } from "contexts/info";
import { colors } from "styles/colors";
import { ImageIcon } from "components/CapableIcons";
import { ImageUploadProps } from "./ImageUpload.types";
import { OutlinedButton } from "../Buttons";
import { theme } from "styles/theme";

const dropzoneContainer = {
  flex: 1,
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  alignText: "center",
  padding: "20px",
  borderWidth: 1,
  borderRadius: 4,
  borderColor: colors.disabledGrey,
  borderStyle: "dashed",
  backgroundColor: alpha(colors.lapis, 0.05),
  color: colors.darkGrey,
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
  ":focus": {
    borderColor: colors.darkGrey,
  },
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row" as const,
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  flexDirection: "column" as const,
  alignItems: "center",
  marginBottom: 8,
  marginRight: 8,
  height: 200,
  maxWidth: 300,
  padding: 4,
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  maxWidth: 400,
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "contain" as const,
};

const ImageUpload = (props: ImageUploadProps): JSX.Element => {
  const addInfoAlert = useContext(AddInfoContext);
  const {
    onChange,
    maxFiles = 1,
    dropTextTitle,
    dropTextBody,
    dropTextFoot,
    value,
    icon,
    acceptedFileTypes = { "image/jpeg": [".jpeg", ".png"] },
  } = props;
  const dropZoneRef = useRef<DropzoneRef>(null);
  const [files, setFiles] = useState<any>(value ? [{ preview: value }] : []);

  const handleOnDrop = (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        addInfoAlert({ status: "error", message: err.message, isToast: true });
      });
    });

    const fileUrls = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setFiles(fileUrls);
    onChange(fileUrls);
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <Dropzone
      ref={dropZoneRef}
      onDrop={handleOnDrop}
      maxFiles={maxFiles}
      maxSize={25000000} // 25 MB
      accept={acceptedFileTypes}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()} style={dropzoneContainer}>
            <input {...getInputProps()} />
            {!thumbs.length && (
              <Box
                sx={{
                  padding: (theme) => theme.spacing(2),
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  height: "208px",
                }}
              >
                {icon ?? <ImageIcon />}
                <p>
                  <Box>
                    {dropTextTitle && (
                      <Box sx={{ paddingTop: "0.5rem", fontWeight: "bold" }}>{dropTextTitle}</Box>
                    )}
                    {dropTextBody && <Box sx={{ paddingTop: "1.5rem" }}>{dropTextBody}</Box>}
                    {dropTextFoot && (
                      <Box sx={{ paddingTop: "0.5rem", fontSize: "0.8rem" }}>{dropTextFoot}</Box>
                    )}
                  </Box>
                </p>
              </Box>
            )}
            <aside style={thumbsContainer}>{thumbs}</aside>
            {maxFiles && maxFiles !== 1 && !thumbs.length && (
              <em>({maxFiles} files are the maximum number of files you can drop here)</em>
            )}
          </div>
          {thumbs.length > 0 && (
            <Box sx={{ textAlign: "center" }}>
              <OutlinedButton
                style={{ marginTop: theme.spacing(2) }}
                onClick={() => {
                  dropZoneRef?.current?.open();
                }}
              >
                Change image
              </OutlinedButton>
            </Box>
          )}
        </section>
      )}
    </Dropzone>
  );
};

export default ImageUpload;
