import Box from "@mui/material/Box";
import React, { useState, useEffect, useContext } from "react";

import { ActiveCancelButton } from "components/CapableIcons";
import { AddInfoContext } from "contexts/info";
import { colors } from "styles/colors";
import { formatError } from "../../utils/strings";
import { OutlinedButton } from "../styled/Buttons";
import { Patient, PatientRelatedPerson } from "models/Patient/Patient.types";
import { theme } from "styles/theme";
import { useAPIList, API_Type } from "../../hooks/listAll";
import AddPractitionerModal from "components/modals/AddPractitionerModal";
import api from "api";
import Avatar from "components/styled/avatar/Avatar";
import InlinePaginatedTable from "../styled/InlinePaginatedTable";
import RemovePractitionerModal from "components/modals/RemovePractitionerModal";
import Section from "./Section";
import { Practitioner } from "models/Practitioner/Practitioner.types";
import { UserTypes } from "models/User/User.types";

const PatientCareTeam = ({ patient }: { patient: Patient }): JSX.Element => {
  const addInfoAlert = useContext(AddInfoContext);

  const [practitioners] = useAPIList(API_Type.Practitioner);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [addModal, setAddModal] = useState<boolean>(false);
  const [showRemovePractitionerModal, setShowRemovePractitionerModal] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState<any[]>([]);
  const [careTeam, setCareTeam] = useState<PatientRelatedPerson[]>([]);
  const [relatedPractitioners, setRelatedPractitioners] = useState<Practitioner[]>([]);

  useEffect(() => {
    (async () => {
      const careTeamResponse = await api.client.PatientRelatedPerson.list({
        page: 1,
        size: 10,
        filters: [
          JSON.stringify({
            field: "relationship_type",
            operator: "eq",
            value: UserTypes.Practitioner,
          }),
          JSON.stringify({
            field: "patient_id",
            operator: "eq",
            value: patient.id,
          }),
        ],
      }).catch((e) => {
        addInfoAlert({ status: "error", message: formatError(e) });
      });
      if (careTeamResponse && careTeamResponse.body) {
        setTotalRowCount(parseInt(careTeamResponse.headers["total-count"], 10));
        setCareTeam(careTeamResponse.body);
        const practitioners = careTeamResponse.body.map((team) => team.related_person);
        setRelatedPractitioners(practitioners);
      }
    })();
  }, [addInfoAlert, patient.id, addModal, showRemovePractitionerModal]);

  const careTeamColumns = [
    {
      field: "first_name",
      headerName: "Name",
      flex: 1,
      minWidth: 300,
      renderCell: (params) => {
        if (params.row && relatedPractitioners.length) {
          const currentPractitionerID = params?.row?.id;
          const practitioner = relatedPractitioners.find(
            (pract) => pract.id === currentPractitionerID
          );
          return practitioner ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              <Avatar
                user={practitioner}
                color={theme.palette.primary.main}
                style={{
                  margin: (theme) => theme.spacing(1.5),
                }}
              />
              {getFullName(practitioner)}
            </Box>
          ) : (
            <></>
          );
        }
        return null;
      },
      sortable: false,
    },
    {
      // field: "id",
      maxWidth: 100,
      renderCell: (params) => {
        const currentPractitionerID = params?.row?.id;
        const practitionerRelationship = careTeam.find(
          (team) => team.related_person.id === currentPractitionerID
        );
        return (
          practitionerRelationship && (
            <>
              <RemovePractitionerModal
                onClose={() => setShowRemovePractitionerModal(false)}
                open={showRemovePractitionerModal}
                relationship={practitionerRelationship}
                patient={patient}
                onChange={removePractitioner}
              />
              <OutlinedButton
                style={{
                  backgroundColor: "white",
                  borderRadius: "4px",
                  borderColor: colors.lightGrey3,
                  cursor: "pointer",
                }}
                onClick={() => setShowRemovePractitionerModal(true)}
              >
                <ActiveCancelButton />
              </OutlinedButton>
            </>
          )
        );
      },
      sortable: false,
    },
  ];

  const getFullName = (person: any) => {
    return person.first_name && person.last_name
      ? `${person.first_name} ${person.last_name}`
      : `${person.email}`;
  };

  useEffect(() => {
    (async () => {
      const careTeamResponse = await api.client.PatientRelatedPerson.list({
        page: page,
        size: 10,
        filters: [
          JSON.stringify({
            field: "relationship_type",
            operator: "eq",
            value: UserTypes.Practitioner,
          }),
          JSON.stringify({
            field: "patient_id",
            operator: "eq",
            value: patient.id,
          }),
        ],
      }).catch((e) => {
        addInfoAlert({ status: "error", message: formatError(e) });
      });

      if (careTeamResponse?.body) {
        const practitioners = careTeamResponse.body.map((team) => team.related_person);
        setRows(practitioners);
      }
    })();
  }, [addInfoAlert, page, patient.id, addModal, showRemovePractitionerModal]);

  const addPractitionerToCareTeam = async (practitioner) => {
    if (practitioner) {
      const opts = {
        body: {
          patient_related_person: {
            relationship_type: UserTypes.Practitioner,
            patient_id: patient.id,
            related_person_id: practitioner.id,
          },
        },
      };
      const addPractitionerResponse = await api.client.PatientRelatedPerson.create(opts).catch(
        (e) => {
          addInfoAlert({ status: "error", message: formatError(e) });
        }
      );
      if (addPractitionerResponse) {
        setAddModal(false);
        addInfoAlert({
          status: "success",
          message: "Practitioner assigned!",
          isToast: true,
        });
      }
    }
  };

  const removePractitioner = (relationship) => {
    const removeResponse = api.client.PatientRelatedPerson.delete(relationship.id).catch((e) => {
      addInfoAlert({ status: "error", message: formatError(e), isToast: true });
      setShowRemovePractitionerModal(false);
    });
    if (removeResponse) {
      const practitionerName = `${relationship.related_person.first_name} ${relationship.related_person.last_name}`;
      addInfoAlert({
        status: "success",
        message: `${practitionerName} was successfully removed`,
        isToast: true,
      });
      setShowRemovePractitionerModal(false);
    }
  };

  const CreateButton = (
    <>
      <AddPractitionerModal
        onClose={() => setAddModal(false)}
        onChange={addPractitionerToCareTeam}
        open={addModal}
        patient={patient}
        practitioners={practitioners}
      />
      <OutlinedButton onClick={() => setAddModal(true)}>Assign practitioner</OutlinedButton>
    </>
  );

  return (
    <Section title="Care Team" actionButtons={[CreateButton]}>
      {rows && Boolean(rows.length) ? (
        <InlinePaginatedTable
          rows={rows}
          columns={careTeamColumns}
          onPageChange={(pageIndex) => setPage(pageIndex + 1)}
          rowCount={totalRowCount}
        />
      ) : (
        "No practitioners have been assigned yet."
      )}
    </Section>
  );
};

export default PatientCareTeam;
