import React from "react";
import Box from "@mui/material/Box";

import { colors } from "../../styles/colors";

const RightSidebar = ({
  widthOfSidebar,
  sidebarInfo: SidebarInfo,
}: {
  widthOfSidebar: string;
  sidebarInfo: any;
}): JSX.Element => {
  return (
    <Box
      sx={{
        width: widthOfSidebar,
        minWidth: widthOfSidebar,
        backgroundColor: colors.white,
        padding: (theme) => theme.spacing(2),
      }}
    >
      <SidebarInfo />
    </Box>
  );
};

export default RightSidebar;
