/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { ProductsProduct } from "./ProductsProduct";

/**
 * The ProductsBody model module.
 * @module model/ProductsBody
 * @version v0.3
 */
export class ProductsBody {
  /**
   * Constructs a new <code>ProductsBody</code>.
   * @alias module:model/ProductsBody
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>ProductsBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ProductsBody} obj Optional instance to populate.
   * @return {module:model/ProductsBody} The populated <code>ProductsBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ProductsBody();
      if (data.hasOwnProperty("product"))
        obj.product = ProductsProduct.constructFromObject(data["product"]);
    }
    return obj;
  }
}

/**
 * @member {module:model/ProductsProduct} product
 */
ProductsBody.prototype.product = undefined;
