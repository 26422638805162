import { useMutation } from "@tanstack/react-query";

import { queryClient } from "index";
import { ReactQueryKeys } from "constants/keys";
import { Tag } from "../models/Tag";
import api from "api/";

export const createTag = async ({ name, description }: { name: string; description?: string }) => {
  const response = await api.client.Tag.create({
    body: {
      tag: {
        name: name,
        description: description,
      },
    },
  });

  if (!response.ok) {
    const error = `Error creating Tag ${response.error}`;
    console.error(error);
    throw new Error(error);
  }

  if (!response.body) {
    const error = `Error creating Tag`;
    console.error(error);
    throw new Error(error);
  }

  return response.body as Tag;
};

export const useCreateTag = () => {
  return useMutation(createTag, {
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.TAGS]);
    },
  });
};
