import React, { useState } from "react";
import auth from "../../api/auth";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Alert } from "@mui/material";
import { Auth } from "aws-amplify";
import { AmplifyGoogleButton } from "@aws-amplify/ui-react";

export const SignInWithGoogleButton = () => {
  const [isError, setIsError] = useState(false);

  const forgotUrl = `${process.env.REACT_APP_BASE_PATH}/forgot_url`;
  const onClick = () => {
    setIsError(false);
    const { userPoolDomain } = auth.getAuthProperties() || { userPoolDomain: null };

    if (!userPoolDomain) {
      setIsError(true);

      return;
    }
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  };

  return (
    <>
      {isError && (
        <Alert sx={{ marginTop: 2 }} severity="info">
          <div>
            If you would like to use Google SSO to sign in, you will need to browse to your new
            portal URL. Visit the
            <a style={{ color: "inherit" }} href={forgotUrl}>
              {` forgot URL page `}
            </a>
            to have your new portal URL emailed to you.
          </div>
        </Alert>
      )}
      {/* 
      //TODO: remove amplify ui and create a custom google button  
      //TODO: feature/sc-10737/remove-amplify-ui-and-create-a-custom-google*/}
      <AmplifyGoogleButton onClick={onClick} />
    </>
  );
};
