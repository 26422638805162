/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The SubscriptionsSubscription model module.
 * @module model/SubscriptionsSubscription
 * @version v0.3
 */
export class SubscriptionsSubscription {
  /**
   * Constructs a new <code>SubscriptionsSubscription</code>.
   * @alias module:model/SubscriptionsSubscription
   * @class
   * @param patientId {String} ID of the Patient you want to create a subscription for
   * @param priceIds {Array.<String>}
   */
  constructor(patientId, priceIds) {
    this.patientId = patientId;
    this.priceIds = priceIds;
  }

  /**
   * Constructs a <code>SubscriptionsSubscription</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SubscriptionsSubscription} obj Optional instance to populate.
   * @return {module:model/SubscriptionsSubscription} The populated <code>SubscriptionsSubscription</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SubscriptionsSubscription();
      if (data.hasOwnProperty("patient_id"))
        obj.patientId = ApiClient.convertToType(data["patient_id"], "String");
      if (data.hasOwnProperty("price_ids"))
        obj.priceIds = ApiClient.convertToType(data["price_ids"], ["String"]);
      if (data.hasOwnProperty("promotion_code"))
        obj.promotionCode = ApiClient.convertToType(data["promotion_code"], "String");
      if (data.hasOwnProperty("cancel_at_period_end"))
        obj.cancelAtPeriodEnd = ApiClient.convertToType(data["cancel_at_period_end"], "Boolean");
    }
    return obj;
  }
}

/**
 * ID of the Patient you want to create a subscription for
 * @member {String} patientId
 */
SubscriptionsSubscription.prototype.patientId = undefined;

/**
 * @member {Array.<String>} priceIds
 */
SubscriptionsSubscription.prototype.priceIds = undefined;

/**
 * The Stripe promotion code to apply to this subscription
 * @member {String} promotionCode
 */
SubscriptionsSubscription.prototype.promotionCode = undefined;

/**
 * Set to true if you want the subscription to end after the current period
 * @member {Boolean} cancelAtPeriodEnd
 */
SubscriptionsSubscription.prototype.cancelAtPeriodEnd = undefined;
