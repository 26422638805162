/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The MedicationOrdersidaddMedicationsMedicationOrder model module.
 * @module model/MedicationOrdersidaddMedicationsMedicationOrder
 * @version v0.3
 */
export class MedicationOrdersidaddMedicationsMedicationOrder {
  /**
   * Constructs a new <code>MedicationOrdersidaddMedicationsMedicationOrder</code>.
   * @alias module:model/MedicationOrdersidaddMedicationsMedicationOrder
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>MedicationOrdersidaddMedicationsMedicationOrder</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MedicationOrdersidaddMedicationsMedicationOrder} obj Optional instance to populate.
   * @return {module:model/MedicationOrdersidaddMedicationsMedicationOrder} The populated <code>MedicationOrdersidaddMedicationsMedicationOrder</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MedicationOrdersidaddMedicationsMedicationOrder();
      if (data.hasOwnProperty("patient_medication_ids"))
        obj.patientMedicationIds = ApiClient.convertToType(data["patient_medication_ids"], [
          "String",
        ]);
    }
    return obj;
  }
}

/**
 * @member {Array.<String>} patientMedicationIds
 */
MedicationOrdersidaddMedicationsMedicationOrder.prototype.patientMedicationIds = undefined;
