import { RadioGroupProps } from "./RadioGroup.types";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import MuiRadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import React from "react";

import Radio from "@mui/material/Radio";
interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}));

// We can create a few different types of radio buttons, this is a standard one :o
const MyFormControlLabel = (props: FormControlLabelProps) => {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
};

const RadioGroup = ({
  options,
  defaultValue,
  onChange,
  error = "",
  value,
}: RadioGroupProps): JSX.Element => {
  return (
    <FormControl error={!!error}>
      <MuiRadioGroup
        name="use-radio-group"
        defaultValue={defaultValue}
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
      >
        {options.map(({ value: optionValue, label }, optionIndex) => {
          return (
            <MyFormControlLabel
              value={optionValue}
              label={label}
              key={optionValue + optionIndex}
              control={<Radio />}
            />
          );
        })}
      </MuiRadioGroup>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default RadioGroup;
