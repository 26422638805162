/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The IntegrationscurexaCurexaIntegration1 model module.
 * @module model/IntegrationscurexaCurexaIntegration1
 * @version v0.3
 */
export class IntegrationscurexaCurexaIntegration1 {
  /**
   * Constructs a new <code>IntegrationscurexaCurexaIntegration1</code>.
   * @alias module:model/IntegrationscurexaCurexaIntegration1
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>IntegrationscurexaCurexaIntegration1</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/IntegrationscurexaCurexaIntegration1} obj Optional instance to populate.
   * @return {module:model/IntegrationscurexaCurexaIntegration1} The populated <code>IntegrationscurexaCurexaIntegration1</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new IntegrationscurexaCurexaIntegration1();
      if (data.hasOwnProperty("credentials_client_id"))
        obj.credentialsClientId = ApiClient.convertToType(data["credentials_client_id"], "String");
      if (data.hasOwnProperty("credentials_client_secret"))
        obj.credentialsClientSecret = ApiClient.convertToType(
          data["credentials_client_secret"],
          "String"
        );
      if (data.hasOwnProperty("enabled"))
        obj.enabled = ApiClient.convertToType(data["enabled"], "Boolean");
      if (data.hasOwnProperty("automatic_order_creation"))
        obj.automaticOrderCreation = ApiClient.convertToType(
          data["automatic_order_creation"],
          "Boolean"
        );
      if (data.hasOwnProperty("allow_patient_bypass_of_order_approval"))
        obj.allowPatientBypassOfOrderApproval = ApiClient.convertToType(
          data["allow_patient_bypass_of_order_approval"],
          "Boolean"
        );
      if (data.hasOwnProperty("require_payment_for_patient_orders"))
        obj.requirePaymentForPatientOrders = ApiClient.convertToType(
          data["require_payment_for_patient_orders"],
          "Boolean"
        );
    }
    return obj;
  }
}

/**
 * @member {String} credentialsClientId
 */
IntegrationscurexaCurexaIntegration1.prototype.credentialsClientId = undefined;

/**
 * @member {String} credentialsClientSecret
 */
IntegrationscurexaCurexaIntegration1.prototype.credentialsClientSecret = undefined;

/**
 * @member {Boolean} enabled
 */
IntegrationscurexaCurexaIntegration1.prototype.enabled = undefined;

/**
 * If set to 'true', medication orders will be fulfilled as soon as a prescription has been approved. If set to 'false', a draft version of the order will be created, and an additional API call will be needed to confirm and fulfill the order.
 * @member {Boolean} automaticOrderCreation
 */
IntegrationscurexaCurexaIntegration1.prototype.automaticOrderCreation = undefined;

/**
 * If set to 'true', requests made with a patient token will be authorized to create orders that don't require tenant approval.
 * @member {Boolean} allowPatientBypassOfOrderApproval
 * @default false
 */
IntegrationscurexaCurexaIntegration1.prototype.allowPatientBypassOfOrderApproval = false;

/**
 * If set to 'true', orders will be created with a status of 'awaiting_payment' until the patient pays for the order.
 * @member {Boolean} requirePaymentForPatientOrders
 * @default false
 */
IntegrationscurexaCurexaIntegration1.prototype.requirePaymentForPatientOrders = false;
