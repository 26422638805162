import { FormInputTypes, FormSchemaTypes } from "components/styled/form/Form.types";
import { getPolicyForm, getSubscriberForm } from "components/users/formData/UserInsurance";
import { WizardForm } from "components/styled/wizard/Wizard.types";
import React, { useState } from "react";

import WizardModal from "components/styled/wizard/WizardModal";

const AddPolicyModal = ({
  open,
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  open: boolean;
  onSubmit: (d) => void;
}) => {
  const [watchedFields, setWatchedFields] = useState<{
    subscriberType?: string;
  }>({});

  const getAddPolicyForm = () => {
    const addPolicyForm: Array<WizardForm> = [
      {
        nextText: "Next",
        title: "Add policy",
        form: [
          {
            name: "subscriberType",
            defaultValue: "",
            schema: FormSchemaTypes.Field("Subscriber type is required"),
            component: FormInputTypes.RadioGroup,
            breakpoints: {
              xs: 12,
              md: 4,
            },
            props: {
              options: [
                { label: "Patient", value: "patient" },
                { label: "Someone else", value: "someone_else" },
              ],
            },
          },
        ],
      },
    ];

    if (watchedFields?.subscriberType === "patient") {
      addPolicyForm.push({
        title: "Add policy",
        form: getPolicyForm({}),
      });
    } else if (watchedFields?.subscriberType === "someone_else") {
      addPolicyForm.push(
        {
          title: "Add policy",
          form: getSubscriberForm({}),
        },
        {
          title: "Add policy",
          form: getPolicyForm({}),
        }
      );
    }

    return addPolicyForm;
  };

  const wizardForm = getAddPolicyForm();

  return open ? (
    <WizardModal
      open={open}
      onClose={() => {
        setWatchedFields({});
        onClose();
      }}
      forms={wizardForm}
      onSubmit={onSubmit}
      setWatchedFields={setWatchedFields}
    />
  ) : null;
};

export default AddPolicyModal;
