import { VisibilityOff, Visibility, InfoOutlined } from "@mui/icons-material";
import { InputAdornment, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { colors } from "styles/colors";

export const InputAdornmentForPassword = ({
  showPassword,
  setShowPassword,
  title,
  ariaLabel,
}: {
  showPassword: boolean;
  setShowPassword: (p: boolean) => void;
  title?: string;
  ariaLabel: string;
}) => {
  return (
    <>
      <InputAdornment position="end">
        <IconButton
          aria-label={ariaLabel}
          onClick={(e) => {
            e.preventDefault();
            setShowPassword(!showPassword);
          }}
          edge="end"
          sx={{
            ":hover": { color: colors.darkGrey, backgroundColor: "transparent" },
          }}
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
      {title && (
        <InputAdornment position="end" sx={{ color: colors.lightGrey2 }}>
          <Tooltip title={title} enterDelay={300} arrow placement="top">
            <InfoOutlined />
          </Tooltip>
        </InputAdornment>
      )}
    </>
  );
};
