import { Patient } from "models/Patient/Patient.types";
import InlinePaginatedTable from "components/styled/InlinePaginatedTable";
import Section from "components/patients/Section";
import React, { useContext, useState } from "react";
import { OutlinedButton } from "components/styled/Buttons";
import { usePatientRelatedPersons } from "../../features/patientRelatedPersons/hooks/usePatientRelatedPersons";
import { Link } from "react-router-dom";
import Avatar from "components/styled/avatar/Avatar";
import { theme } from "styles/theme";
import Box from "@mui/material/Box";
import { capitalizeFirstOnly, displayName } from "utils/strings";
import Modal from "components/styled/modal/Modal";
import FormControl from "@mui/material/FormControl";
import { SearchableSelectOption } from "components/styled/searchable-select/SearchableSelect.types";
import { fetchPatientsSearch } from "components/patients/usePatient.hook";
import SearchableSelect from "components/styled/searchable-select/SearchableSelect";
import AsyncSearchSelectV2 from "components/styled/async-search-select-v2/AsyncSearchSelectV2";
import { capableClient } from "../../services/capableClient";
import { AddInfoContext } from "contexts/info";
import {
  PatientRelatedPerson,
  RelatedPersonOptions,
} from "../../features/patientRelatedPersons/models/PatientRelatedPerson";
import { colors } from "styles/colors";
import { ActiveCancelButton } from "components/CapableIcons";
import { UserTypes } from "models/User/User.types";

export const PatientRelationships = ({ patient }: { patient: Patient }) => {
  const [page, setPage] = useState<number>(1);
  const { data: patientRelationsResponse, refetch } = usePatientRelatedPersons({
    page: page,
    size: 10,
    filters: [
      JSON.stringify({
        field: "relationship_type",
        operator: "not_eq",
        value: UserTypes.Practitioner,
      }),
      JSON.stringify({
        field: "patient_id",
        operator: "eq",
        value: patient.id,
      }),
    ],
  });
  const [addRelationModalOpen, setAddRelationModalOpen] = useState<boolean>(false);
  const [removeRelationModalOpen, setRemoveRelationModalOpen] = useState<boolean>(false);
  const [selectedRelationship, setSelectedRelationship] = useState<PatientRelatedPerson>();
  const patientRelations = patientRelationsResponse?.data || [];
  const totalRelations = parseInt(patientRelationsResponse?.headers?.["total-count"] || "0");
  const AddRelationButton = (
    <>
      <OutlinedButton onClick={() => setAddRelationModalOpen(true)}>
        Add Relationship
      </OutlinedButton>
    </>
  );

  const columns = [
    {
      field: "first_name",
      headerName: "Name",
      minWidth: "300",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const relatedPerson = params.row.related_person;
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            <Avatar
              user={relatedPerson}
              color={theme.palette.primary.main}
              style={{
                margin: (theme) => theme.spacing(1.5),
              }}
            />
            <Link to={`/patients/${relatedPerson.id}`}>
              {relatedPerson.first_name} {relatedPerson.last_name}
            </Link>
          </Box>
        );
      },
    },
    {
      field: "relationship_type",
      headerName: "Relationship",
      minWidth: "300",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return capitalizeFirstOnly(params.value);
      },
    },
    {
      field: "id",
      headerName: "",
      maxWidth: 100,
      sortable: false,
      renderCell: (params) => (
        <OutlinedButton
          style={{
            backgroundColor: "white",
            borderRadius: "4px",
            borderColor: colors.lightGrey3,
            cursor: "pointer",
          }}
          onClick={() => {
            setSelectedRelationship(params.row);
            setRemoveRelationModalOpen(true);
          }}
        >
          <ActiveCancelButton />
        </OutlinedButton>
      ),
    },
  ];

  return (
    <Section title="Relationships" actionButtons={[AddRelationButton]}>
      {patientRelations.length ? (
        <InlinePaginatedTable
          rows={patientRelations}
          columns={columns}
          onPageChange={(pageIndex) => setPage(pageIndex + 1)}
          rowCount={totalRelations}
        />
      ) : (
        "No relationships have been recorded yet."
      )}
      <AddRelationshipModal
        open={addRelationModalOpen}
        onClose={() => setAddRelationModalOpen(false)}
        patient={patient}
        refetch={refetch}
      />
      <RemoveRelationshipModal
        onClose={() => {
          setRemoveRelationModalOpen(false);
          setSelectedRelationship(undefined);
        }}
        open={removeRelationModalOpen}
        patientRelatedPerson={selectedRelationship}
        patient={patient}
        refetch={refetch}
      />
    </Section>
  );
};

const RemoveRelationshipModal = ({
  onClose,
  open,
  patient,
  patientRelatedPerson,
  refetch,
}: {
  onClose: () => void;
  open: boolean;
  patient: Patient;
  patientRelatedPerson: PatientRelatedPerson | undefined;
  refetch: () => void;
}) => {
  const addInfoAlert = useContext(AddInfoContext);

  if (!patientRelatedPerson) return null;

  const handleSubmit = async () => {
    try {
      await capableClient.patientRelatedPersons.deletePatientRelatedPersons({
        id: patientRelatedPerson.id,
      });
      refetch();
      onClose();
      addInfoAlert({
        status: "success",
        message: "Patient relation removed!",
        isToast: true,
      });
    } catch (e) {
      addInfoAlert({
        status: "error",
        message: "Failed to remove patient relation",
        isToast: true,
      });
    }
  };

  return (
    <Modal
      onClose={onClose}
      open={open}
      title={`Remove relationship`}
      primaryButtonText={"Remove"}
      onPrimaryButtonClick={handleSubmit}
    >
      Are you sure you want to{" "}
      <b>
        remove {patientRelatedPerson.related_person.first_name}{" "}
        {patientRelatedPerson.related_person.last_name}
      </b>{" "}
      as {patientRelatedPerson.relationship_type} of {displayName(patient)}?
    </Modal>
  );
};

const AddRelationshipModal = ({
  onClose,
  open,
  patient,
  refetch,
}: {
  onClose: () => void;
  open: boolean;
  patient: Patient;
  refetch: () => void;
}) => {
  const addInfoAlert = useContext(AddInfoContext);
  const [selectedPatientOption, setSelectedPatientOption] = useState<SearchableSelectOption>();
  const [selectedRelationType, setSelectedRelationType] = useState<SearchableSelectOption>();
  const handleClose = () => {
    setSelectedPatientOption(undefined);
    setSelectedRelationType(undefined);
    onClose();
  };
  const handleSubmit = async () => {
    try {
      if (!selectedPatientOption || !selectedRelationType) {
        return;
      }
      await capableClient.patientRelatedPersons.postPatientRelatedPersons({
        requestBody: {
          patient_related_person: {
            relationship_type: selectedRelationType?.value,
            patient_id: patient.id,
            related_person_id: selectedPatientOption?.value,
          },
        },
      });
      refetch();
      handleClose();
      addInfoAlert({
        status: "success",
        message: "Patient relation added!",
        isToast: true,
      });
    } catch (e) {
      addInfoAlert({
        status: "error",
        message: "Failed to add patient relation",
        isToast: true,
      });
    }
  };

  return (
    <Modal
      onClose={handleClose}
      open={open}
      title={`Add relationship to ${patient.first_name} ${patient.last_name}`}
      primaryButtonText={"Add relation"}
      disabled={!selectedPatientOption || !selectedRelationType}
      onPrimaryButtonClick={handleSubmit}
    >
      <Box>
        <FormControl sx={{ width: "100%", marginBottom: "1rem" }}>
          <AsyncSearchSelectV2
            fetchOptions={fetchPatientsSearch}
            label="Related Patient"
            value={selectedPatientOption || null}
            onChange={(value) => {
              setSelectedPatientOption(value as SearchableSelectOption);
            }}
          />
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <SearchableSelect
            options={RelatedPersonOptions}
            label="Relationship Type"
            value={selectedRelationType}
            setSelectedItem={(option: SearchableSelectOption) => setSelectedRelationType(option)}
          />
        </FormControl>
      </Box>
    </Modal>
  );
};
