import React from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EventNoteIcon from "@mui/icons-material/EventNote";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import {
  careplanStatuses,
  eligibilityStatuses,
  goalStatuses,
  labStatuses,
  medicationStatuses,
  submissionStatuses,
  taskStatuses,
} from "constants/statuses";
import { capitalize } from "utils/strings";
import { CardInfo } from "components/patients/PatientCard";
import { Doctor, PrescriptionRenewal, RenewCheckmark } from "components/CapableIcons";
import { getLocaleDateString } from "./dates";
import { EXTERNAL_PRODUCT_PROVIDERS, HUMAN_READABLE_EXTERNAL_PROVIDERS } from "constants/products";
import { PencilSquare } from "components/CapableIcons";
import { Patient } from "models/Patient/Patient.types";
import { User, UserTypes } from "models/User/User.types";

/* eslint-disable @typescript-eslint/no-explicit-any */
const getLabMetrics = (apiInfo: any) => {
  const metrics = [
    { text: `Ordered on ${getLocaleDateString(apiInfo.created_at)}`, Icon: EventNoteIcon },
    {
      text: `Provider${String.fromCharCode(160)}id:${String.fromCharCode(160)}${
        apiInfo.provider_order_id
      }`,
      Icon: AssignmentIcon,
    },
    {
      text: `Provider: ${HUMAN_READABLE_EXTERNAL_PROVIDERS[apiInfo.provider_name]}`,
      Icon: LocalPharmacyIcon,
    },
    apiInfo.tracking_number && apiInfo.shipping_tracking_url
      ? {
          text: (
            <>
              Lab To Patient Tracking:{" "}
              <a
                style={{ cursor: "pointer" }}
                href={apiInfo.shipping_tracking_url}
                target="_blank"
                rel="noreferrer"
              >
                {apiInfo.tracking_number}, ({apiInfo.carrier_service})
              </a>
            </>
          ),
          Icon: LocationOnIcon,
        }
      : {
          text: apiInfo.tracking_number
            ? `Lab To Patient Tracking: ${apiInfo.tracking_number}, (${apiInfo.carrier_service})`
            : "Waiting on lab to patient tracking details",
          Icon: LocationOnIcon,
        },
  ];

  // Only display if provider is not imaware since they do not provide patient to lab shipping info
  if (apiInfo.provider_name !== EXTERNAL_PRODUCT_PROVIDERS.IMAWARE) {
    metrics.push(
      apiInfo.patient_to_lab_tracking_number &&
        apiInfo.patient_to_lab_shipping_tracking_url &&
        apiInfo.provider_name !== EXTERNAL_PRODUCT_PROVIDERS.ASH_WELLNESS
        ? {
            text: (
              <>
                Patient To Lab Tracking:{" "}
                <a
                  style={{ cursor: "pointer" }}
                  href={apiInfo.patient_to_lab_shipping_tracking_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {apiInfo.patient_to_lab_tracking_number}, (
                  {apiInfo.patient_to_lab_carrier_service})
                </a>
              </>
            ),
            Icon: LocationOnIcon,
          }
        : {
            text: apiInfo.patient_to_lab_tracking_number
              ? `Patient To Lab Tracking: ${apiInfo.patient_to_lab_tracking_number}, (${apiInfo.patient_to_lab_carrier_service})`
              : "Waiting on patient to lab tracking details",
            Icon: LocationOnIcon,
          }
    );
  }

  metrics.push({
    // @ts-ignore
    link_text: "Download results",
    link: ["results_ready", "partial_results_ready"].includes(apiInfo.order_status)
      ? apiInfo.order_id
      : null,
    Icon: FileDownloadOutlinedIcon,
  });

  return metrics;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const createCardInfo = (apiInfo: any, cardType: string, patientID: string): CardInfo => {
  const apiMap = {
    "Care Plans": {
      title: apiInfo.name,
      metrics: [
        { text: `Updated on ${getLocaleDateString(apiInfo.updated_at)}`, Icon: PencilSquare },
        { text: `Created on ${getLocaleDateString(apiInfo.created_at)}`, Icon: EventNoteIcon },
      ],
      status: careplanStatuses[apiInfo.status] || careplanStatuses["unknown"],
      url: `/patients/${patientID}/careplans/${apiInfo.id}`,
    },
    Goals: {
      title: apiInfo.name,
      metrics: [
        { text: `Updated on ${getLocaleDateString(apiInfo.updated_at)}`, Icon: PencilSquare },
        { text: `Created on ${getLocaleDateString(apiInfo.created_at)}`, Icon: EventNoteIcon },
      ],
      status: goalStatuses[apiInfo.achievement_status] || "Unknown",
      url: `/patients/${patientID}/careplans/${apiInfo.care_plan_id}/goals/${apiInfo.id}`,
    },
    Tasks: {
      title: apiInfo.name,
      metrics: [
        { text: `Updated on ${getLocaleDateString(apiInfo.updated_at)}`, Icon: PencilSquare },
        { text: `Created on ${getLocaleDateString(apiInfo.created_at)}`, Icon: EventNoteIcon },
      ],
      status: taskStatuses[apiInfo.achievement_status] || "Unknown",
      url: `/patients/${patientID}/careplans/${apiInfo.care_plan_id}/tasks/${apiInfo.id}`,
    },
    Submissions: {
      title: apiInfo.questionnaire_name,
      metrics: [
        { text: `Updated on ${getLocaleDateString(apiInfo.updated_at)}`, Icon: PencilSquare },
        { text: `Created on ${getLocaleDateString(apiInfo.created_at)}`, Icon: EventNoteIcon },
      ],
      status: submissionStatuses[apiInfo.status] || "Unknown",
      url: `/submissions/${apiInfo.id}`,
    },
    Medications: {
      title: apiInfo.name,
      metrics: [
        { text: apiInfo.directions || "", Icon: RenewCheckmark },
        { text: getLocaleDateString(apiInfo.created_at), Icon: EventNoteIcon },
        { text: apiInfo.prescribing_doctor || "", Icon: Doctor },
        {
          text:
            apiInfo.refills_left && apiInfo.refills_prescribed
              ? `${apiInfo.refills_left} of ${apiInfo.refills_prescribed} refills left`
              : "",
          Icon: PrescriptionRenewal,
        },
      ],
      status: apiInfo.last_order_status
        ? medicationStatuses.orderStatuses[apiInfo.last_order_status]
        : medicationStatuses.prescriptionStatuses[apiInfo.status],
      status_details: apiInfo.last_order_status_details,
    },
    Orders: {
      title: apiInfo.product_name,
      metrics: [
        { text: `Ordered on ${getLocaleDateString(apiInfo.created_at)}`, Icon: EventNoteIcon },
        {
          text: apiInfo?.order?.tracking_number
            ? `Tracking: ${apiInfo?.order?.tracking_number}, (${apiInfo?.order?.carrier_service})`
            : "Waiting on tracking details",
          Icon: LocationOnIcon,
        },
      ],
      status: medicationStatuses.orderStatuses[apiInfo?.order?.status],
    },
    Prescriptions: {
      title: apiInfo.name,
      metrics: [
        { text: apiInfo.directions || "", Icon: RenewCheckmark },
        { text: getLocaleDateString(apiInfo.created_at), Icon: EventNoteIcon },
        { text: apiInfo.prescribing_doctor || "", Icon: Doctor },
        {
          text:
            apiInfo.refills_left && apiInfo.refills_prescribed
              ? // The refills field does not include the initial prescription, so we need to add 1 to the number of refills prescribed.
                `${apiInfo.refills_left + (apiInfo.initial_fullfil_exhausted ? 0 : 1)} of ${
                  apiInfo.refills_prescribed + 1
                } refills left`
              : "",
          Icon: PrescriptionRenewal,
        },
      ],
      status: apiInfo.last_order_status
        ? medicationStatuses.orderStatuses[apiInfo.last_order_status]
        : medicationStatuses.prescriptionStatuses[apiInfo.status],
    },
    "Lab Tests": {
      title: `${apiInfo.name} ${
        apiInfo.provider_lab_test_name ? "(" + apiInfo.provider_lab_test_name + ")" : ""
      }`,
      metrics: getLabMetrics(apiInfo),
      status: labStatuses[apiInfo.order_status] || "Unknown",
    },
    Policies: {
      title: apiInfo.plan_name,
      id: apiInfo.id,
      subtitle: apiInfo.coordination_of_benefits && capitalize(apiInfo.coordination_of_benefits),
      metrics: [
        { text: `Group #: ${apiInfo.group_number}` },
        { text: `Member ID: ${apiInfo.member_id}` },
        { text: `Subscriber: ${apiInfo.subscriber_name}` },
        { text: `Effective: ${getLocaleDateString(apiInfo.effective_date)}` },
      ],
      enabled: apiInfo.enabled,
      status: eligibilityStatuses[apiInfo.status],
    },
  };

  return apiMap[cardType];
};

export const isPatient = (user: User | Patient): user is Patient => {
  return user.type === UserTypes.Patient;
};
