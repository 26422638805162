/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The PatientsPatientAddressesAttributes model module.
 * @module model/PatientsPatientAddressesAttributes
 * @version v0.3
 */
export class PatientsPatientAddressesAttributes {
  /**
   * Constructs a new <code>PatientsPatientAddressesAttributes</code>.
   * @alias module:model/PatientsPatientAddressesAttributes
   * @class
   * @param line1 {String}
   * @param city {String}
   * @param state {String}
   * @param country {String}
   * @param zip {String}
   */
  constructor(line1, city, state, country, zip) {
    this.line1 = line1;
    this.city = city;
    this.state = state;
    this.country = country;
    this.zip = zip;
  }

  /**
   * Constructs a <code>PatientsPatientAddressesAttributes</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PatientsPatientAddressesAttributes} obj Optional instance to populate.
   * @return {module:model/PatientsPatientAddressesAttributes} The populated <code>PatientsPatientAddressesAttributes</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PatientsPatientAddressesAttributes();
      if (data.hasOwnProperty("line1"))
        obj.line1 = ApiClient.convertToType(data["line1"], "String");
      if (data.hasOwnProperty("line2"))
        obj.line2 = ApiClient.convertToType(data["line2"], "String");
      if (data.hasOwnProperty("city")) obj.city = ApiClient.convertToType(data["city"], "String");
      if (data.hasOwnProperty("state"))
        obj.state = ApiClient.convertToType(data["state"], "String");
      if (data.hasOwnProperty("country"))
        obj.country = ApiClient.convertToType(data["country"], "String");
      if (data.hasOwnProperty("zip")) obj.zip = ApiClient.convertToType(data["zip"], "String");
      if (data.hasOwnProperty("primary"))
        obj.primary = ApiClient.convertToType(data["primary"], "Boolean");
      if (data.hasOwnProperty("label"))
        obj.label = ApiClient.convertToType(data["label"], "String");
      if (data.hasOwnProperty("active"))
        obj.active = ApiClient.convertToType(data["active"], "Boolean");
    }
    return obj;
  }
}

/**
 * @member {String} line1
 */
PatientsPatientAddressesAttributes.prototype.line1 = undefined;

/**
 * @member {String} line2
 */
PatientsPatientAddressesAttributes.prototype.line2 = undefined;

/**
 * @member {String} city
 */
PatientsPatientAddressesAttributes.prototype.city = undefined;

/**
 * @member {String} state
 */
PatientsPatientAddressesAttributes.prototype.state = undefined;

/**
 * @member {String} country
 */
PatientsPatientAddressesAttributes.prototype.country = undefined;

/**
 * @member {String} zip
 */
PatientsPatientAddressesAttributes.prototype.zip = undefined;

/**
 * Primary address. Only one allowed per patient.
 * @member {Boolean} primary
 */
PatientsPatientAddressesAttributes.prototype.primary = undefined;

/**
 * A label for the address, such as \"home\", \"office\", \"grandma's house\", etc.
 * @member {String} label
 */
PatientsPatientAddressesAttributes.prototype.label = undefined;

/**
 * Indicates whether the address is active
 * @member {Boolean} active
 */
PatientsPatientAddressesAttributes.prototype.active = undefined;
