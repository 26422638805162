import { capableClient } from "services/capableClient";

export const fetchAllHelper = async (requestClass: string, requestMethod: string, params: any) => {
  // Barnard max limits page size to 100
  params["size"] = 100;
  let currentPage = 1;
  let hasNextPage = true;
  let records: any[] = [];
  let headers;

  while (hasNextPage) {
    params["page"] = currentPage;
    const response = await capableClient[requestClass][requestMethod](params);
    if (response.status >= 400) {
      const error = `Error retrieving ${requestClass} ${response.error}`;
      console.error(error);
      throw new Error(error);
    }

    const fetchedRecords = response.data as any[];

    records = [...records, ...fetchedRecords];
    headers = response.headers;

    if (parseInt(response.headers["total-pages"]) > currentPage) {
      currentPage += 1;
    } else {
      hasNextPage = false;
    }
  }
  return {
    data: records,
    headers,
  };
};
