import { makeStyles } from "@mui/styles";
import {
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { Search } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import MaUTable from "@mui/material/Table";
import React, { useContext } from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel/TableSortLabel";

import { BreakpointContext } from "contexts/breakpoint";
import { colors } from "styles/colors";
import { TableProps } from "./Table.types";
import { theme } from "styles/theme";
import Filter from "../filter/Filter";
import TextFieldInput from "../text-field-input/TextFieldInput";

const useStyles = makeStyles({
  root: {
    border: "none",
    height: (props: { height: string | undefined }) => props.height || "75vh",
    "& .MuiTableHead": {
      borderBottom: "2px solid blue",
    },
    "& .MuiTableHead-root .MuiTableRow-root": {
      height: "60px",
      background: colors.white,
      "&:hover": {
        backgroundColor: colors.white,
      },
    },
    "& .MuiTableRow-root": {
      height: (props: { rowHeight: string | undefined; height: string | undefined }) =>
        props?.rowHeight ? props.rowHeight : "100%",
      minHeight: "80px",
      "&:hover": {
        backgroundColor: colors.tableHover,
      },
    },
    "& .MuiTableCell-body": {
      display: "inline-flex !important",
      alignItems: "center",
    },
  },
});

const Table = ({
  columns,
  rows,
  pageSize = 10,
  onRowClick,
  totalCount,
  onPageChange,
  pageIndex,
  prevParams,
  nextParams,
  path,
  hidePaginationControls = false,
  useCursorPagination = false,
  filters,
  setFilters,
  globalOperator,
  setGlobalOperator,
  filterFieldOptions,
  applyFilters,
  sortingSettings,
  setSortingSettings,
  searchValue,
  onSearchChange,
  tableFooter,
  tableRef,
  rowHeight,
  containerHeight,
  tableHeight,
}: TableProps): JSX.Element => {
  const classes = useStyles({ rowHeight: rowHeight, height: tableHeight });
  const history = useHistory();
  const { isMobileView } = useContext(BreakpointContext);

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: rows,
      initialState: { pageIndex, pageSize },
      manualPagination: true,
      pageCount: -1,
      manualSortBy: true,
    },
    useGlobalFilter,
    useSortBy,
    useFlexLayout,
    usePagination,
    useRowSelect
  );

  const handleClick = (row) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  return (
    <div className={classes.root}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          flexDirection: isMobileView ? "column-reverse" : "row",
          overflow: "hidden",
          paddingTop: hidePaginationControls ? "3rem" : "0",
        }}
      >
        <Box
          sx={{
            maxWidth: isMobileView ? "calc(100%-16px)" : "60%",
            width: isMobileView ? "calc(100%-16px)" : "60%",
          }}
        >
          {filters && Boolean(filters.length) && typeof globalOperator === "number" && (
            <Filter
              filters={filters}
              setFilters={setFilters}
              globalOperator={globalOperator}
              setGlobalOperator={setGlobalOperator}
              filterFieldOptions={filterFieldOptions}
              applyFilters={applyFilters}
            />
          )}
          {onSearchChange && (
            <TextFieldInput
              label={""}
              value={searchValue}
              onChange={(value) => onSearchChange(value)}
              variant="standard"
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              style={{ maxWidth: "300px", margin: theme.spacing(2) }}
            />
          )}
        </Box>
        {!hidePaginationControls && (
          <TablePagination
            data-sentry-unmask
            rowsPerPageOptions={[]}
            count={totalCount}
            rowsPerPage={pageSize}
            page={pageIndex}
            labelDisplayedRows={({ from, to, count }) => {
              if (useCursorPagination) return "";
              return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
            }}
            onPageChange={(e, page) => {
              if (useCursorPagination) {
                history.push({
                  pathname: path,
                  search: pageIndex > page ? prevParams : nextParams,
                });
              } else {
                onPageChange(page);
              }
            }}
            backIconButtonProps={
              typeof prevParams === "string"
                ? {
                    disabled: prevParams === "",
                  }
                : undefined
            }
            nextIconButtonProps={
              typeof nextParams === "string"
                ? {
                    disabled: nextParams === "",
                  }
                : undefined
            }
            sx={{
              border: "0",
              padding: "0",
            }}
          />
        )}
      </Box>
      <TableContainer ref={tableRef} sx={containerHeight ? { height: containerHeight } : {}}>
        <MaUTable {...getTableProps()} stickyHeader>
          {/* stickyHeader prop doesnt work due to useTable adding display flex to each column */}
          {/* instead just make Thead sticky, note maxHeight must be set */}
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 2 }}>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <TableRow
                sx={{ height: "auto" }}
                {...headerGroup.getHeaderGroupProps()}
                key={`${headerGroup.id}_${headerGroupIndex}`}
              >
                {headerGroup.headers.map((column, columnIndex) => {
                  const currentColumnSetting =
                    sortingSettings &&
                    sortingSettings.find((s) => s.replace("-", "") === column.id);

                  return (
                    <TableCell
                      {...(column.id === "selection"
                        ? column.getHeaderProps()
                        : column.getHeaderProps(column.getSortByToggleProps()))}
                      key={`${column.id}_${columnIndex}`}
                    >
                      {column.render("Header")}
                      {column.id !== "selection" ? (
                        <TableSortLabel
                          hideSortIcon={column.disableSortBy}
                          active={Boolean(currentColumnSetting)}
                          direction={
                            currentColumnSetting && currentColumnSetting.startsWith("-")
                              ? "desc"
                              : "asc"
                          }
                          onClick={() => {
                            const resetURL = `${path}?page=1`;
                            history.push(resetURL);

                            if (sortBy.length === 0) {
                              setSortingSettings(["-created_at"]);
                              return;
                            }

                            const currentSetting = sortBy.find((s) => s.id === column.id);

                            if (currentSetting) {
                              const direction = currentSetting?.desc ? "desc" : "asc";
                              setSortingSettings([
                                `${direction === "desc" ? "-" : ""}${column.id}`,
                              ]);
                            } else {
                              const newSetting = sortBy[0];
                              setSortingSettings([`${newSetting.desc ? "-" : ""}${newSetting.id}`]);
                            }
                          }}
                        />
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  key={`${row.id}_${rowIndex}`}
                  onClick={() => handleClick(row.original)}
                >
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <TableCell {...cell.getCellProps()} key={`cell_${cell.row.id}_${cellIndex}`}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          {tableFooter}
        </MaUTable>
      </TableContainer>
    </div>
  );
};

export default Table;
