import { useFlags } from "launchdarkly-react-client-sdk";
import { administrativeGenderOptions, genderOptions } from "../../forms/constants/details";
import { formatISO, isValid } from "date-fns/esm";
import { getLocaleDateString } from "../../../utils/dates";
import { Paper, Box } from "@mui/material";
import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { theme } from "../../../styles/theme";
import FormControl from "@mui/material/FormControl";
import React, { useState, useEffect } from "react";

import Avatar from "../../styled/avatar/Avatar";
import DatePicker from "../../styled/date-picker/DatePicker";
import SearchableSelect from "../../styled/searchable-select/SearchableSelect";
import Section from "../Section";
import TagsInput from "../../styled/tags-input/TagsInput";
import TextFieldInput from "../../styled/text-field-input/TextFieldInput";
import UserEthnicityDropdown from "../../users/UserEthnicityDropdown";
import UserRaceDropdown from "../../users/UserRaceDropdown";
import TagsInputDeprecated from "components/styled/tags-input-deprecated/TagsInputDeprecated";

const DetailsFormSection = ({
  details,
  setDetails,
  userType,
  setTouchedFields,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setDetails: any;
  userType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setTouchedFields: any;
}): JSX.Element => {
  const { tagsV1 } = useFlags();

  const [date, setDate] = useState<Date | null>(
    details.birth_date ? new Date(details.birth_date?.replace(/-/g, "/")) : null
  );
  const race = details?.races?.[0];
  const ethnicity = details?.ethnicities?.[0];

  useEffect(() => {
    (async () => {
      if (date) {
        if (isValid(date) && date.getFullYear() > 1900) {
          const isoDate = formatISO(date);
          setDetails((details) => ({ ...details, birth_date: isoDate }));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const detailFormInputSx = {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    margin: (theme) => theme.spacing(1.5),
    gap: (theme) => theme.spacing(3),
  };

  return (
    <Section title="Details">
      <Paper
        sx={{
          padding: (theme) => theme.spacing(4),
          borderRadius: (theme) => theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            marginBottom: (theme) => theme.spacing(2),
            fontSize: "14px",
          }}
        >
          <Avatar
            user={details}
            diameter={theme.spacing(10)}
            style={{
              marginX: (theme) => theme.spacing(1.5),
              fontWeight: "bold",
              fontSize: "23px",
              marginBottom: theme.spacing(2),
            }}
          />
          <Box
            sx={{
              marginLeft: (theme) => theme.spacing(2),
              display: "flex",
              flexDirection: "column",
              paddingTop: (theme) => theme.spacing(1),
            }}
          >
            <span style={{ fontWeight: "bold" }}>{userType.toUpperCase()}</span>
            <span>
              {userType === "patient" ? "MEMBER SINCE " : "CREATED ON "}
              {getLocaleDateString(details?.created_at).toUpperCase()}
            </span>
            <span style={{ color: theme.palette.text.secondary }}>ID: {details.id}</span>
          </Box>
        </Box>
        <Box sx={detailFormInputSx as SystemStyleObject}>
          <FormControl sx={{ width: "100%" }}>
            <TextFieldInput
              label="First name"
              value={details.first_name}
              onChange={(value) => {
                setDetails((details) => ({ ...details, first_name: value }));
                setTouchedFields((touchedFields) => ({ ...touchedFields, first_name: value }));
              }}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextFieldInput
              label="Last name"
              value={details.last_name}
              onChange={(value) => {
                setDetails((details) => ({ ...details, last_name: value }));
                setTouchedFields((touchedFields) => ({ ...touchedFields, last_name: value }));
              }}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            margin: (theme) => theme.spacing(1.5),
            gap: (theme) => theme.spacing(3),
          }}
        >
          <FormControl sx={{ width: "100%" }}>
            <DatePicker
              label="Date of birth"
              date={date}
              onChange={(value) => {
                setDate(value);
                setTouchedFields((touchedFields) => ({ ...touchedFields, birth_date: value }));
              }}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <SearchableSelect
              label="Gender Identity"
              setSelectedItem={(value) => {
                setDetails((details) => ({ ...details, gender_identity: value }));
                setTouchedFields((touchedFields) => {
                  return { ...touchedFields, gender_identity: value?.value || null };
                });
              }}
              value={details.gender_identity || null}
              options={genderOptions}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <SearchableSelect
              label="Administrative Gender"
              setSelectedItem={(value) => {
                setDetails((details) => ({ ...details, administrative_gender: value }));
                setTouchedFields((touchedFields) => {
                  return { ...touchedFields, administrative_gender: value?.value || null };
                });
              }}
              value={details.administrative_gender || null}
              options={administrativeGenderOptions}
            />
          </FormControl>
        </Box>
        <Box sx={detailFormInputSx as SystemStyleObject}>
          <Box sx={{ width: "100%" }}>
            <UserRaceDropdown
              onChange={(value) => {
                setDetails((details) => ({ ...details, races: value?.value ? [value] : [] }));
                setTouchedFields((touchedFields) => ({
                  ...touchedFields,
                  races: value?.value ? [{ level_2_display: value.display }] : [],
                }));
              }}
              value={race?.level_2_code || race?.code}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <UserEthnicityDropdown
              onChange={(value) => {
                setDetails((details) => ({ ...details, ethnicities: value?.value ? [value] : [] }));
                setTouchedFields((touchedFields) => ({
                  ...touchedFields,
                  ethnicities: value?.value ? [{ level_2_display: value.display }] : [],
                }));
              }}
              value={ethnicity?.level_2_code || ethnicity?.code}
            />
          </Box>
        </Box>
        {userType === "patient" && (
          <Box sx={detailFormInputSx as SystemStyleObject}>
            <FormControl sx={{ width: "100%" }}>
              <TextFieldInput
                label="Pronouns"
                value={details.pronouns}
                onChange={(value) => {
                  setDetails((details) => ({ ...details, pronouns: value }));
                  setTouchedFields((touchedFields) => ({ ...touchedFields, pronouns: value }));
                }}
              />
            </FormControl>
          </Box>
        )}
        {userType === "practitioner" && (
          <Box sx={detailFormInputSx as SystemStyleObject}>
            <FormControl sx={{ width: "100%" }}>
              <TextFieldInput
                label="Short Bio"
                value={details.biography}
                onChange={(value) => {
                  setDetails((details) => ({ ...details, biography: value }));
                  setTouchedFields((touchedFields) => ({ ...touchedFields, biography: value }));
                }}
                placeholder="ex. 18 years of experience managing chronic pain"
              />
            </FormControl>
          </Box>
        )}
        <Box sx={detailFormInputSx as SystemStyleObject}>
          <FormControl sx={{ width: "100%" }}>
            {tagsV1 ? (
              <TagsInput
                tags={details.tag_list}
                onChange={(value) => {
                  setDetails((details) => ({ ...details, tag_list: value }));
                  setTouchedFields((touchedFields) => ({ ...touchedFields, tag_list: value }));
                }}
              />
            ) : (
              <TagsInputDeprecated
                tags={details.tag_list.length ? details.tag_list : []}
                onChange={(value) => {
                  setDetails((details) => ({ ...details, tag_list: value }));
                  setTouchedFields((touchedFields) => ({ ...touchedFields, tag_list: value }));
                }}
              />
            )}
          </FormControl>
        </Box>
      </Paper>
    </Section>
  );
};

export default DetailsFormSection;
