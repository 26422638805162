/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The LeadsLead model module.
 * @module model/LeadsLead
 * @version v0.3
 */
export class LeadsLead {
  /**
   * Constructs a new <code>LeadsLead</code>.
   * @alias module:model/LeadsLead
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>LeadsLead</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/LeadsLead} obj Optional instance to populate.
   * @return {module:model/LeadsLead} The populated <code>LeadsLead</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new LeadsLead();
      if (data.hasOwnProperty("identity_external_id"))
        obj.identityExternalId = ApiClient.convertToType(data["identity_external_id"], "String");
      if (data.hasOwnProperty("metadata"))
        obj.metadata = ApiClient.convertToType(data["metadata"], { String: Object });
    }
    return obj;
  }
}

/**
 * External ID to pair with the user identity generated in Capable Health
 * @member {String} identityExternalId
 */
LeadsLead.prototype.identityExternalId = undefined;

/**
 * Any JSON-formatted data you want to associate with this object
 * @member {Object.<String, Object>} metadata
 */
LeadsLead.prototype.metadata = undefined;
