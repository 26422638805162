import React from "react";
import { styled } from "@mui/system";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { theme } from "../../styles/theme";

export const StyledWideTooltip = styled(({ className, ...props }: TooltipProps) => {
  return (
    <Tooltip
      sx={{ marginLeft: (theme) => theme.spacing(1) }}
      {...props}
      classes={{ popper: className }}
    />
  );
})({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    backgroundColor: theme.palette.text.primary,
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&::before": {
      backgroundColor: theme.palette.text.primary,
    },
  },
});
