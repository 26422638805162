/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The OrganizationsidlogoOrganization model module.
 * @module model/OrganizationsidlogoOrganization
 * @version v0.3
 */
export class OrganizationsidlogoOrganization {
  /**
   * Constructs a new <code>OrganizationsidlogoOrganization</code>.
   * @alias module:model/OrganizationsidlogoOrganization
   * @class
   * @param logo {Blob} File that will be uploaded as an attachment and assoicated as the Logo
   */
  constructor(logo) {
    this.logo = logo;
  }

  /**
   * Constructs a <code>OrganizationsidlogoOrganization</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/OrganizationsidlogoOrganization} obj Optional instance to populate.
   * @return {module:model/OrganizationsidlogoOrganization} The populated <code>OrganizationsidlogoOrganization</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OrganizationsidlogoOrganization();
      if (data.hasOwnProperty("logo")) obj.logo = ApiClient.convertToType(data["logo"], "Blob");
    }
    return obj;
  }
}

/**
 * File that will be uploaded as an attachment and assoicated as the Logo
 * @member {Blob} logo
 */
OrganizationsidlogoOrganization.prototype.logo = undefined;
