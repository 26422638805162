import Auth from "@capable-health/capable-auth-sdk";
import { useMutation } from "@tanstack/react-query";

export const useSignIn = () => {
  return useMutation({
    mutationFn: async ({ email, password }: { email: string; password: string }) => {
      await Auth.credentials.signIn(email, password);
    },
  });
};
