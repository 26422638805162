import Box from "@mui/material/Box";
import React, { useState } from "react";

import { theme } from "styles/theme";
import Avatar from "components/styled/avatar/Avatar";
import Modal from "components/styled/modal/Modal";
import SearchableSelect from "components/styled/searchable-select/SearchableSelect";
import { User } from "models/User/User.types";
import { Patient } from "models/Patient/Patient.types";
import { Practitioner } from "models/Practitioner/Practitioner.types";

const AddPractitionerModal = ({
  onClose,
  open,
  patient,
  practitioners,
  onChange,
}: {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  open: boolean;
  patient: Patient;
  practitioners: Practitioner[];
  onChange: (practitioner: User | null) => Promise<void>;
}) => {
  const [selectedPractitioner, setSelectedPractitioner] = useState<User | null>(null);

  const practitionerOptions = practitioners.map((practitioner) => {
    return {
      ...practitioner,
      value: practitioner.id,
      label: `${practitioner.first_name} ${practitioner.last_name}`,
    };
  });

  return (
    practitionerOptions && (
      <Modal
        onClose={onClose}
        open={open}
        title={`Assign Practitioner(s) to ${patient.first_name} ${patient.last_name}'s Care Team `}
        primaryButtonText={"Assign"}
        onPrimaryButtonClick={() => {
          onChange(selectedPractitioner);
          setSelectedPractitioner(null);
        }}
      >
        <Box
          sx={{
            marginBottom: (theme) => theme.spacing(5),
          }}
        >
          <SearchableSelect
            label="Name"
            setSelectedItem={(value) => {
              setSelectedPractitioner(value);
            }}
            value={selectedPractitioner}
            options={practitionerOptions}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => [
              props,
              <Box
                key={option.id}
                sx={{
                  mr: 2,
                  flexShrink: 0,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  overflow: "hidden",
                  height: "100%",
                }}
              >
                <Avatar
                  user={option}
                  diameter={theme.spacing(3.5)}
                  color={theme.palette.primary.main}
                  style={{
                    margin: (theme) => theme.spacing(1.5),
                    borderWidth: theme.spacing(0.2),
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                />
                {option.label}
              </Box>,
            ]}
          />
        </Box>
      </Modal>
    )
  );
};

export default AddPractitionerModal;
