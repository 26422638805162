import { styled } from "@mui/material/styles";
import React from "react";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";

import { ButtonChoiceProps } from "./ButtonChoice.types";
import { colors } from "styles/colors";

// @ts-ignore
const TabsStyled = styled(TabsUnstyled)((props) => ({
  background: colors.lightGrey7,
  border: "2px solid #d6d7d9",
  borderRadius: "50px",
  display: "inline-block",
  overflow: "hidden",
  cursor: "pointer",
  ...props.sx,
}));

const TabListStyled = styled(TabsListUnstyled)`
  height: 100%;
`;

// @ts-ignore
const TabStyled = styled(TabUnstyled)((props) => ({
  height: "100%",
  borderRadius: "50px",
  border: 0,
  cursor: "pointer",
  fontWeight: "700",
  ...props.sx,
  [`&.${tabUnstyledClasses.selected}`]: {
    // @ts-ignore
    backgroundColor: props.selectedColor,
    color: "white",
  },
}));

const ButtonChoice = ({
  value,
  options,
  handleChange,
  selectedColor = colors.buttonChoiceBlue,
  size = "medium",
}: ButtonChoiceProps): JSX.Element => {
  const sizes = {
    small: {
      tabsStyled: { height: "28px" },
      tabStyled: { paddingLeft: "10px", paddingRight: "10px" },
    },
    medium: {
      tabsStyled: { height: "34px" },
      tabStyled: { paddingLeft: "16px", paddingRight: "16px" },
    },
  };

  return (
    <TabsStyled
      value={value}
      onChange={(e, val) => handleChange(val as number)}
      sx={sizes[size].tabsStyled}
    >
      <TabListStyled>
        {options?.map(({ label }, key) => {
          return (
            <TabStyled
              // @ts-ignore
              selectedColor={selectedColor}
              key={`${label}-${key}`}
              sx={sizes[size].tabStyled}
            >
              {label}
            </TabStyled>
          );
        })}
        {/* @ts-ignore */}
      </TabListStyled>
    </TabsStyled>
  );
};

export default ButtonChoice;
