import { alpha } from "@mui/material/styles";
import { Autocomplete, Divider, Paper, TextField } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import Box from "@mui/material/Box/Box";
import ClickAwayListener from "@mui/base/ClickAwayListener/ClickAwayListener";
import debounce from "lodash.debounce";
import React, { useState, useCallback, useEffect, useMemo, useContext } from "react";

import { AddInfoContext } from "contexts/info";
import { colors } from "styles/colors";
import { formatError } from "utils/strings";
import { TagsInputProps } from "./TagsInput.types";
import { theme } from "styles/theme";
import { useCreateTag } from "features/tags/hooks/useCreateTag.hook";
import { useTags } from "features/tags/hooks/useTags.hook";

interface TagOption {
  readonly label: string;
  readonly value: string;
}

const TagsInput = (props: TagsInputProps): JSX.Element => {
  const { tags = [], onChange, variant = "outlined", readOnly = false, disabled = false } = props;

  const addInfoAlert = useContext(AddInfoContext);
  const { createTags } = useFlags();

  const [input, setInput] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const { mutate: createTag } = useCreateTag();

  const { data: tagOptions, isLoading: tagsLoading } = useTags({
    search: search,
    size: 500,
    sortBy: ["name"],
  });
  const options =
    tagOptions?.body?.map((tag) => ({ label: tag.name, value: tag.name } as TagOption)) ?? [];

  const inputIsValidNewTag =
    input === search &&
    !tagsLoading &&
    !tags.map((t) => t.toLowerCase()).includes(input.toLowerCase()) &&
    !options.map((t) => t.value.toLowerCase()).includes(input.toLowerCase());

  const tagsAsOptionsValue = useMemo(
    () =>
      tags.map((tag) => {
        return { label: tag, value: tag };
      }),
    [tags]
  );

  const onCreate = (name: string) => {
    createTag(
      { name: name },
      {
        onError: (error) => {
          addInfoAlert({
            status: "error",
            message: formatError(error),
          });
        },
      }
    );
  };

  const CustomPaper = (props: React.HTMLAttributes<HTMLElement>) => {
    return (
      <Paper
        elevation={8}
        {...props}
        sx={{ border: 1, borderColor: colors.lightGrey4 }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Box sx={{ maxHeight: "200px", overflow: "scroll" }}>{props.children}</Box>
        <Divider />
        {createTags && input && (
          <Box
            onMouseDown={(e) => {
              e.preventDefault();
            }}
          >
            <Box
              sx={{
                paddingX: (theme) => theme.spacing(2),
                paddingY: (theme) => theme.spacing(1),
                fontSize: "13px",
              }}
            >
              Create a tag
            </Box>
            <Box
              sx={{
                paddingX: (theme) => theme.spacing(2),
                paddingY: (theme) => theme.spacing(1),
                ":hover": inputIsValidNewTag
                  ? {
                      backgroundColor: alpha(theme.palette.primary.light, 0.1),
                      cursor: "pointer",
                    }
                  : {},
              }}
              onMouseDown={(e) => {
                e.preventDefault();
                if (inputIsValidNewTag) {
                  onCreate(input);
                  onChange([...tags, input]);
                  setInput("");
                }
              }}
              color={inputIsValidNewTag ? colors.black : colors.disabledGrey}
            >
              {input}
            </Box>
          </Box>
        )}
      </Paper>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSearchString = useCallback(
    debounce((inputText: string) => {
      setSearch(inputText);
    }, 500),
    []
  );

  useEffect(() => {
    (async () => {
      updateSearchString(input);
    })();
  }, [input, updateSearchString]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
      }}
    >
      <Autocomplete<TagOption, true>
        multiple
        disableCloseOnSelect
        open={open}
        value={tagsAsOptionsValue}
        getOptionLabel={(option) => option.label}
        filterOptions={(x) => x}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        inputValue={input}
        onInputChange={(_, newInput) => {
          setInput(newInput);
        }}
        onChange={(_, selectedOptions) => {
          const selectedOptionStrings = selectedOptions.map((option) => option.value);
          onChange(selectedOptionStrings);
        }}
        readOnly={readOnly}
        disabled={disabled}
        popupIcon={null}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              value={input}
              label="Tags"
              onClick={(e) => {
                e.preventDefault();
                if (!disabled && !readOnly) {
                  setOpen(true);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Backspace") {
                  event.stopPropagation();
                }
              }}
              variant={variant}
            />
          );
        }}
        options={options}
        noOptionsText="No matching tags"
        ChipProps={{ sx: { backgroundColor: alpha(theme.palette.primary.light, 0.1) } }}
        PaperComponent={CustomPaper}
      />
    </ClickAwayListener>
  );
};

export default TagsInput;
