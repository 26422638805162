import { useContext, useEffect, useState } from "react";

import { AddInfoContext } from "../contexts/info";
import { formatError } from "../utils/strings";
import { SOME_RIDICULOUS_NUMBER } from "../constants/api";
import api from "../api";

export enum API_Type {
  AppointmentType = "AppointmentType",
  Calendar = "Calendar",
  CarePlanTemplate = "CarePlanTemplate",
  Compound = "Compound",
  Conversation = "Conversation",
  Ethnicity = "Ethnicity",
  Encounter = "Encounter",
  GoalTemplate = "GoalTemplate",
  Integrations = "Integrations",
  LabTest = "LabTest",
  Medication = "Medication",
  MedicationOrder = "MedicationOrder",
  Observation = "Observation",
  ObservationType = "ObservationType",
  Organization = "Organization",
  Patient = "Patient",
  PatientMedication = "PatientMedication",
  Practitioner = "Practitioner",
  Product = "Product",
  Questionnaire = "Questionnaire",
  Race = "Race",
  Subscription = "Subscription",
  TargetTemplate = "TargetTemplate",
  TaskTemplate = "TaskTemplate",
  TenantAdmin = "TenantAdmin",
}

export const getAllResults = (
  APIType: API_Type,
  params?: Record<string, unknown>,
  stateFuncs?: any
) => {
  const accumulator = [];
  const emptyParams = params && !Object.keys(params).length;
  const paramsAreDefined = params && Object.values(params).every((p) => p);

  const getPage = async (page) => {
    if (emptyParams && APIType !== API_Type.LabTest && APIType !== API_Type.AppointmentType) {
      // sometimes, api responses are not paginated, ie: for external sources like MDI medications/compounds
      // sending api.client.list({}) || api.client.list(null) || api.client.list(undefined)
      // doesn't work the same as api.client.list()
      // Lab test however do expect params like list({}).
      // this is why this special case has been created
      const res = await api.client?.[APIType]?.list().catch((e) => {
        stateFuncs.onError(e);
      });

      if (res?.body) {
        stateFuncs.onSuccess(res.body);
        stateFuncs.onLoad(false);
      }
    } else {
      let opts = {
        page,
        size: SOME_RIDICULOUS_NUMBER,
      };

      if (APIType === API_Type.Subscription) {
        delete opts.page;
      }

      if (paramsAreDefined) {
        opts = { ...opts, ...params };
      }

      const res = await api.client?.[APIType]?.list(opts).catch((e) => {
        stateFuncs.onLoad(false);
        stateFuncs.onError(e);
      });

      if (res && res.headers && res.body) {
        const totalPages = parseInt(res.headers["total-pages"], 10);
        const currentPage = parseInt(res.headers["current-page"], 10);

        // @ts-ignore
        accumulator.push(...res.body);

        if (totalPages && currentPage && totalPages !== currentPage) {
          getPage(currentPage + 1);
        } else {
          stateFuncs.onLoad(false);
          stateFuncs.onSuccess(accumulator);
        }
      }
    }
  };

  if (!params || emptyParams || (params && paramsAreDefined)) {
    stateFuncs.onLoad(true);
    getPage(1);
  }
};

export const useAPIList = (
  APIType: API_Type,
  params?: Record<string, unknown>,
  dependencies?: any,
  showInfoAlert = true
): Array<any> => {
  const addInfoAlert = useContext(AddInfoContext);
  const [results, setResults] = useState<any | []>([]);
  const [error, setError] = useState("");
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllResults(APIType, params, {
      onError: (e) => {
        if (showInfoAlert) {
          addInfoAlert({ status: "error", message: formatError(e) });
        }
        setError(e);
      },
      onSuccess: (r) => setResults(r),
      onLoad: setIsLoading,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies || []);

  return [results, error, loading];
};
