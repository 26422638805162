/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The ConversationsaddChatParticipantConversation model module.
 * @module model/ConversationsaddChatParticipantConversation
 * @version v0.3
 */
export class ConversationsaddChatParticipantConversation {
  /**
   * Constructs a new <code>ConversationsaddChatParticipantConversation</code>.
   * @alias module:model/ConversationsaddChatParticipantConversation
   * @class
   * @param conversationSid {String} Conversation SID
   */
  constructor(conversationSid) {
    this.conversationSid = conversationSid;
  }

  /**
   * Constructs a <code>ConversationsaddChatParticipantConversation</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ConversationsaddChatParticipantConversation} obj Optional instance to populate.
   * @return {module:model/ConversationsaddChatParticipantConversation} The populated <code>ConversationsaddChatParticipantConversation</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ConversationsaddChatParticipantConversation();
      if (data.hasOwnProperty("conversation_sid"))
        obj.conversationSid = ApiClient.convertToType(data["conversation_sid"], "String");
      if (data.hasOwnProperty("user_id"))
        obj.userId = ApiClient.convertToType(data["user_id"], "String");
    }
    return obj;
  }
}

/**
 * Conversation SID
 * @member {String} conversationSid
 */
ConversationsaddChatParticipantConversation.prototype.conversationSid = undefined;

/**
 * User ID, if not provided, the current user is used
 * @member {String} userId
 */
ConversationsaddChatParticipantConversation.prototype.userId = undefined;
