import React, {
  useState,
  Dispatch,
  SetStateAction,
  KeyboardEventHandler,
  FocusEventHandler,
} from "react";
import Section from "components/patients/Section";
import { Paper } from "@mui/material";
import CreatableSelect from "react-select/creatable";
import { alpha } from "@mui/material/styles";
import { theme } from "styles/theme";
import { OnChangeValue } from "react-select";
import { Option } from "components/styled/async-search-select/AsyncSearchSelect.types";

const PatientAllergens = ({
  allergens,
  setAllergens,
  originalAllergens,
}: {
  allergens: string[];
  setAllergens: Dispatch<SetStateAction<string[]>>;
  originalAllergens: string[];
}): JSX.Element => {
  const allergensAsOptions = allergens?.map((allergy) => {
    return { label: allergy, value: allergy };
  });
  const [inputValue, setInputValue] = useState<string>();

  const onDelete = (value: OnChangeValue<Option, true>) => {
    const newAllergensArray = value.map((v) => {
      return v.value;
    });

    setAllergens(newAllergensArray);
  };

  const handleInputChange = (inputVal: string) => {
    setInputValue(inputVal);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (!allergens.includes(inputValue)) {
          setAllergens([...allergens, inputValue]);
        }
        setInputValue("");
        event.preventDefault();
    }
  };

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    if (!inputValue) return;
    if (!allergens.includes(inputValue)) {
      setAllergens([...allergens, inputValue]);
    }
    setInputValue("");
  };

  return (
    <Section title="Allergies">
      <Paper
        sx={{
          padding: (theme) => theme.spacing(4),
          borderRadius: (theme) => theme.spacing(2),
        }}
      >
        <CreatableSelect
          isClearable={false}
          components={{ DropdownIndicator: () => null }}
          inputValue={inputValue}
          isMulti
          menuIsOpen={false}
          onChange={onDelete}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={handleOnBlur}
          placeholder="Add allergy"
          value={allergensAsOptions}
          styles={{
            multiValueRemove: (base, option) => ({
              ...base,
              display: originalAllergens.includes(option.data.value) ? "none" : "inherit",
              ":hover": {
                backgroundColor: alpha(theme.palette.primary.light, 0.4),
                borderRadius: "10px",
              },
            }),
            multiValue: (styles, option) => ({
              ...styles,
              backgroundColor: alpha(theme.palette.primary.light, 0.1),
              borderRadius: "10px",
              paddingRight: originalAllergens.includes(option.data.value) ? "6px" : "3px",
            }),
          }}
        />
      </Paper>
    </Section>
  );
};

export default PatientAllergens;
