/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The AttachmentsBody model module.
 * @module model/AttachmentsBody
 * @version v0.3
 */
export class AttachmentsBody {
  /**
   * Constructs a new <code>AttachmentsBody</code>.
   * @alias module:model/AttachmentsBody
   * @class
   * @param userId {String}
   * @param fileType {String}
   * @param file {Blob}
   */
  constructor(userId, fileType, file) {
    this.userId = userId;
    this.fileType = fileType;
    this.file = file;
  }

  /**
   * Constructs a <code>AttachmentsBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AttachmentsBody} obj Optional instance to populate.
   * @return {module:model/AttachmentsBody} The populated <code>AttachmentsBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AttachmentsBody();
      if (data.hasOwnProperty("user_id"))
        obj.userId = ApiClient.convertToType(data["user_id"], "String");
      if (data.hasOwnProperty("description"))
        obj.description = ApiClient.convertToType(data["description"], "String");
      if (data.hasOwnProperty("file_type"))
        obj.fileType = ApiClient.convertToType(data["file_type"], "String");
      if (data.hasOwnProperty("file")) obj.file = ApiClient.convertToType(data["file"], "Blob");
    }
    return obj;
  }
}

/**
 * @member {String} userId
 */
AttachmentsBody.prototype.userId = undefined;

/**
 * @member {String} description
 */
AttachmentsBody.prototype.description = undefined;

/**
 * @member {String} fileType
 */
AttachmentsBody.prototype.fileType = undefined;

/**
 * @member {Blob} file
 */
AttachmentsBody.prototype.file = undefined;
