import { alpha } from "@mui/material/styles";
import { Autocomplete, Divider, Paper, TextField } from "@mui/material";
import Box from "@mui/material/Box/Box";
import ClickAwayListener from "@mui/base/ClickAwayListener/ClickAwayListener";
import debounce from "lodash.debounce";
import React, { useState, useCallback, useEffect } from "react";

import { colors } from "styles/colors";
import { formatError } from "utils/strings";
import { AsyncSearchSelectV2Props } from "./AsyncSearchSelectV2.types";
import { theme } from "styles/theme";
import { Option } from "./AsyncSearchSelectV2.types";

const AsyncSearchSelectV2 = (props: AsyncSearchSelectV2Props): JSX.Element => {
  const {
    value,
    label,
    onChange,
    onBlur,
    fetchOptions,
    createOption,
    variant = "outlined",
    readOnly = false,
    disabled = false,
    required = false,
    noOptionsText = "No matching options",
    createNewOptionText = "Create new option",
    error = false,
    helperText = "",
    minSearchLength = 0,
  } = props;

  const [input, setInput] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [options, setOptions] = useState<Option[]>([]);
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setLoadingOptions(true);
        const response = await fetchOptions(search);
        setOptions(response);
        setLoadingOptions(false);
      } catch (e) {
        console.error(formatError(e));
        setOptions([]);
        setLoadingOptions(false);
      }
    })();
  }, [fetchOptions, search]);

  const inputIsValidNewOption =
    input === search &&
    !loadingOptions &&
    !(value?.value.toLowerCase() === input.toLowerCase()) &&
    !options.map((t) => t.value.toLowerCase()).includes(input.toLowerCase());

  const CustomPaper = (props: React.HTMLAttributes<HTMLElement>) => {
    return (
      <Paper
        elevation={8}
        {...props}
        sx={{ border: 1, borderColor: colors.lightGrey4 }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Box sx={{ maxHeight: "200px", overflow: "scroll" }}>{props.children}</Box>
        <Divider />
        {createOption && input && (
          <Box
            onMouseDown={(e) => {
              e.preventDefault();
            }}
          >
            <Box
              sx={{
                paddingX: (theme) => theme.spacing(2),
                paddingY: (theme) => theme.spacing(1),
                fontSize: "13px",
              }}
            >
              {createNewOptionText}
            </Box>
            <Box
              sx={{
                paddingX: (theme) => theme.spacing(2),
                paddingY: (theme) => theme.spacing(1),
                ":hover": inputIsValidNewOption
                  ? {
                      backgroundColor: alpha(theme.palette.primary.light, 0.1),
                      cursor: "pointer",
                    }
                  : {},
              }}
              onMouseDown={async (e) => {
                e.preventDefault();
                if (inputIsValidNewOption) {
                  try {
                    const response = await createOption(input);
                    onChange({ label: input, value: response.data.id });
                  } catch {
                    console.error("Could not create option");
                  } finally {
                    setInput("");
                  }
                }
              }}
              color={inputIsValidNewOption ? colors.black : colors.disabledGrey}
            >
              {input}
            </Box>
          </Box>
        )}
      </Paper>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSearchString = useCallback(
    debounce((inputText: string) => {
      setSearch(inputText);
    }, 500),
    []
  );

  useEffect(() => {
    (async () => {
      if (input.length >= minSearchLength) {
        updateSearchString(input);
      }
    })();
  }, [input, minSearchLength, updateSearchString]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
      }}
    >
      <Autocomplete<Option, false>
        open={open}
        value={value}
        getOptionLabel={(option) => option.label}
        filterOptions={(x) => x}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        inputValue={input}
        onInputChange={(_, newInput) => {
          setInput(newInput);
        }}
        onChange={(_, selectedOption) => {
          onChange(selectedOption);
          setOpen(false);
        }}
        readOnly={readOnly}
        disabled={disabled}
        popupIcon={null}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              value={input}
              label={label}
              onClick={(e) => {
                e.preventDefault();
                if (!readOnly && !disabled) {
                  setOpen(true);
                }
              }}
              onBlur={onBlur}
              onKeyDown={(event) => {
                if (event.key === "Backspace") {
                  event.stopPropagation();
                }
              }}
              variant={variant}
              disabled={disabled}
              error={error}
              helperText={helperText}
              required={required}
            />
          );
        }}
        options={options}
        noOptionsText={noOptionsText}
        PaperComponent={CustomPaper}
      />
    </ClickAwayListener>
  );
};

export default AsyncSearchSelectV2;
