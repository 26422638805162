/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { IntegrationscurexaCurexaIntegration } from "./IntegrationscurexaCurexaIntegration";

/**
 * The IntegrationsCurexaBody model module.
 * @module model/IntegrationsCurexaBody
 * @version v0.3
 */
export class IntegrationsCurexaBody {
  /**
   * Constructs a new <code>IntegrationsCurexaBody</code>.
   * @alias module:model/IntegrationsCurexaBody
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>IntegrationsCurexaBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/IntegrationsCurexaBody} obj Optional instance to populate.
   * @return {module:model/IntegrationsCurexaBody} The populated <code>IntegrationsCurexaBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new IntegrationsCurexaBody();
      if (data.hasOwnProperty("curexa_integration"))
        obj.curexaIntegration = IntegrationscurexaCurexaIntegration.constructFromObject(
          data["curexa_integration"]
        );
    }
    return obj;
  }
}

/**
 * @member {module:model/IntegrationscurexaCurexaIntegration} curexaIntegration
 */
IntegrationsCurexaBody.prototype.curexaIntegration = undefined;
