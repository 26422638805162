/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The IdAvatarBody1 model module.
 * @module model/IdAvatarBody1
 * @version v0.3
 */
export class IdAvatarBody1 {
  /**
   * Constructs a new <code>IdAvatarBody1</code>.
   * @alias module:model/IdAvatarBody1
   * @class
   * @param avatar {Blob} File that will be uploaded as an attachment and associated as the avatar
   */
  constructor(avatar) {
    this.avatar = avatar;
  }

  /**
   * Constructs a <code>IdAvatarBody1</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/IdAvatarBody1} obj Optional instance to populate.
   * @return {module:model/IdAvatarBody1} The populated <code>IdAvatarBody1</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new IdAvatarBody1();
      if (data.hasOwnProperty("avatar"))
        obj.avatar = ApiClient.convertToType(data["avatar"], "Blob");
    }
    return obj;
  }
}

/**
 * File that will be uploaded as an attachment and associated as the avatar
 * @member {Blob} avatar
 */
IdAvatarBody1.prototype.avatar = undefined;
