/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The TaskstemplatesTaskTemplate model module.
 * @module model/TaskstemplatesTaskTemplate
 * @version v0.3
 */
export class TaskstemplatesTaskTemplate {
  /**
   * Constructs a new <code>TaskstemplatesTaskTemplate</code>.
   * @alias module:model/TaskstemplatesTaskTemplate
   * @class
   * @param name {String} Name of the task template
   */
  constructor(name) {
    this.name = name;
  }

  /**
   * Constructs a <code>TaskstemplatesTaskTemplate</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TaskstemplatesTaskTemplate} obj Optional instance to populate.
   * @return {module:model/TaskstemplatesTaskTemplate} The populated <code>TaskstemplatesTaskTemplate</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TaskstemplatesTaskTemplate();
      if (data.hasOwnProperty("name")) obj.name = ApiClient.convertToType(data["name"], "String");
      if (data.hasOwnProperty("description"))
        obj.description = ApiClient.convertToType(data["description"], "String");
      if (data.hasOwnProperty("external_id"))
        obj.externalId = ApiClient.convertToType(data["external_id"], "String");
      if (data.hasOwnProperty("tag_list"))
        obj.tagList = ApiClient.convertToType(data["tag_list"], ["String"]);
      if (data.hasOwnProperty("cms_entry_id"))
        obj.cmsEntryId = ApiClient.convertToType(data["cms_entry_id"], "String");
    }
    return obj;
  }
}

/**
 * Name of the task template
 * @member {String} name
 */
TaskstemplatesTaskTemplate.prototype.name = undefined;

/**
 * Description of the task template
 * @member {String} description
 */
TaskstemplatesTaskTemplate.prototype.description = undefined;

/**
 * External ID for this task template
 * @member {String} externalId
 */
TaskstemplatesTaskTemplate.prototype.externalId = undefined;

/**
 * Add an array of strings to help manage your resources
 * @member {Array.<String>} tagList
 */
TaskstemplatesTaskTemplate.prototype.tagList = undefined;

/**
 * Add a link to your CMS content
 * @member {String} cmsEntryId
 */
TaskstemplatesTaskTemplate.prototype.cmsEntryId = undefined;
