/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The SubscriptionsidSubscriptionPrices model module.
 * @module model/SubscriptionsidSubscriptionPrices
 * @version v0.3
 */
export class SubscriptionsidSubscriptionPrices {
  /**
   * Constructs a new <code>SubscriptionsidSubscriptionPrices</code>.
   * @alias module:model/SubscriptionsidSubscriptionPrices
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>SubscriptionsidSubscriptionPrices</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SubscriptionsidSubscriptionPrices} obj Optional instance to populate.
   * @return {module:model/SubscriptionsidSubscriptionPrices} The populated <code>SubscriptionsidSubscriptionPrices</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SubscriptionsidSubscriptionPrices();
      if (data.hasOwnProperty("id")) obj.id = ApiClient.convertToType(data["id"], "String");
      if (data.hasOwnProperty("price"))
        obj.price = ApiClient.convertToType(data["price"], "String");
      if (data.hasOwnProperty("delete"))
        obj._delete = ApiClient.convertToType(data["delete"], "Boolean");
    }
    return obj;
  }
}

/**
 * ID of the Stripe price line item you want to update
 * @member {String} id
 */
SubscriptionsidSubscriptionPrices.prototype.id = undefined;

/**
 * New price (plan) for the subscription line item
 * @member {String} price
 */
SubscriptionsidSubscriptionPrices.prototype.price = undefined;

/**
 * Set to true if you want to delete the line item
 * @member {Boolean} _delete
 */
SubscriptionsidSubscriptionPrices.prototype._delete = undefined;
