import { useQuery } from "@tanstack/react-query";
import { ApiParams } from "types/apiTypes";
import { ReactQueryKeys } from "constants/keys";
import { capableClient } from "../../../services/capableClient";
import { PatientRelatedPerson } from "../models/PatientRelatedPerson";

interface PatientRelatedPersonParams extends ApiParams {
  sortBy?: Array<"created_at" | "-created_at" | "updated_at" | "-updated_at">;
}

const fetchPatientRelatedPersons = async (params: PatientRelatedPersonParams) => {
  try {
    const response = await capableClient.patientRelatedPersons.getPatientRelatedPersons(params);
    return response as { data: PatientRelatedPerson[]; headers: Record<string, string> };
  } catch (e) {
    const error = `Error retrieving patient related person`;
    throw new Error(error);
  }
};

export const usePatientRelatedPersons = (params: PatientRelatedPersonParams) => {
  return useQuery(
    [ReactQueryKeys.PATIENT_RELATED_PERSONS, params],
    () => fetchPatientRelatedPersons(params),
    { keepPreviousData: true }
  );
};
