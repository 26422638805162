import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import MuiSelect from "@mui/material/Select";
import React from "react";

import { SelectProps } from "./Select.types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const Select = (props: SelectProps): JSX.Element => {
  const {
    variant = "standard",
    fullWidth,
    label,
    inputID,
    value,
    onChange,
    choices,
    multiple,
    formControlStyle,
    selectStyle,
    inputProps,
    renderValue,
    readOnly,
    disabled = false,
    labelStyles,
    error = false,
    helperText,
    size = "medium",
    noneOption = false,
  } = props;

  const handleChange = (e) => {
    if (multiple) {
      onChange(typeof e.target.value === "string" ? e.target.value.split(",") : e.target.value);
    } else onChange(e.target.value);
  };

  return (
    <FormControl
      variant={variant}
      fullWidth={fullWidth}
      sx={formControlStyle}
      error={!!error}
      size={size}
    >
      <InputLabel id={inputID} sx={labelStyles}>
        {label}
      </InputLabel>
      <MuiSelect
        labelId={inputID}
        id={inputID}
        label={label}
        value={value}
        onChange={handleChange}
        multiple={multiple}
        MenuProps={MenuProps}
        inputProps={inputProps}
        renderValue={renderValue}
        sx={selectStyle}
        readOnly={readOnly}
        disabled={disabled}
      >
        {noneOption && (
          <MenuItem value={""}>
            <em>None</em>
          </MenuItem>
        )}
        {choices?.map((choice) => {
          return (
            <MenuItem key={choice.value} value={choice.value}>
              {choice.label}
            </MenuItem>
          );
        })}
      </MuiSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default Select;
