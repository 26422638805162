/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { AllergensidAllergen } from "./AllergensidAllergen";

/**
 * The AllergensIdBody model module.
 * @module model/AllergensIdBody
 * @version v0.3
 */
export class AllergensIdBody {
  /**
   * Constructs a new <code>AllergensIdBody</code>.
   * @alias module:model/AllergensIdBody
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>AllergensIdBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AllergensIdBody} obj Optional instance to populate.
   * @return {module:model/AllergensIdBody} The populated <code>AllergensIdBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AllergensIdBody();
      if (data.hasOwnProperty("allergen"))
        obj.allergen = AllergensidAllergen.constructFromObject(data["allergen"]);
    }
    return obj;
  }
}

/**
 * @member {module:model/AllergensidAllergen} allergen
 */
AllergensIdBody.prototype.allergen = undefined;
