/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The IdCardBody model module.
 * @module model/IdCardBody
 * @version v0.3
 */
export class IdCardBody {
  /**
   * Constructs a new <code>IdCardBody</code>.
   * @alias module:model/IdCardBody
   * @class
   * @param insuranceCardFront {Blob}
   * @param insuranceCardBack {Blob}
   */
  constructor(insuranceCardFront, insuranceCardBack) {
    this.insuranceCardFront = insuranceCardFront;
    this.insuranceCardBack = insuranceCardBack;
  }

  /**
   * Constructs a <code>IdCardBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/IdCardBody} obj Optional instance to populate.
   * @return {module:model/IdCardBody} The populated <code>IdCardBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new IdCardBody();
      if (data.hasOwnProperty("insurance_card_front"))
        obj.insuranceCardFront = ApiClient.convertToType(data["insurance_card_front"], "Blob");
      if (data.hasOwnProperty("insurance_card_back"))
        obj.insuranceCardBack = ApiClient.convertToType(data["insurance_card_back"], "Blob");
    }
    return obj;
  }
}

/**
 * @member {Blob} insuranceCardFront
 */
IdCardBody.prototype.insuranceCardFront = undefined;

/**
 * @member {Blob} insuranceCardBack
 */
IdCardBody.prototype.insuranceCardBack = undefined;
