/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { IntegrationsacuityAcuityIntegration } from "./IntegrationsacuityAcuityIntegration";

/**
 * The IntegrationsLgcBody model module.
 * @module model/IntegrationsLgcBody
 * @version v0.3
 */
export class IntegrationsLgcBody {
  /**
   * Constructs a new <code>IntegrationsLgcBody</code>.
   * @alias module:model/IntegrationsLgcBody
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>IntegrationsLgcBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/IntegrationsLgcBody} obj Optional instance to populate.
   * @return {module:model/IntegrationsLgcBody} The populated <code>IntegrationsLgcBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new IntegrationsLgcBody();
      if (data.hasOwnProperty("lgc_integration"))
        obj.lgcIntegration = IntegrationsacuityAcuityIntegration.constructFromObject(
          data["lgc_integration"]
        );
    }
    return obj;
  }
}

/**
 * @member {module:model/IntegrationsacuityAcuityIntegration} lgcIntegration
 */
IntegrationsLgcBody.prototype.lgcIntegration = undefined;
