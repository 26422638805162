import { format } from "date-fns";
import { Eligibility, Policy } from "models/Insurance/insurance";

// Context on implementation of getLocalTimezone() and getLocalTimezoneOffset():
// https://bobbyhadz.com/blog/javascript-get-timezone-name

// Gets the local timezone
// There is no global standardized list of timezones, but:
// IANA (e.g. "America/New_York") is more verbose, and is the generally accepted accurate format
// Abbreviations (e.g. "EST") are more concise but outdated and not always unique
export const getLocalTimezone = (format: "IANA" | "abbrev" = "IANA"): string => {
  return format === "IANA"
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : new Date()
        .toLocaleDateString("en-US", {
          day: "2-digit",
          timeZoneName: "short",
        })
        .slice(4);
};

// Gets the offset from UTC to the local timezone (takes into account daylight savings)
export const getLocalTimezoneOffset = (units: "hours" | "minutes" = "hours"): number => {
  const offset = -new Date().getTimezoneOffset();
  return units === "hours" ? offset / 60 : offset;
};

export const unixTimestampToMs = (unix) => unix * 1000;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLocaleDateString = (d: string, options?: any): string => {
  const LocalDate = new Date(d);

  const defaultOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const mergedOptions = { ...defaultOptions, ...options };
  return LocalDate.toLocaleDateString("en-US", mergedOptions);
};

export const getLocaleTimeString = (d: string): string => {
  const LocalDate = new Date(d);
  const options = {
    hour: "numeric",
    minute: "numeric",
  } as const;
  return LocalDate.toLocaleTimeString(undefined, options);
};

export const getLocaleDateTimeString = (d: string): string => {
  const LocalDate = new Date(d);
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  } as const;
  return LocalDate.toLocaleDateString("en-US", options);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMessageTimestampLabel = (sent: any): string => {
  if (typeof sent != "number") {
    if (typeof sent == "object" || typeof sent === "string") {
      try {
        sent = Date.parse(sent);
      } catch (error) {
        return "unknown";
      }
    } else {
      return "unknown";
    }
  }
  const now = Date.now();
  const diff = now - sent;
  let timeLabel;
  if (diff < 60000) {
    timeLabel = `< 1 minute ago`;
  } else if (diff < 3600000) {
    const minutes = Math.floor(diff / 60000);
    timeLabel = `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (diff < 86400000) {
    const hours = Math.floor(diff / 3600000);
    timeLabel = `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else {
    const sentDate = new Date(sent);
    timeLabel = format(sentDate, "MMM d, yyyy h:mmaaa");
  }
  return timeLabel;
};

export const intervalDatesFromTimes = (date: Date, startTime: string, endTime: string) => {
  if (!(date && startTime && endTime)) {
    return { startDate: null, endDate: null };
  }
  const startDate = new Date(date);

  const startTimePieces: number[] = startTime.split(":").map((t) => parseInt(t));
  const endTimePieces: number[] = endTime.split(":").map((t) => parseInt(t));

  const startOn = new Date(startDate.setHours(startTimePieces[0], startTimePieces[1]));
  let endOn = new Date(startDate.setHours(endTimePieces[0], endTimePieces[1]));

  if (endOn < startOn) {
    endOn = new Date(endOn.setDate(endOn.getDate() + 1));
  }

  return { startDate: startOn, endDate: endOn };
};

export const datePlusTime = (date: Date, time: string | undefined | null) => {
  if (!time) {
    return date;
  }
  const timePieces: number[] = time.split(":").map((t) => parseInt(t));

  return new Date(date.setHours(timePieces[0], timePieces[1]));
};

export const getEffectiveDate = (policy: Policy, checks: Eligibility[]) => {
  const lastCheck = checks.find((check) => check.insurance_policy_id === policy.id);

  if (lastCheck) {
    return getLocaleDateString(lastCheck.effective_start_date);
  }
  return null;
};
