/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The CarePlansCarePlan model module.
 * @module model/CarePlansCarePlan
 * @version v0.3
 */
export class CarePlansCarePlan {
  /**
   * Constructs a new <code>CarePlansCarePlan</code>.
   * @alias module:model/CarePlansCarePlan
   * @class
   * @param carePlanTemplateId {String} ID of the care plan template being used to create the care plan
   * @param patientId {String} ID of the patient being assigned the care plan
   */
  constructor(carePlanTemplateId, patientId) {
    this.carePlanTemplateId = carePlanTemplateId;
    this.patientId = patientId;
  }

  /**
   * Constructs a <code>CarePlansCarePlan</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CarePlansCarePlan} obj Optional instance to populate.
   * @return {module:model/CarePlansCarePlan} The populated <code>CarePlansCarePlan</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CarePlansCarePlan();
      if (data.hasOwnProperty("care_plan_template_id"))
        obj.carePlanTemplateId = ApiClient.convertToType(data["care_plan_template_id"], "String");
      if (data.hasOwnProperty("patient_id"))
        obj.patientId = ApiClient.convertToType(data["patient_id"], "String");
      if (data.hasOwnProperty("name")) obj.name = ApiClient.convertToType(data["name"], "String");
      if (data.hasOwnProperty("description"))
        obj.description = ApiClient.convertToType(data["description"], "String");
      if (data.hasOwnProperty("status"))
        obj.status = ApiClient.convertToType(data["status"], "String");
      if (data.hasOwnProperty("tag_list"))
        obj.tagList = ApiClient.convertToType(data["tag_list"], ["String"]);
      if (data.hasOwnProperty("cms_entry_id"))
        obj.cmsEntryId = ApiClient.convertToType(data["cms_entry_id"], "String");
    }
    return obj;
  }
}

/**
 * ID of the care plan template being used to create the care plan
 * @member {String} carePlanTemplateId
 */
CarePlansCarePlan.prototype.carePlanTemplateId = undefined;

/**
 * ID of the patient being assigned the care plan
 * @member {String} patientId
 */
CarePlansCarePlan.prototype.patientId = undefined;

/**
 * Name of the care plan. Defaults to the name of the care plan template being used if a new name is not provided.
 * @member {String} name
 */
CarePlansCarePlan.prototype.name = undefined;

/**
 * General description of the care plan. Defaults to the description of the care plan template being used if a new description is not provided.
 * @member {String} description
 */
CarePlansCarePlan.prototype.description = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
CarePlansCarePlan.StatusEnum = {
  /**
   * value: "active"
   * @const
   */
  active: "active",

  /**
   * value: "completed"
   * @const
   */
  completed: "completed",

  /**
   * value: "revoked"
   * @const
   */
  revoked: "revoked",

  /**
   * value: "draft"
   * @const
   */
  draft: "draft",

  /**
   * value: "on_hold"
   * @const
   */
  onHold: "on_hold",

  /**
   * value: "entered_in_error"
   * @const
   */
  enteredInError: "entered_in_error",

  /**
   * value: "unknown"
   * @const
   */
  unknown: "unknown",
};
/**
 * Status of the care plan
 * @member {module:model/CarePlansCarePlan.StatusEnum} status
 * @default 'active'
 */
CarePlansCarePlan.prototype.status = "active";

/**
 * Add an array of strings to help manage your resources
 * @member {Array.<String>} tagList
 */
CarePlansCarePlan.prototype.tagList = undefined;

/**
 * Add a link to your CMS content
 * @member {String} cmsEntryId
 */
CarePlansCarePlan.prototype.cmsEntryId = undefined;
