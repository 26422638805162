import { PhotonClient, PhotonProvider } from "@photonhealth/react";
import api from "api/";
import { ENV } from "constants/environments";
import React, { createContext, useEffect, useState } from "react";
import { ReactChildren } from "types/react";
import { useHistory } from "react-router-dom";

export const PhotonHealthIntegrationPresent = createContext(false);

const photonClientCredentials = async () => {
  try {
    let photonHealthClientId = localStorage.getItem("photon_health_client_id");
    let photonHealthOrganizationId = localStorage.getItem("photon_health_org_id");

    if (!photonHealthClientId || !photonHealthOrganizationId) {
      const photonHealthIntegration = await api.client.IntegrationsPhotonHealth.list();
      photonHealthClientId = photonHealthIntegration.body.spa_client_id;
      photonHealthOrganizationId = photonHealthIntegration.body.organization_id;

      if (photonHealthClientId && photonHealthOrganizationId) {
        localStorage.setItem("photon_health_client_id", photonHealthClientId);
        localStorage.setItem("photon_health_org_id", photonHealthOrganizationId);
      }
    }

    return { photonHealthClientId, photonHealthOrganizationId };
  } catch (error) {
    if (error instanceof Error && !error.message.toLowerCase().match(/not found/)) {
      console.error(error);
    }

    return {};
  }
};

export const PhotonHealthProvider = ({ children }: ReactChildren): JSX.Element => {
  const [photonHealthClient, setPhotonHealthClient] = useState<PhotonClient | null>(null);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const { photonHealthClientId, photonHealthOrganizationId } = await photonClientCredentials();

      if (photonHealthClientId && photonHealthOrganizationId) {
        let client = new PhotonClient({
          domain:
            process.env.REACT_APP_ENV === ENV.PRODUCTION
              ? "auth.photon.health"
              : "auth.neutron.health",
          clientId: photonHealthClientId,
          redirectURI: window.location.origin,
          organization: photonHealthOrganizationId,
        });

        if (process.env.REACT_APP_ENV !== ENV.PRODUCTION) {
          client = client.setDevelopment();
        }

        setPhotonHealthClient(client);
      }
    })();
  }, []);

  if (photonHealthClient) {
    return (
      <PhotonHealthIntegrationPresent.Provider value={true}>
        <PhotonProvider
          client={photonHealthClient}
          onRedirectCallback={(appState) => {
            history.push(appState?.returnTo);
          }}
        >
          {children}
        </PhotonProvider>
      </PhotonHealthIntegrationPresent.Provider>
    );
  } else {
    return (
      <PhotonHealthIntegrationPresent.Provider value={false}>
        {children}
      </PhotonHealthIntegrationPresent.Provider>
    );
  }
};
