import Box from "@mui/material/Box";
import React from "react";

const Section = ({
  title,
  style,
  children,
  actionButtons,
}: {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionButtons?: any;
}): JSX.Element => {
  return (
    <Box
      id={title}
      sx={{
        width: "100%",
        padding: (theme) => theme.spacing(2, 0),
        ...style,
      }}
    >
      <Box
        sx={{
          fontWeight: "bold",
          fontSize: "16px",
          margin: (theme) => theme.spacing(2, 0),
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {title}
        <Box>
          {actionButtons &&
            actionButtons.map((b, i) => (
              <Box
                component="span"
                key={i}
                sx={{
                  marginRight: (theme) => (i === actionButtons.length - 1 ? "" : theme.spacing(2)),
                }}
              >
                {b}{" "}
              </Box>
            ))}
        </Box>
      </Box>
      <Box sx={{ margin: (theme) => theme.spacing(1, 0, 4, 0) }}>{children}</Box>
    </Box>
  );
};

export default Section;
