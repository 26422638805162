/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { PaymentIntentsPaymentIntent } from "./PaymentIntentsPaymentIntent";

/**
 * The PaymentIntentsBody model module.
 * @module model/PaymentIntentsBody
 * @version v0.3
 */
export class PaymentIntentsBody {
  /**
   * Constructs a new <code>PaymentIntentsBody</code>.
   * @alias module:model/PaymentIntentsBody
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>PaymentIntentsBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PaymentIntentsBody} obj Optional instance to populate.
   * @return {module:model/PaymentIntentsBody} The populated <code>PaymentIntentsBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PaymentIntentsBody();
      if (data.hasOwnProperty("payment_intent"))
        obj.paymentIntent = PaymentIntentsPaymentIntent.constructFromObject(data["payment_intent"]);
    }
    return obj;
  }
}

/**
 * @member {module:model/PaymentIntentsPaymentIntent} paymentIntent
 */
PaymentIntentsBody.prototype.paymentIntent = undefined;
