import { useQuery } from "@tanstack/react-query";
import { ReactQueryKeys } from "constants/keys";
import { ObservationType } from "../models/ObservationType";
import { fetchAllHelper } from "hooks/fetchAllHelper";

const fetchObservationTypes = async () => {
  const response = await fetchAllHelper("observationTypes", "getObservationsTypes", {});
  return response.data as ObservationType[];
};

export const useObservationTypes = () => {
  return useQuery([ReactQueryKeys.OBSERVATION_TYPES], fetchObservationTypes, {});
};
