/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { AppointmentsAppointment } from "./AppointmentsAppointment";

/**
 * The AppointmentsBody model module.
 * @module model/AppointmentsBody
 * @version v0.3
 */
export class AppointmentsBody {
  /**
   * Constructs a new <code>AppointmentsBody</code>.
   * @alias module:model/AppointmentsBody
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>AppointmentsBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AppointmentsBody} obj Optional instance to populate.
   * @return {module:model/AppointmentsBody} The populated <code>AppointmentsBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AppointmentsBody();
      if (data.hasOwnProperty("appointment"))
        obj.appointment = AppointmentsAppointment.constructFromObject(data["appointment"]);
    }
    return obj;
  }
}

/**
 * @member {module:model/AppointmentsAppointment} appointment
 */
AppointmentsBody.prototype.appointment = undefined;
