import api from "api/";
import { API_Type } from "hooks/listAll";

export const getAllPagesBody = async <T>(type: API_Type, PAGE_SIZE = 500, opt = {}) => {
  const firstPage = await api.client?.[type].list({
    page: 1,
    size: PAGE_SIZE,
    ...opt,
  });
  const totalPage = firstPage.headers["total-pages"];

  const otherPages = await Promise.all(
    //totalPage - 1 to remove the first page as we already have it
    Array.from(Array(totalPage - 1), (_, i) => {
      return api.client?.[type].list({
        // start at page 2 as we have the first page
        page: i + 2,
        size: PAGE_SIZE,
        ...opt,
      });
    })
  );

  const allPagesBody = [firstPage, ...otherPages].flatMap((page) => {
    return page.body;
  });

  return allPagesBody as T[];
};
