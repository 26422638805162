/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The PatientsPatientRaces model module.
 * @module model/PatientsPatientRaces
 * @version v0.3
 */
export class PatientsPatientRaces {
  /**
   * Constructs a new <code>PatientsPatientRaces</code>.
   * @alias module:model/PatientsPatientRaces
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>PatientsPatientRaces</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PatientsPatientRaces} obj Optional instance to populate.
   * @return {module:model/PatientsPatientRaces} The populated <code>PatientsPatientRaces</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PatientsPatientRaces();
      if (data.hasOwnProperty("level_2_display"))
        obj.level2Display = ApiClient.convertToType(data["level_2_display"], "String");
      if (data.hasOwnProperty("level_3_display"))
        obj.level3Display = ApiClient.convertToType(data["level_3_display"], "String");
    }
    return obj;
  }
}

/**
 * Allowed values for the <code>level2Display</code> property.
 * @enum {String}
 * @readonly
 */
PatientsPatientRaces.Level2DisplayEnum = {
  /**
   * value: "American Indian or Alaska Native"
   * @const
   */
  americanIndianOrAlaskaNative: "American Indian or Alaska Native",

  /**
   * value: "Asian"
   * @const
   */
  asian: "Asian",

  /**
   * value: "Black or African American"
   * @const
   */
  blackOrAfricanAmerican: "Black or African American",

  /**
   * value: "Native Hawaiian or Other Pacific Islander"
   * @const
   */
  nativeHawaiianOrOtherPacificIslander: "Native Hawaiian or Other Pacific Islander",

  /**
   * value: "White"
   * @const
   */
  white: "White",
};
/**
 * @member {module:model/PatientsPatientRaces.Level2DisplayEnum} level2Display
 */
PatientsPatientRaces.prototype.level2Display = undefined;

/**
 * Allowed values for the <code>level3Display</code> property.
 * @enum {String}
 * @readonly
 */
PatientsPatientRaces.Level3DisplayEnum = {
  /**
   * value: "American Indian"
   * @const
   */
  americanIndian: "American Indian",

  /**
   * value: "Alaska Native"
   * @const
   */
  alaskaNative: "Alaska Native",

  /**
   * value: "Abenaki"
   * @const
   */
  abenaki: "Abenaki",

  /**
   * value: "Algonquian"
   * @const
   */
  algonquian: "Algonquian",

  /**
   * value: "Apache"
   * @const
   */
  apache: "Apache",

  /**
   * value: "Arapaho"
   * @const
   */
  arapaho: "Arapaho",

  /**
   * value: "Arikara"
   * @const
   */
  arikara: "Arikara",

  /**
   * value: "Assiniboine"
   * @const
   */
  assiniboine: "Assiniboine",

  /**
   * value: "Assiniboine Sioux"
   * @const
   */
  assiniboineSioux: "Assiniboine Sioux",

  /**
   * value: "Bannock"
   * @const
   */
  bannock: "Bannock",

  /**
   * value: "Blackfeet"
   * @const
   */
  blackfeet: "Blackfeet",

  /**
   * value: "Brotherton"
   * @const
   */
  brotherton: "Brotherton",

  /**
   * value: "Burt Lake Band"
   * @const
   */
  burtLakeBand: "Burt Lake Band",

  /**
   * value: "Caddo"
   * @const
   */
  caddo: "Caddo",

  /**
   * value: "Cahuilla"
   * @const
   */
  cahuilla: "Cahuilla",

  /**
   * value: "California Tribes"
   * @const
   */
  californiaTribes: "California Tribes",

  /**
   * value: "Canadian and Latin American Indian"
   * @const
   */
  canadianAndLatinAmericanIndian: "Canadian and Latin American Indian",

  /**
   * value: "Catawba"
   * @const
   */
  catawba: "Catawba",

  /**
   * value: "Cayuse"
   * @const
   */
  cayuse: "Cayuse",

  /**
   * value: "Chehalis"
   * @const
   */
  chehalis: "Chehalis",

  /**
   * value: "Chemakuan"
   * @const
   */
  chemakuan: "Chemakuan",

  /**
   * value: "Chemehuevi"
   * @const
   */
  chemehuevi: "Chemehuevi",

  /**
   * value: "Cherokee"
   * @const
   */
  cherokee: "Cherokee",

  /**
   * value: "Cherokee Shawnee"
   * @const
   */
  cherokeeShawnee: "Cherokee Shawnee",

  /**
   * value: "Cheyenne"
   * @const
   */
  cheyenne: "Cheyenne",

  /**
   * value: "Cheyenne-Arapaho"
   * @const
   */
  cheyenneArapaho: "Cheyenne-Arapaho",

  /**
   * value: "Chickahominy"
   * @const
   */
  chickahominy: "Chickahominy",

  /**
   * value: "Chickasaw"
   * @const
   */
  chickasaw: "Chickasaw",

  /**
   * value: "Chinook"
   * @const
   */
  chinook: "Chinook",

  /**
   * value: "Chippewa"
   * @const
   */
  chippewa: "Chippewa",

  /**
   * value: "Chippewa Cree"
   * @const
   */
  chippewaCree: "Chippewa Cree",

  /**
   * value: "Chitimacha"
   * @const
   */
  chitimacha: "Chitimacha",

  /**
   * value: "Choctaw"
   * @const
   */
  choctaw: "Choctaw",

  /**
   * value: "Chumash"
   * @const
   */
  chumash: "Chumash",

  /**
   * value: "Clear Lake"
   * @const
   */
  clearLake: "Clear Lake",

  /**
   * value: "Coeur D'Alene"
   * @const
   */
  coeurDAlene: "Coeur D'Alene",

  /**
   * value: "Coharie"
   * @const
   */
  coharie: "Coharie",

  /**
   * value: "Colorado River"
   * @const
   */
  coloradoRiver: "Colorado River",

  /**
   * value: "Colville"
   * @const
   */
  colville: "Colville",

  /**
   * value: "Comanche"
   * @const
   */
  comanche: "Comanche",

  /**
   * value: "Coos, Lower Umpqua, Siuslaw"
   * @const
   */
  coosLowerUmpquaSiuslaw: "Coos, Lower Umpqua, Siuslaw",

  /**
   * value: "Coos"
   * @const
   */
  coos: "Coos",

  /**
   * value: "Coquilles"
   * @const
   */
  coquilles: "Coquilles",

  /**
   * value: "Costanoan"
   * @const
   */
  costanoan: "Costanoan",

  /**
   * value: "Coushatta"
   * @const
   */
  coushatta: "Coushatta",

  /**
   * value: "Cowlitz"
   * @const
   */
  cowlitz: "Cowlitz",

  /**
   * value: "Cree"
   * @const
   */
  cree: "Cree",

  /**
   * value: "Creek"
   * @const
   */
  creek: "Creek",

  /**
   * value: "Croatan"
   * @const
   */
  croatan: "Croatan",

  /**
   * value: "Crow"
   * @const
   */
  crow: "Crow",

  /**
   * value: "Cupeno"
   * @const
   */
  cupeno: "Cupeno",

  /**
   * value: "Delaware"
   * @const
   */
  delaware: "Delaware",

  /**
   * value: "Diegueno"
   * @const
   */
  diegueno: "Diegueno",

  /**
   * value: "Eastern Tribes"
   * @const
   */
  easternTribes: "Eastern Tribes",

  /**
   * value: "Esselen"
   * @const
   */
  esselen: "Esselen",

  /**
   * value: "Fort Belknap"
   * @const
   */
  fortBelknap: "Fort Belknap",

  /**
   * value: "Fort Berthold"
   * @const
   */
  fortBerthold: "Fort Berthold",

  /**
   * value: "Fort Mcdowell"
   * @const
   */
  fortMcdowell: "Fort Mcdowell",

  /**
   * value: "Fort Hall"
   * @const
   */
  fortHall: "Fort Hall",

  /**
   * value: "Gabrieleno"
   * @const
   */
  gabrieleno: "Gabrieleno",

  /**
   * value: "Grand Ronde"
   * @const
   */
  grandRonde: "Grand Ronde",

  /**
   * value: "Gros Ventres"
   * @const
   */
  grosVentres: "Gros Ventres",

  /**
   * value: "Haliwa"
   * @const
   */
  haliwa: "Haliwa",

  /**
   * value: "Hidatsa"
   * @const
   */
  hidatsa: "Hidatsa",

  /**
   * value: "Hoopa"
   * @const
   */
  hoopa: "Hoopa",

  /**
   * value: "Hoopa Extension"
   * @const
   */
  hoopaExtension: "Hoopa Extension",

  /**
   * value: "Houma"
   * @const
   */
  houma: "Houma",

  /**
   * value: "Inaja-Cosmit"
   * @const
   */
  inajaCosmit: "Inaja-Cosmit",

  /**
   * value: "Iowa"
   * @const
   */
  iowa: "Iowa",

  /**
   * value: "Iroquois"
   * @const
   */
  iroquois: "Iroquois",

  /**
   * value: "Juaneno"
   * @const
   */
  juaneno: "Juaneno",

  /**
   * value: "Kalispel"
   * @const
   */
  kalispel: "Kalispel",

  /**
   * value: "Karuk"
   * @const
   */
  karuk: "Karuk",

  /**
   * value: "Kaw"
   * @const
   */
  kaw: "Kaw",

  /**
   * value: "Kickapoo"
   * @const
   */
  kickapoo: "Kickapoo",

  /**
   * value: "Kiowa"
   * @const
   */
  kiowa: "Kiowa",

  /**
   * value: "Klallam"
   * @const
   */
  klallam: "Klallam",

  /**
   * value: "Klamath"
   * @const
   */
  klamath: "Klamath",

  /**
   * value: "Konkow"
   * @const
   */
  konkow: "Konkow",

  /**
   * value: "Kootenai"
   * @const
   */
  kootenai: "Kootenai",

  /**
   * value: "Lassik"
   * @const
   */
  lassik: "Lassik",

  /**
   * value: "Long Island"
   * @const
   */
  longIsland: "Long Island",

  /**
   * value: "Luiseno"
   * @const
   */
  luiseno: "Luiseno",

  /**
   * value: "Lumbee"
   * @const
   */
  lumbee: "Lumbee",

  /**
   * value: "Lummi"
   * @const
   */
  lummi: "Lummi",

  /**
   * value: "Maidu"
   * @const
   */
  maidu: "Maidu",

  /**
   * value: "Makah"
   * @const
   */
  makah: "Makah",

  /**
   * value: "Maliseet"
   * @const
   */
  maliseet: "Maliseet",

  /**
   * value: "Mandan"
   * @const
   */
  mandan: "Mandan",

  /**
   * value: "Mattaponi"
   * @const
   */
  mattaponi: "Mattaponi",

  /**
   * value: "Menominee"
   * @const
   */
  menominee: "Menominee",

  /**
   * value: "Miami"
   * @const
   */
  miami: "Miami",

  /**
   * value: "Miccosukee"
   * @const
   */
  miccosukee: "Miccosukee",

  /**
   * value: "Micmac"
   * @const
   */
  micmac: "Micmac",

  /**
   * value: "Mission Indians"
   * @const
   */
  missionIndians: "Mission Indians",

  /**
   * value: "Miwok"
   * @const
   */
  miwok: "Miwok",

  /**
   * value: "Modoc"
   * @const
   */
  modoc: "Modoc",

  /**
   * value: "Mohegan"
   * @const
   */
  mohegan: "Mohegan",

  /**
   * value: "Mono"
   * @const
   */
  mono: "Mono",

  /**
   * value: "Nanticoke"
   * @const
   */
  nanticoke: "Nanticoke",

  /**
   * value: "Narragansett"
   * @const
   */
  narragansett: "Narragansett",

  /**
   * value: "Navajo"
   * @const
   */
  navajo: "Navajo",

  /**
   * value: "Nez Perce"
   * @const
   */
  nezPerce: "Nez Perce",

  /**
   * value: "Nomalaki"
   * @const
   */
  nomalaki: "Nomalaki",

  /**
   * value: "Northwest Tribes"
   * @const
   */
  northwestTribes: "Northwest Tribes",

  /**
   * value: "Omaha"
   * @const
   */
  omaha: "Omaha",

  /**
   * value: "Oregon Athabaskan"
   * @const
   */
  oregonAthabaskan: "Oregon Athabaskan",

  /**
   * value: "Osage"
   * @const
   */
  osage: "Osage",

  /**
   * value: "Otoe-Missouria"
   * @const
   */
  otoeMissouria: "Otoe-Missouria",

  /**
   * value: "Ottawa"
   * @const
   */
  ottawa: "Ottawa",

  /**
   * value: "Paiute"
   * @const
   */
  paiute: "Paiute",

  /**
   * value: "Pamunkey"
   * @const
   */
  pamunkey: "Pamunkey",

  /**
   * value: "Passamaquoddy"
   * @const
   */
  passamaquoddy: "Passamaquoddy",

  /**
   * value: "Pawnee"
   * @const
   */
  pawnee: "Pawnee",

  /**
   * value: "Penobscot"
   * @const
   */
  penobscot: "Penobscot",

  /**
   * value: "Peoria"
   * @const
   */
  peoria: "Peoria",

  /**
   * value: "Pequot"
   * @const
   */
  pequot: "Pequot",

  /**
   * value: "Pima"
   * @const
   */
  pima: "Pima",

  /**
   * value: "Piscataway"
   * @const
   */
  piscataway: "Piscataway",

  /**
   * value: "Pit River"
   * @const
   */
  pitRiver: "Pit River",

  /**
   * value: "Pomo"
   * @const
   */
  pomo: "Pomo",

  /**
   * value: "Ponca"
   * @const
   */
  ponca: "Ponca",

  /**
   * value: "Potawatomi"
   * @const
   */
  potawatomi: "Potawatomi",

  /**
   * value: "Powhatan"
   * @const
   */
  powhatan: "Powhatan",

  /**
   * value: "Pueblo"
   * @const
   */
  pueblo: "Pueblo",

  /**
   * value: "Puget Sound Salish"
   * @const
   */
  pugetSoundSalish: "Puget Sound Salish",

  /**
   * value: "Quapaw"
   * @const
   */
  quapaw: "Quapaw",

  /**
   * value: "Quinault"
   * @const
   */
  quinault: "Quinault",

  /**
   * value: "Rappahannock"
   * @const
   */
  rappahannock: "Rappahannock",

  /**
   * value: "Reno-Sparks"
   * @const
   */
  renoSparks: "Reno-Sparks",

  /**
   * value: "Round Valley"
   * @const
   */
  roundValley: "Round Valley",

  /**
   * value: "Sac and Fox"
   * @const
   */
  sacAndFox: "Sac and Fox",

  /**
   * value: "Salinan"
   * @const
   */
  salinan: "Salinan",

  /**
   * value: "Salish"
   * @const
   */
  salish: "Salish",

  /**
   * value: "Salish and Kootenai"
   * @const
   */
  salishAndKootenai: "Salish and Kootenai",

  /**
   * value: "Schaghticoke"
   * @const
   */
  schaghticoke: "Schaghticoke",

  /**
   * value: "Scott Valley"
   * @const
   */
  scottValley: "Scott Valley",

  /**
   * value: "Seminole"
   * @const
   */
  seminole: "Seminole",

  /**
   * value: "Serrano"
   * @const
   */
  serrano: "Serrano",

  /**
   * value: "Shasta"
   * @const
   */
  shasta: "Shasta",

  /**
   * value: "Shawnee"
   * @const
   */
  shawnee: "Shawnee",

  /**
   * value: "Shinnecock"
   * @const
   */
  shinnecock: "Shinnecock",

  /**
   * value: "Shoalwater Bay"
   * @const
   */
  shoalwaterBay: "Shoalwater Bay",

  /**
   * value: "Shoshone"
   * @const
   */
  shoshone: "Shoshone",

  /**
   * value: "Shoshone Paiute"
   * @const
   */
  shoshonePaiute: "Shoshone Paiute",

  /**
   * value: "Siletz"
   * @const
   */
  siletz: "Siletz",

  /**
   * value: "Sioux"
   * @const
   */
  sioux: "Sioux",

  /**
   * value: "Siuslaw"
   * @const
   */
  siuslaw: "Siuslaw",

  /**
   * value: "Spokane"
   * @const
   */
  spokane: "Spokane",

  /**
   * value: "Stewart"
   * @const
   */
  stewart: "Stewart",

  /**
   * value: "Stockbridge"
   * @const
   */
  stockbridge: "Stockbridge",

  /**
   * value: "Susanville"
   * @const
   */
  susanville: "Susanville",

  /**
   * value: "Tohono O'Odham"
   * @const
   */
  tohonoOOdham: "Tohono O'Odham",

  /**
   * value: "Tolowa"
   * @const
   */
  tolowa: "Tolowa",

  /**
   * value: "Tonkawa"
   * @const
   */
  tonkawa: "Tonkawa",

  /**
   * value: "Tygh"
   * @const
   */
  tygh: "Tygh",

  /**
   * value: "Umatilla"
   * @const
   */
  umatilla: "Umatilla",

  /**
   * value: "Umpqua"
   * @const
   */
  umpqua: "Umpqua",

  /**
   * value: "Ute"
   * @const
   */
  ute: "Ute",

  /**
   * value: "Wailaki"
   * @const
   */
  wailaki: "Wailaki",

  /**
   * value: "Walla-Walla"
   * @const
   */
  wallaWalla: "Walla-Walla",

  /**
   * value: "Wampanoag"
   * @const
   */
  wampanoag: "Wampanoag",

  /**
   * value: "Warm Springs"
   * @const
   */
  warmSprings: "Warm Springs",

  /**
   * value: "Wascopum"
   * @const
   */
  wascopum: "Wascopum",

  /**
   * value: "Washoe"
   * @const
   */
  washoe: "Washoe",

  /**
   * value: "Wichita"
   * @const
   */
  wichita: "Wichita",

  /**
   * value: "Wind River"
   * @const
   */
  windRiver: "Wind River",

  /**
   * value: "Winnebago"
   * @const
   */
  winnebago: "Winnebago",

  /**
   * value: "Winnemucca"
   * @const
   */
  winnemucca: "Winnemucca",

  /**
   * value: "Wintun"
   * @const
   */
  wintun: "Wintun",

  /**
   * value: "Wiyot"
   * @const
   */
  wiyot: "Wiyot",

  /**
   * value: "Yakama"
   * @const
   */
  yakama: "Yakama",

  /**
   * value: "Yakama Cowlitz"
   * @const
   */
  yakamaCowlitz: "Yakama Cowlitz",

  /**
   * value: "Yaqui"
   * @const
   */
  yaqui: "Yaqui",

  /**
   * value: "Yavapai Apache"
   * @const
   */
  yavapaiApache: "Yavapai Apache",

  /**
   * value: "Yokuts"
   * @const
   */
  yokuts: "Yokuts",

  /**
   * value: "Yuchi"
   * @const
   */
  yuchi: "Yuchi",

  /**
   * value: "Yuman"
   * @const
   */
  yuman: "Yuman",

  /**
   * value: "Yurok"
   * @const
   */
  yurok: "Yurok",

  /**
   * value: "Chiricahua"
   * @const
   */
  chiricahua: "Chiricahua",

  /**
   * value: "Fort Sill Apache"
   * @const
   */
  fortSillApache: "Fort Sill Apache",

  /**
   * value: "Jicarilla Apache"
   * @const
   */
  jicarillaApache: "Jicarilla Apache",

  /**
   * value: "Lipan Apache"
   * @const
   */
  lipanApache: "Lipan Apache",

  /**
   * value: "Mescalero Apache"
   * @const
   */
  mescaleroApache: "Mescalero Apache",

  /**
   * value: "Oklahoma Apache"
   * @const
   */
  oklahomaApache: "Oklahoma Apache",

  /**
   * value: "Payson Apache"
   * @const
   */
  paysonApache: "Payson Apache",

  /**
   * value: "San Carlos Apache"
   * @const
   */
  sanCarlosApache: "San Carlos Apache",

  /**
   * value: "White Mountain Apache"
   * @const
   */
  whiteMountainApache: "White Mountain Apache",

  /**
   * value: "Northern Arapaho"
   * @const
   */
  northernArapaho: "Northern Arapaho",

  /**
   * value: "Southern Arapaho"
   * @const
   */
  southernArapaho: "Southern Arapaho",

  /**
   * value: "Wind River Arapaho"
   * @const
   */
  windRiverArapaho: "Wind River Arapaho",

  /**
   * value: "Fort Peck Assiniboine Sioux"
   * @const
   */
  fortPeckAssiniboineSioux: "Fort Peck Assiniboine Sioux",

  /**
   * value: "Oklahoma Cado"
   * @const
   */
  oklahomaCado: "Oklahoma Cado",

  /**
   * value: "Agua Caliente Cahuilla"
   * @const
   */
  aguaCalienteCahuilla: "Agua Caliente Cahuilla",

  /**
   * value: "Augustine"
   * @const
   */
  augustine: "Augustine",

  /**
   * value: "Cabazon"
   * @const
   */
  cabazon: "Cabazon",

  /**
   * value: "Los Coyotes"
   * @const
   */
  losCoyotes: "Los Coyotes",

  /**
   * value: "Morongo"
   * @const
   */
  morongo: "Morongo",

  /**
   * value: "Santa Rosa Cahuilla"
   * @const
   */
  santaRosaCahuilla: "Santa Rosa Cahuilla",

  /**
   * value: "Torres-Martinez"
   * @const
   */
  torresMartinez: "Torres-Martinez",

  /**
   * value: "Cahto"
   * @const
   */
  cahto: "Cahto",

  /**
   * value: "Chimariko"
   * @const
   */
  chimariko: "Chimariko",

  /**
   * value: "Coast Miwok"
   * @const
   */
  coastMiwok: "Coast Miwok",

  /**
   * value: "Digger"
   * @const
   */
  digger: "Digger",

  /**
   * value: "Kawaiisu"
   * @const
   */
  kawaiisu: "Kawaiisu",

  /**
   * value: "Kern River"
   * @const
   */
  kernRiver: "Kern River",

  /**
   * value: "Mattole"
   * @const
   */
  mattole: "Mattole",

  /**
   * value: "Red Wood"
   * @const
   */
  redWood: "Red Wood",

  /**
   * value: "Santa Rosa"
   * @const
   */
  santaRosa: "Santa Rosa",

  /**
   * value: "Takelma"
   * @const
   */
  takelma: "Takelma",

  /**
   * value: "Wappo"
   * @const
   */
  wappo: "Wappo",

  /**
   * value: "Yana"
   * @const
   */
  yana: "Yana",

  /**
   * value: "Yuki"
   * @const
   */
  yuki: "Yuki",

  /**
   * value: "Canadian Indian"
   * @const
   */
  canadianIndian: "Canadian Indian",

  /**
   * value: "Central American Indian"
   * @const
   */
  centralAmericanIndian: "Central American Indian",

  /**
   * value: "French American Indian"
   * @const
   */
  frenchAmericanIndian: "French American Indian",

  /**
   * value: "Mexican American Indian"
   * @const
   */
  mexicanAmericanIndian: "Mexican American Indian",

  /**
   * value: "South American Indian"
   * @const
   */
  southAmericanIndian: "South American Indian",

  /**
   * value: "Spanish American Indian"
   * @const
   */
  spanishAmericanIndian: "Spanish American Indian",

  /**
   * value: "Hoh"
   * @const
   */
  hoh: "Hoh",

  /**
   * value: "Quileute"
   * @const
   */
  quileute: "Quileute",

  /**
   * value: "Cherokee Alabama"
   * @const
   */
  cherokeeAlabama: "Cherokee Alabama",

  /**
   * value: "Cherokees of Northeast Alabama"
   * @const
   */
  cherokeesOfNortheastAlabama: "Cherokees of Northeast Alabama",

  /**
   * value: "Cherokees of Southeast Alabama"
   * @const
   */
  cherokeesOfSoutheastAlabama: "Cherokees of Southeast Alabama",

  /**
   * value: "Eastern Cherokee"
   * @const
   */
  easternCherokee: "Eastern Cherokee",

  /**
   * value: "Echota Cherokee"
   * @const
   */
  echotaCherokee: "Echota Cherokee",

  /**
   * value: "Etowah Cherokee"
   * @const
   */
  etowahCherokee: "Etowah Cherokee",

  /**
   * value: "Northern Cherokee"
   * @const
   */
  northernCherokee: "Northern Cherokee",

  /**
   * value: "Tuscola"
   * @const
   */
  tuscola: "Tuscola",

  /**
   * value: "United Keetowah Band of Cherokee"
   * @const
   */
  unitedKeetowahBandOfCherokee: "United Keetowah Band of Cherokee",

  /**
   * value: "Western Cherokee"
   * @const
   */
  westernCherokee: "Western Cherokee",

  /**
   * value: "Northern Cheyenne"
   * @const
   */
  northernCheyenne: "Northern Cheyenne",

  /**
   * value: "Southern Cheyenne"
   * @const
   */
  southernCheyenne: "Southern Cheyenne",

  /**
   * value: "Eastern Chickahominy"
   * @const
   */
  easternChickahominy: "Eastern Chickahominy",

  /**
   * value: "Western Chickahominy"
   * @const
   */
  westernChickahominy: "Western Chickahominy",

  /**
   * value: "Clatsop"
   * @const
   */
  clatsop: "Clatsop",

  /**
   * value: "Columbia River Chinook"
   * @const
   */
  columbiaRiverChinook: "Columbia River Chinook",

  /**
   * value: "Kathlamet"
   * @const
   */
  kathlamet: "Kathlamet",

  /**
   * value: "Upper Chinook"
   * @const
   */
  upperChinook: "Upper Chinook",

  /**
   * value: "Wakiakum Chinook"
   * @const
   */
  wakiakumChinook: "Wakiakum Chinook",

  /**
   * value: "Willapa Chinook"
   * @const
   */
  willapaChinook: "Willapa Chinook",

  /**
   * value: "Wishram"
   * @const
   */
  wishram: "Wishram",

  /**
   * value: "Bad River"
   * @const
   */
  badRiver: "Bad River",

  /**
   * value: "Bay Mills Chippewa"
   * @const
   */
  bayMillsChippewa: "Bay Mills Chippewa",

  /**
   * value: "Bois Forte"
   * @const
   */
  boisForte: "Bois Forte",

  /**
   * value: "Burt Lake Chippewa"
   * @const
   */
  burtLakeChippewa: "Burt Lake Chippewa",

  /**
   * value: "Fond du Lac"
   * @const
   */
  fondDuLac: "Fond du Lac",

  /**
   * value: "Grand Portage"
   * @const
   */
  grandPortage: "Grand Portage",

  /**
   * value: "Grand Traverse Band of Ottawa/Chippewa"
   * @const
   */
  grandTraverseBandOfOttawaChippewa: "Grand Traverse Band of Ottawa/Chippewa",

  /**
   * value: "Keweenaw"
   * @const
   */
  keweenaw: "Keweenaw",

  /**
   * value: "Lac Courte Oreilles"
   * @const
   */
  lacCourteOreilles: "Lac Courte Oreilles",

  /**
   * value: "Lac du Flambeau"
   * @const
   */
  lacDuFlambeau: "Lac du Flambeau",

  /**
   * value: "Lac Vieux Desert Chippewa"
   * @const
   */
  lacVieuxDesertChippewa: "Lac Vieux Desert Chippewa",

  /**
   * value: "Lake Superior"
   * @const
   */
  lakeSuperior: "Lake Superior",

  /**
   * value: "Leech Lake"
   * @const
   */
  leechLake: "Leech Lake",

  /**
   * value: "Little Shell Chippewa"
   * @const
   */
  littleShellChippewa: "Little Shell Chippewa",

  /**
   * value: "Mille Lacs"
   * @const
   */
  milleLacs: "Mille Lacs",

  /**
   * value: "Minnesota Chippewa"
   * @const
   */
  minnesotaChippewa: "Minnesota Chippewa",

  /**
   * value: "Ontonagon"
   * @const
   */
  ontonagon: "Ontonagon",

  /**
   * value: "Red Cliff Chippewa"
   * @const
   */
  redCliffChippewa: "Red Cliff Chippewa",

  /**
   * value: "Red Lake Chippewa"
   * @const
   */
  redLakeChippewa: "Red Lake Chippewa",

  /**
   * value: "Saginaw Chippewa"
   * @const
   */
  saginawChippewa: "Saginaw Chippewa",

  /**
   * value: "St. Croix Chippewa"
   * @const
   */
  stCroixChippewa: "St. Croix Chippewa",

  /**
   * value: "Sault Ste. Marie Chippewa"
   * @const
   */
  saultSteMarieChippewa: "Sault Ste. Marie Chippewa",

  /**
   * value: "Sokoagon Chippewa"
   * @const
   */
  sokoagonChippewa: "Sokoagon Chippewa",

  /**
   * value: "Turtle Mountain"
   * @const
   */
  turtleMountain: "Turtle Mountain",

  /**
   * value: "White Earth"
   * @const
   */
  whiteEarth: "White Earth",

  /**
   * value: "Rocky Boy's Chippewa Cree"
   * @const
   */
  rockyBoysChippewaCree: "Rocky Boy's Chippewa Cree",

  /**
   * value: "Clifton Choctaw"
   * @const
   */
  cliftonChoctaw: "Clifton Choctaw",

  /**
   * value: "Jena Choctaw"
   * @const
   */
  jenaChoctaw: "Jena Choctaw",

  /**
   * value: "Mississippi Choctaw"
   * @const
   */
  mississippiChoctaw: "Mississippi Choctaw",

  /**
   * value: "Mowa Band of Choctaw"
   * @const
   */
  mowaBandOfChoctaw: "Mowa Band of Choctaw",

  /**
   * value: "Oklahoma Choctaw"
   * @const
   */
  oklahomaChoctaw: "Oklahoma Choctaw",

  /**
   * value: "Santa Ynez"
   * @const
   */
  santaYnez: "Santa Ynez",

  /**
   * value: "Oklahoma Comanche"
   * @const
   */
  oklahomaComanche: "Oklahoma Comanche",

  /**
   * value: "Alabama Coushatta"
   * @const
   */
  alabamaCoushatta: "Alabama Coushatta",

  /**
   * value: "Alabama Creek"
   * @const
   */
  alabamaCreek: "Alabama Creek",

  /**
   * value: "Alabama Quassarte"
   * @const
   */
  alabamaQuassarte: "Alabama Quassarte",

  /**
   * value: "Eastern Creek"
   * @const
   */
  easternCreek: "Eastern Creek",

  /**
   * value: "Eastern Muscogee"
   * @const
   */
  easternMuscogee: "Eastern Muscogee",

  /**
   * value: "Kialegee"
   * @const
   */
  kialegee: "Kialegee",

  /**
   * value: "Lower Muscogee"
   * @const
   */
  lowerMuscogee: "Lower Muscogee",

  /**
   * value: "Machis Lower Creek Indian"
   * @const
   */
  machisLowerCreekIndian: "Machis Lower Creek Indian",

  /**
   * value: "Poarch Band"
   * @const
   */
  poarchBand: "Poarch Band",

  /**
   * value: "Principal Creek Indian Nation"
   * @const
   */
  principalCreekIndianNation: "Principal Creek Indian Nation",

  /**
   * value: "Star Clan of Muscogee Creeks"
   * @const
   */
  starClanOfMuscogeeCreeks: "Star Clan of Muscogee Creeks",

  /**
   * value: "Thlopthlocco"
   * @const
   */
  thlopthlocco: "Thlopthlocco",

  /**
   * value: "Tuckabachee"
   * @const
   */
  tuckabachee: "Tuckabachee",

  /**
   * value: "Agua Caliente"
   * @const
   */
  aguaCaliente: "Agua Caliente",

  /**
   * value: "Eastern Delaware"
   * @const
   */
  easternDelaware: "Eastern Delaware",

  /**
   * value: "Lenni-Lenape"
   * @const
   */
  lenniLenape: "Lenni-Lenape",

  /**
   * value: "Munsee"
   * @const
   */
  munsee: "Munsee",

  /**
   * value: "Oklahoma Delaware"
   * @const
   */
  oklahomaDelaware: "Oklahoma Delaware",

  /**
   * value: "Rampough Mountain"
   * @const
   */
  rampoughMountain: "Rampough Mountain",

  /**
   * value: "Sand Hill"
   * @const
   */
  sandHill: "Sand Hill",

  /**
   * value: "Campo"
   * @const
   */
  campo: "Campo",

  /**
   * value: "Capitan Grande"
   * @const
   */
  capitanGrande: "Capitan Grande",

  /**
   * value: "Cuyapaipe"
   * @const
   */
  cuyapaipe: "Cuyapaipe",

  /**
   * value: "La Posta"
   * @const
   */
  laPosta: "La Posta",

  /**
   * value: "Manzanita"
   * @const
   */
  manzanita: "Manzanita",

  /**
   * value: "Mesa Grande"
   * @const
   */
  mesaGrande: "Mesa Grande",

  /**
   * value: "San Pasqual"
   * @const
   */
  sanPasqual: "San Pasqual",

  /**
   * value: "Santa Ysabel"
   * @const
   */
  santaYsabel: "Santa Ysabel",

  /**
   * value: "Sycuan"
   * @const
   */
  sycuan: "Sycuan",

  /**
   * value: "Attacapa"
   * @const
   */
  attacapa: "Attacapa",

  /**
   * value: "Biloxi"
   * @const
   */
  biloxi: "Biloxi",

  /**
   * value: "Georgetown (Eastern Tribes)"
   * @const
   */
  georgetownEasternTribes: "Georgetown (Eastern Tribes)",

  /**
   * value: "Moor"
   * @const
   */
  moor: "Moor",

  /**
   * value: "Nansemond"
   * @const
   */
  nansemond: "Nansemond",

  /**
   * value: "Natchez"
   * @const
   */
  natchez: "Natchez",

  /**
   * value: "Nausu Waiwash"
   * @const
   */
  nausuWaiwash: "Nausu Waiwash",

  /**
   * value: "Nipmuc"
   * @const
   */
  nipmuc: "Nipmuc",

  /**
   * value: "Paugussett"
   * @const
   */
  paugussett: "Paugussett",

  /**
   * value: "Pocomoke Acohonock"
   * @const
   */
  pocomokeAcohonock: "Pocomoke Acohonock",

  /**
   * value: "Southeastern Indians"
   * @const
   */
  southeasternIndians: "Southeastern Indians",

  /**
   * value: "Susquehanock"
   * @const
   */
  susquehanock: "Susquehanock",

  /**
   * value: "Tunica Biloxi"
   * @const
   */
  tunicaBiloxi: "Tunica Biloxi",

  /**
   * value: "Waccamaw-Siousan"
   * @const
   */
  waccamawSiousan: "Waccamaw-Siousan",

  /**
   * value: "Wicomico"
   * @const
   */
  wicomico: "Wicomico",

  /**
   * value: "Atsina"
   * @const
   */
  atsina: "Atsina",

  /**
   * value: "Trinity"
   * @const
   */
  trinity: "Trinity",

  /**
   * value: "Whilkut"
   * @const
   */
  whilkut: "Whilkut",

  /**
   * value: "Iowa of Kansas-Nebraska"
   * @const
   */
  iowaOfKansasNebraska: "Iowa of Kansas-Nebraska",

  /**
   * value: "Iowa of Oklahoma"
   * @const
   */
  iowaOfOklahoma: "Iowa of Oklahoma",

  /**
   * value: "Cayuga"
   * @const
   */
  cayuga: "Cayuga",

  /**
   * value: "Mohawk"
   * @const
   */
  mohawk: "Mohawk",

  /**
   * value: "Oneida"
   * @const
   */
  oneida: "Oneida",

  /**
   * value: "Onondaga"
   * @const
   */
  onondaga: "Onondaga",

  /**
   * value: "Seneca"
   * @const
   */
  seneca: "Seneca",

  /**
   * value: "Seneca Nation"
   * @const
   */
  senecaNation: "Seneca Nation",

  /**
   * value: "Seneca-Cayuga"
   * @const
   */
  senecaCayuga: "Seneca-Cayuga",

  /**
   * value: "Tonawanda Seneca"
   * @const
   */
  tonawandaSeneca: "Tonawanda Seneca",

  /**
   * value: "Tuscarora"
   * @const
   */
  tuscarora: "Tuscarora",

  /**
   * value: "Wyandotte"
   * @const
   */
  wyandotte: "Wyandotte",

  /**
   * value: "Oklahoma Kickapoo"
   * @const
   */
  oklahomaKickapoo: "Oklahoma Kickapoo",

  /**
   * value: "Texas Kickapoo"
   * @const
   */
  texasKickapoo: "Texas Kickapoo",

  /**
   * value: "Oklahoma Kiowa"
   * @const
   */
  oklahomaKiowa: "Oklahoma Kiowa",

  /**
   * value: "Jamestown"
   * @const
   */
  jamestown: "Jamestown",

  /**
   * value: "Lower Elwha"
   * @const
   */
  lowerElwha: "Lower Elwha",

  /**
   * value: "Port Gamble Klallam"
   * @const
   */
  portGambleKlallam: "Port Gamble Klallam",

  /**
   * value: "Matinecock"
   * @const
   */
  matinecock: "Matinecock",

  /**
   * value: "Montauk"
   * @const
   */
  montauk: "Montauk",

  /**
   * value: "Poospatuck"
   * @const
   */
  poospatuck: "Poospatuck",

  /**
   * value: "Setauket"
   * @const
   */
  setauket: "Setauket",

  /**
   * value: "La Jolla"
   * @const
   */
  laJolla: "La Jolla",

  /**
   * value: "Pala"
   * @const
   */
  pala: "Pala",

  /**
   * value: "Pauma"
   * @const
   */
  pauma: "Pauma",

  /**
   * value: "Pechanga"
   * @const
   */
  pechanga: "Pechanga",

  /**
   * value: "Soboba"
   * @const
   */
  soboba: "Soboba",

  /**
   * value: "Twenty-Nine Palms"
   * @const
   */
  twentyNinePalms: "Twenty-Nine Palms",

  /**
   * value: "Temecula"
   * @const
   */
  temecula: "Temecula",

  /**
   * value: "Mountain Maidu"
   * @const
   */
  mountainMaidu: "Mountain Maidu",

  /**
   * value: "Nishinam"
   * @const
   */
  nishinam: "Nishinam",

  /**
   * value: "Illinois Miami"
   * @const
   */
  illinoisMiami: "Illinois Miami",

  /**
   * value: "Indiana Miami"
   * @const
   */
  indianaMiami: "Indiana Miami",

  /**
   * value: "Oklahoma Miami"
   * @const
   */
  oklahomaMiami: "Oklahoma Miami",

  /**
   * value: "Aroostook"
   * @const
   */
  aroostook: "Aroostook",

  /**
   * value: "Alamo Navajo"
   * @const
   */
  alamoNavajo: "Alamo Navajo",

  /**
   * value: "Canoncito Navajo"
   * @const
   */
  canoncitoNavajo: "Canoncito Navajo",

  /**
   * value: "Ramah Navajo"
   * @const
   */
  ramahNavajo: "Ramah Navajo",

  /**
   * value: "Alsea"
   * @const
   */
  alsea: "Alsea",

  /**
   * value: "Celilo"
   * @const
   */
  celilo: "Celilo",

  /**
   * value: "Columbia"
   * @const
   */
  columbia: "Columbia",

  /**
   * value: "Kalapuya"
   * @const
   */
  kalapuya: "Kalapuya",

  /**
   * value: "Molala"
   * @const
   */
  molala: "Molala",

  /**
   * value: "Talakamish"
   * @const
   */
  talakamish: "Talakamish",

  /**
   * value: "Tenino"
   * @const
   */
  tenino: "Tenino",

  /**
   * value: "Tillamook"
   * @const
   */
  tillamook: "Tillamook",

  /**
   * value: "Wenatchee"
   * @const
   */
  wenatchee: "Wenatchee",

  /**
   * value: "Yahooskin"
   * @const
   */
  yahooskin: "Yahooskin",

  /**
   * value: "Burt Lake Ottawa"
   * @const
   */
  burtLakeOttawa: "Burt Lake Ottawa",

  /**
   * value: "Michigan Ottawa"
   * @const
   */
  michiganOttawa: "Michigan Ottawa",

  /**
   * value: "Oklahoma Ottawa"
   * @const
   */
  oklahomaOttawa: "Oklahoma Ottawa",

  /**
   * value: "Bishop"
   * @const
   */
  bishop: "Bishop",

  /**
   * value: "Bridgeport"
   * @const
   */
  bridgeport: "Bridgeport",

  /**
   * value: "Burns Paiute"
   * @const
   */
  burnsPaiute: "Burns Paiute",

  /**
   * value: "Cedarville"
   * @const
   */
  cedarville: "Cedarville",

  /**
   * value: "Fort Bidwell"
   * @const
   */
  fortBidwell: "Fort Bidwell",

  /**
   * value: "Fort Independence"
   * @const
   */
  fortIndependence: "Fort Independence",

  /**
   * value: "Kaibab"
   * @const
   */
  kaibab: "Kaibab",

  /**
   * value: "Las Vegas"
   * @const
   */
  lasVegas: "Las Vegas",

  /**
   * value: "Lone Pine"
   * @const
   */
  lonePine: "Lone Pine",

  /**
   * value: "Lovelock"
   * @const
   */
  lovelock: "Lovelock",

  /**
   * value: "Malheur Paiute"
   * @const
   */
  malheurPaiute: "Malheur Paiute",

  /**
   * value: "Moapa"
   * @const
   */
  moapa: "Moapa",

  /**
   * value: "Northern Paiute"
   * @const
   */
  northernPaiute: "Northern Paiute",

  /**
   * value: "Owens Valley"
   * @const
   */
  owensValley: "Owens Valley",

  /**
   * value: "Pyramid Lake"
   * @const
   */
  pyramidLake: "Pyramid Lake",

  /**
   * value: "San Juan Southern Paiute"
   * @const
   */
  sanJuanSouthernPaiute: "San Juan Southern Paiute",

  /**
   * value: "Southern Paiute"
   * @const
   */
  southernPaiute: "Southern Paiute",

  /**
   * value: "Summit Lake"
   * @const
   */
  summitLake: "Summit Lake",

  /**
   * value: "Utu Utu Gwaitu Paiute"
   * @const
   */
  utuUtuGwaituPaiute: "Utu Utu Gwaitu Paiute",

  /**
   * value: "Walker River"
   * @const
   */
  walkerRiver: "Walker River",

  /**
   * value: "Yerington Paiute"
   * @const
   */
  yeringtonPaiute: "Yerington Paiute",

  /**
   * value: "Indian Township"
   * @const
   */
  indianTownship: "Indian Township",

  /**
   * value: "Pleasant Point Passamaquoddy"
   * @const
   */
  pleasantPointPassamaquoddy: "Pleasant Point Passamaquoddy",

  /**
   * value: "Oklahoma Pawnee"
   * @const
   */
  oklahomaPawnee: "Oklahoma Pawnee",

  /**
   * value: "Oklahoma Peoria"
   * @const
   */
  oklahomaPeoria: "Oklahoma Peoria",

  /**
   * value: "Marshantucket Pequot"
   * @const
   */
  marshantucketPequot: "Marshantucket Pequot",

  /**
   * value: "Gila River Pima-Maricopa"
   * @const
   */
  gilaRiverPimaMaricopa: "Gila River Pima-Maricopa",

  /**
   * value: "Salt River Pima-Maricopa"
   * @const
   */
  saltRiverPimaMaricopa: "Salt River Pima-Maricopa",

  /**
   * value: "Central Pomo"
   * @const
   */
  centralPomo: "Central Pomo",

  /**
   * value: "Dry Creek"
   * @const
   */
  dryCreek: "Dry Creek",

  /**
   * value: "Eastern Pomo"
   * @const
   */
  easternPomo: "Eastern Pomo",

  /**
   * value: "Kashia"
   * @const
   */
  kashia: "Kashia",

  /**
   * value: "Northern Pomo"
   * @const
   */
  northernPomo: "Northern Pomo",

  /**
   * value: "Scotts Valley"
   * @const
   */
  scottsValley: "Scotts Valley",

  /**
   * value: "Stonyford"
   * @const
   */
  stonyford: "Stonyford",

  /**
   * value: "Sulphur Bank"
   * @const
   */
  sulphurBank: "Sulphur Bank",

  /**
   * value: "Nebraska Ponca"
   * @const
   */
  nebraskaPonca: "Nebraska Ponca",

  /**
   * value: "Oklahoma Ponca"
   * @const
   */
  oklahomaPonca: "Oklahoma Ponca",

  /**
   * value: "Citizen Band Potawatomi"
   * @const
   */
  citizenBandPotawatomi: "Citizen Band Potawatomi",

  /**
   * value: "Forest County"
   * @const
   */
  forestCounty: "Forest County",

  /**
   * value: "Hannahville"
   * @const
   */
  hannahville: "Hannahville",

  /**
   * value: "Huron Potawatomi"
   * @const
   */
  huronPotawatomi: "Huron Potawatomi",

  /**
   * value: "Pokagon Potawatomi"
   * @const
   */
  pokagonPotawatomi: "Pokagon Potawatomi",

  /**
   * value: "Prairie Band"
   * @const
   */
  prairieBand: "Prairie Band",

  /**
   * value: "Wisconsin Potawatomi"
   * @const
   */
  wisconsinPotawatomi: "Wisconsin Potawatomi",

  /**
   * value: "Acoma"
   * @const
   */
  acoma: "Acoma",

  /**
   * value: "Arizona Tewa"
   * @const
   */
  arizonaTewa: "Arizona Tewa",

  /**
   * value: "Cochiti"
   * @const
   */
  cochiti: "Cochiti",

  /**
   * value: "Hopi"
   * @const
   */
  hopi: "Hopi",

  /**
   * value: "Isleta"
   * @const
   */
  isleta: "Isleta",

  /**
   * value: "Jemez"
   * @const
   */
  jemez: "Jemez",

  /**
   * value: "Keres"
   * @const
   */
  keres: "Keres",

  /**
   * value: "Laguna"
   * @const
   */
  laguna: "Laguna",

  /**
   * value: "Nambe"
   * @const
   */
  nambe: "Nambe",

  /**
   * value: "Picuris"
   * @const
   */
  picuris: "Picuris",

  /**
   * value: "Piro"
   * @const
   */
  piro: "Piro",

  /**
   * value: "Pojoaque"
   * @const
   */
  pojoaque: "Pojoaque",

  /**
   * value: "San Felipe"
   * @const
   */
  sanFelipe: "San Felipe",

  /**
   * value: "San Ildefonso"
   * @const
   */
  sanIldefonso: "San Ildefonso",

  /**
   * value: "San Juan Pueblo"
   * @const
   */
  sanJuanPueblo: "San Juan Pueblo",

  /**
   * value: "San Juan De"
   * @const
   */
  sanJuanDe: "San Juan De",

  /**
   * value: "San Juan"
   * @const
   */
  sanJuan: "San Juan",

  /**
   * value: "Sandia"
   * @const
   */
  sandia: "Sandia",

  /**
   * value: "Santa Ana"
   * @const
   */
  santaAna: "Santa Ana",

  /**
   * value: "Santa Clara"
   * @const
   */
  santaClara: "Santa Clara",

  /**
   * value: "Santo Domingo"
   * @const
   */
  santoDomingo: "Santo Domingo",

  /**
   * value: "Taos"
   * @const
   */
  taos: "Taos",

  /**
   * value: "Tesuque"
   * @const
   */
  tesuque: "Tesuque",

  /**
   * value: "Tewa"
   * @const
   */
  tewa: "Tewa",

  /**
   * value: "Tigua"
   * @const
   */
  tigua: "Tigua",

  /**
   * value: "Zia"
   * @const
   */
  zia: "Zia",

  /**
   * value: "Zuni"
   * @const
   */
  zuni: "Zuni",

  /**
   * value: "Duwamish"
   * @const
   */
  duwamish: "Duwamish",

  /**
   * value: "Kikiallus"
   * @const
   */
  kikiallus: "Kikiallus",

  /**
   * value: "Lower Skagit"
   * @const
   */
  lowerSkagit: "Lower Skagit",

  /**
   * value: "Muckleshoot"
   * @const
   */
  muckleshoot: "Muckleshoot",

  /**
   * value: "Nisqually"
   * @const
   */
  nisqually: "Nisqually",

  /**
   * value: "Nooksack"
   * @const
   */
  nooksack: "Nooksack",

  /**
   * value: "Port Madison"
   * @const
   */
  portMadison: "Port Madison",

  /**
   * value: "Puyallup"
   * @const
   */
  puyallup: "Puyallup",

  /**
   * value: "Samish"
   * @const
   */
  samish: "Samish",

  /**
   * value: "Sauk-Suiattle"
   * @const
   */
  saukSuiattle: "Sauk-Suiattle",

  /**
   * value: "Skokomish"
   * @const
   */
  skokomish: "Skokomish",

  /**
   * value: "Skykomish"
   * @const
   */
  skykomish: "Skykomish",

  /**
   * value: "Snohomish"
   * @const
   */
  snohomish: "Snohomish",

  /**
   * value: "Snoqualmie"
   * @const
   */
  snoqualmie: "Snoqualmie",

  /**
   * value: "Squaxin Island"
   * @const
   */
  squaxinIsland: "Squaxin Island",

  /**
   * value: "Steilacoom"
   * @const
   */
  steilacoom: "Steilacoom",

  /**
   * value: "Stillaguamish"
   * @const
   */
  stillaguamish: "Stillaguamish",

  /**
   * value: "Suquamish"
   * @const
   */
  suquamish: "Suquamish",

  /**
   * value: "Swinomish"
   * @const
   */
  swinomish: "Swinomish",

  /**
   * value: "Tulalip"
   * @const
   */
  tulalip: "Tulalip",

  /**
   * value: "Upper Skagit"
   * @const
   */
  upperSkagit: "Upper Skagit",

  /**
   * value: "Iowa Sac and Fox"
   * @const
   */
  iowaSacAndFox: "Iowa Sac and Fox",

  /**
   * value: "Missouri Sac and Fox"
   * @const
   */
  missouriSacAndFox: "Missouri Sac and Fox",

  /**
   * value: "Oklahoma Sac and Fox"
   * @const
   */
  oklahomaSacAndFox: "Oklahoma Sac and Fox",

  /**
   * value: "Big Cypress"
   * @const
   */
  bigCypress: "Big Cypress",

  /**
   * value: "Brighton"
   * @const
   */
  brighton: "Brighton",

  /**
   * value: "Florida Seminole"
   * @const
   */
  floridaSeminole: "Florida Seminole",

  /**
   * value: "Hollywood Seminole"
   * @const
   */
  hollywoodSeminole: "Hollywood Seminole",

  /**
   * value: "Oklahoma Seminole"
   * @const
   */
  oklahomaSeminole: "Oklahoma Seminole",

  /**
   * value: "San Manual"
   * @const
   */
  sanManual: "San Manual",

  /**
   * value: "Absentee Shawnee"
   * @const
   */
  absenteeShawnee: "Absentee Shawnee",

  /**
   * value: "Eastern Shawnee"
   * @const
   */
  easternShawnee: "Eastern Shawnee",

  /**
   * value: "Battle Mountain"
   * @const
   */
  battleMountain: "Battle Mountain",

  /**
   * value: "Duckwater"
   * @const
   */
  duckwater: "Duckwater",

  /**
   * value: "Elko"
   * @const
   */
  elko: "Elko",

  /**
   * value: "Ely"
   * @const
   */
  ely: "Ely",

  /**
   * value: "Goshute"
   * @const
   */
  goshute: "Goshute",

  /**
   * value: "Panamint"
   * @const
   */
  panamint: "Panamint",

  /**
   * value: "Ruby Valley"
   * @const
   */
  rubyValley: "Ruby Valley",

  /**
   * value: "Skull Valley"
   * @const
   */
  skullValley: "Skull Valley",

  /**
   * value: "South Fork Shoshone"
   * @const
   */
  southForkShoshone: "South Fork Shoshone",

  /**
   * value: "Te-Moak Western Shoshone"
   * @const
   */
  teMoakWesternShoshone: "Te-Moak Western Shoshone",

  /**
   * value: "Timbi-Sha Shoshone"
   * @const
   */
  timbiShaShoshone: "Timbi-Sha Shoshone",

  /**
   * value: "Washakie"
   * @const
   */
  washakie: "Washakie",

  /**
   * value: "Wind River Shoshone"
   * @const
   */
  windRiverShoshone: "Wind River Shoshone",

  /**
   * value: "Yomba"
   * @const
   */
  yomba: "Yomba",

  /**
   * value: "Duck Valley"
   * @const
   */
  duckValley: "Duck Valley",

  /**
   * value: "Fallon"
   * @const
   */
  fallon: "Fallon",

  /**
   * value: "Fort McDermitt"
   * @const
   */
  fortMcDermitt: "Fort McDermitt",

  /**
   * value: "Blackfoot Sioux"
   * @const
   */
  blackfootSioux: "Blackfoot Sioux",

  /**
   * value: "Brule Sioux"
   * @const
   */
  bruleSioux: "Brule Sioux",

  /**
   * value: "Cheyenne River Sioux"
   * @const
   */
  cheyenneRiverSioux: "Cheyenne River Sioux",

  /**
   * value: "Crow Creek Sioux"
   * @const
   */
  crowCreekSioux: "Crow Creek Sioux",

  /**
   * value: "Dakota Sioux"
   * @const
   */
  dakotaSioux: "Dakota Sioux",

  /**
   * value: "Flandreau Santee"
   * @const
   */
  flandreauSantee: "Flandreau Santee",

  /**
   * value: "Fort Peck"
   * @const
   */
  fortPeck: "Fort Peck",

  /**
   * value: "Lake Traverse Sioux"
   * @const
   */
  lakeTraverseSioux: "Lake Traverse Sioux",

  /**
   * value: "Lower Brule Sioux"
   * @const
   */
  lowerBruleSioux: "Lower Brule Sioux",

  /**
   * value: "Lower Sioux"
   * @const
   */
  lowerSioux: "Lower Sioux",

  /**
   * value: "Mdewakanton Sioux"
   * @const
   */
  mdewakantonSioux: "Mdewakanton Sioux",

  /**
   * value: "Miniconjou"
   * @const
   */
  miniconjou: "Miniconjou",

  /**
   * value: "Oglala Sioux"
   * @const
   */
  oglalaSioux: "Oglala Sioux",

  /**
   * value: "Pine Ridge Sioux"
   * @const
   */
  pineRidgeSioux: "Pine Ridge Sioux",

  /**
   * value: "Pipestone Sioux"
   * @const
   */
  pipestoneSioux: "Pipestone Sioux",

  /**
   * value: "Prairie Island Sioux"
   * @const
   */
  prairieIslandSioux: "Prairie Island Sioux",

  /**
   * value: "Prior Lake Sioux"
   * @const
   */
  priorLakeSioux: "Prior Lake Sioux",

  /**
   * value: "Rosebud Sioux"
   * @const
   */
  rosebudSioux: "Rosebud Sioux",

  /**
   * value: "Sans Arc Sioux"
   * @const
   */
  sansArcSioux: "Sans Arc Sioux",

  /**
   * value: "Santee Sioux"
   * @const
   */
  santeeSioux: "Santee Sioux",

  /**
   * value: "Sisseton-Wahpeton"
   * @const
   */
  sissetonWahpeton: "Sisseton-Wahpeton",

  /**
   * value: "Sisseton Sioux"
   * @const
   */
  sissetonSioux: "Sisseton Sioux",

  /**
   * value: "Spirit Lake Sioux"
   * @const
   */
  spiritLakeSioux: "Spirit Lake Sioux",

  /**
   * value: "Standing Rock Sioux"
   * @const
   */
  standingRockSioux: "Standing Rock Sioux",

  /**
   * value: "Teton Sioux"
   * @const
   */
  tetonSioux: "Teton Sioux",

  /**
   * value: "Two Kettle Sioux"
   * @const
   */
  twoKettleSioux: "Two Kettle Sioux",

  /**
   * value: "Upper Sioux"
   * @const
   */
  upperSioux: "Upper Sioux",

  /**
   * value: "Wahpekute Sioux"
   * @const
   */
  wahpekuteSioux: "Wahpekute Sioux",

  /**
   * value: "Wahpeton Sioux"
   * @const
   */
  wahpetonSioux: "Wahpeton Sioux",

  /**
   * value: "Wazhaza Sioux"
   * @const
   */
  wazhazaSioux: "Wazhaza Sioux",

  /**
   * value: "Yankton Sioux"
   * @const
   */
  yanktonSioux: "Yankton Sioux",

  /**
   * value: "Yanktonai Sioux"
   * @const
   */
  yanktonaiSioux: "Yanktonai Sioux",

  /**
   * value: "Ak-Chin"
   * @const
   */
  akChin: "Ak-Chin",

  /**
   * value: "Gila Bend"
   * @const
   */
  gilaBend: "Gila Bend",

  /**
   * value: "San Xavier"
   * @const
   */
  sanXavier: "San Xavier",

  /**
   * value: "Sells"
   * @const
   */
  sells: "Sells",

  /**
   * value: "Cow Creek Umpqua"
   * @const
   */
  cowCreekUmpqua: "Cow Creek Umpqua",

  /**
   * value: "Allen Canyon"
   * @const
   */
  allenCanyon: "Allen Canyon",

  /**
   * value: "Uintah Ute"
   * @const
   */
  uintahUte: "Uintah Ute",

  /**
   * value: "Ute Mountain Ute"
   * @const
   */
  uteMountainUte: "Ute Mountain Ute",

  /**
   * value: "Gay Head Wampanoag"
   * @const
   */
  gayHeadWampanoag: "Gay Head Wampanoag",

  /**
   * value: "Mashpee Wampanoag"
   * @const
   */
  mashpeeWampanoag: "Mashpee Wampanoag",

  /**
   * value: "Alpine"
   * @const
   */
  alpine: "Alpine",

  /**
   * value: "Carson"
   * @const
   */
  carson: "Carson",

  /**
   * value: "Dresslerville"
   * @const
   */
  dresslerville: "Dresslerville",

  /**
   * value: "Ho-chunk"
   * @const
   */
  hoChunk: "Ho-chunk",

  /**
   * value: "Nebraska Winnebago"
   * @const
   */
  nebraskaWinnebago: "Nebraska Winnebago",

  /**
   * value: "Table Bluff"
   * @const
   */
  tableBluff: "Table Bluff",

  /**
   * value: "Barrio Libre"
   * @const
   */
  barrioLibre: "Barrio Libre",

  /**
   * value: "Pascua Yaqui"
   * @const
   */
  pascuaYaqui: "Pascua Yaqui",

  /**
   * value: "Chukchansi"
   * @const
   */
  chukchansi: "Chukchansi",

  /**
   * value: "Tachi"
   * @const
   */
  tachi: "Tachi",

  /**
   * value: "Tule River"
   * @const
   */
  tuleRiver: "Tule River",

  /**
   * value: "Cocopah"
   * @const
   */
  cocopah: "Cocopah",

  /**
   * value: "Havasupai"
   * @const
   */
  havasupai: "Havasupai",

  /**
   * value: "Hualapai"
   * @const
   */
  hualapai: "Hualapai",

  /**
   * value: "Maricopa"
   * @const
   */
  maricopa: "Maricopa",

  /**
   * value: "Mohave"
   * @const
   */
  mohave: "Mohave",

  /**
   * value: "Quechan"
   * @const
   */
  quechan: "Quechan",

  /**
   * value: "Yavapai"
   * @const
   */
  yavapai: "Yavapai",

  /**
   * value: "Coast Yurok"
   * @const
   */
  coastYurok: "Coast Yurok",

  /**
   * value: "Alaska Indian"
   * @const
   */
  alaskaIndian: "Alaska Indian",

  /**
   * value: "Eskimo"
   * @const
   */
  eskimo: "Eskimo",

  /**
   * value: "Aleut"
   * @const
   */
  aleut: "Aleut",

  /**
   * value: "Alaskan Athabascan"
   * @const
   */
  alaskanAthabascan: "Alaskan Athabascan",

  /**
   * value: "Southeast Alaska"
   * @const
   */
  southeastAlaska: "Southeast Alaska",

  /**
   * value: "Ahtna"
   * @const
   */
  ahtna: "Ahtna",

  /**
   * value: "Alatna"
   * @const
   */
  alatna: "Alatna",

  /**
   * value: "Alexander"
   * @const
   */
  alexander: "Alexander",

  /**
   * value: "Allakaket"
   * @const
   */
  allakaket: "Allakaket",

  /**
   * value: "Alanvik"
   * @const
   */
  alanvik: "Alanvik",

  /**
   * value: "Anvik"
   * @const
   */
  anvik: "Anvik",

  /**
   * value: "Arctic"
   * @const
   */
  arctic: "Arctic",

  /**
   * value: "Beaver"
   * @const
   */
  beaver: "Beaver",

  /**
   * value: "Birch Creek"
   * @const
   */
  birchCreek: "Birch Creek",

  /**
   * value: "Cantwell"
   * @const
   */
  cantwell: "Cantwell",

  /**
   * value: "Chalkyitsik"
   * @const
   */
  chalkyitsik: "Chalkyitsik",

  /**
   * value: "Chickaloon"
   * @const
   */
  chickaloon: "Chickaloon",

  /**
   * value: "Chistochina"
   * @const
   */
  chistochina: "Chistochina",

  /**
   * value: "Chitina"
   * @const
   */
  chitina: "Chitina",

  /**
   * value: "Circle"
   * @const
   */
  circle: "Circle",

  /**
   * value: "Cook Inlet"
   * @const
   */
  cookInlet: "Cook Inlet",

  /**
   * value: "Copper Center"
   * @const
   */
  copperCenter: "Copper Center",

  /**
   * value: "Copper River"
   * @const
   */
  copperRiver: "Copper River",

  /**
   * value: "Dot Lake"
   * @const
   */
  dotLake: "Dot Lake",

  /**
   * value: "Doyon"
   * @const
   */
  doyon: "Doyon",

  /**
   * value: "Eagle"
   * @const
   */
  eagle: "Eagle",

  /**
   * value: "Eklutna"
   * @const
   */
  eklutna: "Eklutna",

  /**
   * value: "Evansville"
   * @const
   */
  evansville: "Evansville",

  /**
   * value: "Fort Yukon"
   * @const
   */
  fortYukon: "Fort Yukon",

  /**
   * value: "Gakona"
   * @const
   */
  gakona: "Gakona",

  /**
   * value: "Galena"
   * @const
   */
  galena: "Galena",

  /**
   * value: "Grayling"
   * @const
   */
  grayling: "Grayling",

  /**
   * value: "Gulkana"
   * @const
   */
  gulkana: "Gulkana",

  /**
   * value: "Healy Lake"
   * @const
   */
  healyLake: "Healy Lake",

  /**
   * value: "Holy Cross"
   * @const
   */
  holyCross: "Holy Cross",

  /**
   * value: "Hughes"
   * @const
   */
  hughes: "Hughes",

  /**
   * value: "Huslia"
   * @const
   */
  huslia: "Huslia",

  /**
   * value: "Iliamna"
   * @const
   */
  iliamna: "Iliamna",

  /**
   * value: "Kaltag"
   * @const
   */
  kaltag: "Kaltag",

  /**
   * value: "Kluti Kaah"
   * @const
   */
  klutiKaah: "Kluti Kaah",

  /**
   * value: "Knik"
   * @const
   */
  knik: "Knik",

  /**
   * value: "Koyukuk"
   * @const
   */
  koyukuk: "Koyukuk",

  /**
   * value: "Lake Minchumina"
   * @const
   */
  lakeMinchumina: "Lake Minchumina",

  /**
   * value: "Lime"
   * @const
   */
  lime: "Lime",

  /**
   * value: "Mcgrath"
   * @const
   */
  mcgrath: "Mcgrath",

  /**
   * value: "Manley Hot Springs"
   * @const
   */
  manleyHotSprings: "Manley Hot Springs",

  /**
   * value: "Mentasta Lake"
   * @const
   */
  mentastaLake: "Mentasta Lake",

  /**
   * value: "Minto"
   * @const
   */
  minto: "Minto",

  /**
   * value: "Nenana"
   * @const
   */
  nenana: "Nenana",

  /**
   * value: "Nikolai"
   * @const
   */
  nikolai: "Nikolai",

  /**
   * value: "Ninilchik"
   * @const
   */
  ninilchik: "Ninilchik",

  /**
   * value: "Nondalton"
   * @const
   */
  nondalton: "Nondalton",

  /**
   * value: "Northway"
   * @const
   */
  northway: "Northway",

  /**
   * value: "Nulato"
   * @const
   */
  nulato: "Nulato",

  /**
   * value: "Pedro Bay"
   * @const
   */
  pedroBay: "Pedro Bay",

  /**
   * value: "Rampart"
   * @const
   */
  rampart: "Rampart",

  /**
   * value: "Ruby"
   * @const
   */
  ruby: "Ruby",

  /**
   * value: "Salamatof"
   * @const
   */
  salamatof: "Salamatof",

  /**
   * value: "Seldovia"
   * @const
   */
  seldovia: "Seldovia",

  /**
   * value: "Slana"
   * @const
   */
  slana: "Slana",

  /**
   * value: "Shageluk"
   * @const
   */
  shageluk: "Shageluk",

  /**
   * value: "Stevens"
   * @const
   */
  stevens: "Stevens",

  /**
   * value: "Stony River"
   * @const
   */
  stonyRiver: "Stony River",

  /**
   * value: "Takotna"
   * @const
   */
  takotna: "Takotna",

  /**
   * value: "Tanacross"
   * @const
   */
  tanacross: "Tanacross",

  /**
   * value: "Tanaina"
   * @const
   */
  tanaina: "Tanaina",

  /**
   * value: "Tanana"
   * @const
   */
  tanana: "Tanana",

  /**
   * value: "Tanana Chiefs"
   * @const
   */
  tananaChiefs: "Tanana Chiefs",

  /**
   * value: "Tazlina"
   * @const
   */
  tazlina: "Tazlina",

  /**
   * value: "Telida"
   * @const
   */
  telida: "Telida",

  /**
   * value: "Tetlin"
   * @const
   */
  tetlin: "Tetlin",

  /**
   * value: "Tok"
   * @const
   */
  tok: "Tok",

  /**
   * value: "Tyonek"
   * @const
   */
  tyonek: "Tyonek",

  /**
   * value: "Venetie"
   * @const
   */
  venetie: "Venetie",

  /**
   * value: "Wiseman"
   * @const
   */
  wiseman: "Wiseman",

  /**
   * value: "Tlingit-Haida"
   * @const
   */
  tlingitHaida: "Tlingit-Haida",

  /**
   * value: "Tsimshian"
   * @const
   */
  tsimshian: "Tsimshian",

  /**
   * value: "Angoon"
   * @const
   */
  angoon: "Angoon",

  /**
   * value: "Central Council of Tlingit and Haida Tribes"
   * @const
   */
  centralCouncilOfTlingitAndHaidaTribes: "Central Council of Tlingit and Haida Tribes",

  /**
   * value: "Chilkat"
   * @const
   */
  chilkat: "Chilkat",

  /**
   * value: "Chilkoot"
   * @const
   */
  chilkoot: "Chilkoot",

  /**
   * value: "Craig"
   * @const
   */
  craig: "Craig",

  /**
   * value: "Douglas"
   * @const
   */
  douglas: "Douglas",

  /**
   * value: "Haida"
   * @const
   */
  haida: "Haida",

  /**
   * value: "Hoonah"
   * @const
   */
  hoonah: "Hoonah",

  /**
   * value: "Hydaburg"
   * @const
   */
  hydaburg: "Hydaburg",

  /**
   * value: "Kake"
   * @const
   */
  kake: "Kake",

  /**
   * value: "Kasaan"
   * @const
   */
  kasaan: "Kasaan",

  /**
   * value: "Kenaitze"
   * @const
   */
  kenaitze: "Kenaitze",

  /**
   * value: "Ketchikan"
   * @const
   */
  ketchikan: "Ketchikan",

  /**
   * value: "Klawock"
   * @const
   */
  klawock: "Klawock",

  /**
   * value: "Pelican"
   * @const
   */
  pelican: "Pelican",

  /**
   * value: "Petersburg"
   * @const
   */
  petersburg: "Petersburg",

  /**
   * value: "Saxman"
   * @const
   */
  saxman: "Saxman",

  /**
   * value: "Sitka"
   * @const
   */
  sitka: "Sitka",

  /**
   * value: "Tenakee Springs"
   * @const
   */
  tenakeeSprings: "Tenakee Springs",

  /**
   * value: "Tlingit"
   * @const
   */
  tlingit: "Tlingit",

  /**
   * value: "Wrangell"
   * @const
   */
  wrangell: "Wrangell",

  /**
   * value: "Yakutat"
   * @const
   */
  yakutat: "Yakutat",

  /**
   * value: "Metlakatla"
   * @const
   */
  metlakatla: "Metlakatla",

  /**
   * value: "Greenland Eskimo"
   * @const
   */
  greenlandEskimo: "Greenland Eskimo",

  /**
   * value: "Inupiat Eskimo"
   * @const
   */
  inupiatEskimo: "Inupiat Eskimo",

  /**
   * value: "Siberian Eskimo"
   * @const
   */
  siberianEskimo: "Siberian Eskimo",

  /**
   * value: "Yupik Eskimo"
   * @const
   */
  yupikEskimo: "Yupik Eskimo",

  /**
   * value: "Ambler"
   * @const
   */
  ambler: "Ambler",

  /**
   * value: "Anaktuvuk"
   * @const
   */
  anaktuvuk: "Anaktuvuk",

  /**
   * value: "Anaktuvuk Pass"
   * @const
   */
  anaktuvukPass: "Anaktuvuk Pass",

  /**
   * value: "Arctic Slope Inupiat"
   * @const
   */
  arcticSlopeInupiat: "Arctic Slope Inupiat",

  /**
   * value: "Arctic Slope Corporation"
   * @const
   */
  arcticSlopeCorporation: "Arctic Slope Corporation",

  /**
   * value: "Atqasuk"
   * @const
   */
  atqasuk: "Atqasuk",

  /**
   * value: "Barrow"
   * @const
   */
  barrow: "Barrow",

  /**
   * value: "Bering Straits Inupiat"
   * @const
   */
  beringStraitsInupiat: "Bering Straits Inupiat",

  /**
   * value: "Brevig Mission"
   * @const
   */
  brevigMission: "Brevig Mission",

  /**
   * value: "Buckland"
   * @const
   */
  buckland: "Buckland",

  /**
   * value: "Chinik"
   * @const
   */
  chinik: "Chinik",

  /**
   * value: "Council"
   * @const
   */
  council: "Council",

  /**
   * value: "Deering"
   * @const
   */
  deering: "Deering",

  /**
   * value: "Elim"
   * @const
   */
  elim: "Elim",

  /**
   * value: "Golovin"
   * @const
   */
  golovin: "Golovin",

  /**
   * value: "Inalik Diomede"
   * @const
   */
  inalikDiomede: "Inalik Diomede",

  /**
   * value: "Inupiaq"
   * @const
   */
  inupiaq: "Inupiaq",

  /**
   * value: "Kaktovik"
   * @const
   */
  kaktovik: "Kaktovik",

  /**
   * value: "Kawerak"
   * @const
   */
  kawerak: "Kawerak",

  /**
   * value: "Kiana"
   * @const
   */
  kiana: "Kiana",

  /**
   * value: "Kivalina"
   * @const
   */
  kivalina: "Kivalina",

  /**
   * value: "Kobuk"
   * @const
   */
  kobuk: "Kobuk",

  /**
   * value: "Kotzebue"
   * @const
   */
  kotzebue: "Kotzebue",

  /**
   * value: "Koyuk"
   * @const
   */
  koyuk: "Koyuk",

  /**
   * value: "Kwiguk"
   * @const
   */
  kwiguk: "Kwiguk",

  /**
   * value: "Mauneluk Inupiat"
   * @const
   */
  maunelukInupiat: "Mauneluk Inupiat",

  /**
   * value: "Nana Inupiat"
   * @const
   */
  nanaInupiat: "Nana Inupiat",

  /**
   * value: "Noatak"
   * @const
   */
  noatak: "Noatak",

  /**
   * value: "Nome"
   * @const
   */
  nome: "Nome",

  /**
   * value: "Noorvik"
   * @const
   */
  noorvik: "Noorvik",

  /**
   * value: "Nuiqsut"
   * @const
   */
  nuiqsut: "Nuiqsut",

  /**
   * value: "Point Hope"
   * @const
   */
  pointHope: "Point Hope",

  /**
   * value: "Point Lay"
   * @const
   */
  pointLay: "Point Lay",

  /**
   * value: "Selawik"
   * @const
   */
  selawik: "Selawik",

  /**
   * value: "Shaktoolik"
   * @const
   */
  shaktoolik: "Shaktoolik",

  /**
   * value: "Shishmaref"
   * @const
   */
  shishmaref: "Shishmaref",

  /**
   * value: "Shungnak"
   * @const
   */
  shungnak: "Shungnak",

  /**
   * value: "Solomon"
   * @const
   */
  solomon: "Solomon",

  /**
   * value: "Teller"
   * @const
   */
  teller: "Teller",

  /**
   * value: "Unalakleet"
   * @const
   */
  unalakleet: "Unalakleet",

  /**
   * value: "Wainwright"
   * @const
   */
  wainwright: "Wainwright",

  /**
   * value: "Wales"
   * @const
   */
  wales: "Wales",

  /**
   * value: "White Mountain"
   * @const
   */
  whiteMountain: "White Mountain",

  /**
   * value: "White Mountain Inupiat"
   * @const
   */
  whiteMountainInupiat: "White Mountain Inupiat",

  /**
   * value: "Mary's Igloo"
   * @const
   */
  marysIgloo: "Mary's Igloo",

  /**
   * value: "Gambell"
   * @const
   */
  gambell: "Gambell",

  /**
   * value: "Savoonga"
   * @const
   */
  savoonga: "Savoonga",

  /**
   * value: "Siberian Yupik"
   * @const
   */
  siberianYupik: "Siberian Yupik",

  /**
   * value: "Akiachak"
   * @const
   */
  akiachak: "Akiachak",

  /**
   * value: "Akiak"
   * @const
   */
  akiak: "Akiak",

  /**
   * value: "Alakanuk"
   * @const
   */
  alakanuk: "Alakanuk",

  /**
   * value: "Aleknagik"
   * @const
   */
  aleknagik: "Aleknagik",

  /**
   * value: "Andreafsky"
   * @const
   */
  andreafsky: "Andreafsky",

  /**
   * value: "Aniak"
   * @const
   */
  aniak: "Aniak",

  /**
   * value: "Atmautluak"
   * @const
   */
  atmautluak: "Atmautluak",

  /**
   * value: "Bethel"
   * @const
   */
  bethel: "Bethel",

  /**
   * value: "Bill Moore's Slough"
   * @const
   */
  billMooresSlough: "Bill Moore's Slough",

  /**
   * value: "Bristol Bay Yupik"
   * @const
   */
  bristolBayYupik: "Bristol Bay Yupik",

  /**
   * value: "Calista Yupik"
   * @const
   */
  calistaYupik: "Calista Yupik",

  /**
   * value: "Chefornak"
   * @const
   */
  chefornak: "Chefornak",

  /**
   * value: "Chevak"
   * @const
   */
  chevak: "Chevak",

  /**
   * value: "Chuathbaluk"
   * @const
   */
  chuathbaluk: "Chuathbaluk",

  /**
   * value: "Clark's Point"
   * @const
   */
  clarksPoint: "Clark's Point",

  /**
   * value: "Crooked Creek"
   * @const
   */
  crookedCreek: "Crooked Creek",

  /**
   * value: "Dillingham"
   * @const
   */
  dillingham: "Dillingham",

  /**
   * value: "Eek"
   * @const
   */
  eek: "Eek",

  /**
   * value: "Ekuk"
   * @const
   */
  ekuk: "Ekuk",

  /**
   * value: "Ekwok"
   * @const
   */
  ekwok: "Ekwok",

  /**
   * value: "Emmonak"
   * @const
   */
  emmonak: "Emmonak",

  /**
   * value: "Goodnews Bay"
   * @const
   */
  goodnewsBay: "Goodnews Bay",

  /**
   * value: "Hooper Bay"
   * @const
   */
  hooperBay: "Hooper Bay",

  /**
   * value: "Iqurmuit (Russian Mission)"
   * @const
   */
  iqurmuitRussianMission: "Iqurmuit (Russian Mission)",

  /**
   * value: "Kalskag"
   * @const
   */
  kalskag: "Kalskag",

  /**
   * value: "Kasigluk"
   * @const
   */
  kasigluk: "Kasigluk",

  /**
   * value: "Kipnuk"
   * @const
   */
  kipnuk: "Kipnuk",

  /**
   * value: "Koliganek"
   * @const
   */
  koliganek: "Koliganek",

  /**
   * value: "Kongiganak"
   * @const
   */
  kongiganak: "Kongiganak",

  /**
   * value: "Kotlik"
   * @const
   */
  kotlik: "Kotlik",

  /**
   * value: "Kwethluk"
   * @const
   */
  kwethluk: "Kwethluk",

  /**
   * value: "Kwigillingok"
   * @const
   */
  kwigillingok: "Kwigillingok",

  /**
   * value: "Levelock"
   * @const
   */
  levelock: "Levelock",

  /**
   * value: "Lower Kalskag"
   * @const
   */
  lowerKalskag: "Lower Kalskag",

  /**
   * value: "Manokotak"
   * @const
   */
  manokotak: "Manokotak",

  /**
   * value: "Marshall"
   * @const
   */
  marshall: "Marshall",

  /**
   * value: "Mekoryuk"
   * @const
   */
  mekoryuk: "Mekoryuk",

  /**
   * value: "Mountain Village"
   * @const
   */
  mountainVillage: "Mountain Village",

  /**
   * value: "Naknek"
   * @const
   */
  naknek: "Naknek",

  /**
   * value: "Napaumute"
   * @const
   */
  napaumute: "Napaumute",

  /**
   * value: "Napakiak"
   * @const
   */
  napakiak: "Napakiak",

  /**
   * value: "Napaskiak"
   * @const
   */
  napaskiak: "Napaskiak",

  /**
   * value: "Newhalen"
   * @const
   */
  newhalen: "Newhalen",

  /**
   * value: "New Stuyahok"
   * @const
   */
  newStuyahok: "New Stuyahok",

  /**
   * value: "Newtok"
   * @const
   */
  newtok: "Newtok",

  /**
   * value: "Nightmute"
   * @const
   */
  nightmute: "Nightmute",

  /**
   * value: "Nunapitchukv"
   * @const
   */
  nunapitchukv: "Nunapitchukv",

  /**
   * value: "Oscarville"
   * @const
   */
  oscarville: "Oscarville",

  /**
   * value: "Pilot Station"
   * @const
   */
  pilotStation: "Pilot Station",

  /**
   * value: "Pitkas Point"
   * @const
   */
  pitkasPoint: "Pitkas Point",

  /**
   * value: "Platinum"
   * @const
   */
  platinum: "Platinum",

  /**
   * value: "Portage Creek"
   * @const
   */
  portageCreek: "Portage Creek",

  /**
   * value: "Quinhagak"
   * @const
   */
  quinhagak: "Quinhagak",

  /**
   * value: "Red Devil"
   * @const
   */
  redDevil: "Red Devil",

  /**
   * value: "St. Michael"
   * @const
   */
  stMichael: "St. Michael",

  /**
   * value: "Scammon Bay"
   * @const
   */
  scammonBay: "Scammon Bay",

  /**
   * value: "Sheldon's Point"
   * @const
   */
  sheldonsPoint: "Sheldon's Point",

  /**
   * value: "Sleetmute"
   * @const
   */
  sleetmute: "Sleetmute",

  /**
   * value: "Stebbins"
   * @const
   */
  stebbins: "Stebbins",

  /**
   * value: "Togiak"
   * @const
   */
  togiak: "Togiak",

  /**
   * value: "Toksook"
   * @const
   */
  toksook: "Toksook",

  /**
   * value: "Tulukskak"
   * @const
   */
  tulukskak: "Tulukskak",

  /**
   * value: "Tuntutuliak"
   * @const
   */
  tuntutuliak: "Tuntutuliak",

  /**
   * value: "Tununak"
   * @const
   */
  tununak: "Tununak",

  /**
   * value: "Twin Hills"
   * @const
   */
  twinHills: "Twin Hills",

  /**
   * value: "Georgetown (Yupik-Eskimo)"
   * @const
   */
  georgetownYupikEskimo: "Georgetown (Yupik-Eskimo)",

  /**
   * value: "St. Mary's"
   * @const
   */
  stMarys: "St. Mary's",

  /**
   * value: "Umkumiate"
   * @const
   */
  umkumiate: "Umkumiate",

  /**
   * value: "Alutiiq Aleut"
   * @const
   */
  alutiiqAleut: "Alutiiq Aleut",

  /**
   * value: "Bristol Bay Aleut"
   * @const
   */
  bristolBayAleut: "Bristol Bay Aleut",

  /**
   * value: "Chugach Aleut"
   * @const
   */
  chugachAleut: "Chugach Aleut",

  /**
   * value: "Eyak"
   * @const
   */
  eyak: "Eyak",

  /**
   * value: "Koniag Aleut"
   * @const
   */
  koniagAleut: "Koniag Aleut",

  /**
   * value: "Sugpiaq"
   * @const
   */
  sugpiaq: "Sugpiaq",

  /**
   * value: "Suqpigaq"
   * @const
   */
  suqpigaq: "Suqpigaq",

  /**
   * value: "Unangan Aleut"
   * @const
   */
  unanganAleut: "Unangan Aleut",

  /**
   * value: "Tatitlek"
   * @const
   */
  tatitlek: "Tatitlek",

  /**
   * value: "Ugashik"
   * @const
   */
  ugashik: "Ugashik",

  /**
   * value: "Chignik"
   * @const
   */
  chignik: "Chignik",

  /**
   * value: "Chignik Lake"
   * @const
   */
  chignikLake: "Chignik Lake",

  /**
   * value: "Egegik"
   * @const
   */
  egegik: "Egegik",

  /**
   * value: "Igiugig"
   * @const
   */
  igiugig: "Igiugig",

  /**
   * value: "Ivanof Bay"
   * @const
   */
  ivanofBay: "Ivanof Bay",

  /**
   * value: "King Salmon"
   * @const
   */
  kingSalmon: "King Salmon",

  /**
   * value: "Kokhanok"
   * @const
   */
  kokhanok: "Kokhanok",

  /**
   * value: "Perryville"
   * @const
   */
  perryville: "Perryville",

  /**
   * value: "Pilot Point"
   * @const
   */
  pilotPoint: "Pilot Point",

  /**
   * value: "Port Heiden"
   * @const
   */
  portHeiden: "Port Heiden",

  /**
   * value: "Chenega"
   * @const
   */
  chenega: "Chenega",

  /**
   * value: "Chugach Corporation"
   * @const
   */
  chugachCorporation: "Chugach Corporation",

  /**
   * value: "English Bay"
   * @const
   */
  englishBay: "English Bay",

  /**
   * value: "Port Graham"
   * @const
   */
  portGraham: "Port Graham",

  /**
   * value: "Akhiok"
   * @const
   */
  akhiok: "Akhiok",

  /**
   * value: "Agdaagux"
   * @const
   */
  agdaagux: "Agdaagux",

  /**
   * value: "Karluk"
   * @const
   */
  karluk: "Karluk",

  /**
   * value: "Kodiak"
   * @const
   */
  kodiak: "Kodiak",

  /**
   * value: "Larsen Bay"
   * @const
   */
  larsenBay: "Larsen Bay",

  /**
   * value: "Old Harbor"
   * @const
   */
  oldHarbor: "Old Harbor",

  /**
   * value: "Ouzinkie"
   * @const
   */
  ouzinkie: "Ouzinkie",

  /**
   * value: "Port Lions"
   * @const
   */
  portLions: "Port Lions",

  /**
   * value: "Akutan"
   * @const
   */
  akutan: "Akutan",

  /**
   * value: "Aleut Corporation"
   * @const
   */
  aleutCorporation: "Aleut Corporation",

  /**
   * value: "Aleutian"
   * @const
   */
  aleutian: "Aleutian",

  /**
   * value: "Aleutian Islander"
   * @const
   */
  aleutianIslander: "Aleutian Islander",

  /**
   * value: "Atka"
   * @const
   */
  atka: "Atka",

  /**
   * value: "Belkofski"
   * @const
   */
  belkofski: "Belkofski",

  /**
   * value: "Chignik Lagoon"
   * @const
   */
  chignikLagoon: "Chignik Lagoon",

  /**
   * value: "King Cove"
   * @const
   */
  kingCove: "King Cove",

  /**
   * value: "False Pass"
   * @const
   */
  falsePass: "False Pass",

  /**
   * value: "Nelson Lagoon"
   * @const
   */
  nelsonLagoon: "Nelson Lagoon",

  /**
   * value: "Nikolski"
   * @const
   */
  nikolski: "Nikolski",

  /**
   * value: "Pauloff Harbor"
   * @const
   */
  pauloffHarbor: "Pauloff Harbor",

  /**
   * value: "Qagan Toyagungin"
   * @const
   */
  qaganToyagungin: "Qagan Toyagungin",

  /**
   * value: "Qawalangin"
   * @const
   */
  qawalangin: "Qawalangin",

  /**
   * value: "St. George"
   * @const
   */
  stGeorge: "St. George",

  /**
   * value: "St. Paul"
   * @const
   */
  stPaul: "St. Paul",

  /**
   * value: "Sand Point"
   * @const
   */
  sandPoint: "Sand Point",

  /**
   * value: "South Naknek"
   * @const
   */
  southNaknek: "South Naknek",

  /**
   * value: "Unalaska"
   * @const
   */
  unalaska: "Unalaska",

  /**
   * value: "Unga"
   * @const
   */
  unga: "Unga",

  /**
   * value: "Asian Indian"
   * @const
   */
  asianIndian: "Asian Indian",

  /**
   * value: "Bangladeshi"
   * @const
   */
  bangladeshi: "Bangladeshi",

  /**
   * value: "Bhutanese"
   * @const
   */
  bhutanese: "Bhutanese",

  /**
   * value: "Burmese"
   * @const
   */
  burmese: "Burmese",

  /**
   * value: "Cambodian"
   * @const
   */
  cambodian: "Cambodian",

  /**
   * value: "Chinese"
   * @const
   */
  chinese: "Chinese",

  /**
   * value: "Taiwanese"
   * @const
   */
  taiwanese: "Taiwanese",

  /**
   * value: "Filipino"
   * @const
   */
  filipino: "Filipino",

  /**
   * value: "Hmong"
   * @const
   */
  hmong: "Hmong",

  /**
   * value: "Indonesian"
   * @const
   */
  indonesian: "Indonesian",

  /**
   * value: "Japanese"
   * @const
   */
  japanese: "Japanese",

  /**
   * value: "Korean"
   * @const
   */
  korean: "Korean",

  /**
   * value: "Laotian"
   * @const
   */
  laotian: "Laotian",

  /**
   * value: "Malaysian"
   * @const
   */
  malaysian: "Malaysian",

  /**
   * value: "Okinawan"
   * @const
   */
  okinawan: "Okinawan",

  /**
   * value: "Pakistani"
   * @const
   */
  pakistani: "Pakistani",

  /**
   * value: "Sri Lankan"
   * @const
   */
  sriLankan: "Sri Lankan",

  /**
   * value: "Thai"
   * @const
   */
  thai: "Thai",

  /**
   * value: "Vietnamese"
   * @const
   */
  vietnamese: "Vietnamese",

  /**
   * value: "Iwo Jiman"
   * @const
   */
  iwoJiman: "Iwo Jiman",

  /**
   * value: "Maldivian"
   * @const
   */
  maldivian: "Maldivian",

  /**
   * value: "Nepalese"
   * @const
   */
  nepalese: "Nepalese",

  /**
   * value: "Singaporean"
   * @const
   */
  singaporean: "Singaporean",

  /**
   * value: "Madagascar"
   * @const
   */
  madagascar: "Madagascar",

  /**
   * value: "Black"
   * @const
   */
  black: "Black",

  /**
   * value: "African American"
   * @const
   */
  africanAmerican: "African American",

  /**
   * value: "African"
   * @const
   */
  african: "African",

  /**
   * value: "Bahamian"
   * @const
   */
  bahamian: "Bahamian",

  /**
   * value: "Barbadian"
   * @const
   */
  barbadian: "Barbadian",

  /**
   * value: "Dominican"
   * @const
   */
  dominican: "Dominican",

  /**
   * value: "Dominica Islander"
   * @const
   */
  dominicaIslander: "Dominica Islander",

  /**
   * value: "Haitian"
   * @const
   */
  haitian: "Haitian",

  /**
   * value: "Jamaican"
   * @const
   */
  jamaican: "Jamaican",

  /**
   * value: "Tobagoan"
   * @const
   */
  tobagoan: "Tobagoan",

  /**
   * value: "Trinidadian"
   * @const
   */
  trinidadian: "Trinidadian",

  /**
   * value: "West Indian"
   * @const
   */
  westIndian: "West Indian",

  /**
   * value: "Botswanan"
   * @const
   */
  botswanan: "Botswanan",

  /**
   * value: "Ethiopian"
   * @const
   */
  ethiopian: "Ethiopian",

  /**
   * value: "Liberian"
   * @const
   */
  liberian: "Liberian",

  /**
   * value: "Namibian"
   * @const
   */
  namibian: "Namibian",

  /**
   * value: "Nigerian"
   * @const
   */
  nigerian: "Nigerian",

  /**
   * value: "Zairean"
   * @const
   */
  zairean: "Zairean",

  /**
   * value: "Polynesian"
   * @const
   */
  polynesian: "Polynesian",

  /**
   * value: "Micronesian"
   * @const
   */
  micronesian: "Micronesian",

  /**
   * value: "Melanesian"
   * @const
   */
  melanesian: "Melanesian",

  /**
   * value: "Other Pacific Islander"
   * @const
   */
  otherPacificIslander: "Other Pacific Islander",

  /**
   * value: "Native Hawaiian"
   * @const
   */
  nativeHawaiian: "Native Hawaiian",

  /**
   * value: "Samoan"
   * @const
   */
  samoan: "Samoan",

  /**
   * value: "Tahitian"
   * @const
   */
  tahitian: "Tahitian",

  /**
   * value: "Tongan"
   * @const
   */
  tongan: "Tongan",

  /**
   * value: "Tokelauan"
   * @const
   */
  tokelauan: "Tokelauan",

  /**
   * value: "Guamanian or Chamorro"
   * @const
   */
  guamanianOrChamorro: "Guamanian or Chamorro",

  /**
   * value: "Guamanian"
   * @const
   */
  guamanian: "Guamanian",

  /**
   * value: "Chamorro"
   * @const
   */
  chamorro: "Chamorro",

  /**
   * value: "Mariana Islander"
   * @const
   */
  marianaIslander: "Mariana Islander",

  /**
   * value: "Marshallese"
   * @const
   */
  marshallese: "Marshallese",

  /**
   * value: "Palauan"
   * @const
   */
  palauan: "Palauan",

  /**
   * value: "Carolinian"
   * @const
   */
  carolinian: "Carolinian",

  /**
   * value: "Kosraean"
   * @const
   */
  kosraean: "Kosraean",

  /**
   * value: "Pohnpeian"
   * @const
   */
  pohnpeian: "Pohnpeian",

  /**
   * value: "Saipanese"
   * @const
   */
  saipanese: "Saipanese",

  /**
   * value: "Kiribati"
   * @const
   */
  kiribati: "Kiribati",

  /**
   * value: "Chuukese"
   * @const
   */
  chuukese: "Chuukese",

  /**
   * value: "Yapese"
   * @const
   */
  yapese: "Yapese",

  /**
   * value: "Fijian"
   * @const
   */
  fijian: "Fijian",

  /**
   * value: "Papua New Guinean"
   * @const
   */
  papuaNewGuinean: "Papua New Guinean",

  /**
   * value: "Solomon Islander"
   * @const
   */
  solomonIslander: "Solomon Islander",

  /**
   * value: "New Hebrides"
   * @const
   */
  newHebrides: "New Hebrides",

  /**
   * value: "European"
   * @const
   */
  european: "European",

  /**
   * value: "Middle Eastern or North African"
   * @const
   */
  middleEasternOrNorthAfrican: "Middle Eastern or North African",

  /**
   * value: "Arab"
   * @const
   */
  arab: "Arab",

  /**
   * value: "Armenian"
   * @const
   */
  armenian: "Armenian",

  /**
   * value: "English"
   * @const
   */
  english: "English",

  /**
   * value: "French"
   * @const
   */
  french: "French",

  /**
   * value: "German"
   * @const
   */
  german: "German",

  /**
   * value: "Irish"
   * @const
   */
  irish: "Irish",

  /**
   * value: "Italian"
   * @const
   */
  italian: "Italian",

  /**
   * value: "Polish"
   * @const
   */
  polish: "Polish",

  /**
   * value: "Scottish"
   * @const
   */
  scottish: "Scottish",

  /**
   * value: "Assyrian"
   * @const
   */
  assyrian: "Assyrian",

  /**
   * value: "Egyptian"
   * @const
   */
  egyptian: "Egyptian",

  /**
   * value: "Iranian"
   * @const
   */
  iranian: "Iranian",

  /**
   * value: "Iraqi"
   * @const
   */
  iraqi: "Iraqi",

  /**
   * value: "Lebanese"
   * @const
   */
  lebanese: "Lebanese",

  /**
   * value: "Palestinian"
   * @const
   */
  palestinian: "Palestinian",

  /**
   * value: "Syrian"
   * @const
   */
  syrian: "Syrian",

  /**
   * value: "Afghanistani"
   * @const
   */
  afghanistani: "Afghanistani",

  /**
   * value: "Israeli"
   * @const
   */
  israeli: "Israeli",
};
/**
 * @member {module:model/PatientsPatientRaces.Level3DisplayEnum} level3Display
 */
PatientsPatientRaces.prototype.level3Display = undefined;
