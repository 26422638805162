/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The EncountersEncounterNotesAttributes model module.
 * @module model/EncountersEncounterNotesAttributes
 * @version v0.3
 */
export class EncountersEncounterNotesAttributes {
  /**
   * Constructs a new <code>EncountersEncounterNotesAttributes</code>.
   * @alias module:model/EncountersEncounterNotesAttributes
   * @class
   * @param text {String}
   */
  constructor(text) {
    this.text = text;
  }

  /**
   * Constructs a <code>EncountersEncounterNotesAttributes</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EncountersEncounterNotesAttributes} obj Optional instance to populate.
   * @return {module:model/EncountersEncounterNotesAttributes} The populated <code>EncountersEncounterNotesAttributes</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EncountersEncounterNotesAttributes();
      if (data.hasOwnProperty("text")) obj.text = ApiClient.convertToType(data["text"], "String");
    }
    return obj;
  }
}

/**
 * @member {String} text
 */
EncountersEncounterNotesAttributes.prototype.text = undefined;
