import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import React from "react";

import { AuthProvider } from "./contexts/auth";
import { InfoProvider } from "./contexts/info";
import { AccountConfirmation } from "./pages/AccountConfirmation";
import AppAuthenticator from "./AppAuthenticator";
import AuthenticatedApp from "./AuthenticatedApp";
import ForgotUrl from "./pages/ForgotUrl";
import UnauthenticatedFallback from "./pages/UnauthenticatedFallback";
import { PhotonHealthProvider } from "contexts/photonHealth";
import { ForgotPassWord } from "features/authentication/ForgotPassword";
import { ResetPassword } from "features/authentication/ResetPassword";
import { ResetPasswordConfirmation } from "features/authentication/ResetPasswordConfirmation";

function App(): JSX.Element {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <ErrorBoundary FallbackComponent={UnauthenticatedFallback}>
        <Router>
          <AuthProvider>
            <Switch>
              <Route exact path="/confirmation" component={AccountConfirmation} />
              <Route exact path="/forgot_password" component={ForgotPassWord} />
              <Route exact path="/reset_password" component={ResetPassword} />
              <Route
                exact
                path="/reset_password_confirmation"
                component={ResetPasswordConfirmation}
              />
              <Route exact path="/forgot_url" component={ForgotUrl} />
              <AppAuthenticator>
                <InfoProvider>
                  <PhotonHealthProvider>
                    <Route component={AuthenticatedApp} />
                  </PhotonHealthProvider>
                </InfoProvider>
              </AppAuthenticator>
            </Switch>
          </AuthProvider>
        </Router>
      </ErrorBoundary>
    </div>
  );
}

export default App;
