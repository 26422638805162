import { useQuery } from "@tanstack/react-query";

import { Patient } from "models/Patient/Patient.types";
import { ReactQueryKeys } from "constants/keys";
import api from "api";
import { capableClient } from "../../services/capableClient";
import { displayName } from "utils/strings";

const fetchPatient = async (id: string) => {
  const response = await api.client.Patient.get(id);
  if (response.body) {
    return response.body as Patient;
  }
  throw new Error("Could not retrieve patient");
};

export const usePatient = (id: string) => {
  return useQuery([ReactQueryKeys.PATIENT, id], () => fetchPatient(id), {
    enabled: !!id,
  });
};

export const fetchPatientsSearch = async (searchQuery: string) => {
  try {
    const response = await capableClient.patients.getPatients({
      size: 20,
      filters: [
        JSON.stringify({
          field: "first_name",
          operator: "matches",
          value: searchQuery,
        }),
        JSON.stringify({
          field: "last_name",
          operator: "matches",
          value: searchQuery,
        }),
        JSON.stringify({
          field: "email",
          operator: "matches",
          value: searchQuery,
        }),
      ],
      filtersOperator: "or",
    });

    return response.data.map((patient: Patient) => ({
      value: patient.id,
      label: `${displayName(patient)}, Patient ID: ${patient.id}`,
    }));
  } catch (e) {
    const error = "Error retrieving patients";
    console.error(error);
    throw Error(error);
  }
};
