import { useForm, Controller } from "react-hook-form";
import React, { useContext } from "react";
import { Alert, Box, Card, TextField, Typography, useTheme } from "@mui/material";
import { colors } from "styles/colors";
import { BreakpointContext } from "contexts/breakpoint";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Link, useHistory, useLocation } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { CapableLogoLong } from "components/CapableIcons";
import { emailRequiredMessage, emailValidationErrorMessage } from "./Auth.utils";
import { useForgotPassword } from "./useForgotPassword";

const validationSchema = z.object({
  email: z.string().min(1, { message: emailRequiredMessage }).email({
    message: emailValidationErrorMessage,
  }),
});

type ValidationSchema = z.infer<typeof validationSchema>;

export const ForgotPassWord = () => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const { isMobileView } = useContext(BreakpointContext);
  const theme = useTheme();
  const history = useHistory();

  const {
    control,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors, isValid },
  } = useForm<ValidationSchema>({
    defaultValues: {
      email: urlSearchParams.get("email") ?? "",
    },
    resolver: zodResolver(validationSchema),
  });

  const { forgotPassword, isError, isLoading } = useForgotPassword(() => {
    const encodedEmail = encodeURIComponent(getValues("email"));
    history.push(`/reset_password?email=${encodedEmail}`);
  });

  const processForm = async (data: ValidationSchema) => {
    await forgotPassword(data.email);
  };

  const formControlStyles = {
    margin: theme.spacing(1, 0),
    width: "100%",
  };

  return (
    <Box
      sx={{
        backgroundColor: colors.lightGrey1,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Card
        sx={{
          width: isMobileView ? "100%" : "500px",
          marginTop: "100px",
          padding: theme.spacing(5),
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        <Box sx={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
          <CapableLogoLong />
        </Box>

        {isError && (
          <Alert sx={{ marginTop: 2 }} severity="error">
            {isError}
          </Alert>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h3"
            sx={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(4) }}
          >
            Forgot your password?
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
            Enter your email below and we will send you a message to reset your password
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(processForm)}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <>
                <TextField
                  label="Email"
                  variant="outlined"
                  style={formControlStyles}
                  {...field}
                  onBlur={() => {
                    trigger("email");
                  }}
                  onChange={(e) => {
                    setTimeout(() => {
                      trigger("email");
                    }, 5000);
                    e.target.value = e.target.value.trim();
                    field.onChange(e);
                  }}
                  helperText={errors.email?.message}
                  error={!!errors.email}
                />
              </>
            )}
          />

          <LoadingButton
            loading={isLoading}
            variant="contained"
            disabled={!isValid}
            onClick={handleSubmit(processForm)}
            style={formControlStyles}
            sx={{ marginTop: (theme) => theme.spacing(3) }}
          >
            Send code
          </LoadingButton>
        </form>
        <Box>
          <Link to="/">Back to sign in</Link>
        </Box>
      </Card>
    </Box>
  );
};
