import { colors } from "styles/colors";
import AsyncSelect from "react-select/async";
import React, { useState, useRef } from "react";

import { AsyncSearchSelectProps, LiveSelectProps, Option } from "./AsyncSearchSelect.types";

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
  }),
  menu: (provided) => ({ ...provided, zIndex: 2 }),
  multiValue: (styles, { data }) => ({
    ...styles,
    borderRadius: "15px",
    height: "32px",
    alignItems: "center",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    backgroundColor: colors.lightGrey2,
    borderRadius: "50%",
    height: "20px",
    width: "20px",
    marginRight: "5px",
    marginLeft: "5px",
    color: colors.lightGrey6,
    ":hover": {
      backgroundColor: colors.darkGrey,
    },
  }),
};

const AsyncSearchSelect = (props: AsyncSearchSelectProps): JSX.Element => {
  const timeout = useRef<number>();

  const fetchSuggestions = async (search: string): Promise<Option[]> => {
    return props.apiHook(search);
  };

  const onChange = (option: Option | Option[]) => {
    props.onChange(option);
  };

  const handleDebouncedSearch = async (inputValue: string) => {
    return await new Promise<Option[]>((resolve) => {
      clearTimeout(timeout.current);
      timeout.current = window.setTimeout(() => resolve(fetchSuggestions(inputValue)), 600);
    });
  };

  return (
    <LiveSelect
      isMulti={props.isMulti}
      isClearable
      onChange={onChange}
      initialValue={props.value}
      placeholder={props.field}
      noOptionsMessage={() => "Type to Search"}
      getOptions={handleDebouncedSearch}
      ariaLabel={props.field}
    />
  );
};

export default AsyncSearchSelect;

export const LiveSelect = (props: LiveSelectProps) => {
  const {
    initialValue,
    onChange,
    minSearchLength = 2,
    isMulti = false,
    getOptions,
    ...ownProps
  } = props;
  const [value, setValue] = useState<Option | Option[] | null>(initialValue ?? null);

  const loadOptions = async (search: string): Promise<Option[]> => {
    if (search.length < minSearchLength) return [];
    try {
      return await getOptions(search);
    } catch (e) {
      return [];
    }
  };
  const handleChange = (option) => {
    onChange(option);
    setValue(option);
  };

  return (
    <AsyncSelect
      isMulti={isMulti}
      value={value}
      onChange={handleChange}
      loadOptions={loadOptions}
      styles={customStyles}
      maxMenuHeight={250}
      {...ownProps}
    />
  );
};
