import { ErrorBoundary } from "react-error-boundary";
import { Route, Switch } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { Suspense } from "react";

import { useInitializeTwilioClients } from "components/conversations/useTwilioClient.hook";
import { usePractitionerAndTenantAdminsData } from "components/conversations/usePractitionerAndTenantAdminsData.hook";
import EditLayout from "layout/EditLayout";
import EditUser from "components/users/EditUser";
import ObservationTypeGroups from "./features/observationTypeGroups/components/ObservationTypeGroups";
import { colors } from "styles/colors";
import { FullScreenLoader } from "components/FullScreenLoader";

import { captureException } from "@sentry/react";

// Pages ->
const AuthenticatedFallback = React.lazy(() => import("./pages/AuthenticatedFallback"));
const BusinessIntelligence = React.lazy(() => import("pages/BusinessIntelligence"));
const CareplanCreate = React.lazy(() => import("./pages/CareplanCreate"));
const CareplanID = React.lazy(() => import("./pages/CareplanID"));
const CareplanTemplateCreate = React.lazy(() => import("./pages/CareplanTemplateCreate"));
const CareplanTemplateID = React.lazy(() => import("./pages/CareplanTemplateID"));
const CareplanTemplates = React.lazy(() => import("./pages/CareplanTemplates"));
const ConversationChatCreate = React.lazy(
  () => import("components/conversations/ConversationChatCreate")
);
const ConversationContainer = React.lazy(
  () => import("components/conversations/ConversationContainer")
);
const ConversationsContainer = React.lazy(
  () => import("components/conversations/ConversationsContainer")
);
const ConversationSMSCreate = React.lazy(
  () => import("components/conversations/ConversationSMSCreate")
);
const Credentials = React.lazy(() => import("./pages/Credentials"));
const EditUserInsurance = React.lazy(() => import("components/users/EditUserInsurance"));
const EncounterCreate = React.lazy(() => import("./pages/EncounterCreate"));
const EncounterID = React.lazy(() => import("./pages/EncounterID"));
const GoalCreate = React.lazy(() => import("./pages/GoalCreate"));
const GoalID = React.lazy(() => import("./pages/GoalID"));
const GoalTemplateCreate = React.lazy(() => import("./pages/GoalTemplateCreate"));
const GoalTemplateID = React.lazy(() => import("./pages/GoalTemplateID"));
const GoalTemplates = React.lazy(() => import("./pages/GoalTemplates"));
const Home = React.lazy(() => import("./pages/Home"));
const IntegrationID = React.lazy(() => import("./pages/IntegrationID"));
const Integrations = React.lazy(() => import("./pages/Integrations"));
const Logs = React.lazy(() => import("./pages/Logs"));
const ObservationTypes = React.lazy(() => import("./pages/ObservationTypes"));
const PatientInvitePasswordless = React.lazy(() => import("pages/PatientInvitePasswordless"));
const PageNotFound = React.lazy(() => import("./pages/PageNotFound"));
const PatientLayout = React.lazy(() => import("./layout/PatientLayout"));
const PatientID = React.lazy(() => import("./pages/PatientID"));
const Patients = React.lazy(() => import("./pages/Patients"));
const Payments = React.lazy(() => import("./pages/Payments"));
const PrescriptionCreate = React.lazy(() => import("pages/PrescriptionCreate"));
const ProductCreate = React.lazy(() => import("./pages/ProductCreate"));
const ProductID = React.lazy(() => import("./pages/ProductID"));
const Products = React.lazy(() => import("./pages/Products"));
const QuestionnaireCreate = React.lazy(() => import("./pages/QuestionnaireCreate"));
const QuestionnaireID = React.lazy(() => import("./pages/QuestionnaireID"));
const Questionnaires = React.lazy(() => import("./pages/Questionnaires"));
const SavedSearch = React.lazy(() => import("./pages/SavedSearch"));
const Settings = React.lazy(() => import("./features/settings/pages/Settings"));
const SubmissionID = React.lazy(() => import("./pages/SubmissionID"));
const SubmissionRecommendationID = React.lazy(() => import("./pages/SubmissionRecommendationID"));
const Submissions = React.lazy(() => import("./pages/Submissions"));

const TargetCreate = React.lazy(() => import("./pages/TargetCreate"));
const TargetID = React.lazy(() => import("./pages/TargetID"));
const TargetTemplateCreate = React.lazy(() => import("./pages/TargetTemplateCreate"));
const TargetTemplateID = React.lazy(() => import("./pages/TargetTemplateID"));
const TargetTemplates = React.lazy(() => import("./pages/TargetTemplates"));
const TaskCreate = React.lazy(() => import("./pages/TaskCreate"));
const TaskID = React.lazy(() => import("./pages/TaskID"));
const TaskTemplateCreate = React.lazy(() => import("./pages/TaskTemplateCreate"));
const TaskTemplateID = React.lazy(() => import("./pages/TaskTemplateID"));
const TaskTemplates = React.lazy(() => import("./pages/TaskTemplates"));
const Team = React.lazy(() => import("./pages/Team"));
const TeamCreate = React.lazy(() => import("./pages/TeamCreate"));
const TeamID = React.lazy(() => import("./pages/TeamID"));
const Today = React.lazy(() => import("./pages/Today"));

const VideoCallWithPatientAccordian = React.lazy(
  () => import("./pages/VideoCallWithPatientAccordian")
);
const VideoCallWithPatientSidebar = React.lazy(() => import("./pages/VideoCallWithPatientSidebar"));

const WebhookCreate = React.lazy(() => import("./pages/WebhookCreate"));
const WebhookID = React.lazy(() => import("./pages/WebhookID"));

const Webhooks = React.lazy(() => import("./pages/Webhooks"));
const Workflows = React.lazy(() => import("./pages/Workflows"));
const BetaWorkflowsId = React.lazy(() => import("pages/Workflows/BetaWorkflowID/BetaWorkflowsId"));

const WorkflowID = React.lazy(() => import("./pages/WorkflowId"));

const boundaryErrorHandler = (error: Error, info: { componentStack: string }) => {
  // Send anything captured by the boundary to Sentry
  captureException(error, { extra: info });
};

function AuthenticatedApp(): JSX.Element {
  const { invitePatient, tagsV1, allowObservationGrouping, workflowV3, conversationsV3 } =
    useFlags();
  //Getting all the practitioners and tenant admins data and adding to the cache

  const { isLoading: IsLoadingPractitionerAndTenantAdminsData } =
    usePractitionerAndTenantAdminsData();

  useInitializeTwilioClients();

  //TODO: see what we want to do if we get error and are not able to get permissions?
  //TODO: enable this hook and make sure its loaded correctly before showing the app

  if (IsLoadingPractitionerAndTenantAdminsData) {
    return <FullScreenLoader />;
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <ErrorBoundary FallbackComponent={AuthenticatedFallback} onError={boundaryErrorHandler}>
        <Switch>
          <Route exact path="/">
            <Suspense fallback={<></>}>
              <Home />
            </Suspense>
          </Route>
          <Route exact path="/today">
            <Suspense fallback={<></>}>
              <Today />
            </Suspense>
          </Route>
          <Route exact path="/saved-search/:id">
            <Suspense fallback={<></>}>
              <SavedSearch />
            </Suspense>
          </Route>

          {/* Patient Pages */}
          <Route exact path="/patients">
            <Suspense fallback={<></>}>
              <Patients />
            </Suspense>
          </Route>
          {invitePatient && (
            <Route exact path="/patients/invite">
              <Suspense fallback={<></>}>
                <PatientInvitePasswordless />
              </Suspense>
            </Route>
          )}
          <Route
            exact
            path={`/patients/:id`}
            render={() => (
              <Suspense fallback={<></>}>
                <PatientLayout>
                  <PatientID />
                </PatientLayout>
              </Suspense>
            )}
          />

          {conversationsV3 && (
            <Route
              exact
              path={`/patients/:id/conversations`}
              render={() => (
                <Suspense fallback={<></>}>
                  <PatientLayout>
                    <ConversationsContainer />
                  </PatientLayout>
                </Suspense>
              )}
            />
          )}
          {conversationsV3 && (
            <Route
              exact
              path={`/patients/:id/conversations/chat/create`}
              render={() => (
                <Suspense fallback={<></>}>
                  <PatientLayout>
                    <ConversationChatCreate />
                  </PatientLayout>
                </Suspense>
              )}
            />
          )}
          {conversationsV3 && (
            <Route
              exact
              path={`/patients/:id/conversations/sms/create`}
              render={() => (
                <Suspense fallback={<></>}>
                  <PatientLayout>
                    <ConversationSMSCreate />
                  </PatientLayout>
                </Suspense>
              )}
            />
          )}
          {conversationsV3 && (
            <Route
              exact
              path={`/patients/:id/conversations/:conversationType(chat|sms)/:conversationId`}
              render={() => (
                <Suspense fallback={<></>}>
                  <PatientLayout>
                    <ConversationContainer />
                  </PatientLayout>
                </Suspense>
              )}
            />
          )}

          <Route exact path={`/patients/:id/edit`}>
            <Suspense fallback={<></>}>
              <EditLayout BodyComponent={EditUser} userType="patient" />
            </Suspense>
          </Route>
          <Route exact path={`/patients/:id/insurance/:insuranceId`}>
            <Suspense fallback={<></>}>
              <EditUserInsurance />
            </Suspense>
          </Route>
          <Route
            exact
            path="/patients/:id/careplans/create"
            render={() => (
              <PatientLayout
                childrenSx={{ backgroundColor: colors.lightGrey7, minHeight: "100%" }}
                topNav={() => <></>}
              >
                <Suspense fallback={<></>}>
                  <CareplanCreate />
                </Suspense>
              </PatientLayout>
            )}
          />
          <Route
            exact
            path="/patients/:id/careplans/:careplanId"
            render={() => (
              <Suspense fallback={<></>}>
                <PatientLayout
                  childrenSx={{ backgroundColor: colors.lightGrey7, minHeight: "100%" }}
                  topNav={() => <></>}
                >
                  <CareplanID />
                </PatientLayout>
              </Suspense>
            )}
          />
          <Route
            exact
            path="/patients/:id/careplans/:careplanId/goals/create"
            render={() => (
              <Suspense fallback={<></>}>
                <PatientLayout
                  childrenSx={{ backgroundColor: colors.lightGrey7, minHeight: "100%" }}
                  topNav={() => <></>}
                >
                  <GoalCreate />
                </PatientLayout>
              </Suspense>
            )}
          />
          <Route
            exact
            path="/patients/:id/careplans/:careplanId/goals/:goalId"
            render={() => (
              <Suspense fallback={<></>}>
                <PatientLayout
                  childrenSx={{ backgroundColor: colors.lightGrey7, minHeight: "100%" }}
                  topNav={() => <></>}
                >
                  <GoalID />
                </PatientLayout>
              </Suspense>
            )}
          />
          <Route
            exact
            path="/patients/:id/careplans/:careplanId/tasks/create"
            render={() => (
              <Suspense fallback={<></>}>
                <PatientLayout
                  childrenSx={{ backgroundColor: colors.lightGrey7, minHeight: "100%" }}
                  topNav={() => <></>}
                >
                  <TaskCreate />
                </PatientLayout>
              </Suspense>
            )}
          />
          <Route
            exact
            path="/patients/:id/careplans/:careplanId/tasks/:taskId"
            render={() => (
              <Suspense fallback={<></>}>
                <PatientLayout
                  childrenSx={{ backgroundColor: colors.lightGrey7, minHeight: "100%" }}
                  topNav={() => <></>}
                >
                  <TaskID />
                </PatientLayout>
              </Suspense>
            )}
          />
          <Route
            exact
            path="/patients/:id/careplans/:careplanId/goals/:goalId/targets/create"
            render={() => (
              <Suspense fallback={<></>}>
                <PatientLayout
                  childrenSx={{ backgroundColor: colors.lightGrey7, minHeight: "100%" }}
                  topNav={() => <></>}
                >
                  <TargetCreate />
                </PatientLayout>
              </Suspense>
            )}
          />
          <Route
            exact
            path="/patients/:id/careplans/:careplanId/goals/:goalId/targets/:targetId"
            render={() => (
              <Suspense fallback={<></>}>
                <PatientLayout
                  childrenSx={{ backgroundColor: colors.lightGrey7, minHeight: "100%" }}
                  topNav={() => <></>}
                >
                  <TargetID />
                </PatientLayout>
              </Suspense>
            )}
          />
          <Route exact path="/group-video-call/:room" component={VideoCallWithPatientAccordian}>
            <Suspense fallback={<></>}>
              <VideoCallWithPatientAccordian />
            </Suspense>
          </Route>
          <Route exact path="/video-call/:room" component={VideoCallWithPatientSidebar}>
            <Suspense fallback={<></>}>
              <VideoCallWithPatientSidebar />
            </Suspense>
          </Route>
          <Route
            exact
            path="/patients/:id/encounters/create"
            render={() => (
              <Suspense fallback={<></>}>
                <PatientLayout
                  childrenSx={{ backgroundColor: colors.lightGrey7, minHeight: "100%" }}
                  topNav={() => <></>}
                >
                  <EncounterCreate />
                </PatientLayout>
              </Suspense>
            )}
          />
          <Route
            exact
            path="/patients/:id/encounters/:encounterId"
            render={() => (
              <Suspense fallback={<></>}>
                <PatientLayout
                  childrenSx={{ backgroundColor: colors.lightGrey7, minHeight: "100%" }}
                  topNav={() => <></>}
                >
                  <EncounterID />
                </PatientLayout>
              </Suspense>
            )}
          />
          <Route
            exact
            path="/patients/:id/prescriptions/create"
            render={() => (
              <Suspense fallback={<></>}>
                <PatientLayout
                  childrenSx={{ backgroundColor: colors.lightGrey7, minHeight: "100%" }}
                  topNav={() => <></>}
                >
                  <PrescriptionCreate />
                </PatientLayout>
              </Suspense>
            )}
          />

          <Route
            exact
            path={`/patients/:id/:patientSection`}
            render={() => (
              <Suspense fallback={<></>}>
                <PatientLayout>
                  <PatientID />
                </PatientLayout>
              </Suspense>
            )}
          />
          {/* Care Library Pages */}
          <Route exact path="/questionnaires">
            <Suspense fallback={<></>}>
              <Questionnaires />
            </Suspense>
          </Route>
          <Route exact path="/questionnaires/create">
            <Suspense fallback={<></>}>
              <QuestionnaireCreate />
            </Suspense>
          </Route>
          <Route exact path="/questionnaires/:id">
            <Suspense fallback={<></>}>
              <QuestionnaireID />
            </Suspense>
          </Route>
          <Route exact path="/submissions">
            <Suspense fallback={<></>}>
              <Submissions />
            </Suspense>
          </Route>
          <Route exact path="/submissions/:id">
            <Suspense fallback={<></>}>
              <SubmissionID />
            </Suspense>
          </Route>
          <Route exact path="/submissions/:id/recommendations">
            <Suspense fallback={<></>}>
              <SubmissionRecommendationID />
            </Suspense>
          </Route>
          <Route exact path="/careplan-templates">
            <Suspense fallback={<></>}>
              <CareplanTemplates />
            </Suspense>
          </Route>
          <Route exact path="/careplan-templates/create">
            <Suspense fallback={<></>}>
              <CareplanTemplateCreate />
            </Suspense>
          </Route>
          <Route exact path="/careplan-templates/:id">
            <Suspense fallback={<></>}>
              <CareplanTemplateID />
            </Suspense>
          </Route>
          <Route exact path="/goal-templates">
            <Suspense fallback={<></>}>
              <GoalTemplates />
            </Suspense>
          </Route>
          <Route exact path="/goal-templates/create">
            <Suspense fallback={<></>}>
              <GoalTemplateCreate />
            </Suspense>
          </Route>
          <Route exact path="/goal-templates/:id">
            <Suspense fallback={<></>}>
              <GoalTemplateID />
            </Suspense>
          </Route>
          <Route exact path="/target-templates">
            <Suspense fallback={<></>}>
              <TargetTemplates />
            </Suspense>
          </Route>
          <Route exact path="/target-templates/create">
            <Suspense fallback={<></>}>
              <TargetTemplateCreate />
            </Suspense>
          </Route>
          <Route exact path="/target-templates/:id">
            <Suspense fallback={<></>}>
              <TargetTemplateID />
            </Suspense>
          </Route>
          <Route exact path="/observation-types">
            <Suspense fallback={<></>}>
              <ObservationTypes />
            </Suspense>
          </Route>
          {allowObservationGrouping && (
            <Route exact path="/observation-type-groupings">
              <Suspense fallback={<></>}>
                <ObservationTypeGroups />
              </Suspense>
            </Route>
          )}
          <Route exact path="/task-templates">
            <Suspense fallback={<></>}>
              <TaskTemplates />
            </Suspense>
          </Route>
          <Route exact path="/task-templates/create">
            <Suspense fallback={<></>}>
              <TaskTemplateCreate />
            </Suspense>
          </Route>
          <Route exact path="/task-templates/:id">
            <Suspense fallback={<></>}>
              <TaskTemplateID />
            </Suspense>
          </Route>
          <Route exact path="/products">
            <Suspense fallback={<></>}>
              <Products />
            </Suspense>
          </Route>
          <Route exact path="/products/create">
            <Suspense fallback={<></>}>
              <ProductCreate />
            </Suspense>
          </Route>
          <Route exact path="/products/:id">
            <Suspense fallback={<></>}>
              <ProductID />
            </Suspense>
          </Route>
          <Route exact path="/workflows">
            <Suspense fallback={<></>}>
              <Workflows />
            </Suspense>
          </Route>
          {workflowV3 ? (
            <Route exact path="/workflows/:id">
              <Suspense fallback={<></>}>
                <WorkflowID />
              </Suspense>
            </Route>
          ) : (
            <Route exact path="/workflows/:id">
              <Suspense fallback={<></>}>
                <BetaWorkflowsId />
              </Suspense>
            </Route>
          )}

          {/* Admin pages */}
          <Route exact path="/business-intelligence" component={BusinessIntelligence} />
          <Route exact path="/team" component={Team}>
            <Suspense fallback={<></>}>
              <Team />
            </Suspense>
          </Route>
          <Route exact path="/team/create">
            <Suspense fallback={<></>}>
              <TeamCreate />
            </Suspense>
          </Route>
          <Route path="/team/practitioner/:id">
            <Suspense fallback={<></>}>
              <EditLayout BodyComponent={EditUser} userType="practitioner" />
            </Suspense>
          </Route>
          <Route path="/team/admin/:id" component={TeamID}>
            <Suspense fallback={<></>}>
              <TeamID />
            </Suspense>
          </Route>
          <Route exact path="/team/:id" component={TeamID}>
            <Suspense fallback={<></>}>
              <TeamID />
            </Suspense>
          </Route>
          {tagsV1 && (
            <Route exact path="/settings">
              <Suspense fallback={<></>}>
                <Settings />
              </Suspense>
            </Route>
          )}
          {tagsV1 && (
            <Route exact path="/settings/:section(tags)">
              <Suspense fallback={<></>}>
                <Settings />
              </Suspense>
            </Route>
          )}
          <Route exact path="/integrations">
            <Suspense fallback={<></>}>
              <Integrations />
            </Suspense>
          </Route>
          <Route
            exact
            path="/integrations/:id(curexa|acuity|ash-wellness|contentful|freshpaint|mdi-integration|segment|butterfly-labs|imaware|stripe|shopify|candid|photon-health|trust-commerce)"
          >
            <Suspense fallback={<></>}>
              <IntegrationID />
            </Suspense>
          </Route>
          <Route exact path="/webhooks">
            <Suspense fallback={<></>}>
              <Webhooks />
            </Suspense>
          </Route>
          <Route exact path="/webhooks/create">
            <Suspense fallback={<></>}>
              <WebhookCreate />
            </Suspense>
          </Route>
          <Route exact path="/webhooks/:id">
            <Suspense fallback={<></>}>
              <WebhookID />
            </Suspense>
          </Route>
          <Route exact path="/credentials">
            <Suspense fallback={<></>}>
              <Credentials />
            </Suspense>
          </Route>
          <Route exact path="/payments">
            <Suspense fallback={<></>}>
              <Payments />
            </Suspense>
          </Route>
          <Route exact path="/logs">
            <Suspense fallback={<></>}>
              <Logs />
            </Suspense>
          </Route>
          <Route>
            <Suspense fallback={<></>}>
              <PageNotFound />
            </Suspense>
          </Route>
        </Switch>
      </ErrorBoundary>
    </div>
  );
}

export default AuthenticatedApp;
