import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import ListSubheader from "@mui/material/ListSubheader";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";

import { colors, LIGHT_GREY_4 } from "styles/colors";
import { theme } from "styles/theme";

import TextFieldInput from "../text-field-input/TextFieldInput";
import { SearchableSelectProps } from "./SearchableSelect.types";

const LISTBOX_PADDING: number = parseInt(theme.spacing(1), 10);

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
    overflow: "auto",
  };

  if (Object.prototype.hasOwnProperty.call(dataSet, "group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" noWrap {...dataSet[0]} style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  );
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

OuterElementType.displayName = "OuterElementType";

const useResetCache = (data: any) => {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactChild[] = [];
    (children as React.ReactChild[]).forEach(
      (item: React.ReactChild & { children?: React.ReactChild[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      }
    );

    const itemCount = itemData.length;
    const itemSize = 42;

    const getChildSize = (child: React.ReactChild) => {
      if (Object.prototype.hasOwnProperty.call(child, "group")) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <Box
        ref={ref}
        style={{
          overflow: "hidden",
          border: "1px solid",
          borderColor: colors[LIGHT_GREY_4],
          borderRadius: "4px",
          outline: 0,
        }}
      >
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 4 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </Box>
    );
  }
);

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

// TODO: This component is missing error states and styles
const SearchableSelect = ({
  label,
  inputID,
  value,
  setSelectedItem,
  inputProps,
  options,
  groupBy,
  renderOption = (props, option: any) => [props, option.label],
  getOptionLabel = (option?: any) => option?.label || "",
  readOnly = false,
  disabled,
  defaultValue,
  required = "false",
  multiple = false,
  error,
  fullWidth,
  helperText,
  textFieldParams,
  disableCloseOnSelect = false,
}: SearchableSelectProps): JSX.Element => {
  if (typeof value === "string" && value) {
    value = options.find((o) => {
      return o.value === value;
    });
  }

  return (
    <Autocomplete
      id={inputID}
      disableCloseOnSelect={disableCloseOnSelect}
      disableListWrap
      value={value}
      multiple={multiple}
      defaultValue={defaultValue}
      onChange={(event, newValue) => {
        setSelectedItem(newValue);
      }}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      renderInput={(params: any) => (
        <TextFieldInput
          {...params}
          inputProps={{ ...params.inputProps }}
          variant="outlined"
          label={label}
          error={error}
          helperText={helperText}
          disabled={disabled}
          {...textFieldParams}
        />
      )}
      isOptionEqualToValue={(option: any, value: any) => option.value === value?.value}
      options={options}
      getOptionLabel={getOptionLabel}
      groupBy={groupBy}
      renderOption={renderOption}
      renderGroup={(params) => params}
      readOnly={readOnly}
      disabled={disabled}
      fullWidth={fullWidth}
      error={required}
      {...inputProps}
    />
  );
};

export default SearchableSelect;
