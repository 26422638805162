/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The IntegrationsbutterflyLabsButterflyLabsIntegration model module.
 * @module model/IntegrationsbutterflyLabsButterflyLabsIntegration
 * @version v0.3
 */
export class IntegrationsbutterflyLabsButterflyLabsIntegration {
  /**
   * Constructs a new <code>IntegrationsbutterflyLabsButterflyLabsIntegration</code>.
   * @alias module:model/IntegrationsbutterflyLabsButterflyLabsIntegration
   * @class
   * @param apiKey {String}
   */
  constructor(apiKey) {
    this.apiKey = apiKey;
  }

  /**
   * Constructs a <code>IntegrationsbutterflyLabsButterflyLabsIntegration</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/IntegrationsbutterflyLabsButterflyLabsIntegration} obj Optional instance to populate.
   * @return {module:model/IntegrationsbutterflyLabsButterflyLabsIntegration} The populated <code>IntegrationsbutterflyLabsButterflyLabsIntegration</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new IntegrationsbutterflyLabsButterflyLabsIntegration();
      if (data.hasOwnProperty("api_key"))
        obj.apiKey = ApiClient.convertToType(data["api_key"], "String");
      if (data.hasOwnProperty("enabled"))
        obj.enabled = ApiClient.convertToType(data["enabled"], "Boolean");
    }
    return obj;
  }
}

/**
 * @member {String} apiKey
 */
IntegrationsbutterflyLabsButterflyLabsIntegration.prototype.apiKey = undefined;

/**
 * @member {Boolean} enabled
 * @default true
 */
IntegrationsbutterflyLabsButterflyLabsIntegration.prototype.enabled = true;
