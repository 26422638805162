import { Client } from "@twilio/conversations";
import { useAtom } from "jotai";
import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  ConversationClientType,
  ConversationClientTypes,
  fetchTwilioToken,
  useTwilioToken,
} from "./useTwilioToken.hook";
import { ReactQueryKeys } from "constants/keys";
import { twilioClientsAtom } from "atoms";
import { InfiniteMessagesData } from "./useConversationMessages.hook";
import { addMessage, isMessageSentByUser } from "./useConversationMessages.utils";
import { useCurrentUser } from "features/authentication/useCurrentUser";

const invalidateInbox = async (queryClient: QueryClient) => {
  await queryClient.refetchQueries([ReactQueryKeys.PATIENTS]);
  await queryClient.invalidateQueries([ReactQueryKeys.CONVERSATIONS_ALL]);
  await queryClient.invalidateQueries([ReactQueryKeys.INBOX]);
};

export const useTwilioClient = (type: ConversationClientType) => {
  const queryClient = useQueryClient();
  const { data: currentUser } = useCurrentUser();

  const { data: twilioTokenData, isError: isErrorTwilioToken } = useTwilioToken(type);
  const [twilioClients, setTwilioClients] = useAtom(twilioClientsAtom);

  const getClient = async () => {
    if (twilioClients[type]) {
      return twilioClients[type];
    }

    const token = twilioTokenData.twilio_auth_token;
    const client = new Client(token);

    client.on("conversationAdded", (conversation) => {
      queryClient.invalidateQueries([ReactQueryKeys.CONVERSATIONS]);
    });
    client.on("messageAdded", (message) => {
      if (type === ConversationClientTypes.CHAT || type === ConversationClientTypes.SMS) {
        return;
      }
      const conversationSid = message.conversation.sid;
      const queryKey = [ReactQueryKeys.MESSAGES, conversationSid];
      //We only update on incoming messages as outgoing are handled by useSendMessage.hook.tsx
      if (!isMessageSentByUser(message, currentUser ?? undefined)) {
        queryClient.setQueryData(queryKey, (currentCache: InfiniteMessagesData | undefined) =>
          addMessage(message, currentCache)
        );
        queryClient.invalidateQueries([ReactQueryKeys.CONVERSATIONS]);

        /* 
          TODO: update the cache before invalidating 
          disabled at the moment as it would refresh properly after cache invalidation, 
          most likely we can bring this back once we optimize our payload 
        */

        // queryClient.setQueryData<FetchInboxData>([ReactQueryKeys.INBOX], (currentCache) => {
        //   const newCache = cloneDeep(currentCache);

        //   const newConversations = newCache?.conversations.map((conversation) => {
        //     let lastMessage = conversation.lastMessage;
        //     if (message.conversation.sid === conversation.twilioConversation.sid) {
        //       console.log("it is");
        //       lastMessage = message;
        //     }

        //     return { ...conversation, lastMessage };
        //   });

        //   const sortedConversations = sortInboxConversations(
        //     newConversations ?? [],
        //     currentCache?.patientParticipants ?? {}
        //   );

        //   return {
        //     ...newCache,
        //     conversations: sortedConversations,
        //   } as FetchInboxData;
        // });

        invalidateInbox(queryClient);
      }
    });

    client.on("tokenAboutToExpire", async () => {
      const fetchTokenResponse = await fetchTwilioToken(type);
      const newToken = fetchTokenResponse?.twilio_auth_token;
      client.updateToken(newToken);
    });

    client.on("tokenExpired", async () => {
      const fetchTokenResponse = await fetchTwilioToken(type);
      const newToken = fetchTokenResponse?.twilio_auth_token;
      client.updateToken(newToken);
    });

    setTwilioClients({ ...twilioClients, [type]: client });

    return client;
  };

  const query = useQuery([ReactQueryKeys.TWILIO_CLIENT, type], getClient, {
    staleTime: Infinity,
    enabled: !!twilioTokenData?.twilio_auth_token,
  });

  return { ...query, isError: query.isError || isErrorTwilioToken };
};

export const useInitializeTwilioClients = () => {
  useTwilioClient(ConversationClientTypes.CHAT);
  useTwilioClient(ConversationClientTypes.CHAT_OWNER);
  useTwilioClient(ConversationClientTypes.SMS);
  useTwilioClient(ConversationClientTypes.SMS_OWNER);
};
