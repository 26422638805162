import { WizardModalProps } from "./Wizard.types";
import React, { useRef, useState } from "react";

import Modal from "../modal/Modal";
import Wizard from "./Wizard";
import "form-request-submit-polyfill";

const WizardModal = ({
  open,
  onClose,
  forms,
  onSubmit,
  setWatchedFields,
  serverErrors,
  disableCloseButton = false,
  disableCloseOnFocusOut = false,
}: WizardModalProps): JSX.Element => {
  const [wizardPosition, setWizardPosition] = useState(0);
  const wizardRef = useRef<HTMLFormElement>(null);

  const getPrimaryButtonText = () => {
    if (forms[wizardPosition]?.nextText) {
      return forms[wizardPosition].nextText;
    } else {
      return wizardPosition + 1 === forms.length ? "Submit" : "Next";
    }
  };

  const getSecondaryButtonText = () => {
    return wizardPosition === 0 ? "Cancel" : "Back";
  };

  return (
    <Modal
      disableCloseButton={disableCloseButton}
      disableCloseOnFocusOut={disableCloseOnFocusOut}
      title={forms[wizardPosition]?.title || ""}
      subtitle={forms[wizardPosition]?.subtitle || ""}
      open={open}
      onClose={onClose}
      primaryButtonText={getPrimaryButtonText()}
      secondaryButtonText={getSecondaryButtonText()}
      onPrimaryButtonClick={() => wizardRef.current?.requestSubmit()}
      onSecondaryButtonClick={(e) => {
        if (wizardPosition === 0) {
          onClose(e);
        } else {
          setWizardPosition(wizardPosition - 1);
        }
      }}
    >
      <Wizard
        serverErrors={serverErrors}
        forms={forms}
        wizardRef={wizardRef}
        wizardPosition={wizardPosition}
        setWizardPosition={setWizardPosition}
        onSubmit={onSubmit}
        setWatchedFields={setWatchedFields}
      />
    </Modal>
  );
};

export default WizardModal;
