import React from "react";
import { useAPIList, API_Type } from "../../hooks/listAll";

import SearchableSelect from "../styled/searchable-select/SearchableSelect";

const UserEthnicityDropdown = ({
  value,
  onChange,
}: {
  value: any;
  onChange(template): void;
}): JSX.Element | null => {
  const [ethnicities] = useAPIList(API_Type.Ethnicity, { byLevel: ["2"] });

  const ethnicityOptions = ethnicities.map((ethnicity) => {
    return {
      ...ethnicity,
      value: ethnicity.code,
      label: `${ethnicity.display}`,
    };
  });

  if (ethnicityOptions.length === 0) return null;

  return (
    <SearchableSelect
      options={ethnicityOptions}
      label="Ethnicity"
      inputID="ethnicity-dropdown"
      value={value}
      setSelectedItem={onChange}
      style={{ marginTop: "20px", marginBottom: "20px" }}
      required="true"
      renderOption={(props, option) => {
        const array = [props, option?.display];
        return array;
      }}
    />
  );
};

export default UserEthnicityDropdown;
