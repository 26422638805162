import { WizardProps } from "./Wizard.types";
import React, { useState } from "react";

import Form from "../form/Form";

const Wizard = (props: WizardProps): JSX.Element => {
  const {
    wizardPosition,
    setWizardPosition,
    wizardRef,
    onSubmit,
    setWatchedFields,
    forms,
    serverErrors,
  } = props;
  const [myData, setMyData] = useState({});

  const updateWizardState = (data) => {
    const newData = {
      ...myData,
      ...data,
    };
    setMyData(newData);

    if (wizardPosition + 1 !== props.forms.length) {
      setWizardPosition(wizardPosition + 1);
    } else {
      onSubmit(newData);
    }
  };

  return (
    <Form
      serverErrors={serverErrors}
      formData={forms[wizardPosition]?.form}
      forwardedRef={wizardRef}
      onSubmit={(data) => updateWizardState(data)}
      setWatchedFields={setWatchedFields}
    />
  );
};

export default Wizard;
