import { useTheme } from "@mui/system";
import Box from "@mui/material/Box";
import React, { useContext } from "react";

import { BreakpointContext } from "../../contexts/breakpoint";
import { colors } from "../../styles/colors";
import { ReactChildren } from "../../types/react";
import InfoAlertContainer from "../InfoAlertContainer";
import PortalBodyContainer from "./PortalBodyContainer";
import SideNavigation from "./SideNavigation";
import TopNavigation from "./TopNavigation";

const PortalLayout = ({ children }: ReactChildren): JSX.Element => {
  const theme = useTheme();
  const { isMobileView } = useContext(BreakpointContext);

  const sideNavWidth = !isMobileView ? 240 : 0;
  const topNavHeight = theme.spacing(9);

  return (
    <Box sx={{ display: "flex", color: colors.darkGrey, overflow: "hidden" }}>
      {isMobileView ? null : (
        <span style={{ height: "100vh", width: `${sideNavWidth}px` }}>
          <SideNavigation />
        </span>
      )}
      <span style={{ height: "100vh", width: `calc(100vw - ${sideNavWidth}px)` }}>
        <TopNavigation
          isMobileView={isMobileView}
          style={{
            height: topNavHeight,
            width: isMobileView ? "100%" : `calc(100vw - ${sideNavWidth}px)`,
          }}
        />
        <PortalBodyContainer
          style={{
            marginTop: topNavHeight,
            height: `calc(100vh - ${topNavHeight}px)`,
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          <InfoAlertContainer displayLimit={3} />
          {children}
        </PortalBodyContainer>
      </span>
    </Box>
  );
};

export default PortalLayout;
