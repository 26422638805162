/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { OrganizationsidOrganization } from "./OrganizationsidOrganization";

/**
 * The OrganizationsIdBody model module.
 * @module model/OrganizationsIdBody
 * @version v0.3
 */
export class OrganizationsIdBody {
  /**
   * Constructs a new <code>OrganizationsIdBody</code>.
   * @alias module:model/OrganizationsIdBody
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>OrganizationsIdBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/OrganizationsIdBody} obj Optional instance to populate.
   * @return {module:model/OrganizationsIdBody} The populated <code>OrganizationsIdBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OrganizationsIdBody();
      if (data.hasOwnProperty("organization"))
        obj.organization = OrganizationsidOrganization.constructFromObject(data["organization"]);
    }
    return obj;
  }
}

/**
 * @member {module:model/OrganizationsidOrganization} organization
 */
OrganizationsIdBody.prototype.organization = undefined;
