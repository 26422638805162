import { useHistory } from "react-router";
import { Variant } from "@mui/material/styles/createTypography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import React from "react";
import Typography from "@mui/material/Typography";

import { colors } from "../../styles/colors";

const TopNav = ({
  title,
  subtitle: Subtitle,
  topNavHeight,
  widthOfContent,
  actionButtons,
  onBackButtonClick,
  titleVariant = "h2",
  rightContent: RightContent,
}: {
  title: string;
  subtitle?: any;
  topNavHeight: string;
  widthOfContent: string;
  actionButtons: any;
  onBackButtonClick?: () => void;
  titleVariant?: Variant;
  rightContent: any;
}): JSX.Element => {
  const history = useHistory();

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        // eslint-disable-next-line
        zIndex: (theme) => theme.zIndex.appBar,
        alignItems: "center",
        height: topNavHeight,
        width: widthOfContent,
        backgroundColor: colors.white,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: widthOfContent,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // eslint-disable-next-line
            padding: (theme) => theme.spacing(2),
            justifyContent: "space-between",
            width: widthOfContent,
            overflow: "hidden",
          }}
        >
          <Box component="span" sx={{ display: "flex" }}>
            <IconButton
              component={Link}
              onClick={() => (onBackButtonClick ? onBackButtonClick() : history.goBack())}
              // eslint-disable-next-line
              sx={{ marginRight: (theme) => theme.spacing(2) }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              variant={titleVariant}
              sx={{
                maxWidth: "650px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "flex",
                alignItems: "center",
              }}
            >
              {title}
            </Typography>

            {Subtitle && <Subtitle />}
          </Box>
          <Box>
            {RightContent && <RightContent />}

            {actionButtons && actionButtons.length
              ? actionButtons.map((b, i) => (
                  <Box
                    component="span"
                    key={i}
                    sx={{
                      marginRight: (theme) => theme.spacing(1),
                    }}
                  >
                    {b}
                  </Box>
                ))
              : null}
          </Box>
        </Box>
      </Box>
      <Divider sx={{ marginBlockStart: 0, marginBlockEnd: 0, borderWidth: "1px" }} />
    </Box>
  );
};

export default TopNav;
