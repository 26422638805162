import { useForm, Controller } from "react-hook-form";
import React, { useContext, useState } from "react";
import { Alert, Box, Card, TextField, Typography, useTheme } from "@mui/material";
import { colors } from "styles/colors";
import { BreakpointContext } from "contexts/breakpoint";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useLocation } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { CapableLogoLong } from "components/CapableIcons";
import { useSignIn } from "./useSignIn";
import {
  emailRequiredMessage,
  emailValidationErrorMessage,
  passwordRegEx,
  passwordRequirementsMessage,
} from "./Auth.utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import { SignInWithGoogleButton } from "./SignInWithGoogleButton";
import { InputAdornmentForPassword } from "./InputAdornmentForPassword";

const validationSchema = z.object({
  email: z.string().trim().min(1, { message: emailRequiredMessage }).email({
    message: emailValidationErrorMessage,
  }),
  password: z.string().trim().regex(passwordRegEx, {
    message: passwordRequirementsMessage,
  }),
});

type ValidationSchema = z.infer<typeof validationSchema>;

export const SignIn = () => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const { isMobileView } = useContext(BreakpointContext);
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const { ssoGoogle, hideCredentialInputsForSignIn } = useFlags();
  const { mutate: signIn, isError, isLoading, error } = useSignIn();

  //This applies only to PIP care at the moment as they only want to see google sign in
  const showOnlySignInWithGoogle = ssoGoogle && hideCredentialInputsForSignIn;
  const errorMessage = isError
    ? (error as unknown as { message: string; __type: string })["message"]
    : "";

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
  } = useForm<ValidationSchema>({
    defaultValues: {
      email: urlSearchParams.get("email") ?? "",
      password: urlSearchParams.get("tpass") ?? "",
    },
    resolver: zodResolver(validationSchema),
  });

  const processForm = async (data: ValidationSchema) => {
    signIn({ email: data.email, password: data.password });
  };

  const submitOnEnter = (event) => {
    if (event.key === "Enter") {
      if (isValid) {
        handleSubmit(processForm)();
      }
    }
  };

  const formControlStyles = {
    margin: theme.spacing(1, 0),
    width: "100%",
  };

  if (showOnlySignInWithGoogle) {
    return (
      <Box
        sx={{
          backgroundColor: colors.lightGrey1,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Card
          sx={{
            width: isMobileView ? "100%" : "500px",
            marginTop: "100px",
            padding: theme.spacing(5),
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Typography variant="h5" paddingBottom={1}>
            Sign in to your account{" "}
          </Typography>
          <Box sx={{ width: "100%", paddingY: 2 }}>
            <SignInWithGoogleButton />
          </Box>
        </Card>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        backgroundColor: colors.lightGrey1,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Card
        sx={{
          width: isMobileView ? "100%" : "500px",
          marginTop: "100px",
          padding: theme.spacing(5),
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        <Box sx={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
          <CapableLogoLong />
        </Box>
        {ssoGoogle && (
          <Box sx={{ width: "100%", paddingY: 2 }}>
            <SignInWithGoogleButton />
          </Box>
        )}
        {isError && (
          <Alert sx={{ marginTop: 2 }} severity="error">
            {errorMessage}
          </Alert>
        )}

        <form onSubmit={handleSubmit(processForm)}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <>
                <TextField
                  label="Email"
                  variant="outlined"
                  inputProps={{ tabIndex: 1 }}
                  style={formControlStyles}
                  {...field}
                  onKeyDownCapture={submitOnEnter}
                  onBlur={() => {
                    trigger("email");
                  }}
                  onChange={(e) => {
                    setTimeout(() => {
                      trigger("email");
                    }, 1000);
                    e.target.value = e.target.value.trim();
                    field.onChange(e);
                  }}
                  helperText={errors.email?.message}
                  error={!!errors.email}
                />
              </>
            )}
          />

          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <>
                <TextField
                  label="Password"
                  variant="outlined"
                  inputProps={{ tabIndex: 2 }}
                  type={showPassword ? "text" : "password"}
                  style={formControlStyles}
                  {...field}
                  onBlur={() => {
                    trigger("password");
                  }}
                  onKeyDownCapture={submitOnEnter}
                  onChange={(e) => {
                    setTimeout(() => {
                      trigger("password");
                    }, 1000);

                    field.onChange(e);
                  }}
                  helperText={errors.password?.message}
                  error={!!errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornmentForPassword
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                        title={passwordRequirementsMessage}
                        ariaLabel="Toggle password visibility"
                      />
                    ),
                  }}
                />
              </>
            )}
          />
          <Box display={"flex"} sx={{ marginBottom: (theme) => theme.spacing(3) }}>
            {`Forgot your password? `}
            {/* Could not use react router Link as the component did not refresh, most likely we have something 🐠 in our setup */}
            <a style={{ marginLeft: 5 }} href="/forgot_password ">
              Reset password
            </a>
          </Box>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            disabled={!isValid}
            tabIndex={3}
            onClick={handleSubmit(processForm)}
            style={formControlStyles}
            sx={{ marginTop: (theme) => theme.spacing(3) }}
          >
            Sign In
          </LoadingButton>
        </form>
      </Card>
    </Box>
  );
};
