/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { IntegrationsAcuityBody } from "../model/IntegrationsAcuityBody";
import { IntegrationsAcuityBody1 } from "../model/IntegrationsAcuityBody1";
import { IntegrationsAshWellnessBody } from "../model/IntegrationsAshWellnessBody";
import { IntegrationsAshWellnessBody1 } from "../model/IntegrationsAshWellnessBody1";
import { IntegrationsButterflyLabsBody } from "../model/IntegrationsButterflyLabsBody";
import { IntegrationsButterflyLabsBody1 } from "../model/IntegrationsButterflyLabsBody1";
import { IntegrationsCandidBody } from "../model/IntegrationsCandidBody";
import { IntegrationsCandidBody1 } from "../model/IntegrationsCandidBody1";
import { IntegrationsCurexaBody } from "../model/IntegrationsCurexaBody";
import { IntegrationsCurexaBody1 } from "../model/IntegrationsCurexaBody1";
import { IntegrationsFormsortBody } from "../model/IntegrationsFormsortBody";
import { IntegrationsFormsortBody1 } from "../model/IntegrationsFormsortBody1";
import { IntegrationsFreshpaintBody } from "../model/IntegrationsFreshpaintBody";
import { IntegrationsFreshpaintBody1 } from "../model/IntegrationsFreshpaintBody1";
import { IntegrationsImawareBody } from "../model/IntegrationsImawareBody";
import { IntegrationsImawareBody1 } from "../model/IntegrationsImawareBody1";
import { IntegrationsLgcBody } from "../model/IntegrationsLgcBody";
import { IntegrationsLgcBody1 } from "../model/IntegrationsLgcBody1";
import { IntegrationsMdiBody } from "../model/IntegrationsMdiBody";
import { IntegrationsMdiBody1 } from "../model/IntegrationsMdiBody1";
import { IntegrationsPhotonHealthBody } from "../model/IntegrationsPhotonHealthBody";
import { IntegrationsPhotonHealthBody1 } from "../model/IntegrationsPhotonHealthBody1";
import { IntegrationsSegmentBody } from "../model/IntegrationsSegmentBody";
import { IntegrationsSegmentBody1 } from "../model/IntegrationsSegmentBody1";
import { IntegrationsShopifyBody } from "../model/IntegrationsShopifyBody";
import { IntegrationsShopifyBody1 } from "../model/IntegrationsShopifyBody1";
import { IntegrationsStripeBody } from "../model/IntegrationsStripeBody";
import { IntegrationsStripeBody1 } from "../model/IntegrationsStripeBody1";

/**
 * Integrations service.
 * @module api/IntegrationsApi
 * @version v0.3
 */
export class IntegrationsApi {
  /**
    * Constructs a new IntegrationsApi. 
    * @alias module:api/IntegrationsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the integrationsAcuityGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsAcuityGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Acuity Integration
   * @param {module:api/IntegrationsApi~integrationsAcuityGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsAcuityGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/acuity",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsAcuityPatch operation.
   * @callback moduleapi/IntegrationsApi~integrationsAcuityPatchCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Acuity Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsAcuityBody1} opts.body
   * @param {module:api/IntegrationsApi~integrationsAcuityPatchCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsAcuityPatch(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/acuity",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsAcuityPost operation.
   * @callback moduleapi/IntegrationsApi~integrationsAcuityPostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Acuity Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsAcuityBody} opts.body
   * @param {module:api/IntegrationsApi~integrationsAcuityPostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsAcuityPost(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/acuity",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsAshWellnessGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsAshWellnessGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Ash Wellness Integration
   * @param {module:api/IntegrationsApi~integrationsAshWellnessGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsAshWellnessGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/ash_wellness",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsAshWellnessPatch operation.
   * @callback moduleapi/IntegrationsApi~integrationsAshWellnessPatchCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * AshWellness Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsAshWellnessBody1} opts.body
   * @param {module:api/IntegrationsApi~integrationsAshWellnessPatchCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsAshWellnessPatch(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/ash_wellness",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsAshWellnessPost operation.
   * @callback moduleapi/IntegrationsApi~integrationsAshWellnessPostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * new Ash Wellness Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsAshWellnessBody} opts.body
   * @param {module:api/IntegrationsApi~integrationsAshWellnessPostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsAshWellnessPost(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/ash_wellness",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsButterflyLabsGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsButterflyLabsGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ButterflyLabs Integration
   * @param {module:api/IntegrationsApi~integrationsButterflyLabsGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsButterflyLabsGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/butterfly_labs",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsButterflyLabsPatch operation.
   * @callback moduleapi/IntegrationsApi~integrationsButterflyLabsPatchCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ButterflyLabs Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsButterflyLabsBody1} opts.body
   * @param {module:api/IntegrationsApi~integrationsButterflyLabsPatchCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsButterflyLabsPatch(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/butterfly_labs",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsButterflyLabsPost operation.
   * @callback moduleapi/IntegrationsApi~integrationsButterflyLabsPostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ButterflyLabs Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsButterflyLabsBody} opts.body
   * @param {module:api/IntegrationsApi~integrationsButterflyLabsPostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsButterflyLabsPost(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/butterfly_labs",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsCandidGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsCandidGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Candid Integration
   * @param {module:api/IntegrationsApi~integrationsCandidGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsCandidGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/candid",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsCandidPatch operation.
   * @callback moduleapi/IntegrationsApi~integrationsCandidPatchCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Candid Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsCandidBody1} opts.body
   * @param {module:api/IntegrationsApi~integrationsCandidPatchCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsCandidPatch(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/candid",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsCandidPost operation.
   * @callback moduleapi/IntegrationsApi~integrationsCandidPostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Candid Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsCandidBody} opts.body
   * @param {module:api/IntegrationsApi~integrationsCandidPostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsCandidPost(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/candid",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsCurexaGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsCurexaGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Curexa Integration
   * @param {module:api/IntegrationsApi~integrationsCurexaGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsCurexaGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/curexa",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsCurexaPatch operation.
   * @callback moduleapi/IntegrationsApi~integrationsCurexaPatchCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Curexa Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsCurexaBody1} opts.body
   * @param {module:api/IntegrationsApi~integrationsCurexaPatchCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsCurexaPatch(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/curexa",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsCurexaPost operation.
   * @callback moduleapi/IntegrationsApi~integrationsCurexaPostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Curexa Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsCurexaBody} opts.body
   * @param {module:api/IntegrationsApi~integrationsCurexaPostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsCurexaPost(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/curexa",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsFormsortGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsFormsortGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Formsort Integration
   * @param {module:api/IntegrationsApi~integrationsFormsortGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsFormsortGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/formsort",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsFormsortPatch operation.
   * @callback moduleapi/IntegrationsApi~integrationsFormsortPatchCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Formsort Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsFormsortBody1} opts.body
   * @param {module:api/IntegrationsApi~integrationsFormsortPatchCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsFormsortPatch(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/formsort",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsFormsortPost operation.
   * @callback moduleapi/IntegrationsApi~integrationsFormsortPostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Formsort Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsFormsortBody} opts.body
   * @param {module:api/IntegrationsApi~integrationsFormsortPostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsFormsortPost(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/formsort",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsFreshpaintGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsFreshpaintGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Freshpaint Integration
   * @param {module:api/IntegrationsApi~integrationsFreshpaintGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsFreshpaintGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/freshpaint",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsFreshpaintPatch operation.
   * @callback moduleapi/IntegrationsApi~integrationsFreshpaintPatchCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Freshpaint Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsFreshpaintBody1} opts.body
   * @param {module:api/IntegrationsApi~integrationsFreshpaintPatchCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsFreshpaintPatch(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/freshpaint",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsFreshpaintPost operation.
   * @callback moduleapi/IntegrationsApi~integrationsFreshpaintPostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Freshpaint Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsFreshpaintBody} opts.body
   * @param {module:api/IntegrationsApi~integrationsFreshpaintPostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsFreshpaintPost(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/freshpaint",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Integrations
   * @param {module:api/IntegrationsApi~integrationsGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsImawareGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsImawareGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Imaware Integration
   * @param {module:api/IntegrationsApi~integrationsImawareGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsImawareGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/imaware",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsImawarePatch operation.
   * @callback moduleapi/IntegrationsApi~integrationsImawarePatchCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Imaware Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsImawareBody1} opts.body
   * @param {module:api/IntegrationsApi~integrationsImawarePatchCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsImawarePatch(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/imaware",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsImawarePost operation.
   * @callback moduleapi/IntegrationsApi~integrationsImawarePostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Imaware Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsImawareBody} opts.body
   * @param {module:api/IntegrationsApi~integrationsImawarePostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsImawarePost(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/imaware",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsLgcGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsLgcGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * LGC Integration
   * @param {module:api/IntegrationsApi~integrationsLgcGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsLgcGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/lgc",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsLgcPatch operation.
   * @callback moduleapi/IntegrationsApi~integrationsLgcPatchCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * LGC Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsLgcBody1} opts.body
   * @param {module:api/IntegrationsApi~integrationsLgcPatchCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsLgcPatch(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/lgc",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsLgcPost operation.
   * @callback moduleapi/IntegrationsApi~integrationsLgcPostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * LGC Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsLgcBody} opts.body
   * @param {module:api/IntegrationsApi~integrationsLgcPostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsLgcPost(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/lgc",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsLookerGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsLookerGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Looker Integration
   * @param {module:api/IntegrationsApi~integrationsLookerGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsLookerGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/looker",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsMdiGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsMdiGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * MDI Integration
   * @param {module:api/IntegrationsApi~integrationsMdiGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsMdiGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/mdi",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsMdiPatch operation.
   * @callback moduleapi/IntegrationsApi~integrationsMdiPatchCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * MDI Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsMdiBody1} opts.body
   * @param {module:api/IntegrationsApi~integrationsMdiPatchCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsMdiPatch(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/mdi",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsMdiPost operation.
   * @callback moduleapi/IntegrationsApi~integrationsMdiPostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * MDI Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsMdiBody} opts.body
   * @param {module:api/IntegrationsApi~integrationsMdiPostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsMdiPost(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/mdi",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsPhotonHealthGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsPhotonHealthGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * PhotonHealth Integration
   * @param {module:api/IntegrationsApi~integrationsPhotonHealthGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsPhotonHealthGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/photon_health",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsPhotonHealthPatch operation.
   * @callback moduleapi/IntegrationsApi~integrationsPhotonHealthPatchCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * PhotonHealth Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsPhotonHealthBody1} opts.body
   * @param {module:api/IntegrationsApi~integrationsPhotonHealthPatchCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsPhotonHealthPatch(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/photon_health",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsPhotonHealthPost operation.
   * @callback moduleapi/IntegrationsApi~integrationsPhotonHealthPostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * PhotonHealth Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsPhotonHealthBody} opts.body
   * @param {module:api/IntegrationsApi~integrationsPhotonHealthPostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsPhotonHealthPost(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/photon_health",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsSegmentGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsSegmentGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Segment Integration
   * @param {module:api/IntegrationsApi~integrationsSegmentGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsSegmentGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/segment",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsSegmentPatch operation.
   * @callback moduleapi/IntegrationsApi~integrationsSegmentPatchCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Segment Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsSegmentBody1} opts.body
   * @param {module:api/IntegrationsApi~integrationsSegmentPatchCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsSegmentPatch(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/segment",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsSegmentPost operation.
   * @callback moduleapi/IntegrationsApi~integrationsSegmentPostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Segment Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsSegmentBody} opts.body
   * @param {module:api/IntegrationsApi~integrationsSegmentPostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsSegmentPost(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/segment",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsShopifyGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsShopifyGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Shopify Integration
   * @param {module:api/IntegrationsApi~integrationsShopifyGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsShopifyGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/shopify",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsShopifyPatch operation.
   * @callback moduleapi/IntegrationsApi~integrationsShopifyPatchCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Shopify Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsShopifyBody1} opts.body
   * @param {module:api/IntegrationsApi~integrationsShopifyPatchCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsShopifyPatch(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/shopify",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsShopifyPost operation.
   * @callback moduleapi/IntegrationsApi~integrationsShopifyPostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Shopify Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsShopifyBody} opts.body
   * @param {module:api/IntegrationsApi~integrationsShopifyPostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsShopifyPost(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/shopify",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsStripeGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsStripeGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Stripe Integration
   * @param {module:api/IntegrationsApi~integrationsStripeGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsStripeGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/stripe",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsStripeOnboardingGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsStripeOnboardingGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Stripe Onboarding
   * Use this endpoint to initiate or complete the stripe onboarding process, it will return a URL object with an expiration time
   * @param {module:api/IntegrationsApi~integrationsStripeOnboardingGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsStripeOnboardingGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/stripe/onboarding",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsStripePatch operation.
   * @callback moduleapi/IntegrationsApi~integrationsStripePatchCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Stripe Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsStripeBody1} opts.body
   * @param {module:api/IntegrationsApi~integrationsStripePatchCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsStripePatch(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/stripe",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsStripePost operation.
   * @callback moduleapi/IntegrationsApi~integrationsStripePostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Stripe Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsStripeBody} opts.body
   * @param {module:api/IntegrationsApi~integrationsStripePostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsStripePost(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/stripe",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsTrustCommerceGet operation.
   * @callback moduleapi/IntegrationsApi~integrationsTrustCommerceGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * TrustCommerce Integration
   * @param {module:api/IntegrationsApi~integrationsTrustCommerceGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsTrustCommerceGet(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/trust_commerce",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsTrustCommercePatch operation.
   * @callback moduleapi/IntegrationsApi~integrationsTrustCommercePatchCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * TrustCommerce Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsTrustCommerceBody1} opts.body
   * @param {module:api/IntegrationsApi~integrationsTrustCommercePatchCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsTrustCommercePatch(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/trust_commerce",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the integrationsTrustCommercePost operation.
   * @callback moduleapi/IntegrationsApi~integrationsTrustCommercePostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * TrustCommerce Integration
   * @param {Object} opts Optional parameters
   * @param {module:model/IntegrationsTrustCommerceBody} opts.body
   * @param {module:api/IntegrationsApi~integrationsTrustCommercePostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  integrationsTrustCommercePost(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/integrations/trust_commerce",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
}
