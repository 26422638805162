import React, { useEffect, useState, createContext } from "react";

import { getWindowDimensions } from "../utils/window";
import { ReactChildren } from "../types/react";
import { theme } from "../styles/theme";

export const BreakpointContext = createContext({ isMobileView: false });

export const BreakpointProvider = ({ children }: ReactChildren): JSX.Element => {
  const [isMobileView, setIsMobileView] = useState(
    getWindowDimensions().width < theme.breakpoints.values.md
  );

  useEffect(() => {
    function handleResize() {
      if (!isMobileView && getWindowDimensions().width < theme.breakpoints.values.md) {
        setIsMobileView(true);
      }

      if (isMobileView && getWindowDimensions().width > theme.breakpoints.values.md) {
        setIsMobileView(false);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobileView]);

  return (
    <BreakpointContext.Provider value={{ isMobileView }}>{children}</BreakpointContext.Provider>
  );
};
