import { AddInfoContext, RemoveInfoContext } from "contexts/info";
import { createCardInfo } from "utils/patients";
import { formatError } from "utils/strings";
import { OutlinedButton } from "components/styled/Buttons";
import { useEligibility } from "hooks/getEligibilityChecks";
import { useFlags } from "launchdarkly-react-client-sdk";
import { usePolicies } from "hooks/getPolicies";
import React, { useState, useContext, useEffect } from "react";

import AddPolicyModal from "components/modals/AddPolicyModal";
import api from "api";
import Box from "@mui/material/Box";
import EligibilityModal from "components/modals/EligibilityModal";
import EligibilityTable from "./EligibilityTable";
import PolicyCard from "components/patients/profile/insurance/PolicyCard";
import Section from "components/patients/Section";
import TabContainer from "components/styled/tab-container/TabContainer";
import { getEffectiveDate } from "utils/dates";
import { Patient } from "models/Patient/Patient.types";

const tabHeaders = ["Policy", "Eligibility"];

const PatientInsurance = ({ patient }: { patient: Patient }): JSX.Element => {
  const { allowEligibilityChecks } = useFlags();
  const addInfoAlert = useContext(AddInfoContext);
  const removeInfoAlert = useContext(RemoveInfoContext);
  const { policies, refetch: refetchPolicies } = usePolicies(patient);
  const eligibilityChecks = useEligibility(patient).eligibilityChecks;
  const insurance = { eligibilityChecks, policies };

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [insuranceTabs, setInsuranceTabs] = useState({ Policy: [], Eligibility: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isEligibilityModalOpen, setIsEligibilityModalOpen] = useState<boolean>(false);
  const [isAddPolicyModalOpen, setIsAddPolicyModalOpen] = useState(false);

  const handleEligibilityCheck = async (policyId: string) => {
    const alertId = addInfoAlert({
      status: "info",
      message: "Checking eligibility...",
      isToast: true,
      enableAutoHide: false,
    });

    const eligibilityResponse = await api.client.Eligibility.create({
      body: {
        eligibility: {
          insurance_policy_id: policyId,
        },
      },
    }).catch((e) => {
      removeInfoAlert(alertId);
      addInfoAlert({ status: "error", message: formatError(e), isToast: true });
      setIsEligibilityModalOpen(false);
    });

    if (eligibilityResponse && eligibilityResponse.body) {
      insurance.eligibilityChecks.push(eligibilityResponse.body);
      setIsEligibilityModalOpen(false);
      removeInfoAlert(alertId);
    }
    return;
  };

  useEffect(() => {
    if (insurance) {
      (async () => {
        setIsLoading(true);

        const insuranceTabs = {};

        if (eligibilityChecks.length) {
          insuranceTabs["Eligibility"] = insurance;
        }

        let contentToDisplay = policies?.sort((a, b) => {
          const da = new Date(a.created_at).getTime();
          const db = new Date(b.created_at).getTime();
          return da - db;
        });

        if (contentToDisplay?.length) {
          contentToDisplay = contentToDisplay.map((t, i) => ({
            ...t,
            member_id: contentToDisplay[i].patient_number,
            subscriber_name: `${contentToDisplay[i].subscriber.first_name} ${contentToDisplay[i].subscriber.last_name}`,
            status: eligibilityChecks.length && eligibilityChecks[0].status, // first in array will be most recent
            effective_date:
              eligibilityChecks.length && getEffectiveDate(contentToDisplay[i], eligibilityChecks),
          }));
        }

        insuranceTabs["Policy"] = contentToDisplay;

        // @ts-ignore
        setInsuranceTabs(insuranceTabs);

        setIsLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.id, addInfoAlert, policies, eligibilityChecks]);

  const getResultsToDisplay = (index) => {
    const resultsToDisplay = insuranceTabs[tabHeaders[index]];

    if (tabHeaders[index] === "Eligibility") {
      return <EligibilityTable patient={patient} insurance={insurance} />;
    }

    return resultsToDisplay?.map((c, i) => {
      return (
        <PolicyCard
          key={i}
          cardInfo={createCardInfo(c, "Policies", patient.id)}
          patientId={patient.id}
          refetchPolicies={refetchPolicies}
        />
      );
    });
  };

  const onTabClick = async (tabIndex) => {
    if (tabIndex !== currentTabIndex) {
      setCurrentTabIndex(tabIndex);
    }
  };

  const PolicyButton = (
    <>
      <AddPolicyModal
        open={isAddPolicyModalOpen}
        onClose={() => setIsAddPolicyModalOpen(false)}
        onSubmit={async (formData) => {
          let payload: any = {
            patient_id: patient.id,
            patient_number: formData.patientNumber,
            group_number: formData.groupNumber,
            plan_name: formData.planName,
            plan_type: formData.planType.value,
            rx_bin: formData.rxBin ? formData.rxBin : null,
            rx_pcn: formData.rxPcn ? formData.rxPcn : null,
            enabled: false,
            coordination_of_benefits: formData.designation.value,
            payer_identification_attributes: {
              payer_id: formData.payerInfo.value,
              payer_name: formData.payerInfo.label,
            },
          };

          if (formData.subscriberType === "patient") {
            payload = {
              ...payload,
              subscriber_attributes: { subscriber_id: patient.id },
            };
          } else if (formData.subscriberType === "someone_else") {
            payload = {
              ...payload,
              subscriber_attributes: {
                first_name: formData.firstName,
                last_name: formData.lastName,
                administrative_gender: formData.gender.value,
                birth_date: formData.birthDate,
                patient_relationship_to_subscriber_code: formData.relationshipToPatient.label,
                addresses_attributes: [
                  {
                    line1: formData.line1,
                    line2: formData.line2,
                    city: formData.city,
                    state: formData.state,
                    zip: formData.zip,
                    country: "USA",
                    primary: true,
                  },
                ],
              },
            };
          }

          const response = await api.client.InsurancePolicy.create({
            body: {
              insurance_policy: payload,
            },
          }).catch((e) => {
            addInfoAlert({ status: "error", message: formatError(e), isToast: true });
          });

          if (response && response.body) {
            const imagePayload = {};
            if (Array.isArray(formData.frontImage) && formData.frontImage[0]) {
              imagePayload["insuranceCardFront"] = formData.frontImage[0];
            }
            if (Array.isArray(formData.backImage) && formData.backImage[0]) {
              imagePayload["insuranceCardBack"] = formData.backImage[0];
            }
            if (Object.keys(imagePayload).length > 0) {
              const imageResponse = await api.client.InsurancePolicy.uploadCard(
                response.body.id,
                imagePayload
              ).catch((e) => {
                addInfoAlert({ status: "error", message: formatError(e), isToast: true });
              });

              if (imageResponse.ok) {
                refetchPolicies();
                setIsAddPolicyModalOpen(false);
              }
            } else {
              refetchPolicies();
              setIsAddPolicyModalOpen(false);
            }
          }
        }}
      />

      <OutlinedButton onClick={() => setIsAddPolicyModalOpen(true)}>Add policy</OutlinedButton>
    </>
  );

  const EligibilityButton = (
    <>
      <EligibilityModal
        onClose={() => setIsEligibilityModalOpen(false)}
        open={isEligibilityModalOpen}
        policies={policies}
        onChange={handleEligibilityCheck}
      />
      <OutlinedButton onClick={() => setIsEligibilityModalOpen(true)} disabled={!policies.length}>
        Run eligibility check
      </OutlinedButton>
    </>
  );

  return (
    <Section title="Insurance" style={{ width: "100%" }}>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <TabContainer
          tabHeaders={tabHeaders}
          tabEmptyText={["No policies added to patient", "No eligibility checks recorded"]}
          currentTabContent={getResultsToDisplay(currentTabIndex)}
          contentIsComponent={currentTabIndex === 1}
          currentTabIndex={currentTabIndex}
          onTabClick={onTabClick}
          isLoading={isLoading}
          button={
            currentTabIndex === 0 ? PolicyButton : allowEligibilityChecks && EligibilityButton
          }
        />
      </Box>
    </Section>
  );
};

export default PatientInsurance;
