import React, { PropsWithChildren } from "react";
import { PermissionsViewsName, PermissionsViewsPermission } from "./PermissionsViews.type";
import { useUserCanView } from "./useUserCanView.hook";
type ProtectedComponentProps = PropsWithChildren<{
  permissionsViewsName: PermissionsViewsName | PermissionsViewsName[];
  permissionsViewsPermission: PermissionsViewsPermission;
  loaderComponent?: JSX.Element;
  fallBackComponent?: JSX.Element;
  onErrorComponent?: JSX.Element;
}>;

export const ProtectedComponent = ({
  children,
  permissionsViewsName,
  permissionsViewsPermission,
  onErrorComponent,
  fallBackComponent,
  loaderComponent: loader,
}: ProtectedComponentProps) => {
  const {
    data: userCanView,
    isLoading,
    isError,
  } = useUserCanView({ permissionsViewsPermission, permissionsViewsName });

  if (isError) {
    return onErrorComponent ? onErrorComponent : null;
  }

  if (isLoading) {
    return loader ? loader : null;
  }

  return userCanView ? <>{children}</> : fallBackComponent ? fallBackComponent : null;
};
