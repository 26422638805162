import Box from "@mui/material/Box";
import React from "react";

const PortalBodyContainer = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style: React.CSSProperties;
}): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

export default PortalBodyContainer;
