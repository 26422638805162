/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { SurveyssubmissionslinkSubmission } from "./SurveyssubmissionslinkSubmission";

/**
 * The SubmissionsLinkBody model module.
 * @module model/SubmissionsLinkBody
 * @version v0.3
 */
export class SubmissionsLinkBody {
  /**
   * Constructs a new <code>SubmissionsLinkBody</code>.
   * @alias module:model/SubmissionsLinkBody
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>SubmissionsLinkBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SubmissionsLinkBody} obj Optional instance to populate.
   * @return {module:model/SubmissionsLinkBody} The populated <code>SubmissionsLinkBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SubmissionsLinkBody();
      if (data.hasOwnProperty("submission"))
        obj.submission = SurveyssubmissionslinkSubmission.constructFromObject(data["submission"]);
    }
    return obj;
  }
}

/**
 * @member {module:model/SurveyssubmissionslinkSubmission} submission
 */
SubmissionsLinkBody.prototype.submission = undefined;
