import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import isEqual from "date-fns/isEqual";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as DatePickerMui } from "@mui/x-date-pickers/DatePicker";
import React from "react";

import { DatePickerProps } from "./DatePicker.types";

const DatePicker = (props: DatePickerProps) => {
  const {
    label = "Choose date",
    date,
    onChange,
    disabledDates = [],
    readOnly,
    disabled,
    disableFuture = false,
    disablePast = false,
    error,
    helperText,
    renderInputVariant,
    renderInputSize = "medium",
    style,
  } = props;

  const isDateDisabled = (date: Date) => {
    // if date is disabled
    return (
      disabledDates?.filter((disabledDate) => {
        const dateToString = new Date(disabledDate.toDateString());
        return isEqual(date, dateToString);
      }).length > 0
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePickerMui
        label={label}
        value={date}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            error={error}
            helperText={helperText}
            variant={renderInputVariant}
            size={renderInputSize}
            style={style}
          />
        )}
        readOnly={readOnly}
        disableFuture={disableFuture}
        disablePast={disablePast}
        shouldDisableDate={isDateDisabled}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
