/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { IntegrationsashWellnessAshWellnessIntegration1 } from "./IntegrationsashWellnessAshWellnessIntegration1";

/**
 * The IntegrationsAshWellnessBody1 model module.
 * @module model/IntegrationsAshWellnessBody1
 * @version v0.3
 */
export class IntegrationsAshWellnessBody1 {
  /**
   * Constructs a new <code>IntegrationsAshWellnessBody1</code>.
   * @alias module:model/IntegrationsAshWellnessBody1
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>IntegrationsAshWellnessBody1</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/IntegrationsAshWellnessBody1} obj Optional instance to populate.
   * @return {module:model/IntegrationsAshWellnessBody1} The populated <code>IntegrationsAshWellnessBody1</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new IntegrationsAshWellnessBody1();
      if (data.hasOwnProperty("ash_wellness_integration"))
        obj.ashWellnessIntegration =
          IntegrationsashWellnessAshWellnessIntegration1.constructFromObject(
            data["ash_wellness_integration"]
          );
    }
    return obj;
  }
}

/**
 * @member {module:model/IntegrationsashWellnessAshWellnessIntegration1} ashWellnessIntegration
 */
IntegrationsAshWellnessBody1.prototype.ashWellnessIntegration = undefined;
