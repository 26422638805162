import { captureException } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { ReactQueryKeys } from "constants/keys";
import { useAtom } from "jotai";

import { User } from "models/User/User.types";
import api from "../../api";
import { isSignedInAtom } from "./isSignedIn.atom";

export const fetchCurrentUser = async () => {
  try {
    const userPayload = await api.auth.getUserPayload();
    const { userId, userType } = userPayload;
    if (["Practitioner", "TenantAdmin"].includes(userType)) {
      const response = await api.client[userType]
        .get(userId)
        .catch((e) => console.error(`not able to get that userType[${userType}]`, e));
      if (response && response.body) {
        response.body.userType = userType;
        return response.body as User;
      }
    } else {
      const error =
        "You are not logged in as practitioner or tenant admin, unable to get currentUser";
      captureException(error);
      throw new Error(error);
    }
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const useCurrentUser = () => {
  const [isSignedIn] = useAtom(isSignedInAtom);

  return useQuery([ReactQueryKeys.CURRENT_USER], () => fetchCurrentUser(), {
    enabled: isSignedIn,
  });
};
