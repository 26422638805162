import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import React from "react";

import {
  FILTER_TEMPLATE,
  INIT_FILTER_STATE,
  OPERATOR_OPTIONS_BY_TYPE,
  GLOBAL_OPERATOR_OPTIONS,
} from "./Filter.constants";
import { FilterProps, filter, FIELD_TYPES } from "./Filter.types";
import { filtersSettingsComplete, filterIsReset } from "utils/filters";
import ButtonChoice from "../button-choice/ButtonChoice";
import DatePicker from "../date-picker/DatePicker";
import NumberFieldInput from "../number-field-input/NumberFieldInput";
import Select from "../select/Select";
import TextFieldInput from "../text-field-input/TextFieldInput";

const useStyles = makeStyles((theme) => ({
  filterWrapper: {
    maxWidth: "100% important",
    overflow: "hidden",
    // @ts-ignore
    margin: theme.spacing(2),
  },
  topFilterBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // @ts-ignore
    marginBottom: theme.spacing(1.5),
  },
  closeRowSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  closeButtonIcon: {
    width: "15px !important",
    height: "15px !important",
    border: "1px solid #757575 !important",
    borderRadius: "50% !important",
  },
  closeSVG: {
    width: "15px !important",
  },
  addApplyGridItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  addApplyRowSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between !important",
    width: "135px",

    // @ts-ignore
    [theme.breakpoints.up("md")]: {
      width: "80px",
    },
  },
  addButtonIcon: {
    display: "none !important",

    // @ts-ignore
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
      width: "15px !important",
      height: "15px !important",
      border: "1px solid #757575",
      borderRadius: "50%",
    },
  },
  addButtonSVG: {
    display: "none !important",
    // @ts-ignore
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
      width: "18px !important",
      height: "18px !important",
      borderRadius: "50%",
      backgroundColor: "#000",
      color: "white",
    },
  },
  addButtonWithText: {
    display: "inline-block",
    // @ts-ignore
    marginRight: `${theme.spacing(2)} !important`,

    // @ts-ignore
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
  },
  applyButton: {
    // @ts-ignore
    padding: theme.spacing(0.5),
    minWidth: 0,
    "&.Mui-disabled": {
      color: "#848484",
      borderColor: "#b9b9b9",
    },
  },
}));

const FilterRow = ({
  rowIndex,
  filters,
  setFilters,
  filterFieldOptions,
  applyFilters,
}: {
  rowIndex: number;
  filters: filter[];
  setFilters: any;
  filterFieldOptions: any;
  applyFilters: any;
}) => {
  const styles = useStyles();
  const currentFilterSettings = filters[rowIndex];
  const currentFieldType = currentFilterSettings.field
    ? filterFieldOptions.find((c) => c.value === currentFilterSettings.field)?.meta?.fieldType
    : null;
  const currentOperatorChoices = OPERATOR_OPTIONS_BY_TYPE[currentFieldType] || [];
  const getCurrentInputField = () => {
    const onChangeWithValue = (value) => {
      const filtersCopy = [...filters];
      const newFilter = { ...currentFilterSettings, value: value };
      filtersCopy[rowIndex] = newFilter;
      setFilters(filtersCopy);
    };

    const onChangeWithEvent = (event) => {
      const filtersCopy = [...filters];
      const newFilter = { ...currentFilterSettings, value: event.target.value };
      filtersCopy[rowIndex] = newFilter;
      setFilters(filtersCopy);
    };

    switch (currentFieldType) {
      case FIELD_TYPES.boolean:
        return (
          <Select
            size="small"
            variant="outlined"
            inputID={`row-${rowIndex}-value`}
            label="value"
            value={currentFilterSettings.value}
            choices={[
              { label: "true", value: true },
              { label: "false", value: false },
            ]}
            fullWidth
            onChange={onChangeWithValue}
          />
        );
      case FIELD_TYPES.string:
        return (
          <TextFieldInput
            label="value"
            value={currentFilterSettings.value}
            onChange={onChangeWithValue}
            size="small"
          />
        );
      case FIELD_TYPES.enum:
        return (
          <TextFieldInput
            label="value"
            value={currentFilterSettings.value}
            onChange={onChangeWithValue}
            size="small"
          />
        );
      case FIELD_TYPES.date:
        return (
          <DatePicker
            label="value"
            date={currentFilterSettings.value}
            onChange={onChangeWithValue}
            renderInputSize="small"
          />
        );
      case FIELD_TYPES.number:
        return (
          <NumberFieldInput
            id={`row-${rowIndex}-value`}
            label="value"
            size="small"
            variant="outlined"
            value={currentFilterSettings.value}
            onChange={onChangeWithEvent}
            formControlStyles={{ width: "100%" }}
          />
        );
      default:
        // Just a placeholder component, for visual harmony
        return (
          <Select
            size="small"
            variant="outlined"
            inputID={`row-${rowIndex}-value`}
            value=""
            choices={[]}
            onChange={() => {}}
            label="value"
            fullWidth
            disabled
          />
        );
    }
  };

  const onAddFilter = () => {
    const filtersCopy = [...filters];
    filtersCopy.push(FILTER_TEMPLATE);
    setFilters(filtersCopy);
  };

  const disableApplyButton = !filtersSettingsComplete || !filterIsReset;

  return (
    <>
      <Grid item key={`row-${rowIndex}-column`} xs={4} md={3} data-sentry-unmask>
        <Select
          size="small"
          variant="outlined"
          label="field"
          inputID={`row-${rowIndex}-field`}
          onChange={(value) => {
            const filtersCopy = [...filters];
            const newFilter = { field: value, operator: null, value: null };
            filtersCopy[rowIndex] = newFilter;
            setFilters(filtersCopy);
          }}
          value={currentFilterSettings.field}
          choices={filterFieldOptions}
          fullWidth
        />
      </Grid>
      <Grid item key={`row-${rowIndex}-operator`} xs={3} data-sentry-unmask>
        <Select
          size="small"
          variant="outlined"
          label="operator"
          inputID={`row-${rowIndex}-operator`}
          onChange={(value) => {
            const filtersCopy = [...filters];
            const newFilter = { ...currentFilterSettings, operator: value };
            filtersCopy[rowIndex] = newFilter;
            setFilters(filtersCopy);
          }}
          value={currentFilterSettings.operator}
          choices={currentOperatorChoices}
          fullWidth
        />
      </Grid>
      <Grid item key={`row-${rowIndex}-value`} xs={4} md={3}>
        {getCurrentInputField()}
      </Grid>
      <Grid
        item
        key={`row-${rowIndex}-close-icon`}
        xs={1}
        md={0.75}
        className={styles.closeRowSection}
        data-sentry-unmask
      >
        <IconButton
          onClick={() => {
            if (rowIndex === 0 && filters.length === 1) {
              setFilters(INIT_FILTER_STATE);
              return;
            }

            const filtersCopy = [...filters];
            filtersCopy.splice(rowIndex, 1);
            setFilters(filtersCopy);
          }}
          className={styles.closeButtonIcon}
        >
          <CloseIcon className={styles.closeSVG} />
        </IconButton>
      </Grid>

      {rowIndex === filters.length - 1 && (
        <Grid
          item
          key={`row-${rowIndex}-add-apply-icons`}
          xs={12}
          md={2.25}
          className={styles.addApplyGridItem}
          data-sentry-unmask
        >
          <Box className={styles.addApplyRowSection}>
            <IconButton className={styles.addButtonIcon} onClick={onAddFilter}>
              <AddIcon className={styles.addButtonSVG} />
            </IconButton>
            <Button onClick={onAddFilter} size="small" className={styles.addButtonWithText}>
              Add Filter
            </Button>
            <Button
              size="small"
              className={styles.applyButton}
              variant="outlined"
              onClick={applyFilters}
              disabled={disableApplyButton}
            >
              Apply
            </Button>
          </Box>
        </Grid>
      )}
    </>
  );
};

const Filter = (props: FilterProps): JSX.Element => {
  const styles = useStyles();
  return (
    <Box className={styles.filterWrapper}>
      <Box className={styles.topFilterBlock}>
        <Box>Filter By</Box>

        <ButtonChoice
          handleChange={(value) => {
            props.setGlobalOperator(value);
          }}
          value={props.globalOperator}
          options={GLOBAL_OPERATOR_OPTIONS}
          size="small"
        />
      </Box>

      <Grid container spacing={1}>
        {props.filterFieldOptions &&
          props.filters.map((row, index) => {
            return (
              <FilterRow
                key={index}
                rowIndex={index}
                filters={props.filters}
                setFilters={props.setFilters}
                filterFieldOptions={props.filterFieldOptions}
                applyFilters={props.applyFilters}
              />
            );
          })}
      </Grid>
    </Box>
  );
};

export default Filter;
