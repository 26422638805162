import { Link as RouterLink } from "react-router-dom";
import api from "api";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import React, { useContext, useState } from "react";
import Switch from "components/styled/switch/Switch";
import Typography from "@mui/material/Typography";

import { AddInfoContext } from "contexts/info";
import { colors } from "styles/colors";
import { formatError } from "utils/strings";
import StatusBadge from "components/styled/status-badge/StatusBadge";

const PolicyCard = ({
  cardInfo,
  patientId,
  refetchPolicies,
}: {
  cardInfo: any;
  patientId: string;
  refetchPolicies: () => void;
}) => {
  const addInfoAlert = useContext(AddInfoContext);
  const [isEnabled, setIsEnabled] = useState<boolean>(cardInfo.enabled);

  const updatePolicyStatus = async (newEnabledValue) => {
    const response = await api.client.InsurancePolicy.update(cardInfo.id, {
      body: {
        insurance_policy: {
          enabled: newEnabledValue,
        },
      },
    }).catch((e) => {
      addInfoAlert({
        status: "error",
        message: formatError(e),
        isToast: true,
      });
    });

    if (response && response.body) {
      setIsEnabled(newEnabledValue);
      refetchPolicies();
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        minHeight: "250px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            padding: (theme) => theme.spacing(2),
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ fontWeight: "bold", fontSize: "16px" }}>{cardInfo?.title}</Box>
          <Box
            sx={{
              fontSize: "14px",
              color: colors.darkGrey,
              marginBottom: (theme) => theme.spacing(2),
            }}
          >
            {cardInfo?.subtitle}
          </Box>
        </Box>
        {cardInfo.status && <StatusBadge status={cardInfo.status} />}
      </Box>
      <Divider />
      <Box
        sx={{
          marginY: (theme) => theme.spacing(2),
          display: "flex",
          flexDirection: "column",
        }}
      >
        {cardInfo?.metrics?.map((metric, index) => {
          if (metric.text) {
            return (
              <Box
                key={index}
                sx={{
                  margin: (theme) => theme.spacing(0.25, 0),
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{ paddingLeft: (theme) => theme.spacing(1), fontSize: "14px" }}>
                  {metric.text}
                </Box>
              </Box>
            );
          }
        })}
      </Box>
      <Box>
        <Divider
          sx={{
            margin: "0 -20px",
          }}
        />
        <Box
          sx={{
            paddingTop: (theme) => theme.spacing(1),
            backgroundColor: colors.lightGrey3,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              marginBottom: (theme) => theme.spacing(2),
            }}
          >
            <Switch
              name="enable-policy-switch"
              checked={isEnabled}
              onChange={() => updatePolicyStatus(!isEnabled)}
            />
            <Typography sx={{ marginLeft: (theme) => theme.spacing(2) }}>
              {isEnabled ? "ENABLED" : "DISABLED"}
            </Typography>
          </Box>
          <Link
            sx={{
              color: (theme) => theme.palette.primary.main,
              textDecoration: "none",
              ":hover": { fontWeight: "bold" },
              marginTop: (theme) => theme.spacing(1),
              marginRight: (theme) => theme.spacing(3),
            }}
            to={`/patients/${patientId}/insurance/${cardInfo.id}`}
            component={RouterLink}
          >
            Edit
          </Link>
        </Box>
      </Box>
    </Card>
  );
};

export default PolicyCard;
