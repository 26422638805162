/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { OrdersOrder } from "./OrdersOrder";

/**
 * The OrdersBody model module.
 * @module model/OrdersBody
 * @version v0.3
 */
export class OrdersBody {
  /**
   * Constructs a new <code>OrdersBody</code>.
   * @alias module:model/OrdersBody
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>OrdersBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/OrdersBody} obj Optional instance to populate.
   * @return {module:model/OrdersBody} The populated <code>OrdersBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OrdersBody();
      if (data.hasOwnProperty("order")) obj.order = OrdersOrder.constructFromObject(data["order"]);
    }
    return obj;
  }
}

/**
 * @member {module:model/OrdersOrder} order
 */
OrdersBody.prototype.order = undefined;
