/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The AllergensidAllergen model module.
 * @module model/AllergensidAllergen
 * @version v0.3
 */
export class AllergensidAllergen {
  /**
   * Constructs a new <code>AllergensidAllergen</code>.
   * @alias module:model/AllergensidAllergen
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>AllergensidAllergen</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AllergensidAllergen} obj Optional instance to populate.
   * @return {module:model/AllergensidAllergen} The populated <code>AllergensidAllergen</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AllergensidAllergen();
      if (data.hasOwnProperty("clinical_status"))
        obj.clinicalStatus = ApiClient.convertToType(data["clinical_status"], "String");
      if (data.hasOwnProperty("non_terminology_allergen"))
        obj.nonTerminologyAllergen = ApiClient.convertToType(
          data["non_terminology_allergen"],
          "String"
        );
    }
    return obj;
  }
}

/**
 * Allowed values for the <code>clinicalStatus</code> property.
 * @enum {String}
 * @readonly
 */
AllergensidAllergen.ClinicalStatusEnum = {
  /**
   * value: "active"
   * @const
   */
  active: "active",

  /**
   * value: "resolved"
   * @const
   */
  resolved: "resolved",
};
/**
 * indicates if an allergen's clinical state
 * @member {module:model/AllergensidAllergen.ClinicalStatusEnum} clinicalStatus
 */
AllergensidAllergen.prototype.clinicalStatus = undefined;

/**
 * Currently, only plain text allergens are accepted, we do not yet accept SNOMED or RX CUI codes
 * @member {String} nonTerminologyAllergen
 */
AllergensidAllergen.prototype.nonTerminologyAllergen = undefined;
