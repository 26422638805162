import CheckIcon from "@mui/icons-material/Check";
import { colors } from "styles/colors";

export const medicationStatuses = {
  prescriptionStatuses: {
    created: { text: "Prescription Created", color: "rgba(7, 169, 143, 0.18)" },
    assigned: { text: "Prescription Assigned", color: "rgba(7, 169, 143, 0.18)" },
    support: { text: "Prescription Processing", color: "rgba(7, 169, 143, 0.18)" },
    waiting: { text: "Prescription Processing", color: "rgba(7, 169, 143, 0.18)" },
    processing: { text: "Prescription Processing", color: "rgba(7, 169, 143, 0.18)" },
    completed: { text: "Prescription Completed", color: "#07A98F" },
    cancelled: { text: "Prescription Cancelled", color: "#F27652" },
    active: { text: "Active", color: "rgba(7, 169, 143, 0.18)" }, // Photon Health status
    depleted: { text: "Depleted", color: "#07A98F" }, // Photon Health status
    expired: { text: "Expired", color: "#F27652" }, // Photon Health status
  },
  orderStatuses: {
    draft: { text: "Order Drafted" },
    creation_confirmed: { text: "Order Created", color: "rgba(7, 169, 143, 0.18)" },
    order_placed: { text: "Order Placed", color: "rgba(7, 169, 143, 0.18)" },
    processing: { text: "Order Processing" },
    awaiting_payment: { text: "Awaiting Payment" },
    awaiting_shipment: { text: "Awaiting Shipment" },
    shipped_to_patient: { text: "Order Shipped", color: "rgba(7, 169, 143, 0.18)" },
    shipped: { text: "Order Shipped", color: "rgba(7, 169, 143, 0.18)" },
    on_hold: { text: "Order On Hold" },
    cancelled: { text: "Order Cancelled", color: "#F27652" },
    delivered: { text: "Order Delivered", color: "#07A98F" },
    delivered_to_patient: { text: "Order Delivered", color: "#07A98F" },
    shipped_to_lab: { text: "Shipped to Lab" },
    delivered_to_lab: { text: "Delivered to Lab" },
    results_ready: { text: "Results Ready", color: "#07A98F" },
  },
};

export const careplanStatuses = {
  completed: { text: "Completed", color: "#07A98F", Icon: CheckIcon },
  active: { text: "In Progress", color: "rgba(7, 169, 143, 0.18)", Icon: null },
  revoked: { text: "Archived", color: "", Icon: null },
  draft: { text: "Draft", color: "", Icon: null },
  on_hold: { text: "On Hold", color: "", Icon: null },
  entered_in_error: { text: "Entered In Error", color: "#F27652", Icon: null },
  unknown: { text: "Unknown", color: "", Icon: null },
};

export const goalStatuses = {
  achieved: { text: "Completed", color: "#07A98F", Icon: CheckIcon },
  in_progress: { text: "In Progress", color: "rgba(7, 169, 143, 0.18)", Icon: null },
  improving: { text: "Improving", color: "rgba(7, 169, 143, 0.18)", Icon: null },
  worsening: { text: "Worsening", color: "#F27652", Icon: null },
  no_change: { text: "No Change", color: "rgba(7, 169, 143, 0.18)", Icon: null },
  sustaining: { text: "Sustaining", color: "rgba(7, 169, 143, 0.18)", Icon: null },
  not_achieved: { text: "Not Achieved", color: "#F27652", Icon: null },
  no_progress: { text: "No Progress", color: "#F27652", Icon: null },
  not_attainable: { text: "Not Attainable", color: "", Icon: null },
};

export const taskStatuses = {
  completed: { text: "Completed", color: "#07A98F", Icon: CheckIcon },
  in_progress: { text: "In Progress", color: "rgba(7, 169, 143, 0.18)", Icon: null },
  cancelled: { label: "Archived", color: "", Icon: null },
};

export const submissionStatuses = {
  completed: { text: "Completed", color: "#07A98F", Icon: CheckIcon },
  draft: { text: "In Progress", color: "rgba(7, 169, 143, 0.18)", Icon: null },
};

export const labStatuses = {
  draft: { text: "Order Drafted" },
  creation_confirmed: { text: "Order Created", color: "rgba(7, 169, 143, 0.18)" },
  order_placed: { text: "Order Placed", color: "rgba(7, 169, 143, 0.18)" },
  awaiting_payment: { text: "Awaiting Payment" },
  awaiting_shipment: { text: "Awaiting Shipment" },
  shipped_to_patient: { text: "Shipped to Patient", color: "rgba(7, 169, 143, 0.18)" },
  on_hold: { text: "Order On Hold" },
  cancelled: { text: "Order Cancelled", color: "#F27652" },
  delivered_to_patient: { text: "Delivered to Patient", color: "rgba(7, 169, 143, 0.18)" },
  shipped_to_lab: { text: "Shipped to Lab", color: "rgba(7, 169, 143, 0.18)" },
  delivered_to_lab: { text: "Delivered to Lab", color: "rgba(7, 169, 143, 0.18)" },
  results_ready: { text: "Results Ready", color: "#07A98F" },
  partial_results_ready: { text: "Partial Results Ready", color: "#07A98F" },
  invalid_results: { text: "Invalid Results", color: "#DA114D" },
};

enum EligibilityStatus {
  ACTIVE_COVERAGE = "Active Coverage",
  INACTIVE = "Inactive",
  UNKNOWN = "Unknown",
}

interface StatusStyle {
  text: string;
  backgroundColor: string;
  color: string;
  width?: string;
}

export const eligibilityStatuses: Record<EligibilityStatus, StatusStyle> = {
  [EligibilityStatus.ACTIVE_COVERAGE]: {
    text: "Active",
    backgroundColor: "#73EBA3",
    color: "#356A4A",
  },
  [EligibilityStatus.INACTIVE]: { text: "Inactive", backgroundColor: "#DA114D", color: "#890C31" },
  [EligibilityStatus.UNKNOWN]: {
    text: "Unknown",
    backgroundColor: colors.lightGrey2,
    color: colors.darkGrey,
  },
};

export enum ClaimStatus {
  PAID = "Paid",
  FINALIZED_PAID = "finalized_paid",
  FINALIZED_DENIED = "finalized_denied",
  MISSING_INFORMATION = "missing_information",
  NOT_BILLABLE = "not_billable",
  WAITING_FOR_PROVIDER = "waiting_for_provider",
  REJECTED = "rejected",
  DENIED = "denied",
  PAID_INCORRECTLY = "paid_incorrectly",
  CODED = "coded",
  HELD_BY_CUSTOMER = "held_by_customer",
  SUBMITTED_TO_PAYER = "submitted_to_payer",
  BILLER_RECEIVED = "biller_received",
  DRAFT = "draft",
}

export const claimStatuses: Record<ClaimStatus, StatusStyle> = {
  [ClaimStatus.PAID]: { text: "Paid", backgroundColor: "#73EBA3", color: "#356A4A", width: "50px" },
  [ClaimStatus.FINALIZED_PAID]: {
    text: "Finalized paid",
    backgroundColor: "#73EBA3",
    color: "#356A4A",
    width: "150px",
  },
  [ClaimStatus.FINALIZED_DENIED]: {
    text: "Finalized denied",
    backgroundColor: "#DA114D",
    color: "#890C31",
    width: "150px",
  },
  [ClaimStatus.MISSING_INFORMATION]: {
    text: "Missing information",
    backgroundColor: "#DA114D",
    color: "#890C31",
    width: "150px",
  },
  [ClaimStatus.NOT_BILLABLE]: {
    text: "Not billable",
    backgroundColor: "#DA114D",
    color: "#890C31",
    width: "150px",
  },
  [ClaimStatus.WAITING_FOR_PROVIDER]: {
    text: "Waiting for provider",
    backgroundColor: "#DA114D",
    color: "#890C31",
    width: "150px",
  },
  [ClaimStatus.REJECTED]: {
    text: "Rejected",
    backgroundColor: "#DA114D",
    color: "#890C31",
    width: "80px",
  },
  [ClaimStatus.DENIED]: {
    text: "Denied",
    backgroundColor: "#DA114D",
    color: "#890C31",
    width: "80px",
  },
  [ClaimStatus.PAID_INCORRECTLY]: {
    text: "Paid incorrectly",
    backgroundColor: "#DA114D",
    color: "#890C31",
    width: "150px",
  },
  [ClaimStatus.CODED]: {
    text: "Coded",
    backgroundColor: colors.lightGrey2,
    color: colors.darkGrey,
    width: "50px",
  },
  [ClaimStatus.HELD_BY_CUSTOMER]: {
    text: "Held by customer",
    backgroundColor: colors.lightGrey2,
    color: colors.darkGrey,
    width: "150px",
  },
  [ClaimStatus.SUBMITTED_TO_PAYER]: {
    text: "Submitted to payer",
    backgroundColor: colors.lightGrey2,
    color: colors.darkGrey,
    width: "150px",
  },
  [ClaimStatus.BILLER_RECEIVED]: {
    text: "Biller received",
    backgroundColor: colors.lightGrey2,
    color: colors.darkGrey,
    width: "150px",
  },
  [ClaimStatus.DRAFT]: {
    text: "Draft",
    backgroundColor: colors.lightGrey2,
    color: colors.darkGrey,
    width: "50px",
  },
};

export enum TaskStatus {
  OPEN = "open",
  BLOCKED = "blocked",
  WAITING_FOR_REVIEW = "waiting_for_review",
  SENT_TO_PROVIDER = "sent_to_provider",
  SENT_TO_PROVIDER_GROUP = "sent_to_provider_group",
  ADDRESSED_BY_PROVIDER = "addressed_by_provider",
  ADDRESSED_BY_PROVIDER_GROUP = "addressed_by_provider_group",
  FINISHED = "finished",
}

export const insuranceTaskStatuses: Record<TaskStatus, StatusStyle> = {
  [TaskStatus.OPEN]: {
    text: "Open",
    backgroundColor: colors.canaryDark,
    color: colors.canary,
    width: "50px",
  },
  [TaskStatus.ADDRESSED_BY_PROVIDER]: {
    text: "Addressed by provider",
    backgroundColor: colors.lightGrey2,
    color: colors.darkGrey,
    width: "150px",
  },
  [TaskStatus.ADDRESSED_BY_PROVIDER_GROUP]: {
    text: "Addressed by provider group",
    backgroundColor: colors.lightGrey2,
    color: colors.darkGrey,
    width: "180px",
  },
  [TaskStatus.WAITING_FOR_REVIEW]: {
    text: "Waiting for review",
    backgroundColor: colors.lightGrey2,
    color: colors.darkGrey,
    width: "150px",
  },
  [TaskStatus.SENT_TO_PROVIDER]: {
    text: "Sent to provider",
    backgroundColor: colors.lightGrey2,
    color: colors.darkGrey,
    width: "150px",
  },
  [TaskStatus.SENT_TO_PROVIDER_GROUP]: {
    text: "Sent to provider group",
    backgroundColor: colors.lightGrey2,
    color: colors.darkGrey,
    width: "180px",
  },
  [TaskStatus.BLOCKED]: {
    text: "Blocked",
    backgroundColor: "#DA114D",
    color: "#890C31",
    width: "80px",
  },
  [TaskStatus.FINISHED]: {
    text: "Finished",
    backgroundColor: "#73EBA3",
    color: "#356A4A",
    width: "80px",
  },
};
