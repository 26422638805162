/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { IntegrationsshopifyShopifyIntegration1 } from "./IntegrationsshopifyShopifyIntegration1";

/**
 * The IntegrationsShopifyBody1 model module.
 * @module model/IntegrationsShopifyBody1
 * @version v0.3
 */
export class IntegrationsShopifyBody1 {
  /**
   * Constructs a new <code>IntegrationsShopifyBody1</code>.
   * @alias module:model/IntegrationsShopifyBody1
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>IntegrationsShopifyBody1</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/IntegrationsShopifyBody1} obj Optional instance to populate.
   * @return {module:model/IntegrationsShopifyBody1} The populated <code>IntegrationsShopifyBody1</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new IntegrationsShopifyBody1();
      if (data.hasOwnProperty("shopify_integration"))
        obj.shopifyIntegration = IntegrationsshopifyShopifyIntegration1.constructFromObject(
          data["shopify_integration"]
        );
    }
    return obj;
  }
}

/**
 * @member {module:model/IntegrationsshopifyShopifyIntegration1} shopifyIntegration
 */
IntegrationsShopifyBody1.prototype.shopifyIntegration = undefined;
