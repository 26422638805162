/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { ObservationsBody } from "../model/ObservationsBody";

/**
 * Observations service.
 * @module api/ObservationsApi
 * @version v0.3
 */
export class ObservationsApi {
  /**
    * Constructs a new ObservationsApi. 
    * @alias module:api/ObservationsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the observationsGet operation.
   * @callback moduleapi/ObservationsApi~observationsGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List paginated Observations
   * &lt;h3&gt;   &amp;#128275; Access policy &lt;/h3&gt; &lt;p&gt;You can access this endpoint with the following token types:&lt;/p&gt; &lt;p&gt;&amp;#9989; M2M&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;#9989; Patient&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;#9989; Practitioner&lt;/p&gt;
   * @param {Object} opts Optional parameters
   * @param {Number} opts.page Page number (default to <.>)
   * @param {Number} opts.size Page size (default to <.>)
   * @param {Array.<module:model/String>} opts.sortBy Sort results
   * @param {Array.<String>} opts.filters *Note: that the strings are stringified and encoded objects.*  Filter with operators.  | Field          | Operator | | ---------------| ---------| | id | eq, not_eq, matches, does_not_match, in, not_in | | created_at | eq, not_eq, gt, gteq, lt, lteq, in, not_in | | updated_at | eq, not_eq, gt, gteq, lt, lteq, in, not_in | | name | eq, not_eq, matches, does_not_match, in, not_in | | goal_id | eq, not_eq, matches, does_not_match, in, not_in | | patient_id | eq, not_eq, matches, does_not_match, in, not_in | | source_id | eq, not_eq, matches, does_not_match, in, not_in | | source_type | eq, not_eq, matches, does_not_match, in, not_in | | observed_date | eq, not_eq, gt, gteq, lt, lteq, in, not_in | | tags | eq, not_eq, matches, does_not_match, in, not_in | | observation_type_group_id | eq, not_eq, matches, does_not_match, in, not_in | | observation_type_id | eq, not_eq, matches, does_not_match, in, not_in |  Example query value: &#x60;&#x60;&#x60; { &#x27;field&#x27;: &#x27;id&#x27;, &#x27;operator&#x27;:&#x27;eq&#x27;, &#x27;value&#x27;:&#x27;expected_value&#x27;, } &#x60;&#x60;&#x60;  Example stringified and encoded query value: &#x60;&#x60;&#x60; %7B%22field%22%3A%22%23%7Bid%7D%22%2C%22operator%22%3A%22eq%22%2C%22value%22%3A%22expected_value%22%7D &#x60;&#x60;&#x60;
   * @param {module:model/String} opts.filtersOperator Operator to chain filters
   * @param {Array.<String>} opts.byId Filter by id
   * @param {String} opts.byName Filter by name
   * @param {Array.<String>} opts.byTags Filter by tags
   * @param {Array.<String>} opts.byPatientId Filter by patient id
   * @param {Array.<String>} opts.byGoalId Filter by goal id
   * @param {Array.<String>} opts.bySourceId Filter by source id
   * @param {Array.<module:model/String>} opts.bySourceType Filter by source type
   * @param {Array.<String>} opts.byObservationTypeId Filter by observation type id
   * @param {Array.<String>} opts.byObservationTypeGroupId Filter by observation type group id
   * @param {module:api/ObservationsApi~observationsGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  observationsGet(opts, callback) {
    opts = opts || {};
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      page: opts["page"],
      size: opts["size"],
      sort_by: this.apiClient.buildCollectionParam(opts["sortBy"], "csv"),
      "filters[]": this.apiClient.buildCollectionParam(opts["filters"], "multi"),
      filters_operator: opts["filtersOperator"],
      by_id: this.apiClient.buildCollectionParam(opts["byId"], "csv"),
      by_name: opts["byName"],
      by_tags: this.apiClient.buildCollectionParam(opts["byTags"], "csv"),
      by_patient_id: this.apiClient.buildCollectionParam(opts["byPatientId"], "csv"),
      by_goal_id: this.apiClient.buildCollectionParam(opts["byGoalId"], "csv"),
      by_source_id: this.apiClient.buildCollectionParam(opts["bySourceId"], "csv"),
      by_source_type: this.apiClient.buildCollectionParam(opts["bySourceType"], "csv"),
      by_observation_type_id: this.apiClient.buildCollectionParam(
        opts["byObservationTypeId"],
        "csv"
      ),
      by_observation_type_group_id: this.apiClient.buildCollectionParam(
        opts["byObservationTypeGroupId"],
        "csv"
      ),
    };
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/observations",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the observationsIdGet operation.
   * @callback moduleapi/ObservationsApi~observationsIdGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Retrieve an Observation
   * &lt;h3&gt;   &amp;#128275; Access policy &lt;/h3&gt; &lt;p&gt;You can access this endpoint with the following token types:&lt;/p&gt; &lt;p&gt;&amp;#9989; M2M&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;#9989; Patient&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;#9989; Practitioner&lt;/p&gt;
   * @param {String} id
   * @param {module:api/ObservationsApi~observationsIdGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  observationsIdGet(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling observationsIdGet");
    }

    let pathParams = {
      id: id,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/observations/{id}",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the observationsPost operation.
   * @callback moduleapi/ObservationsApi~observationsPostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Create an Observation
   * &lt;h3&gt;   &amp;#128275; Access policy &lt;/h3&gt; &lt;p&gt;You can access this endpoint with the following token types:&lt;/p&gt; &lt;p&gt;&amp;#9989; M2M&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;#9989; Patient&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;#9989; Practitioner&lt;/p&gt;
   * @param {Object} opts Optional parameters
   * @param {module:model/ObservationsBody} opts.body
   * @param {module:api/ObservationsApi~observationsPostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  observationsPost(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/observations",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
}
