import Auth from "@capable-health/capable-auth-sdk";
import { useState } from "react";
import { formatError } from "utils/strings";

export const useForgotPassword = (onSuccess: () => void) => {
  const [isError, setIsError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const forgotPassword = async (email: string) => {
    try {
      setIsError(undefined);
      setIsLoading(true);
      await Auth.credentials.forgotPassword(email);
      onSuccess();
    } catch (error) {
      console.error(error);
      setIsError(formatError(error));
    } finally {
      setIsLoading(false);
    }
  };

  return { forgotPassword, isError, isLoading };
};
