/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The AllergensAllergen model module.
 * @module model/AllergensAllergen
 * @version v0.3
 */
export class AllergensAllergen {
  /**
   * Constructs a new <code>AllergensAllergen</code>.
   * @alias module:model/AllergensAllergen
   * @class
   * @param patientId {String} ID of the patient whom this allergen is associated with
   * @param nonTerminologyAllergen {String} Currently, only plain text allergens are accepted, we do not yet accept SNOMED or RX CUI codes
   */
  constructor(patientId, nonTerminologyAllergen) {
    this.patientId = patientId;
    this.nonTerminologyAllergen = nonTerminologyAllergen;
  }

  /**
   * Constructs a <code>AllergensAllergen</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AllergensAllergen} obj Optional instance to populate.
   * @return {module:model/AllergensAllergen} The populated <code>AllergensAllergen</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AllergensAllergen();
      if (data.hasOwnProperty("clinical_status"))
        obj.clinicalStatus = ApiClient.convertToType(data["clinical_status"], "String");
      if (data.hasOwnProperty("patient_id"))
        obj.patientId = ApiClient.convertToType(data["patient_id"], "String");
      if (data.hasOwnProperty("non_terminology_allergen"))
        obj.nonTerminologyAllergen = ApiClient.convertToType(
          data["non_terminology_allergen"],
          "String"
        );
    }
    return obj;
  }
}

/**
 * Allowed values for the <code>clinicalStatus</code> property.
 * @enum {String}
 * @readonly
 */
AllergensAllergen.ClinicalStatusEnum = {
  /**
   * value: "active"
   * @const
   */
  active: "active",

  /**
   * value: "resolved"
   * @const
   */
  resolved: "resolved",
};
/**
 * indicates if an allergen is still active or resolved. defaults to active
 * @member {module:model/AllergensAllergen.ClinicalStatusEnum} clinicalStatus
 * @default 'active'
 */
AllergensAllergen.prototype.clinicalStatus = "active";

/**
 * ID of the patient whom this allergen is associated with
 * @member {String} patientId
 */
AllergensAllergen.prototype.patientId = undefined;

/**
 * Currently, only plain text allergens are accepted, we do not yet accept SNOMED or RX CUI codes
 * @member {String} nonTerminologyAllergen
 */
AllergensAllergen.prototype.nonTerminologyAllergen = undefined;
