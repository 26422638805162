export type PermissionsViews = `${PermissionsViewsName}.${PermissionsViewsPermission}`[];

export enum PermissionsViewsName {
  TenantAdmin = "tenant_admin",
  Practitioner = "practitioner",
  Patient = "patient",
  Submission = "submission",
  Questionnaire = "questionnaire",
  CarePlanTemplate = "care_plan_template",
  WorkflowLog = "workflow_log",
  GoalTemplate = "goal_template",
  Product = "product",
  ObservationType = "observation_type",
  TaskTemplate = "task_template",
  TargetTemplate = "target_template",
  Payments = "payments",
  BusinessIntelligence = "business_intelligence",
  ApiCredentials = "api_credentials",
  Integration = "integration",
  PersonalizationRuleSet = "personalization_rule_set",
  WebhookEndpoint = "webhook_endpoint",
}

export enum PermissionsViewsPermission {
  Index = "index",
  Create = "create",
}
