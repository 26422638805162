import { OPERATOR_OPTIONS, FIELD_TYPES, GLOBAL_OPERATORS } from "./Filter.types";

export const FILTER_TEMPLATE = {
  field: null,
  operator: null,
  value: null,
};

export const INIT_FILTER_STATE = [FILTER_TEMPLATE];
export const INIT_GLOBAL_OPERATOR_STATE = 0;

export const GLOBAL_OPERATOR_OPTIONS = [
  { label: "all", value: GLOBAL_OPERATORS.and },
  { label: "any", value: GLOBAL_OPERATORS.or },
];

export const OPERATOR_OPTIONS_BY_TYPE = {
  [FIELD_TYPES.boolean]: [{ label: "equals", value: OPERATOR_OPTIONS.equal }],
  [FIELD_TYPES.number]: [
    { label: "equals", value: OPERATOR_OPTIONS.equal },
    { label: "doesn't equal", value: OPERATOR_OPTIONS.notEqual },
    { label: ">", value: OPERATOR_OPTIONS.greaterThan },
    { label: ">=", value: OPERATOR_OPTIONS.greaterThanOrEqual },
    { label: "<", value: OPERATOR_OPTIONS.lesserThan },
    { label: "<=", value: OPERATOR_OPTIONS.lesserThanOrEqual },
  ],
  [FIELD_TYPES.date]: [
    { label: "before", value: OPERATOR_OPTIONS.before },
    { label: "after", value: OPERATOR_OPTIONS.after },
  ],
  [FIELD_TYPES.string]: [
    { label: "equals", value: OPERATOR_OPTIONS.equal },
    { label: "doesn't equal", value: OPERATOR_OPTIONS.notEqual },
    { label: "includes", value: OPERATOR_OPTIONS.matches },
    { label: "doesn't include", value: OPERATOR_OPTIONS.doesNotMatch },
  ],
  [FIELD_TYPES.enum]: [
    { label: "equals", value: OPERATOR_OPTIONS.equal },
    { label: "doesn't equal", value: OPERATOR_OPTIONS.notEqual },
  ],
};
