// Generated by https://quicktype.io

//TODO: see if we can use the model from "@capable-health/capable-auth-sdk"

export interface Phone {
  number?: string;
  id?: string;
  active: boolean;
  primary: string;
  label?: string;
}
export interface User {
  id: string;
  email: string;
  first_name: string;
  middle_name?: string;
  last_name: string;
  tenant_id: string;
  administrative_gender?: string;
  gender_identity: string;
  identity_id: string;
  external_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: any;
  created_at: string;
  organization_ids: string[];
  updated_at: string;
  age: string;
  addresses: Address[];
  biography: string;
  birth_date?: string;
  phones: Phone[];
  tag_list: string[];
  url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  races: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ethnicities: any[];
  avatar_url?: string;
  userType?: UserTypes; //TODO: remove this once migration is done?
  user_type?: UserTypes;
  // TODO: remove this type
  type: UserTypes;
}

export interface Address {
  id: string;
  line1: string;
  line2?: string;
  city: string;
  zip: string;
  country: string;
  active: boolean;
  state: string;
  user_id: string;
  created_at: string;
  updated_at: string;
  label: string;
  primary: boolean;
  addressable_type: string;
  addressable_id: string;
}

export enum UserTypes {
  Practitioner = "Practitioner",
  TenantAdmin = "TenantAdmin",
  Patient = "Patient",
}
