import { Controller } from "react-hook-form";
import React from "react";

import { AsyncSearchSelectProps } from "../async-search-select/AsyncSearchSelect.types";
import { DatePickerProps } from "../date-picker/DatePicker.types";
import { FormInputTypes } from "./Form.types";
import { ImageUploadProps } from "../image-upload/ImageUpload.types";
import { MultiSelectProps } from "../multi-select/MultiSelect.types";
import { RadioGroupProps } from "../radio-group/RadioGroup.types";
import { SearchableSelectProps } from "../searchable-select/SearchableSelect.types";
import { SelectProps } from "../select/Select.types";
import { SwitchProps } from "../switch/Switch.types";
import { TextFieldInputProps } from "../text-field-input/TextFieldInput.types";
import AsyncSearchSelect from "../async-search-select/AsyncSearchSelect";
import DatePicker from "../date-picker/DatePicker";
import ImageUpload from "../image-upload/ImageUpload";
import MultiSelect from "../multi-select/MultiSelect";
import RadioGroup from "../radio-group/RadioGroup";
import SearchableSelect from "../searchable-select/SearchableSelect";
import Select from "../select/Select";
import Switch from "../switch/Switch";
import TextFieldInput from "../text-field-input/TextFieldInput";

export const getFormComponent = ({
  formData,
  fieldArrayName = "",
  fieldArrayIndex = "",
  serverErrors,
  control,
  fieldDefaultValue = "",
  watchedFields,
}: any) => {
  const { name, component, props, defaultValue, element } = formData;
  const serverErrorMessage =
    serverErrors?.find((serverError) => serverError?.name === name)?.message || "";
  const fieldName = `${fieldArrayName && fieldArrayName + "." + fieldArrayIndex + "."}${name}`;

  switch (component) {
    case FormInputTypes.Field: {
      return (
        <Controller
          control={control}
          name={fieldName}
          defaultValue={fieldDefaultValue || defaultValue}
          key={fieldName}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
            return (
              <TextFieldInput
                {...(props as TextFieldInputProps)}
                value={value as string}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error || !!serverErrorMessage}
                helperText={error?.message || serverErrorMessage || props.helperText}
              />
            );
          }}
        />
      );
    }
    case FormInputTypes.Switch: {
      return (
        <Controller
          control={control}
          name={fieldName}
          defaultValue={defaultValue}
          key={fieldName}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Switch
              {...(props as SwitchProps)}
              checked={value}
              onChange={onChange}
              error={!!error || !!serverErrorMessage}
              helperText={error?.message || serverErrorMessage || props?.helperText}
            />
          )}
        />
      );
    }
    case FormInputTypes.DatePicker: {
      return (
        <Controller
          control={control}
          name={fieldName}
          defaultValue={defaultValue}
          key={fieldName}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <DatePicker
              {...(props as DatePickerProps)}
              date={value}
              onChange={onChange}
              error={!!error || !!serverErrorMessage}
              helperText={error?.message || serverErrorMessage || props?.helperText}
            />
          )}
        />
      );
    }
    case FormInputTypes.ImageUpload: {
      return (
        <Controller
          control={control}
          name={fieldName}
          defaultValue={defaultValue}
          key={fieldName}
          render={({ field: { value, onChange } }) => (
            <ImageUpload {...(props as ImageUploadProps)} value={value} onChange={onChange} />
          )}
        />
      );
    }
    case FormInputTypes.Select: {
      return (
        <Controller
          control={control}
          name={fieldName}
          defaultValue={defaultValue}
          key={fieldName}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Select
                {...(props as SelectProps)}
                value={value}
                onChange={onChange}
                error={!!error || !!serverErrorMessage}
                helperText={error?.message || serverErrorMessage || props?.helperText}
                fullWidth
              />
            );
          }}
        />
      );
    }
    case FormInputTypes.MultiSelect: {
      return (
        <Controller
          control={control}
          name={fieldName}
          defaultValue={defaultValue}
          key={fieldName}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <MultiSelect
                {...(props as MultiSelectProps)}
                values={value}
                onChange={onChange}
                error={!!error || !!serverErrorMessage}
                helperText={error?.message || serverErrorMessage || props?.helperText}
                fullWidth
              />
            );
          }}
        />
      );
    }
    case FormInputTypes.SearchableSelect: {
      return (
        <Controller
          control={control}
          name={fieldName}
          defaultValue={defaultValue}
          key={fieldName}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <SearchableSelect
                {...(props as SearchableSelectProps)}
                value={value}
                setSelectedItem={onChange}
                error={!!error || !!serverErrorMessage}
                helperText={error?.message || serverErrorMessage || props?.helperText}
                fullWidth
              />
            );
          }}
        />
      );
    }
    case FormInputTypes.SearchableMultiSelect: {
      return (
        <Controller
          control={control}
          name={fieldName}
          defaultValue={defaultValue}
          key={fieldName}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <SearchableSelect
                multiple
                {...(props as SearchableSelectProps)}
                value={value}
                setSelectedItem={onChange}
                error={!!error || !!serverErrorMessage}
                helperText={error?.message || serverErrorMessage || props?.helperText}
                fullWidth
              />
            );
          }}
        />
      );
    }
    case FormInputTypes.AsyncSearchSelect: {
      return (
        <Controller
          control={control}
          name={fieldName}
          defaultValue={defaultValue}
          key={fieldName}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <AsyncSearchSelect
                {...(props as AsyncSearchSelectProps)}
                value={value}
                onChange={onChange}
                error={!!error || !!serverErrorMessage}
                helperText={error?.message || serverErrorMessage || props?.helperText}
              />
            );
          }}
        />
      );
    }
    case FormInputTypes.RadioGroup: {
      return (
        <Controller
          control={control}
          name={fieldName}
          defaultValue={defaultValue}
          key={fieldName}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <RadioGroup
              {...(props as RadioGroupProps)}
              value={value}
              onChange={onChange}
              error={error?.message || serverErrorMessage}
            />
          )}
        />
      );
    }
    case FormInputTypes.CustomComponent: {
      return element;
    }
  }
};
