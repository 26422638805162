/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The CarePlanstemplatesCarePlanTemplate model module.
 * @module model/CarePlanstemplatesCarePlanTemplate
 * @version v0.3
 */
export class CarePlanstemplatesCarePlanTemplate {
  /**
   * Constructs a new <code>CarePlanstemplatesCarePlanTemplate</code>.
   * @alias module:model/CarePlanstemplatesCarePlanTemplate
   * @class
   * @param name {String} Name of the care plan template. This will be the default name for care plans created using this template.
   * @param description {String} General description of the care plan template. This will be the default description for care plans created from this template.
   */
  constructor(name, description) {
    this.name = name;
    this.description = description;
  }

  /**
   * Constructs a <code>CarePlanstemplatesCarePlanTemplate</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CarePlanstemplatesCarePlanTemplate} obj Optional instance to populate.
   * @return {module:model/CarePlanstemplatesCarePlanTemplate} The populated <code>CarePlanstemplatesCarePlanTemplate</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CarePlanstemplatesCarePlanTemplate();
      if (data.hasOwnProperty("task_template_ids"))
        obj.taskTemplateIds = ApiClient.convertToType(data["task_template_ids"], ["String"]);
      if (data.hasOwnProperty("goal_template_ids"))
        obj.goalTemplateIds = ApiClient.convertToType(data["goal_template_ids"], ["String"]);
      if (data.hasOwnProperty("name")) obj.name = ApiClient.convertToType(data["name"], "String");
      if (data.hasOwnProperty("description"))
        obj.description = ApiClient.convertToType(data["description"], "String");
      if (data.hasOwnProperty("external_id"))
        obj.externalId = ApiClient.convertToType(data["external_id"], "String");
      if (data.hasOwnProperty("tag_list"))
        obj.tagList = ApiClient.convertToType(data["tag_list"], ["String"]);
      if (data.hasOwnProperty("cms_entry_id"))
        obj.cmsEntryId = ApiClient.convertToType(data["cms_entry_id"], "String");
    }
    return obj;
  }
}

/**
 * @member {Array.<String>} taskTemplateIds
 */
CarePlanstemplatesCarePlanTemplate.prototype.taskTemplateIds = undefined;

/**
 * @member {Array.<String>} goalTemplateIds
 */
CarePlanstemplatesCarePlanTemplate.prototype.goalTemplateIds = undefined;

/**
 * Name of the care plan template. This will be the default name for care plans created using this template.
 * @member {String} name
 */
CarePlanstemplatesCarePlanTemplate.prototype.name = undefined;

/**
 * General description of the care plan template. This will be the default description for care plans created from this template.
 * @member {String} description
 */
CarePlanstemplatesCarePlanTemplate.prototype.description = undefined;

/**
 * External ID for this care plan template
 * @member {String} externalId
 */
CarePlanstemplatesCarePlanTemplate.prototype.externalId = undefined;

/**
 * Add an array of strings to help manage your resources
 * @member {Array.<String>} tagList
 */
CarePlanstemplatesCarePlanTemplate.prototype.tagList = undefined;

/**
 * Add a link to your CMS content
 * @member {String} cmsEntryId
 */
CarePlanstemplatesCarePlanTemplate.prototype.cmsEntryId = undefined;
