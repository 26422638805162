/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * Ethnicities service.
 * @module api/EthnicitiesApi
 * @version v0.3
 */
export class EthnicitiesApi {
  /**
    * Constructs a new EthnicitiesApi. 
    * @alias module:api/EthnicitiesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the ethnicitiesGet operation.
   * @callback moduleapi/EthnicitiesApi~ethnicitiesGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all Ethnicities
   * &lt;h3&gt;   &amp;#128275; Access policy &lt;/h3&gt; &lt;p&gt;You can access this endpoint with the following token types:&lt;/p&gt; &lt;p&gt;&amp;#9989; M2M&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;#9989; Patient&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;#9989; Practitioner&lt;/p&gt;
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.byParentCode Filter all races by parent code
   * @param {Array.<String>} opts.byLevel Filter all ethnicities by level
   * @param {Array.<String>} opts.byId Filter by id
   * @param {Number} opts.page Page number (default to <.>)
   * @param {Number} opts.size Page size (default to <.>)
   * @param {module:api/EthnicitiesApi~ethnicitiesGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  ethnicitiesGet(opts, callback) {
    opts = opts || {};
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      by_parent_code: this.apiClient.buildCollectionParam(opts["byParentCode"], "csv"),
      by_level: this.apiClient.buildCollectionParam(opts["byLevel"], "csv"),
      by_id: this.apiClient.buildCollectionParam(opts["byId"], "csv"),
      page: opts["page"],
      size: opts["size"],
    };
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/ethnicities",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
}
