import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/browser";
import { useHistory } from "react-router-dom";
import React, { useEffect, createContext, useState } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";

import { ReactChildren } from "../types/react";
import api from "../api";
import auth from "../api/auth";
import { useCurrentUser } from "features/authentication/useCurrentUser";

export const AuthContext = createContext<{
  isLdUserIdentified: boolean;
  signOut: () => Promise<void>;
}>({
  isLdUserIdentified: false,
  signOut: () => Promise.resolve(),
});

export const AuthProvider = ({ children }: ReactChildren): JSX.Element => {
  const history = useHistory();
  const ldClient = useLDClient();

  let authProperties;

  try {
    authProperties = auth.getAuthProperties();
  } catch (error) {
    if (error instanceof auth.AuthConfigurationError) {
      console.error("Missing auth configuration");
      history.push("/forgot_url");
    } else {
      console.error("Error initializing auth", error);
      throw error;
    }
  }

  const { userPoolId, userPoolWebClientId, userPoolDomain } = authProperties
    ? authProperties
    : { userPoolId: null, userPoolWebClientId: null, userPoolDomain: null };
  const [isLdUserIdentified, setIsLdUserIdentified] = useState(false);
  const [isLdTenantIdentified, setIsLdTenantIdentified] = useState(false);

  const { data: currentUser } = useCurrentUser();

  const signOut = async () => {
    return api.auth.signOut().then(() => {
      setIsLdUserIdentified(false);
      setIsLdTenantIdentified(false);
      Sentry.setUser(null);
      datadogRum.clearUser();
    });
  };

  useEffect(() => {
    (async () => {
      const tenantMigration =
        ldClient && isLdTenantIdentified
          ? !!ldClient.variation("tenant-admin-migration", false) ||
            !!ldClient.variation("tenant-test-migration", false)
          : false;

      const ssoAvailable =
        ldClient && isLdTenantIdentified ? !!ldClient.variation("sso-google", false) : false;

      await auth
        .initFromLocationOrStorage(
          userPoolId,
          userPoolWebClientId,
          userPoolDomain,
          tenantMigration,
          ssoAvailable
        )
        .catch((e) => {
          if (authProperties)
            // if missing auth properties don't throw
            throw e;
        });
    })();
    //FIXME: authProperties can't be added as it causes a lot for re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    history,
    isLdUserIdentified,
    isLdTenantIdentified,
    ldClient,
    userPoolId,
    userPoolWebClientId,
  ]);

  useEffect(() => {
    if (currentUser) {
      Sentry.setUser({
        id: currentUser.id,
        email: currentUser.email,
        email_domain: currentUser.email.substring(currentUser.email.indexOf("@") + 1),
        identity_id: currentUser.identity_id,
        tenant_id: currentUser.tenant_id,
        type: currentUser.userType ?? "",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      datadogRum.setUser({
        id: currentUser.id,
        email: currentUser.email.endsWith("@capablehealth.com") ? currentUser.email : "",
        email_domain: currentUser.email.substring(currentUser.email.indexOf("@") + 1),
        identity_id: currentUser.identity_id,
        tenant_id: currentUser.tenant_id,
        type: currentUser.userType ?? "",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      ldClient?.identify(
        {
          key: currentUser.id,
          firstName: currentUser.first_name,
          lastName: currentUser.last_name,
          email: currentUser.email,
          custom: {
            tenantId: currentUser.tenant_id,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            userType: currentUser.userType ?? "",
          },
        },
        undefined,
        () => {
          // callback for when user is successfully identified with LaunchDarkly
          setIsLdUserIdentified(true); // indicate LaunchDarkly user is identified
        }
      );
    } else {
      ldClient?.identify(
        {
          key: userPoolId,
          custom: {
            userPoolId,
            userPoolWebClientId,
          },
        },
        undefined,
        () => {
          // callback for when user is successfully identified with LaunchDarkly
          setIsLdTenantIdentified(true); // indicate LaunchDarkly tenant user is identified
        }
      );
    }
  }, [currentUser, ldClient, userPoolId, userPoolWebClientId]);

  return (
    <AuthContext.Provider value={{ isLdUserIdentified, signOut }}>{children}</AuthContext.Provider>
  );
};
