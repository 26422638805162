import { ConversationClientType } from "./components/conversations/useTwilioToken.hook";
import { Client, Conversation, Message, Paginator } from "@twilio/conversations";
import { atom } from "jotai";

export const twilioClientsAtom = atom<Record<ConversationClientType, Client | undefined>>({
  chat: undefined,
  chatOwner: undefined,
  sms: undefined,
  smsOwner: undefined,
});

export const boundConversationsAtom = atom<
  Record<ConversationClientType, Record<string, Conversation>>
>({
  chat: {},
  chatOwner: {},
  sms: {},
  smsOwner: {},
});

export const addBoundConversationAtom = atom(
  null,
  (get, set, update: { twilioConversation: Conversation; clientType: ConversationClientType }) => {
    const boundConversations = get(boundConversationsAtom);
    const { twilioConversation, clientType } = update;

    set(boundConversationsAtom, {
      ...boundConversations,
      [clientType]: {
        ...boundConversations[clientType],
        [twilioConversation.sid]: twilioConversation,
      },
    });
  }
);

export const boundMessagePaginatorsAtom = atom<Record<string, Paginator<Message>>>({});

export const setBoundMessagePaginatorAtom = atom(
  null,
  (
    get,
    set,
    update: {
      conversationSid: string;
      paginator: Paginator<Message>;
    }
  ) => {
    const boundPaginators = get(boundMessagePaginatorsAtom);
    const { conversationSid, paginator } = update;

    set(boundMessagePaginatorsAtom, {
      ...boundPaginators,
      [conversationSid]: paginator,
    });
  }
);
