import { useContext, useEffect, useState } from "react";

import { AddInfoContext } from "../contexts/info";
import { formatError } from "../utils/strings";
import { SOME_RIDICULOUS_NUMBER } from "../constants/api";
import api from "../api";
import { Eligibility } from "models/Insurance/insurance";

export const useEligibility = (
  patient
): {
  eligibilityChecks: Eligibility[];
  loading: boolean;
  error: string;
} => {
  const addInfoAlert = useContext(AddInfoContext);
  const [eligibilityChecks, setEligibilityChecks] = useState<Eligibility[]>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    (async () => {
      if (!patient) {
        setLoading(false);
        return;
      }

      const opts = {
        page: 1,
        size: SOME_RIDICULOUS_NUMBER,
        filtersOperator: "eq",
        filters: [
          JSON.stringify({
            field: "patient_id",
            operator: "eq",
            value: patient.id,
          }),
        ],
      };

      const eligibilityResponse = await api.client.Eligibility.list(opts).catch((e) => {
        addInfoAlert({ status: "error", message: formatError(e) });
        setError(e);
      });

      if (eligibilityResponse && eligibilityResponse.body) {
        setEligibilityChecks(eligibilityResponse.body);
        setLoading(false);
      }
    })();
  }, [addInfoAlert, patient]);
  return { eligibilityChecks, loading, error };
};
