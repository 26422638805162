import { alpha, styled } from "@mui/material/styles";
import { FormControlLabel, Switch as MaterialSwitch } from "@mui/material";
import React from "react";

import { colors } from "styles/colors";
import { SwitchProps } from "./Switch.types";

const StyledSwitch = styled(MaterialSwitch)(({ theme, checked, disabled }) => {
  return {
    "&.MuiSwitch-root": {
      height: "38px",
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: disabled ? colors.black : colors.switchTrack,
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: colors.switchGreen1,
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      border: `2px solid ${colors.switchThumb}`,
      height: "20px",
      width: "20px",
    },
    "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
      border: "0 !important",
      backgroundColor: disabled ? "#a7dfbd" : `${colors.switchGreen1} !important`,
      opacity: 1,
    },
    "&  .Mui-disabled .MuiSwitch-thumb": {
      backgroundColor: !checked && colors.switchThumbDisabled,
      border: !checked && "0",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      "&:hover": {
        backgroundColor: alpha(colors.switchGreen1, theme.palette.action.hoverOpacity),
      },
    },
  };
});

const Switch = ({
  name,
  label = "",
  labelPlacement = "start",
  checked,
  onChange,
  style,
  disabled = false,
  ...rest
}: SwitchProps): JSX.Element => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  return (
    <FormControlLabel
      name={name}
      label={label}
      labelPlacement={labelPlacement}
      sx={style}
      control={
        <StyledSwitch
          {...rest}
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          sx={style}
        />
      }
    />
  );
};

export default Switch;
