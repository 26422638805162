import { FormDataType, FormInputTypes, FormProps } from "./Form.types";
import { getFormComponent } from "./utils";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useRef } from "react";

import * as z from "zod";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper/Paper";
import Section from "components/patients/Section";

const Form = ({
  formData,
  onSubmit,
  setWatchedFields,
  forwardedRef,
  serverErrors,
  onError,
  defaultValues,
  resetPartial,
  containerProps,
}: FormProps): JSX.Element => {
  const firstUpdate = useRef(true);

  const { schema }: { schema: z.ZodRawShape } = formData.reduce(
    (prev: any, curr: any) => {
      if (curr.schema) {
        return {
          schema: { ...prev.schema, [curr?.name]: curr.schema },
        };
      } else if (curr.formElements) {
        const formElemSchemas = {};
        curr.formElements.forEach((formElement) => {
          formElemSchemas[formElement.name] = formElement.schema;
        });

        return {
          schema: { ...prev.schema, ...formElemSchemas },
        };
      }
      return { schema: prev.schema };
    },
    {
      schema: {},
    }
  );

  const formSchema = z.object(schema);

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { handleSubmit, control, reset } = methods;

  const watchedFields = useWatch({
    control,
  });

  useEffect(() => {
    if (firstUpdate.current) {
      if (setWatchedFields) {
        firstUpdate.current = false;
        setWatchedFields(watchedFields);
      }
      return;
    }
    setWatchedFields(watchedFields);
  }, [setWatchedFields, watchedFields]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(
          (d) => {
            onSubmit(d);
          },
          (e) => {
            console.error(e);
            onError && onError(e);
          }
        )}
        onReset={() => {
          reset(resetPartial || defaultValues, { keepDirty: false });
        }}
        ref={forwardedRef}
      >
        <Grid
          container
          spacing={{ xs: containerProps?.spacing?.xs || 2, md: containerProps?.spacing?.md || 3 }}
          columns={{
            xs: containerProps?.columns?.xs || 4,
            sm: containerProps?.columns?.sm || 8,
            md: containerProps?.columns?.md || 12,
          }}
        >
          {formData?.map((formObject: FormDataType, formObjectIndex: number) => {
            const { xs = 12, sm, md, lg } = formObject?.breakpoints || {};

            if (formObject.component === FormInputTypes.FormSection) {
              return (
                <Section title={formObject.name}>
                  <Grid
                    item
                    xs={xs}
                    sm={sm || xs}
                    md={md || sm || xs}
                    lg={lg || md || sm || xs}
                    key={formObjectIndex}
                  >
                    <Paper
                      sx={{
                        padding: (theme) => theme.spacing(4),
                        borderRadius: (theme) => theme.spacing(2),
                      }}
                    >
                      <Grid
                        container
                        spacing={{
                          xs: containerProps?.spacing?.xs || 2,
                          md: containerProps?.spacing?.md || 3,
                        }}
                        columns={{
                          xs: containerProps?.columns?.xs || 4,
                          sm: containerProps?.columns?.sm || 8,
                          md: containerProps?.columns?.md || 12,
                        }}
                      >
                        {formObject.formElements.map((sectionElem, sectionElemIndex) => {
                          const {
                            xs: sectionXs,
                            sm: sectionSm,
                            md: sectionMd,
                            lg: sectionLg,
                          } = sectionElem?.breakpoints || {};

                          return (
                            <Grid
                              item
                              xs={sectionXs}
                              sm={sectionSm || sectionXs}
                              md={sectionMd || sectionSm || sectionXs}
                              lg={sectionLg || sectionMd || sectionSm || sectionXs}
                              key={sectionElemIndex}
                            >
                              {getFormComponent({
                                formData: sectionElem,
                                control,
                                serverErrors,
                                watchedFields,
                              })}
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Paper>
                  </Grid>
                </Section>
              );
            }

            return (
              <Grid
                item
                xs={xs}
                sm={sm || xs}
                md={md || sm || xs}
                lg={lg || md || sm || xs}
                key={formObjectIndex}
              >
                {getFormComponent({ formData: formObject, control, serverErrors, watchedFields })}
              </Grid>
            );
          })}
        </Grid>
      </form>
    </FormProvider>
  );
};

export default React.memo(Form);
