import React, { useState, KeyboardEventHandler, FocusEventHandler } from "react";
import { alpha } from "@mui/material/styles";

import CreatableSelect from "react-select/creatable";
import { OnChangeValue } from "react-select";
import { TagsInputProps } from "./TagsInputDeprecated.types";
import { theme } from "styles/theme";

const colourStyles = {
  control: (styles) => ({ ...styles, height: "50px" }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: alpha(theme.palette.primary.light, 0.1),
      borderRadius: "10px",
    };
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    ":hover": {
      backgroundColor: alpha(theme.palette.primary.light, 0.4),
      borderRadius: "10px",
    },
  }),
};

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

const TagsInputDeprecated = (props: TagsInputProps): JSX.Element => {
  const { tags, onChange } = props;

  const tagsAsOptions = tags?.map((tag) => {
    return { label: tag, value: tag };
  });
  const [inputValue, setInputValue] = useState<string>();

  const handleDelete = (value: OnChangeValue<Option, true>) => {
    const newTagsArray = value.map((v) => {
      return v.value;
    });

    onChange(newTagsArray);
  };

  const handleInputChange = (inputVal: string) => {
    setInputValue(inputVal);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case ",":
      case "Enter":
      case "Tab":
        onChange([...tags, inputValue]);
        setInputValue("");
        event.preventDefault();
    }
  };

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    if (!inputValue) return;
    onChange([...tags, inputValue]);
    setInputValue("");
  };

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleDelete}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onBlur={handleOnBlur}
      placeholder="Add tags"
      value={tagsAsOptions}
      styles={colourStyles}
    />
  );
};

export default TagsInputDeprecated;
