import { HashLink } from "react-router-hash-link";
import Box from "@mui/material/Box";
import MaterialLink from "@mui/material/Link";
import React, { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BreakpointContext } from "../../contexts/breakpoint";
import { MenuLinks, PatientMenuLinks } from "pages/PatientID";

const PatientMenu = ({ menuLinks }: { menuLinks: PatientMenuLinks[] }): JSX.Element => {
  const { isMobileView } = useContext(BreakpointContext);
  const location = useLocation();
  const params = useParams<{ id?: string }>();

  const isCurrentSection = (link: MenuLinks) => {
    return location.pathname.includes(link) || location.hash === `#${link}`;
  };

  const getLinkStyles = (link: MenuLinks) => {
    return {
      marginRight: (theme) => theme.spacing(2),
      textDecoration: "none",
      height: 42,
      color: (theme) => theme.palette.text.primary,
      fontSize: isMobileView ? "14px" : "16px",
      borderBottom: (theme) =>
        isCurrentSection(link)
          ? `${theme.spacing(0.5)} solid #0000C8`
          : `${theme.spacing(0.5)} solid white`,
      fontWeight: isCurrentSection(link) ? "bold" : "",
      ":hover": (theme) => ({
        fontWeight: "bold",
        borderBottom: `${theme.spacing(0.5)} solid #0000C8`,
      }),
    };
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        paddingTop: 2,
        backgroundColor: "white",
        width: "max-content",
      }}
    >
      {menuLinks.map(({ link, text }, i) => (
        <MaterialLink
          key={i}
          to={`/patients/${params?.id}/${link}`}
          sx={getLinkStyles(link)}
          component={HashLink}
        >
          {text}
        </MaterialLink>
      ))}
    </Box>
  );
};

export default PatientMenu;
