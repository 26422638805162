import { Alert, Box, Card } from "@mui/material";
import { CapableLogoLong } from "components/CapableIcons";
import { BreakpointContext } from "contexts/breakpoint";
import React, { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { colors } from "styles/colors";
import { theme } from "styles/theme";

export const ResetPasswordConfirmation = () => {
  const { isMobileView } = useContext(BreakpointContext);
  const history = useHistory();
  useEffect(() => {
    const timeout = setTimeout(() => {
      history.push("/");
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  });
  return (
    <Box
      sx={{
        backgroundColor: colors.lightGrey1,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Card
        sx={{
          width: isMobileView ? "100%" : "500px",
          marginTop: "100px",
          padding: theme.spacing(5),
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        <Box sx={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
          <CapableLogoLong />
        </Box>
        <Alert sx={{ marginTop: 2 }} severity="success" variant="filled">
          Your password has been reset successfully. You will now be redirected to the{" "}
          <Link to="/">sign in page</Link>
        </Alert>
      </Card>
    </Box>
  );
};
