import { useHistory, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import queryString from "query-string";
import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";

import { ContainedButton } from "../../components/styled/Buttons";
import { filter } from "../../components/styled/filter/Filter.types";
import { getFilterParams, filtersSettingsComplete, filterIsReset } from "../../utils/filters";
import { Info } from "../../components/CapableIcons";
import { StyledWideTooltip } from "../../components/styled/Tooltips";
import { theme } from "../../styles/theme";
import Table from "../../components/styled/table/Table";

const ListView = ({
  createUrl,
  title,
  createOnClick,
  createButtonLabel,
  disableRowClick,
  rows,
  tableColumns,
  page,
  path,
  totalRowCount,
  tooltipBody,
  nextParams,
  prevParams,
  useCursorPagination,
  openUrlInNewTab,
  filters,
  setFilters,
  globalOperator,
  setGlobalOperator,
  filterFieldOptions,
  setFilterSettings,
  sortingSettings,
  setSortingSettings,
  goToBetaRoute,
  rowClickUrl,
  onSearchChange,
  searchValue,
}: {
  createUrl?: string;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createOnClick?: any;
  createButtonLabel?: string;
  disableRowClick?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rows: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableColumns: any;
  page: number;
  path: string;
  totalRowCount: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tooltipBody?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nextParams?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prevParams?: any;
  useCursorPagination?: boolean;
  openUrlInNewTab?: boolean;
  filters?: filter[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilters?: any;
  globalOperator?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setGlobalOperator?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterFieldOptions?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  applyFilters?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilterSettings?: any;
  sortingSettings?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSortingSettings?: any;
  goToBetaRoute?: (params) => boolean;
  rowClickUrl?: (any) => string;
  onSearchChange?: (value: string) => void;
  searchValue?: string;
}): JSX.Element => {
  const history = useHistory();
  const { search } = useLocation();

  const queryParams = useMemo(() => {
    return queryString.parse(search);
  }, [search]);

  const applyFilters = () => {
    const resetURL = `${path}?page=1`;
    if (filtersSettingsComplete(filters)) {
      // all filter queries should use page 1 as default as we don't know how many results
      // there will be
      history.push(resetURL);
      setFilterSettings(getFilterParams(filters, globalOperator, filterFieldOptions));
    }
    if (filterIsReset(filters)) {
      history.push(resetURL);
      setFilterSettings(null);
    }
  };

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Box sx={{ padding: (theme) => theme.spacing(2) }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: theme.spacing(1, 0, 2.5, 0),
          }}
        >
          <Typography variant="h1" style={{ display: "flex", alignItems: "center" }}>
            {title}
            {tooltipBody && (
              <StyledWideTooltip title={tooltipBody} placement="bottom-start" arrow>
                <Box>
                  <Info />
                </Box>
              </StyledWideTooltip>
            )}
          </Typography>
          <span>
            {(createUrl || createOnClick) && (
              <ContainedButton
                component={Link}
                href={createUrl}
                onClick={createOnClick}
                target={openUrlInNewTab ? "_blank" : "_self"}
                data-sentry-unmask
              >
                {createButtonLabel}
              </ContainedButton>
            )}
          </span>
        </Box>
        <Divider />
      </Box>
      <Box sx={{ cursor: disableRowClick ? "" : "pointer" }}>
        <Table
          rows={rows}
          columns={tableColumns}
          pageIndex={page - 1}
          pageSize={10}
          path={path}
          onRowClick={(params) => {
            if (path && params.id && !disableRowClick) {
              if (goToBetaRoute && goToBetaRoute(params)) {
                history.push(path + "/" + params.id + "/beta");
              } else {
                if (rowClickUrl) {
                  history.push(rowClickUrl(params));
                } else {
                  history.push(path + "/" + params.id + "/");
                }
              }
            }
          }}
          onPageChange={(pageIndex) => {
            if (path) {
              const newQueryParams = { ...queryParams, page: pageIndex + 1 };
              history.replace({
                pathname: path,
                search: "?" + queryString.stringify(newQueryParams),
              });
            }
          }}
          totalCount={totalRowCount}
          nextParams={nextParams}
          prevParams={prevParams}
          useCursorPagination={useCursorPagination}
          filters={filters}
          setFilters={setFilters}
          globalOperator={globalOperator}
          setGlobalOperator={setGlobalOperator}
          filterFieldOptions={filterFieldOptions}
          applyFilters={applyFilters}
          sortingSettings={sortingSettings}
          setSortingSettings={setSortingSettings}
          onSearchChange={onSearchChange}
          searchValue={searchValue}
        />
      </Box>
    </Box>
  );
};

export default ListView;
