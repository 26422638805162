import Box from "@mui/material/Box";
import React, { useState } from "react";

import { colors } from "../../styles/colors";

const ExpandableBottomNav = ({
  bottomNavHeight,
  topNavHeight,
  sidebarInfo: SidebarInfo,
  anchorHeight,
}: {
  bottomNavHeight: string;
  topNavHeight: string;
  sidebarInfo: any;
  anchorHeight: string;
}): JSX.Element => {
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: open ? `calc(100% - ${topNavHeight} - ${anchorHeight} - 20px )` : bottomNavHeight,
        borderTop: `1px solid #dcdcdc`,
        borderRadius: (theme) => theme.spacing(2, 2, 0, 0),
        zIndex: (theme) => theme.zIndex.appBar,
        backgroundColor: colors.lightGrey6,
        transition: "height 0.5s",
      }}
    >
      <SidebarInfo open={open} setOpen={setOpen} />
    </Box>
  );
};

export default ExpandableBottomNav;
