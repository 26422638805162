/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The ConversationscreateGroupChatConversation model module.
 * @module model/ConversationscreateGroupChatConversation
 * @version v0.3
 */
export class ConversationscreateGroupChatConversation {
  /**
   * Constructs a new <code>ConversationscreateGroupChatConversation</code>.
   * @alias module:model/ConversationscreateGroupChatConversation
   * @class
   * @param userIds {Array.<String>}
   */
  constructor(userIds) {
    this.userIds = userIds;
  }

  /**
   * Constructs a <code>ConversationscreateGroupChatConversation</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ConversationscreateGroupChatConversation} obj Optional instance to populate.
   * @return {module:model/ConversationscreateGroupChatConversation} The populated <code>ConversationscreateGroupChatConversation</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ConversationscreateGroupChatConversation();
      if (data.hasOwnProperty("user_ids"))
        obj.userIds = ApiClient.convertToType(data["user_ids"], ["String"]);
      if (data.hasOwnProperty("name")) obj.name = ApiClient.convertToType(data["name"], "String");
    }
    return obj;
  }
}

/**
 * @member {Array.<String>} userIds
 */
ConversationscreateGroupChatConversation.prototype.userIds = undefined;

/**
 * Conversation name
 * @member {String} name
 */
ConversationscreateGroupChatConversation.prototype.name = undefined;
