import { TextFieldInputProps } from "./TextFieldInput.types";
import TextField from "@mui/material/TextField";
import React from "react";
import PhoneInput from "react-phone-number-input/input";

const TextFieldInput = (props: TextFieldInputProps): JSX.Element => {
  const {
    size = "medium",
    type,
    variant = "outlined",
    label,
    value,
    onChange,
    onBlur,
    placeholder,
    inputProps,
    helperText,
    disabled = false,
    required = false,
    error = false,
    hidden = false,
    InputProps = {},
    ...rest
  } = props;

  return (
    <TextField
      error={error}
      id={`text-field-${label}`}
      label={label}
      value={value}
      helperText={helperText}
      disabled={disabled}
      required={required}
      inputProps={inputProps}
      onChange={type === "phone" ? onChange : (e) => onChange && onChange(e.target.value)}
      onBlur={type === "phone" ? onBlur : (e) => onBlur && onBlur(e.target.value)}
      variant={variant}
      size={size}
      placeholder={placeholder}
      sx={{
        display: hidden ? "none" : "block",
      }}
      InputProps={
        type === "phone"
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            { ...InputProps, inputComponent: PhoneNumberInputRef as any }
          : { ...InputProps }
      }
      {...rest}
      fullWidth
    />
  );
};

type PhoneProps = {
  onChange: (value: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  value: string;
  className: string;
};

// eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
const PhoneNumberInputRef = React.forwardRef<any, PhoneProps>((props, ref) => {
  const { onChange, onFocus, onBlur, value, className } = props;
  return (
    <PhoneInput
      value={value}
      onFocus={onFocus}
      onBlur={onBlur}
      className={className}
      onChange={(e) => onChange(e ?? "")}
      country="US"
      ref={ref}
    />
  );
});

export default TextFieldInput;
