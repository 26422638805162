/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { WorkflowsPersonalizationRuleSetInputs } from "./WorkflowsPersonalizationRuleSetInputs";

/**
 * The WorkflowsPersonalizationRuleSetNodes model module.
 * @module model/WorkflowsPersonalizationRuleSetNodes
 * @version v0.3
 */
export class WorkflowsPersonalizationRuleSetNodes {
  /**
   * Constructs a new <code>WorkflowsPersonalizationRuleSetNodes</code>.
   * @alias module:model/WorkflowsPersonalizationRuleSetNodes
   * @class
   * @param name {String} Name of the rule
   * @param inputs {Array.<module:model/WorkflowsPersonalizationRuleSetInputs>} Conditions for the workflow to meet in order to generate suggestions
   * @param targets {Array.<Object>} Suggestions to generate if conditions are met
   * @param id {String}
   */
  constructor(name, inputs, targets, id) {
    this.name = name;
    this.inputs = inputs;
    this.targets = targets;
    this.id = id;
  }

  /**
   * Constructs a <code>WorkflowsPersonalizationRuleSetNodes</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WorkflowsPersonalizationRuleSetNodes} obj Optional instance to populate.
   * @return {module:model/WorkflowsPersonalizationRuleSetNodes} The populated <code>WorkflowsPersonalizationRuleSetNodes</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WorkflowsPersonalizationRuleSetNodes();
      if (data.hasOwnProperty("name")) obj.name = ApiClient.convertToType(data["name"], "String");
      if (data.hasOwnProperty("logical_operator"))
        obj.logicalOperator = ApiClient.convertToType(data["logical_operator"], "String");
      if (data.hasOwnProperty("inputs"))
        obj.inputs = ApiClient.convertToType(data["inputs"], [
          WorkflowsPersonalizationRuleSetInputs,
        ]);
      if (data.hasOwnProperty("targets"))
        obj.targets = ApiClient.convertToType(data["targets"], [
          AnyOfworkflowsPersonalizationRuleSetNodesTargetsItems,
        ]);
      if (data.hasOwnProperty("parent_id"))
        obj.parentId = ApiClient.convertToType(data["parent_id"], "String");
      if (data.hasOwnProperty("id")) obj.id = ApiClient.convertToType(data["id"], "String");
    }
    return obj;
  }
}

/**
 * Name of the rule
 * @member {String} name
 */
WorkflowsPersonalizationRuleSetNodes.prototype.name = undefined;

/**
 * Allowed values for the <code>logicalOperator</code> property.
 * @enum {String}
 * @readonly
 */
WorkflowsPersonalizationRuleSetNodes.LogicalOperatorEnum = {
  /**
   * value: "and"
   * @const
   */
  and: "and",

  /**
   * value: "or"
   * @const
   */
  or: "or",
};
/**
 * Indicates whether ALL of the conditions (corresponding to logical operator 'and') or ANY of the conditions (corresponding to logical operator 'or') for the rule need to be met to generate suggestions
 * @member {module:model/WorkflowsPersonalizationRuleSetNodes.LogicalOperatorEnum} logicalOperator
 */
WorkflowsPersonalizationRuleSetNodes.prototype.logicalOperator = undefined;

/**
 * Conditions for the workflow to meet in order to generate suggestions
 * @member {Array.<module:model/WorkflowsPersonalizationRuleSetInputs>} inputs
 */
WorkflowsPersonalizationRuleSetNodes.prototype.inputs = undefined;

/**
 * Suggestions to generate if conditions are met
 * @member {Array.<Object>} targets
 */
WorkflowsPersonalizationRuleSetNodes.prototype.targets = undefined;

/**
 * @member {String} parentId
 */
WorkflowsPersonalizationRuleSetNodes.prototype.parentId = undefined;

/**
 * @member {String} id
 */
WorkflowsPersonalizationRuleSetNodes.prototype.id = undefined;
