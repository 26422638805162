import React, { createContext, ReactNode, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import isEqual from "lodash.isequal";

import { ReactChildren } from "../types/react";

export interface Info {
  status: "success" | "info" | "warning" | "error";
  message: string | ReactNode;
  timeout?: number;
  clearOnPageChange?: boolean;
  unique?: boolean;
  isToast?: boolean;
  enableAutoHide?: boolean;
}

interface InfoAlert {
  id: string;
  info: Info;
}

export const InfoContext = createContext<InfoAlert[]>([]);
export const AddInfoContext = createContext<(infoItem: Info) => string>(() => "");
export const RemoveInfoContext = createContext<(id?: string) => void>(() => "");

export const InfoProvider = ({ children }: ReactChildren): JSX.Element => {
  const location = useLocation();
  const [infoAlerts, setInfoAlerts] = useState<InfoAlert[]>([]);

  const generateID = () => uuidv4();

  const processInfo = useCallback<(info: Info) => InfoAlert>((info: Info) => {
    const id: string = generateID();
    if (info.timeout) scheduleRemoveAlert(id, info.timeout);
    return { id, info };
  }, []);

  const scheduleRemoveAlert = (id: string, timeout: number) => {
    setTimeout(() => {
      setInfoAlerts((infoAlerts) => infoAlerts.filter((infoAlert) => infoAlert.id !== id));
    }, timeout);
  };

  const addInfoAlert = useCallback(
    (infoItem: Info) => {
      const infoAlert: InfoAlert = processInfo(infoItem);
      setInfoAlerts((infoAlerts) => {
        if (infoItem.unique) {
          const infoAlertAlreadyExists = infoAlerts.some((infoAlert) =>
            isEqual(infoAlert.info, infoItem)
          );
          if (infoAlertAlreadyExists) {
            return infoAlerts;
          }
        }

        return [...infoAlerts, infoAlert];
      });
      return infoAlert.id;
    },
    [processInfo]
  );

  const removeInfoAlert = useCallback((id?: string) => {
    if (id) {
      setInfoAlerts((infoAlerts) => infoAlerts.filter((infoAlert) => infoAlert.id != id));
    } else {
      setInfoAlerts([]);
    }
  }, []);

  useEffect(() => {
    setInfoAlerts((infoAlerts) =>
      infoAlerts.filter((infoAlert) => infoAlert.info.clearOnPageChange === false)
    );
  }, [location]);

  //Remove previous alerts on mount and unmount
  useEffect(() => {
    setInfoAlerts([]);
    return () => {
      setInfoAlerts([]);
    };
  }, []);

  return (
    <AddInfoContext.Provider value={addInfoAlert}>
      <RemoveInfoContext.Provider value={removeInfoAlert}>
        <InfoContext.Provider value={infoAlerts}>{children}</InfoContext.Provider>
      </RemoveInfoContext.Provider>
    </AddInfoContext.Provider>
  );
};
