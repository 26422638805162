/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The WebhookEndpointsWebhookEndpoint model module.
 * @module model/WebhookEndpointsWebhookEndpoint
 * @version v0.3
 */
export class WebhookEndpointsWebhookEndpoint {
  /**
   * Constructs a new <code>WebhookEndpointsWebhookEndpoint</code>.
   * @alias module:model/WebhookEndpointsWebhookEndpoint
   * @class
   * @param targetUrl {String}
   */
  constructor(targetUrl) {
    this.targetUrl = targetUrl;
  }

  /**
   * Constructs a <code>WebhookEndpointsWebhookEndpoint</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebhookEndpointsWebhookEndpoint} obj Optional instance to populate.
   * @return {module:model/WebhookEndpointsWebhookEndpoint} The populated <code>WebhookEndpointsWebhookEndpoint</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebhookEndpointsWebhookEndpoint();
      if (data.hasOwnProperty("name")) obj.name = ApiClient.convertToType(data["name"], "String");
      if (data.hasOwnProperty("target_url"))
        obj.targetUrl = ApiClient.convertToType(data["target_url"], "String");
      if (data.hasOwnProperty("enabled"))
        obj.enabled = ApiClient.convertToType(data["enabled"], "Boolean");
      if (data.hasOwnProperty("subscriptions"))
        obj.subscriptions = ApiClient.convertToType(data["subscriptions"], ["String"]);
    }
    return obj;
  }
}

/**
 * @member {String} name
 */
WebhookEndpointsWebhookEndpoint.prototype.name = undefined;

/**
 * @member {String} targetUrl
 */
WebhookEndpointsWebhookEndpoint.prototype.targetUrl = undefined;

/**
 * @member {Boolean} enabled
 * @default false
 */
WebhookEndpointsWebhookEndpoint.prototype.enabled = false;

/**
 * Allowed values for the <code>subscriptions</code> property.
 * @enum {String}
 * @readonly
 */
WebhookEndpointsWebhookEndpoint.SubscriptionsEnum = {
  /**
   * value: "*"
   * @const
   */
  STAR: "*",
};
/**
 * @member {Array.<module:model/WebhookEndpointsWebhookEndpoint.SubscriptionsEnum>} subscriptions
 */
WebhookEndpointsWebhookEndpoint.prototype.subscriptions = undefined;
