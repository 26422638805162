//TODO: fix the type when removing callback layout pattern
// https://app.shortcut.com/capable/story/6016/update-any-other-routes-that-use-patientlayout-to-use-the-new-pattern
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useHistory, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { AddInfoContext, RemoveInfoContext } from "contexts/info";
import { ContainedButton, OutlinedButton } from "components/styled/Buttons";
import { formatError } from "utils/strings";
import { difference } from "lodash";
import api from "api/";
import ContactFormSection from "components/patients/profile/ContactForm";
import DetailsFormSection from "components/patients/profile//DetailsForm";
import Modal from "components/styled/modal/Modal";
import PatientCareTeam from "components/patients/PatientCareTeam";
import PatientInsurance from "components/patients/profile/insurance/PatientInsurance";
import Box from "@mui/material/Box";
import isEqual from "lodash.isequal";
import { PatientMenuLinks } from "pages/PatientID";
import NPIFormSection from "components/patients/profile/NPIForm";
import PatientAllergens from "components/patients/PatientAllergens";
import { useAddAllergy, useAllergens } from "../../features/allergens/useAllergens.hook";
import { useCurrentUser } from "features/authentication/useCurrentUser";
import { useFlags } from "launchdarkly-react-client-sdk";
import { PatientRelationships } from "components/patients/PatientRelationships";

export const ADDRESS_FIELDS = ["line1", "line2", "city", "state", "zip"];

const USER_TYPE_CLIENT_MAP = {
  practitioner: api.client.Practitioner,
  patient: api.client.Patient,
};

const EditUser = ({
  setMenuLinks,
  setActionButtons,
  setTitle,
  setOnBackButtonClick,
  userType,
}: {
  setMenuLinks: (menuLinks: PatientMenuLinks[]) => void;
  setActionButtons: any;
  setTitle: any;
  setOnBackButtonClick?: any;
  userType: "patient" | "practitioner";
}): JSX.Element => {
  const params = useParams<{ id: string }>();
  const { id: patientId } = params;
  const { allowPatientsToBeRelatedPerson } = useFlags();
  const history = useHistory();
  const { allowCreateBilling } = useFlags();
  const addInfoAlert = useContext(AddInfoContext);
  const removeInfoAlert = useContext(RemoveInfoContext);
  const { data: currentUser } = useCurrentUser();
  const { data: allergensData, isLoading } = useAllergens(patientId);
  const [allergens, setAllergens] = useState<string[]>([]);
  const [originalAllergens, setOriginalAllergens] = useState<string[]>([]);
  const [originalUser, setOriginalUser] = useState<any>({});
  const [user, setUser] = useState<any>({});
  const [address, setAddress] = useState<any>({});
  const [renderingNpi, setRenderingNpi] = useState<any>({});
  const [touchedFields, setTouchedFields] = useState<any>({});
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [phoneFields, setPhoneFields] = useState<any>([]);
  const { mutate: createNewAllergy } = useAddAllergy(patientId);

  useEffect(() => {
    if (!isLoading && allergensData?.length) {
      setAllergens(allergensData.map((allergy) => allergy.non_terminology_allergen));
      setOriginalAllergens(allergensData?.map((allergy) => allergy.non_terminology_allergen) ?? []);
    }
  }, [allergensData, isLoading]);

  const getReqBody = () => {
    const reqBody = {};
    const currentAddress = originalUser.addresses[0] ?? {};
    const addressBody = currentAddress?.id
      ? { id: currentAddress.id, primary: true }
      : { country: "USA", primary: true };

    for (const key in touchedFields) {
      if (ADDRESS_FIELDS.includes(key)) {
        if (touchedFields[key] !== currentAddress[key]) {
          addressBody[key] = touchedFields[key];
          reqBody["addresses_attributes"] = [addressBody];
        }
      } else if (key === "phones_attributes") {
        reqBody["phones_attributes"] = user.phones.map((phone) => {
          if (phone.id) {
            return {
              number: phone.number,
              label: phone.label?.value ? phone.label.value : phone.label,
              active: true,
              primary: phone.primary,
              id: phone.id,
            };
          } else {
            return {
              number: phone.number,
              label: phone.label?.value ? phone.label.value : phone.label,
              active: true,
              primary: phone.primary,
            };
          }
        });
      } else {
        if (touchedFields[key] !== originalUser[key]) {
          reqBody[key] = touchedFields[key];
        }
      }
    }
    if (userType === "practitioner") {
      const addressBody = renderingNpi?.address_id
        ? {
            id: renderingNpi.address_id,
            line1: renderingNpi.line1,
            line2: renderingNpi.line2,
            city: renderingNpi.city,
            state: renderingNpi.state,
            zip: renderingNpi.zip,
            country: renderingNpi.country,
          }
        : {
            line1: renderingNpi.line1,
            line2: renderingNpi.line2,
            city: renderingNpi.city,
            state: renderingNpi.state,
            zip: renderingNpi.zip,
            country: "USA",
          };
      const { country, ...npiFields } = renderingNpi;
      if (Object.values(npiFields).some((value) => value)) {
        if (renderingNpi.id) {
          reqBody["rendering_npi_attributes"] = {
            id: renderingNpi.id,
            number: renderingNpi.number,
            addresses_attributes: [addressBody],
          };
        } else {
          reqBody["rendering_npi_attributes"] = {
            number: renderingNpi.number,
            addresses_attributes: [addressBody],
          };
        }
      }
    }
    return reqBody;
  };

  const isolateAddressObject = (primaryAddress) => {
    if (primaryAddress) {
      const address = {
        line1: primaryAddress.line1,
        line2: primaryAddress.line2,
        city: primaryAddress.city,
        state: primaryAddress.state,
        zip: primaryAddress.zip,
        country: primaryAddress.country,
        id: primaryAddress.id,
      };
      setAddress(address);
      return;
    }
    const address = {
      line1: "",
      line2: "",
      city: "",
      state: "",
      zip: "",
      country: "USA",
    };
    setAddress(address);
    return;
  };

  useEffect(() => {
    (async () => {
      setMenuLinks([]);
      const response = await USER_TYPE_CLIENT_MAP[userType].get(patientId).catch((e) => {
        addInfoAlert({ status: "error", message: formatError(e) });
      });
      if (response && response.body) {
        isolateAddressObject(response.body.addresses[0]);
        setOriginalUser(response.body);
        if (userType === "practitioner") {
          setRenderingNpi(modifyNPI(response.body.rendering_npi));
        }
        setPhoneFields(
          response.body.phones?.length
            ? response.body.phones
            : [{ number: null, label: null, active: null }]
        );
        setUser(response.body);
      }
    })();
  }, [addInfoAlert, patientId, setMenuLinks, userType]);

  useEffect(() => {
    setTitle(`${user.first_name ? user.first_name + " " : ""}${user.last_name ?? ""}`);
  }, [user?.first_name, user?.last_name, setTitle]);

  useEffect(() => {
    if (user) {
      const wasEdited = !isEqual(originalUser, user) || !isEqual(originalAllergens, allergens);
      const CancelButton = (
        <OutlinedButton
          onClick={() => {
            if (wasEdited) {
              setShowSaveModal(true);
            } else {
              history.goBack();
            }
            removeInfoAlert();
          }}
        >
          Cancel
        </OutlinedButton>
      );

      const UpdateButton = (
        <ContainedButton
          onClick={async () => {
            const response = await USER_TYPE_CLIENT_MAP[userType]
              .update(user.id, {
                body: {
                  [userType]: getReqBody(),
                },
              })
              .catch((e) => {
                addInfoAlert({ status: "error", message: formatError(e), isToast: true });
              });
            const newAllergens = difference(allergens, originalAllergens);
            if (response?.body) {
              if (newAllergens.length) {
                await Promise.all(
                  newAllergens.map((allergy) =>
                    createNewAllergy({ allergyName: allergy, patientId: patientId })
                  )
                );
              }
              removeInfoAlert();
              setOriginalUser(response.body);
              if (userType === "practitioner") {
                setRenderingNpi(modifyNPI(response.body.rendering_npi));
              }
              setTouchedFields({});
              setUser(response.body);
              addInfoAlert({
                status: "success",
                message: `${
                  userType.charAt(0).toUpperCase() + userType.slice(1)
                } successfully updated!`,
                timeout: 1000,
                isToast: true,
              });
            }
          }}
        >
          Update
        </ContainedButton>
      );

      setActionButtons([CancelButton, UpdateButton]);

      if (setOnBackButtonClick) {
        setOnBackButtonClick(() => () => {
          if (wasEdited) {
            setShowSaveModal(true);
          } else {
            history.goBack();
          }
        });
      }
    }

    return () => {
      setOnBackButtonClick(() => () => history.goBack());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addInfoAlert,
    currentUser?.id,
    history,
    user,
    address,
    originalUser,
    renderingNpi,
    removeInfoAlert,
    setActionButtons,
    setOnBackButtonClick,
    userType,
    allergens,
    originalAllergens,
  ]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          margin: (theme) => theme.spacing(2.5, 0),
        }}
      >
        <Modal
          onClose={() => setShowSaveModal(false)}
          open={showSaveModal}
          title={"Unsaved Changes"}
          primaryButtonText={"Yes, discard changes"}
          onPrimaryButtonClick={() => {
            setShowSaveModal(false);
            history.goBack();
          }}
        >
          <Box sx={{ margin: (theme) => theme.spacing(2) }}>
            Are you sure you want to discard your changes?
          </Box>
        </Modal>
        {Object.keys(user).length > 0 && (
          <Box
            component="form"
            autoComplete="off"
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <DetailsFormSection
              details={user}
              setDetails={setUser}
              userType={userType}
              setTouchedFields={setTouchedFields}
            />
            <ContactFormSection
              details={user}
              address={address}
              phoneFields={phoneFields}
              setPhoneFields={setPhoneFields}
              setDetails={setUser}
              setAddress={setAddress}
              touchedAddressFields={Object.keys(touchedFields).some((field) =>
                ADDRESS_FIELDS.includes(field)
              )}
              setTouchedFields={setTouchedFields}
            />
            {userType === "practitioner" && (
              <NPIFormSection npi={renderingNpi} setNPI={setRenderingNpi} />
            )}
            {userType === "patient" && (
              <PatientAllergens
                allergens={allergens}
                setAllergens={setAllergens}
                originalAllergens={originalAllergens}
              />
            )}
            {userType === "patient" && allowPatientsToBeRelatedPerson && (
              <PatientRelationships patient={user} />
            )}
            {userType === "patient" && <PatientCareTeam patient={user} />}
            {!allowCreateBilling && userType === "patient" && <PatientInsurance patient={user} />}
          </Box>
        )}
      </Box>
    </>
  );
};

export default EditUser;

const modifyNPI = (npi) => {
  return {
    id: npi.id,
    number: npi.number || "",
    line1: npi.addresses[0]?.line1 || "",
    line2: npi.addresses[0]?.line2 || "",
    city: npi.addresses[0]?.city || "",
    state: npi.addresses[0]?.state || "",
    zip: npi.addresses[0]?.zip || "",
    country: npi.addresses[0]?.country || "USA",
    address_id: npi.addresses[0]?.id,
  };
};
