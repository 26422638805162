/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { GoalsGoalTargetsAttributes } from "./GoalsGoalTargetsAttributes";

/**
 * The GoalsGoal model module.
 * @module model/GoalsGoal
 * @version v0.3
 */
export class GoalsGoal {
  /**
   * Constructs a new <code>GoalsGoal</code>.
   * @alias module:model/GoalsGoal
   * @class
   * @param goalTemplateId {String} ID of the goal template being used to create the goal
   * @param carePlanId {String} ID of the care plan to associate the goal with
   */
  constructor(goalTemplateId, carePlanId) {
    this.goalTemplateId = goalTemplateId;
    this.carePlanId = carePlanId;
  }

  /**
   * Constructs a <code>GoalsGoal</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GoalsGoal} obj Optional instance to populate.
   * @return {module:model/GoalsGoal} The populated <code>GoalsGoal</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GoalsGoal();
      if (data.hasOwnProperty("goal_template_id"))
        obj.goalTemplateId = ApiClient.convertToType(data["goal_template_id"], "String");
      if (data.hasOwnProperty("care_plan_id"))
        obj.carePlanId = ApiClient.convertToType(data["care_plan_id"], "String");
      if (data.hasOwnProperty("targets_attributes"))
        obj.targetsAttributes = ApiClient.convertToType(data["targets_attributes"], [
          GoalsGoalTargetsAttributes,
        ]);
      if (data.hasOwnProperty("name")) obj.name = ApiClient.convertToType(data["name"], "String");
      if (data.hasOwnProperty("description"))
        obj.description = ApiClient.convertToType(data["description"], "String");
      if (data.hasOwnProperty("start_on"))
        obj.startOn = ApiClient.convertToType(data["start_on"], "Date");
      if (data.hasOwnProperty("due_on"))
        obj.dueOn = ApiClient.convertToType(data["due_on"], "Date");
      if (data.hasOwnProperty("cron_expression"))
        obj.cronExpression = ApiClient.convertToType(data["cron_expression"], "String");
      if (data.hasOwnProperty("tag_list"))
        obj.tagList = ApiClient.convertToType(data["tag_list"], ["String"]);
      if (data.hasOwnProperty("cms_entry_id"))
        obj.cmsEntryId = ApiClient.convertToType(data["cms_entry_id"], "String");
    }
    return obj;
  }
}

/**
 * ID of the goal template being used to create the goal
 * @member {String} goalTemplateId
 */
GoalsGoal.prototype.goalTemplateId = undefined;

/**
 * ID of the care plan to associate the goal with
 * @member {String} carePlanId
 */
GoalsGoal.prototype.carePlanId = undefined;

/**
 * @member {Array.<module:model/GoalsGoalTargetsAttributes>} targetsAttributes
 */
GoalsGoal.prototype.targetsAttributes = undefined;

/**
 * Name of the goal
 * @member {String} name
 */
GoalsGoal.prototype.name = undefined;

/**
 * Description of the goal
 * @member {String} description
 */
GoalsGoal.prototype.description = undefined;

/**
 * Start date of the goal
 * @member {Date} startOn
 */
GoalsGoal.prototype.startOn = undefined;

/**
 * Date by which the goal should be reached
 * @member {Date} dueOn
 */
GoalsGoal.prototype.dueOn = undefined;

/**
 * Cron expression defining the goal's frequency of recurrence
 * @member {String} cronExpression
 */
GoalsGoal.prototype.cronExpression = undefined;

/**
 * Add an array of strings to help manage your resources
 * @member {Array.<String>} tagList
 */
GoalsGoal.prototype.tagList = undefined;

/**
 * Add a link to your CMS content
 * @member {String} cmsEntryId
 */
GoalsGoal.prototype.cmsEntryId = undefined;
