import Box from "@mui/material/Box";
import React, { useState, useEffect, useContext } from "react";
import ReactJson from "react-json-view";

import { AddInfoContext } from "contexts/info";
import { Eligibility, EligibilityResponse, Insurance } from "models/Insurance/insurance";
import { formatError } from "utils/strings";
import { renderLocaleDateString } from "components/grid/gridCellRenderers";
import { ViewLogsIcon } from "components/CapableIcons";
import api from "api";
import IconButton from "@mui/material/IconButton/IconButton";
import InlinePaginatedTable from "components/styled/InlinePaginatedTable";
import Modal from "components/styled/modal/Modal";
import StatusBadge from "components/styled/status-badge/StatusBadge";
import { eligibilityStatuses } from "constants/statuses";
import { Patient } from "models/Patient/Patient.types";

const EligibilityTable = ({
  patient,
  insurance,
}: {
  patient: Patient;
  insurance: Insurance;
}): JSX.Element => {
  const addInfoAlert = useContext(AddInfoContext);

  const [eligibilityResponse, setEligibilityResponse] = useState<null | EligibilityResponse>(null);

  const [eligibilityPage, setEligibilityPage] = useState(1);
  const [eligibilityRows, setEligibilityRows] = useState<Eligibility[]>([]);

  const eligibilityColumns = [
    {
      field: "updated_at",
      headerName: "Updated",
      width: 100,
      flex: 1,
      renderCell: renderLocaleDateString,
      sortable: false,
    },
    {
      field: "insurance_policy_id",
      headerName: "Insurance",
      width: 200,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          const currentPolicyId = params?.row?.insurance_policy_id;
          const policy = insurance.policies.find((policy) => policy.id === currentPolicyId);
          if (policy) {
            return <span>{policy.plan_name}</span>;
          }
        }
        return null;
      },
    },
    {
      field: "patient_number",
      headerName: "Member Id",
      width: 150,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          const currentPolicyId = params?.row?.insurance_policy_id;
          const policy = insurance.policies.find((policy) => policy.id === currentPolicyId);
          if (policy) {
            return <span>{policy.patient_number}</span>;
          }
        }
        return null;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return <StatusBadge status={eligibilityStatuses[params.row.status]} />;
        }
        return null;
      },
    },
    {
      field: "effective_start_date",
      headerName: "Start",
      width: 150,
      renderCell: renderLocaleDateString,
      sortable: false,
    },
    {
      field: "effective_end_date",
      headerName: "End",
      width: 150,
      renderCell: renderLocaleDateString,
      sortable: false,
    },
    {
      field: "eligibility_response",
      headerName: "",
      width: 100,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <IconButton
              onClick={async (e) => {
                e.stopPropagation();
                setEligibilityResponse(params.row.eligibility_response);
              }}
            >
              <ViewLogsIcon />
            </IconButton>
          );
        }
        return null;
      },
    },
  ];

  useEffect(() => {
    (async () => {
      const eligibilityResponse = await api.client.Eligibility.list({
        page: eligibilityPage,
        size: 10,
        filters: [
          JSON.stringify({
            field: "patient_id",
            operator: "eq",
            value: patient.id,
          }),
        ],
        sortBy: ["-updated_at"],
      }).catch((e) => {
        addInfoAlert({ status: "error", message: formatError(e) });
      });

      if (eligibilityResponse?.body) {
        setEligibilityRows(eligibilityResponse.body);
      }
    })();
  }, [addInfoAlert, eligibilityPage, patient.id, insurance]);

  return (
    <Box sx={{ width: "100%", marginLeft: (theme) => theme.spacing(2) }}>
      <InlinePaginatedTable
        rows={eligibilityRows}
        columns={eligibilityColumns}
        onPageChange={(pageIndex) => setEligibilityPage(pageIndex + 1)}
        rowCount={insurance.eligibilityChecks.length}
      />
      <Modal
        title="Eligibility response"
        open={!!eligibilityResponse}
        onClose={() => setEligibilityResponse(null)}
      >
        <ReactJson
          displayDataTypes={false}
          displayObjectSize={false}
          enableClipboard={false}
          src={eligibilityResponse ?? {}}
        />
      </Modal>
    </Box>
  );
};

export default EligibilityTable;
