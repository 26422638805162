import { Paper, Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React, { useState } from "react";

import { phoneOptions } from "../../forms/constants/details";
import SearchableSelect from "../../styled/searchable-select/SearchableSelect";
import Section from "../Section";
import TextFieldInput from "../../styled/text-field-input/TextFieldInput";

const ContactFormSection = ({
  details,
  address,
  touchedAddressFields,
  setDetails,
  setAddress,
  phoneFields,
  setPhoneFields,
  setTouchedFields,
}: {
  details: any;
  address;
  phoneFields: any;
  setPhoneFields: any;
  touchedAddressFields: boolean;
  setDetails: any;
  setAddress: any;
  setTouchedFields: any;
}): JSX.Element => {
  const [touchedPhoneField, setTouchedPhoneField] = useState<boolean>(false);

  const handleOnChange = (field: string, value: string) => {
    setAddress((address) => ({ ...address, [field]: value }));
    setTouchedFields((touchedFields) => ({ ...touchedFields, [field]: value }));
  };

  return (
    <Section title="Contact" style={{ width: "100%" }}>
      <Paper
        sx={{
          padding: (theme) => theme.spacing(4),
          borderRadius: (theme) => theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              textAlign: "left",
              margin: (theme) => theme.spacing(1.5),
              gap: (theme) => theme.spacing(3),
            }}
          >
            <TextFieldInput label="Email" value={details.email} disabled onChange={() => {}} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              textAlign: "left",
              margin: (theme) => theme.spacing(1.5),
              gap: (theme) => theme.spacing(3),
            }}
          >
            <FormControl sx={{ width: "100%" }}>
              <TextFieldInput
                label="Street address"
                value={address?.line1}
                error={touchedAddressFields && address.line1 === ""}
                helperText={
                  touchedAddressFields && address.line1 === ""
                    ? "Street address is required"
                    : undefined
                }
                onChange={(value) => {
                  handleOnChange("line1", value);
                }}
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <TextFieldInput
                label="Apt, Building, Floor (Optional)"
                value={address.line2}
                onChange={(value) => {
                  handleOnChange("line2", value);
                }}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              textAlign: "left",
              margin: (theme) => theme.spacing(1.5),
              gap: (theme) => theme.spacing(3),
            }}
          >
            <FormControl sx={{ width: "100%" }}>
              <TextFieldInput
                label="City"
                error={touchedAddressFields && address.city === ""}
                helperText={
                  touchedAddressFields && address.city === "" ? "City is required" : undefined
                }
                value={address.city}
                onChange={(value) => {
                  handleOnChange("city", value);
                }}
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <TextFieldInput
                label="State"
                error={touchedAddressFields && address.state === ""}
                helperText={
                  touchedAddressFields && address.state === "" ? "State is required" : undefined
                }
                value={address.state}
                onChange={(value) => {
                  handleOnChange("state", value);
                }}
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <TextFieldInput
                label="Zip Code"
                error={touchedAddressFields && address.zip === ""}
                helperText={
                  touchedAddressFields && address.zip === "" ? "Zip code is required" : undefined
                }
                value={address.zip}
                onChange={(value) => {
                  handleOnChange("zip", value);
                }}
              />
            </FormControl>
          </Box>
          <PhoneRow
            key={0}
            rowIndex={0}
            numbers={details.phones}
            phoneFields={phoneFields}
            setNumbers={(value) => {
              setDetails((details) => ({ ...details, phones: value }));
              setTouchedFields((touchedFields) => ({
                ...touchedFields,
                phones_attributes: value.map((phone) => ({ ...phone, active: true })),
              }));
              setTouchedPhoneField(true);
            }}
            touchedPhoneField={touchedPhoneField}
            setPhoneFields={(value) => {
              setPhoneFields(value);
            }}
          />
        </Box>
      </Paper>
    </Section>
  );
};

export default ContactFormSection;

const PhoneRow = ({
  rowIndex,
  numbers,
  phoneFields,
  setNumbers,
  setPhoneFields,
  touchedPhoneField,
}: {
  rowIndex: number;
  numbers: any;
  setNumbers: any;
  phoneFields: any;
  setPhoneFields: any;
  touchedPhoneField: boolean;
}) => {
  const currentNumberSettings = phoneFields[rowIndex];
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          textAlign: "left",
          margin: (theme) => theme.spacing(1.5),
          gap: (theme) => theme.spacing(3),
        }}
      >
        <FormControl sx={{ width: "100%" }}>
          <TextFieldInput
            label="Primary phone"
            value={currentNumberSettings?.number}
            onChange={(value) => {
              const numbersCopy = [...phoneFields];
              numbersCopy[rowIndex] = {
                ...currentNumberSettings,
                number: value,
                primary: true,
              };
              setPhoneFields(numbersCopy);
              setNumbers(numbersCopy);
            }}
            type="phone"
            error={touchedPhoneField && !numbers?.[rowIndex]?.number}
            helperText={
              touchedPhoneField && !numbers?.[rowIndex]?.number ? "Phone number is required" : ""
            }
          />
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <SearchableSelect
            label="Label"
            setSelectedItem={(value) => {
              const numbersCopy = [...phoneFields];
              numbersCopy[rowIndex] = {
                ...currentNumberSettings,
                label: value?.value,
              };
              setPhoneFields(numbersCopy);
              setNumbers(numbersCopy);
            }}
            value={currentNumberSettings?.label || null}
            options={phoneOptions}
            error={touchedPhoneField && !currentNumberSettings?.label}
            helperText={
              touchedPhoneField && !currentNumberSettings?.label ? "Phone label is required" : ""
            }
          />
        </FormControl>
      </Box>
    </>
  );
};
