/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The InsuranceClaimsInsuranceClaimAddressAttributes model module.
 * @module model/InsuranceClaimsInsuranceClaimAddressAttributes
 * @version v0.3
 */
export class InsuranceClaimsInsuranceClaimAddressAttributes {
  /**
   * Constructs a new <code>InsuranceClaimsInsuranceClaimAddressAttributes</code>.
   * @alias module:model/InsuranceClaimsInsuranceClaimAddressAttributes
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>InsuranceClaimsInsuranceClaimAddressAttributes</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InsuranceClaimsInsuranceClaimAddressAttributes} obj Optional instance to populate.
   * @return {module:model/InsuranceClaimsInsuranceClaimAddressAttributes} The populated <code>InsuranceClaimsInsuranceClaimAddressAttributes</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InsuranceClaimsInsuranceClaimAddressAttributes();
      if (data.hasOwnProperty("id")) obj.id = ApiClient.convertToType(data["id"], "String");
      if (data.hasOwnProperty("line1"))
        obj.line1 = ApiClient.convertToType(data["line1"], "String");
      if (data.hasOwnProperty("line2"))
        obj.line2 = ApiClient.convertToType(data["line2"], "String");
      if (data.hasOwnProperty("city")) obj.city = ApiClient.convertToType(data["city"], "String");
      if (data.hasOwnProperty("state"))
        obj.state = ApiClient.convertToType(data["state"], "String");
      if (data.hasOwnProperty("country"))
        obj.country = ApiClient.convertToType(data["country"], "String");
      if (data.hasOwnProperty("zip")) obj.zip = ApiClient.convertToType(data["zip"], "String");
    }
    return obj;
  }
}

/**
 * Provide an address ID only when updating an existing address
 * @member {String} id
 */
InsuranceClaimsInsuranceClaimAddressAttributes.prototype.id = undefined;

/**
 * @member {String} line1
 */
InsuranceClaimsInsuranceClaimAddressAttributes.prototype.line1 = undefined;

/**
 * @member {String} line2
 */
InsuranceClaimsInsuranceClaimAddressAttributes.prototype.line2 = undefined;

/**
 * @member {String} city
 */
InsuranceClaimsInsuranceClaimAddressAttributes.prototype.city = undefined;

/**
 * @member {String} state
 */
InsuranceClaimsInsuranceClaimAddressAttributes.prototype.state = undefined;

/**
 * @member {String} country
 */
InsuranceClaimsInsuranceClaimAddressAttributes.prototype.country = undefined;

/**
 * @member {String} zip
 */
InsuranceClaimsInsuranceClaimAddressAttributes.prototype.zip = undefined;
