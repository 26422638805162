import { Box } from "@mui/system";
import React from "react";
import Typography from "@mui/material/Typography";

import { theme } from "../styles/theme";

const UnauthenticatedFallback = (): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h1" alignSelf="center" sx={{ marginTop: theme.spacing(5) }}>
        Sorry, something went wrong.
      </Typography>
    </Box>
  );
};

export default UnauthenticatedFallback;
