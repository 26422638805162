import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../index";
import { ReactQueryKeys } from "constants/keys";
import api from "api/";
import { ObservationTypeGroup } from "../models/ObservationTypeGroup";

const updateObservationTypeGroup = async ({
  id,
  name,
  observation_type_ids,
}: {
  id: string;
  name: string;
  observation_type_ids: string[];
}) => {
  const response = await api.client.ObservationTypeGroup.update(id, {
    body: {
      observation_type_group: {
        name: name,
        observation_type_ids: observation_type_ids,
      },
    },
  });

  if (!response.ok) {
    const error = `Error updating Observation Type Group ${response.error}`;
    console.error(error);
    throw new Error(error);
  }

  return response.body as ObservationTypeGroup;
};

export const useUpdateObservationTypeGroup = () => {
  return useMutation(updateObservationTypeGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.OBSERVATION_TYPE_GROUPS]);
    },
  });
};
