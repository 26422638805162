import { Patient } from "models/Patient/Patient.types";
import { User } from "models/User/User.types";
import { isPatient } from "utils/patients";

export const getInitials = (firstName: string | null, lastName: string | null): string => {
  let initials = "";
  if (firstName) {
    initials += firstName[0].toUpperCase();
  }
  if (lastName) {
    initials += lastName[0].toUpperCase();
  }

  return initials;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatError = (e: any): string => {
  let parsedError = "";
  if (typeof e === "object" && (e.response?.body?.errors || e.body?.errors || e.errors)) {
    const errors = e.response?.body?.errors ?? e.body?.errors ?? e.errors;
    parsedError = errors.map((e) => [e.title, e.message].join(": ")).join("\n");
  } else if (typeof e === "object" && e.response?.body) {
    parsedError = JSON.stringify(e.response.body);
  } else if (typeof e === "object" && e.message) {
    parsedError = e.message;
  } else if (typeof e === "string") {
    parsedError = e;
  } else {
    try {
      parsedError = JSON.stringify(e);
    } catch {
      parsedError = "Sorry, an unexpected error occurred";
    }
  }
  return parsedError;
};

export const displayName = (user: User | Patient): string => {
  let identity: string;
  if (user?.first_name) {
    identity = user.last_name ? `${user.first_name} ${user.last_name}` : user.first_name;
  } else if (user?.email) {
    identity = user.email;
  } else if (isPatient(user)) {
    identity = user.phone_number?.number;
  } else {
    identity = "Unknown";
  }
  return identity;
};

//TODO: fix this type (currently adding relationship_type to Patient which is not correct)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const displayNameWithRelationship = (patient: any): string => {
  let identity;
  if (patient?.first_name) {
    identity = patient.last_name
      ? `${patient.first_name} ${patient.last_name}`
      : patient.first_name;
  } else if (patient?.email) {
    identity = patient.email;
  } else if (patient?.phone_number?.number) {
    identity = patient.phone_number.number;
  } else {
    identity = "Unknown";
  }
  return `${identity}${patient?.relationship_type ? ` (${patient.relationship_type})` : ""}`;
};

export const displayNameWithLabel = (user: User, label?: string): string => {
  return `${displayName(user)}${label ? ` (${label})` : ""}`;
};

export const conversationParticipantsLabel = (participants: User[] | undefined) => {
  const label = participants?.reduce((label, p) => {
    label.length
      ? (label += `, ${displayNameWithRelationship(p)}`)
      : (label += `${displayNameWithRelationship(p)}`);
    return label;
  }, "");
  return label ?? "";
};

export const capitalize = (s: string) => {
  return s
    .toLowerCase()
    .trim()
    .split(" ")
    .map((w) => w[0].toUpperCase() + w.substring(1))
    .join(" ");
};

export const capitalizeFirstOnly = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const KILOBYTE = 1024;
const MEGABYTE = KILOBYTE * 1024;
const GIGABYTE = MEGABYTE * 1024;

export const calculateByteString = (bytes: number) => {
  if (bytes >= GIGABYTE) {
    return `${(bytes / GIGABYTE).toFixed(2)} GB`;
  } else if (bytes >= MEGABYTE) {
    return `${(bytes / MEGABYTE).toFixed(2)} MB`;
  } else if (bytes >= KILOBYTE) {
    return `${(bytes / KILOBYTE).toFixed(2)} KB`;
  } else {
    return `${bytes} bytes`;
  }
};
