/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * Availability service.
 * @module api/AvailabilityApi
 * @version v0.3
 */
export class AvailabilityApi {
  /**
    * Constructs a new AvailabilityApi. 
    * @alias module:api/AvailabilityApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the availabilityGet operation.
   * @callback moduleapi/AvailabilityApi~availabilityGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Retrieve all available appointments
   * &lt;h3&gt;   &amp;#128275; Access policy &lt;/h3&gt; &lt;p&gt;You can access this endpoint with the following token types:&lt;/p&gt; &lt;p&gt;&amp;#9989; M2M&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;#9989; Patient&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;#9989; Practitioner&lt;/p&gt;
   * @param {String} fromDate The start date and time you want to filter by (inclusive). Format: YYYY-MM-DDTHH:MM:SSZ
   * @param {String} appointmentTypeId
   * @param {Object} opts Optional parameters
   * @param {String} opts.toDate The last date and time you want to filter by (inclusive). Maximum seven days from the from_date. Format: YYYY-MM-DDTHH:MM:SSZ
   * @param {String} opts.calendarId
   * @param {Number} opts.page Page number (default to <.>)
   * @param {Number} opts.size Page size (default to <.>)
   * @param {module:api/AvailabilityApi~availabilityGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  availabilityGet(fromDate, appointmentTypeId, opts, callback) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'fromDate' is set
    if (fromDate === undefined || fromDate === null) {
      throw new Error("Missing the required parameter 'fromDate' when calling availabilityGet");
    }
    // verify the required parameter 'appointmentTypeId' is set
    if (appointmentTypeId === undefined || appointmentTypeId === null) {
      throw new Error(
        "Missing the required parameter 'appointmentTypeId' when calling availabilityGet"
      );
    }

    let pathParams = {};
    let queryParams = {
      from_date: fromDate,
      to_date: opts["toDate"],
      appointment_type_id: appointmentTypeId,
      calendar_id: opts["calendarId"],
      page: opts["page"],
      size: opts["size"],
    };
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = null;

    return this.apiClient.callApi(
      "/availability",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
}
