export const EXTERNAL_PRODUCT_TYPES = {
  MDI_MEDICATION: "mdi_medication",
  MDI_COMPOUND: "mdi_compound",
  BUTTERFLY_LABS_LAB_TEST: "butterfly_labs_lab_test",
  IMAWARE_LAB_TEST: "imaware_lab_test",
  ASH_WELLNESS_LAB_TEST: "ash_wellness_lab_test",
  CUREXA_OTC: "curexa_otc",
  MDI_ENCOUNTER: "mdi_encounter",
  ACUITY_APPOINTMENT: "acuity_appointment",
};

export const EXTERNAL_PRODUCT_PROVIDERS = {
  MDI: "mdi",
  BUTTERFLY_LABS: "butterfly_labs",
  IMAWARE: "imaware",
  CUREXA: "curexa",
  ASH_WELLNESS: "ash_wellness",
  ACUITY: "acuity",
};

export const HUMAN_READABLE_EXTERNAL_PROVIDERS = {
  [EXTERNAL_PRODUCT_PROVIDERS.MDI]: "MDI",
  [EXTERNAL_PRODUCT_PROVIDERS.BUTTERFLY_LABS]: "Butterfly Labs",
  [EXTERNAL_PRODUCT_PROVIDERS.IMAWARE]: "Imaware",
  [EXTERNAL_PRODUCT_PROVIDERS.ASH_WELLNESS]: "Ash Wellness",
  [EXTERNAL_PRODUCT_PROVIDERS.ACUITY]: "Acuity",
};

export const PROVIDER_PRODUCT_TYPE = {
  LAB_TEST: "lab_test",
  MEDICATION: "medication",
  COMPOUND: "compound",
  OTC_MEDICATION: "otc_medication",
  ENCOUNTER: "encounter",
  APPOINTMENT: "appointment",
};
