import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import React, { useState } from "react";

import RadioGroup from "components/styled/radio-group/RadioGroup";
import Modal from "components/styled/modal/Modal";
import { Policy } from "models/Insurance/insurance";
import { colors } from "styles/colors";
import { capitalize } from "utils/strings";

const EligibilityModal = ({
  onClose,
  open,
  policies,
  onChange,
}: {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  open: boolean;
  policies: Policy[];
  onChange: (policy: string) => Promise<void>;
}) => {
  const [selectedPayer, setSelectedPayer] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPayer(event.target.value);
  };

  const options = policies.map((policy) => {
    const enabledString = policy.enabled ? "Enabled" : "Disabled";
    const enabledColor = policy.enabled ? colors.approvedGreen : colors.lightGrey2;
    return {
      value: policy.id,
      label: (
        <Box
          sx={{ display: "flex", flexDirection: "column", paddingTop: (theme) => theme.spacing(2) }}
        >
          <span style={{ fontWeight: "bold" }}>{policy.plan_name}</span>
          <span style={{ display: "flex", flexDirection: "row" }}>
            <span>{`${capitalize(policy.coordination_of_benefits)},`}</span>
            <span style={{ color: enabledColor, marginLeft: "5px" }}>{enabledString}</span>
          </span>
        </Box>
      ),
    };
  });

  return (
    <Modal
      onClose={onClose}
      open={open}
      title="Run eligibility check"
      primaryButtonText="Run check"
      disabled={!selectedPayer}
      onPrimaryButtonClick={() => {
        onChange(selectedPayer);
        setSelectedPayer("");
      }}
    >
      <Box
        sx={{
          marginBottom: (theme) => theme.spacing(5),
        }}
      >
        <FormControl component="fieldset" variant="standard">
          <FormLabel
            component="legend"
            sx={{ color: "black", marginBottom: (theme) => theme.spacing(2) }}
          >
            Confirm the policy you would like to check:
          </FormLabel>
          <RadioGroup
            options={options}
            onChange={handleChange}
            defaultValue={""}
            value={selectedPayer}
          />
        </FormControl>
      </Box>
      <Divider />
    </Modal>
  );
};

export default EligibilityModal;
