/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { TasksidTask } from "./TasksidTask";

/**
 * The TasksIdBody model module.
 * @module model/TasksIdBody
 * @version v0.3
 */
export class TasksIdBody {
  /**
   * Constructs a new <code>TasksIdBody</code>.
   * @alias module:model/TasksIdBody
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>TasksIdBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TasksIdBody} obj Optional instance to populate.
   * @return {module:model/TasksIdBody} The populated <code>TasksIdBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TasksIdBody();
      if (data.hasOwnProperty("task")) obj.task = TasksidTask.constructFromObject(data["task"]);
    }
    return obj;
  }
}

/**
 * @member {module:model/TasksidTask} task
 */
TasksIdBody.prototype.task = undefined;
