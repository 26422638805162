import { useQueryClient } from "@tanstack/react-query";
import { Auth, Hub } from "aws-amplify";
import { ReactQueryKeys } from "constants/keys";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { isSignedInAtom } from "./isSignedIn.atom";

export const useAuthState = () => {
  const [isSignedIn, setIsSignedIn] = useAtom(isSignedInAtom);
  const queryClient = useQueryClient();
  const handleUserIsSignedIn = async () => {
    await queryClient.invalidateQueries([ReactQueryKeys.CURRENT_USER]);
    setIsSignedIn(true);
  };

  const handleUserSignOut = async () => {
    queryClient.setQueriesData([ReactQueryKeys.CURRENT_USER], null);

    await queryClient.invalidateQueries([ReactQueryKeys.CURRENT_USER]);
    setIsSignedIn(false);
  };

  useEffect(() => {
    Hub.listen("auth", (data) => {
      //Invalidate the current user cache to make sure it stays up to with auth events
      if (data.payload.event === "signIn") {
        console.log("sign in");
        handleUserIsSignedIn();
      }
      if (data.payload.event === "signOut") {
        handleUserSignOut();
      }

      if (data.payload.event === "configured") {
        Auth.currentSession()
          .then((s) => {
            //Configured will happen on refresh and if we have a session we log you in
            handleUserIsSignedIn();
          })
          .catch((e) => console.warn("Auth configured event [No current session]", e));
      }
    });
  });

  return { isSignedIn };
};
