import { PatientMenuLinks } from "pages/PatientID";
import React, { useState } from "react";

import RightLayout from "./RightSidebar/RightLayout";
//TODO: fix the type when removing callback layout pattern
// https://app.shortcut.com/capable/story/6016/update-any-other-routes-that-use-patientlayout-to-use-the-new-pattern
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EditLayout = ({ BodyComponent, userType }: { BodyComponent: any; userType: string }) => {
  const [title, setTitle] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [onBackButtonClick, setOnBackButtonClick] = useState<any>(undefined);
  const [actionButtons, setActionButtons] = useState([]);
  const [menuLinks, setMenuLinks] = useState<PatientMenuLinks[]>([]);

  return (
    <RightLayout
      title={title}
      actionButtons={actionButtons}
      onBackButtonClick={onBackButtonClick}
      menuLinks={menuLinks}
      showMobile
      sidebar={false}
    >
      <BodyComponent
        setTitle={setTitle}
        setActionButtons={setActionButtons}
        setOnBackButtonClick={setOnBackButtonClick}
        setMenuLinks={setMenuLinks}
        userType={userType}
      />
    </RightLayout>
  );
};

export default EditLayout;
