/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { PatientsPatientAddressesAttributes } from "./PatientsPatientAddressesAttributes";
import { PatientsPatientEthnicities } from "./PatientsPatientEthnicities";
import { PatientsPatientPhonesAttributes } from "./PatientsPatientPhonesAttributes";
import { PatientsPatientRaces } from "./PatientsPatientRaces";

/**
 * The PatientsPatient model module.
 * @module model/PatientsPatient
 * @version v0.3
 */
export class PatientsPatient {
  /**
   * Constructs a new <code>PatientsPatient</code>.
   * @alias module:model/PatientsPatient
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>PatientsPatient</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PatientsPatient} obj Optional instance to populate.
   * @return {module:model/PatientsPatient} The populated <code>PatientsPatient</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PatientsPatient();
      if (data.hasOwnProperty("email"))
        obj.email = ApiClient.convertToType(data["email"], "String");
      if (data.hasOwnProperty("first_name"))
        obj.firstName = ApiClient.convertToType(data["first_name"], "String");
      if (data.hasOwnProperty("middle_name"))
        obj.middleName = ApiClient.convertToType(data["middle_name"], "String");
      if (data.hasOwnProperty("last_name"))
        obj.lastName = ApiClient.convertToType(data["last_name"], "String");
      if (data.hasOwnProperty("birth_date"))
        obj.birthDate = ApiClient.convertToType(data["birth_date"], "Date");
      if (data.hasOwnProperty("gender_identity"))
        obj.genderIdentity = ApiClient.convertToType(data["gender_identity"], "String");
      if (data.hasOwnProperty("administrative_gender"))
        obj.administrativeGender = ApiClient.convertToType(data["administrative_gender"], "String");
      if (data.hasOwnProperty("organization_ids"))
        obj.organizationIds = ApiClient.convertToType(data["organization_ids"], ["String"]);
      if (data.hasOwnProperty("races"))
        obj.races = ApiClient.convertToType(data["races"], [PatientsPatientRaces]);
      if (data.hasOwnProperty("ethnicities"))
        obj.ethnicities = ApiClient.convertToType(data["ethnicities"], [
          PatientsPatientEthnicities,
        ]);
      if (data.hasOwnProperty("identity_id"))
        obj.identityId = ApiClient.convertToType(data["identity_id"], "String");
      if (data.hasOwnProperty("external_id"))
        obj.externalId = ApiClient.convertToType(data["external_id"], "String");
      if (data.hasOwnProperty("identity_external_id"))
        obj.identityExternalId = ApiClient.convertToType(data["identity_external_id"], "String");
      if (data.hasOwnProperty("photon_health_patient_id"))
        obj.photonHealthPatientId = ApiClient.convertToType(
          data["photon_health_patient_id"],
          "String"
        );
      if (data.hasOwnProperty("addresses_attributes"))
        obj.addressesAttributes = ApiClient.convertToType(data["addresses_attributes"], [
          PatientsPatientAddressesAttributes,
        ]);
      if (data.hasOwnProperty("phones_attributes"))
        obj.phonesAttributes = ApiClient.convertToType(data["phones_attributes"], [
          PatientsPatientPhonesAttributes,
        ]);
      if (data.hasOwnProperty("tag_list"))
        obj.tagList = ApiClient.convertToType(data["tag_list"], ["String"]);
      if (data.hasOwnProperty("metadata"))
        obj.metadata = ApiClient.convertToType(data["metadata"], { String: Object });
    }
    return obj;
  }
}

/**
 * @member {String} email
 */
PatientsPatient.prototype.email = undefined;

/**
 * @member {String} firstName
 */
PatientsPatient.prototype.firstName = undefined;

/**
 * @member {String} middleName
 */
PatientsPatient.prototype.middleName = undefined;

/**
 * @member {String} lastName
 */
PatientsPatient.prototype.lastName = undefined;

/**
 * yyyy-mm-dd (ISO 8601)
 * @member {Date} birthDate
 */
PatientsPatient.prototype.birthDate = undefined;

/**
 * Allowed values for the <code>genderIdentity</code> property.
 * @enum {String}
 * @readonly
 */
PatientsPatient.GenderIdentityEnum = {
  /**
   * value: "female"
   * @const
   */
  female: "female",

  /**
   * value: "male"
   * @const
   */
  male: "male",

  /**
   * value: "non_binary"
   * @const
   */
  nonBinary: "non_binary",

  /**
   * value: "non_disclose"
   * @const
   */
  nonDisclose: "non_disclose",

  /**
   * value: "other"
   * @const
   */
  other: "other",

  /**
   * value: "transgender_female"
   * @const
   */
  transgenderFemale: "transgender_female",

  /**
   * value: "transgender_male"
   * @const
   */
  transgenderMale: "transgender_male",
};
/**
 * The gender the patient identifies with. The Patient's gender identity is used as guidance(e.g. for staff) about how to interact with the patient.
 * @member {module:model/PatientsPatient.GenderIdentityEnum} genderIdentity
 */
PatientsPatient.prototype.genderIdentity = undefined;

/**
 * Allowed values for the <code>administrativeGender</code> property.
 * @enum {String}
 * @readonly
 */
PatientsPatient.AdministrativeGenderEnum = {
  /**
   * value: "unknown"
   * @const
   */
  unknown: "unknown",

  /**
   * value: "male"
   * @const
   */
  male: "male",

  /**
   * value: "female"
   * @const
   */
  female: "female",

  /**
   * value: "not_applicable"
   * @const
   */
  notApplicable: "not_applicable",
};
/**
 * The gender of the patient to be used for administrative purposes.
 * @member {module:model/PatientsPatient.AdministrativeGenderEnum} administrativeGender
 */
PatientsPatient.prototype.administrativeGender = undefined;

/**
 * @member {Array.<String>} organizationIds
 */
PatientsPatient.prototype.organizationIds = undefined;

/**
 * @member {Array.<module:model/PatientsPatientRaces>} races
 */
PatientsPatient.prototype.races = undefined;

/**
 * The patient's ethnicity as identified by the patient.
 * @member {Array.<module:model/PatientsPatientEthnicities>} ethnicities
 */
PatientsPatient.prototype.ethnicities = undefined;

/**
 * Pass an identity_id returned by a submission or a lead to link the previously generated identity to the new user being created.
 * @member {String} identityId
 */
PatientsPatient.prototype.identityId = undefined;

/**
 * Provide a custom reference to the patient.
 * @member {String} externalId
 */
PatientsPatient.prototype.externalId = undefined;

/**
 * Provide a custom identity reference for the patient.
 * @member {String} identityExternalId
 */
PatientsPatient.prototype.identityExternalId = undefined;

/**
 * Provide the ID of the associated Photon Health patient.
 * @member {String} photonHealthPatientId
 */
PatientsPatient.prototype.photonHealthPatientId = undefined;

/**
 * @member {Array.<module:model/PatientsPatientAddressesAttributes>} addressesAttributes
 */
PatientsPatient.prototype.addressesAttributes = undefined;

/**
 * @member {Array.<module:model/PatientsPatientPhonesAttributes>} phonesAttributes
 */
PatientsPatient.prototype.phonesAttributes = undefined;

/**
 * Add an array of strings to help manage your resources
 * @member {Array.<String>} tagList
 */
PatientsPatient.prototype.tagList = undefined;

/**
 * Any JSON-formatted data you want to associate with this object
 * @member {Object.<String, Object>} metadata
 */
PatientsPatient.prototype.metadata = undefined;
