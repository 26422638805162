import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import React, { CSSProperties } from "react";

import { colors } from "../../styles/colors";

export const OutlinedButton = ({
  style,
  onClick,
  children,
  href,
  component,
  disabled,
  fullWidth = false,
}: {
  style?: CSSProperties;
  onClick?: any;
  children: React.ReactNode;
  href?: string;
  component?: any;
  disabled?: boolean;
  fullWidth?: boolean;
}): JSX.Element => (
  <Button
    variant="outlined"
    disableElevation
    sx={{
      textTransform: "unset",
      fontWeight: "bold",
      borderColor: "#D6D7D9",
      color: (theme) => theme.palette.primary.main,
      ":hover": {
        backgroundColor: (theme) => theme.palette.secondary.light,
        borderColor: (theme) => theme.palette.secondary.dark,
      },
      ...style,
    }}
    onClick={onClick}
    href={href}
    component={component}
    disabled={disabled}
    fullWidth={fullWidth}
  >
    {children}
  </Button>
);

export const ContainedButton = ({
  style,
  onClick,
  children,
  href,
  component,
  disabled,
  target,
  endIcon,
}: {
  style?: CSSProperties;
  onClick?: any;
  children: React.ReactNode;
  href?: string;
  component?: any;
  disabled?: boolean;
  target?: string;
  endIcon?: any;
}): JSX.Element => (
  <Button
    variant="contained"
    disableElevation
    component={component}
    href={href}
    endIcon={endIcon}
    sx={{
      textTransform: "unset",
      fontWeight: "bold",
      backgroundColor: (theme) => theme.palette.primary.main,
      color: (theme) => theme.palette.primary.contrastText,
      ":hover": {
        backgroundColor: (theme) => theme.palette.secondary.dark,
        color: (theme) => theme.palette.primary.contrastText,
      },
      ...style,
    }}
    onClick={onClick}
    disabled={disabled}
    target={target}
  >
    {children}
  </Button>
);

export const StyledCloseButton = styled(IconButton)`
  position: fixed;
  top: 0;
  right: 0;
  color: ${colors.white};
  background-color: transparent;
  &&:hover {
    background-color: transparent;
  }
`;

export const StyledNavigationButton = ({ type, onClick }: any) => {
  return (
    <OutlinedButton
      onClick={onClick}
      style={{
        marginLeft: "12px",
        padding: "6.4px 0",
        minWidth: "42px",
        borderColor: colors.lightGrey5,
      }}
    >
      {type === "forward" ? (
        <ChevronRight style={{ color: colors.lapis }} />
      ) : (
        <ChevronLeft style={{ color: colors.lapis }} />
      )}
    </OutlinedButton>
  );
};

export const MobileNavigationButton = () => {
  return (
    <Box
      sx={{
        marginLeft: (theme) => theme.spacing(16),
        height: "1px",
        width: "10px",
        display: "flex",
        flexDirection: "row",
      }}
    />
  );
};
