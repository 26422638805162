import Auth from "@capable-health/capable-auth-sdk";

class AuthError extends Error {}
class AuthConfigurationError extends AuthError {}

enum AuthenticationFlows {
  USER_PASSWORD_AUTH,
  USER_SRP_AUTH,
}

function getAuthProperties(): {
  userPoolId: string;
  userPoolWebClientId: string;
  userPoolDomain: string | null;
} {
  const urlParams = new URLSearchParams(window.location.search);

  const userPoolId = urlParams.get("up") || localStorage.getItem("up");
  const userPoolWebClientId = urlParams.get("ac") || localStorage.getItem("ac");
  const userPoolDomain = urlParams.get("domain") || localStorage.getItem("domain");

  if (userPoolId === null || userPoolWebClientId === null)
    throw new AuthConfigurationError("No Auth Configuration Properties given.");

  console.log(`Using auth properties from ${urlParams.has("up") ? "url params" : "localstorage"}`);
  return { userPoolId, userPoolWebClientId, userPoolDomain };
}

function storeAuthProperties(
  userPoolId: string,
  userPoolWebClientId: string,
  userPoolDomain?: string
): void {
  if (userPoolId === null || userPoolWebClientId === null) {
    console.log("Could not store null auth properties.");
  } else {
    localStorage.setItem("up", userPoolId);
    localStorage.setItem("ac", userPoolWebClientId);
  }

  if (userPoolDomain) {
    localStorage.setItem("domain", userPoolDomain);
  }
}

async function initFromLocationOrStorage(
  userPoolId: string,
  userPoolWebClientId: string,
  userPoolDomain: string,
  tenantMigration: boolean,
  ssoAvailable: boolean
): Promise<void> {
  const authenticationFlowType = tenantMigration
    ? AuthenticationFlows.USER_PASSWORD_AUTH
    : AuthenticationFlows.USER_SRP_AUTH;

  const configurationOptions = {
    userPoolId,
    userPoolWebClientId,
    authenticationFlowType: AuthenticationFlows[authenticationFlowType],
  };

  if (ssoAvailable && userPoolDomain) {
    configurationOptions["oauth"] = {
      domain: userPoolDomain,
      redirectSignIn: process.env.REACT_APP_BASE_PATH,
      redirectSignOut: process.env.REACT_APP_BASE_PATH,
      responseType: "code",
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
    };
  }

  if (tenantMigration) {
    configurationOptions["clientMetadata"] = {
      migration: "1",
    };
  }

  console.log(
    "Configuring Auth with: ",
    userPoolId,
    userPoolWebClientId,
    AuthenticationFlows[authenticationFlowType],
    userPoolDomain
  );
  await Auth.credentials.configure(configurationOptions);

  storeAuthProperties(userPoolId, userPoolWebClientId, userPoolDomain);
}

async function signOut(): Promise<void> {
  return Auth.user.signOut().catch((error) => console.log("could not SignOut:", error));
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  AuthConfigurationError,
  AuthenticationFlows,
  initFromLocationOrStorage,
  signOut,
  signIn: Auth.credentials.signIn,
  completeInviteAndSetPassword: Auth.credentials.completeInviteAndSetPassword,
  getAccessToken: Auth.user.getAccessToken,
  getUserPayload: Auth.user.getPayload,
  getAuthProperties,
};
