import api from "api/";
import { SOME_RIDICULOUS_NUMBER } from "constants/api";

interface PayerIdentification {
  payer_name: string;
  payer_id: string;
}

export const fetchPayerNames = async (searchQuery: string) => {
  const response = await api.client.PayerIdentification.list({
    page: 1,
    size: SOME_RIDICULOUS_NUMBER,
    filters: [
      JSON.stringify({
        field: "payer_name",
        operator: "matches",
        value: searchQuery,
      }),
    ],
  });
  if (!response.ok) {
    const error = `Error retrieving Payer Name ${response.error}`;
    console.error(error);
    throw Error(error);
  }

  if (!response.body[0]) {
    const error = `Error retrieving payer names no names returned`;
    console.error(error);
    throw Error(error);
  }

  return response.body.map((result) => ({
    value: result.payer_id,
    label: result.payer_name,
  }));
};
