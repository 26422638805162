import { GridCellParams } from "@mui/x-data-grid";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import React, { useContext, useState } from "react";

import { AddInfoContext } from "../../contexts/info";
import { colors, RUBY, SWITCH_GREEN_1 } from "../../styles/colors";
import { convertRetailPriceCentsToRetailPrice } from "utils/products";
import { formatError } from "../../utils/strings";
import { getLocaleDateString } from "../../utils/dates";
import { getRelationshipPairs } from "../../utils/relatedPersons";
import api from "../../api";
import Modal from "../styled/modal/Modal";
import StatusDot from "../styled/status-dot/StatusDot";
import Switch from "../styled/switch/Switch";
import { UserTypes } from "models/User/User.types";

export const ListItemLink = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Link
      to={href}
      sx={{
        color: (theme) => theme.palette.text.primary,
        textDecoration: "none",
        ":hover": { fontWeight: "bold" },
      }}
      component={RouterLink}
    >
      {children}
    </Link>
  );
};

export const renderGridLinkItem = (params: GridCellParams, options: { hrefRoot: string }): any => {
  return (
    <ListItemLink href={params?.row?.id ? `${options.hrefRoot}/${params?.row?.id}` : ""}>
      {params.value ? params.value.toString() : null}
    </ListItemLink>
  );
};

export const renderTeamMember = (params: GridCellParams, options: { hrefRoot: string }): any => {
  return (
    <ListItemLink href={params?.row?.id ? `${options.hrefRoot}/${params?.row?.id}` : ""}>
      {params.value
        ? params.value.toString() +
          (params?.row?.original?.last_name
            ? " " + params?.row?.original?.last_name.toString()
            : null)
        : null}
    </ListItemLink>
  );
};

export const renderStatusChip = (
  params: GridCellParams,
  options: {
    active: { condition: string | boolean; label?: string };
    inactive: { condition: string | boolean; label?: string };
  }
): JSX.Element => {
  const isActive = params?.value && params.value === options.active.condition;
  let label = "";

  if (
    typeof options.active.condition === "boolean" &&
    options.active.label &&
    options.inactive.label
  ) {
    label = isActive ? options.active.label : options.inactive.label;
  } else {
    label = params.value ? params.value.toString() : "";
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Chip
        label={label}
        variant="outlined"
        sx={{
          backgroundColor: isActive ? (theme) => theme.palette.secondary.light : colors.lightGrey1,
        }}
      />
    </Box>
  );
};

export const renderTagList = (params: GridCellParams): string => {
  // @ts-ignore
  const tagList = params.value ? params.value.join(", ") : "";

  return tagList.length > 40 ? tagList.substr(0, 35) + "..." : tagList;
};

export const renderLocaleDateString = (params: GridCellParams): string => {
  return params.value ? getLocaleDateString(params.value.toString()) : "N/A";
};

export const renderLocaleDateTimeString = (params: GridCellParams): string => {
  return params.value
    ? getLocaleDateString(params.value.toString(), {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      })
    : "N/A";
};

export const renderLocaleDateStringShort = (params: GridCellParams): string => {
  return params.value
    ? getLocaleDateString(params.value.toString(), {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      })
    : "N/A";
};

export const renderPhone = (params: GridCellParams): string => {
  // @ts-ignore
  const primaryPhone = params?.value?.find((p) => p.primary)?.number || "";
  return primaryPhone;
};

export const renderWorkflowStatus = (params: GridCellParams) => (
  <span>{params?.value ? "Active" : "Inactive"}</span>
);

export const RenderVitalCheckmark = (params: GridCellParams): JSX.Element => {
  const addInfoAlert = useContext(AddInfoContext);
  const history = useHistory();

  return (
    <span
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        outline: "none",
      }}
    >
      <Checkbox
        checked={params.value.includes("Vitals")}
        onClick={async (e) => {
          // @ts-ignore
          const value = e.target.checked;
          const response = await api.client.ObservationType.update(params?.row?.original?.id, {
            body: {
              observation_type: {
                tag_list: value
                  ? ["Vitals", ...params.value]
                  : params.value.filter((t) => t !== "Vitals"),
              },
            },
          }).catch((e) => {
            addInfoAlert({ status: "error", message: formatError(e) });
          });
          if (response?.body) {
            history.go(0);
          }
        }}
      />
    </span>
  );
};

export const RenderWebhookValidated = (params: GridCellParams): JSX.Element => {
  return (
    <StatusDot
      color={params.value ? SWITCH_GREEN_1 : RUBY}
      size={"table"}
      label={params.value ? "Yes" : "No"}
    />
  );
};

export const RenderWebhookEnabledSwitch = (params: GridCellParams): JSX.Element => {
  const addInfoAlert = useContext(AddInfoContext);
  const history = useHistory();

  return (
    <span
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        outline: "none",
      }}
    >
      <Switch
        name="webook-enabled-Switch"
        checked={Boolean(params.value)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={async (e) => {
          e.stopPropagation();
          const response = await api.client.Webhook.update(params?.row?.original?.id, {
            body: {
              webhook_endpoint: {
                enabled: e.target.checked,
              },
            },
          }).catch((e) => {
            addInfoAlert({ status: "error", message: formatError(e) });
          });
          if (response?.body) {
            history.go(0);
          }
        }}
      />
    </span>
  );
};

const getRelatedPersonFullName = (person: any) => {
  return person.first_name && person.last_name
    ? `${person.first_name} ${person.last_name}`
    : `${person.email}`;
};

export const RenderPractitioners = ({
  params,
  relationships,
}: {
  params: GridCellParams;
  relationships: any;
}): JSX.Element | null => {
  if (params.value && relationships.length) {
    const currentPatientID = params.value;
    const uniqueRelationships = getRelationshipPairs(
      params?.row?.original?.relationship_ids,
      relationships
    );
    return (
      <Box sx={{ whiteSpace: "nowrap", overflow: "hidden", overflowY: "scroll", height: "55px" }}>
        {Boolean(Object.values(uniqueRelationships).length) &&
          Object.values(uniqueRelationships).map((relPair: any, i) => {
            // @ts-ignore
            const currentPatientRelatedPerson = relPair.find(
              (rel) => rel.patient.id === currentPatientID
            );
            if (currentPatientRelatedPerson?.relationship_type !== UserTypes.Practitioner) {
              return null;
            }

            return (
              <span key={i}>
                {currentPatientRelatedPerson && (
                  <span>
                    {`${getRelatedPersonFullName(currentPatientRelatedPerson.related_person)}`}
                  </span>
                )}
                <br />
              </span>
            );
          })}
      </Box>
    );
  }
  return null;
};

export const RenderRelationshipLinks = ({
  params,
  relationships,
}: {
  params: GridCellParams;
  relationships: any;
}) => {
  if (params.value && params.value.length && relationships.length) {
    const currentPatientID = params?.row?.original?.id;
    const uniqueRelationships = getRelationshipPairs(params.value, relationships);
    return (
      <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {Boolean(Object.values(uniqueRelationships).length) &&
          Object.values(uniqueRelationships).map((relPair: any, i) => {
            // @ts-ignore
            const currentPatientRelatedPerson = relPair.find(
              (rel) => rel.patient.id === currentPatientID
            );
            if (currentPatientRelatedPerson?.relationship_type === UserTypes.Practitioner) {
              return null;
            }

            return (
              <span key={i}>
                {/* If both relationship directions are defined, I'll always find the currentPatientRelatedPerson entry */}
                {currentPatientRelatedPerson && (
                  <span>
                    <Link
                      component={RouterLink}
                      onClick={(e) => e.stopPropagation()}
                      to={`/patients/${currentPatientRelatedPerson.related_person.id}`}
                    >
                      {`${getRelatedPersonFullName(currentPatientRelatedPerson.related_person)}`}
                    </Link>{" "}
                    {`(${currentPatientRelatedPerson.relationship_type})`}
                  </span>
                )}

                {/* This is the case where only one side of the relationship was defined AND 
                  the side that is defined is the 'true' patient's side AND we are currently on the related_person row */}
                {
                  // @ts-ignore
                  relPair.length === 1 && !currentPatientRelatedPerson && (
                    // @ts-ignore
                    <Link
                      component={RouterLink}
                      onClick={(e) => e.stopPropagation()}
                      to={`/patients/${relPair[0].patient.id}`}
                    >
                      {/* @ts-ignore */}
                      {`${getRelatedPersonFullName(relPair[0].patient)}`}
                    </Link>
                  )
                }
                <br />
              </span>
            );
          })}
      </Box>
    );
  }
  return null;
};

export const LongTextContainer = ({ content }: { content: string }): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const cutOff = 100;
  const isLong = content && content.length > cutOff;
  let Body = <Box style={{ whiteSpace: "normal" }}>{content}</Box>;

  if (isLong) {
    // @ts-ignore
    Body = (
      <Box sx={{ whiteSpace: "normal" }}>
        {content.substr(0, cutOff)}
        {" ... "}
        <Box
          component="span"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setIsModalOpen(true);
          }}
          sx={{
            textDecoration: "underline",
            color: (theme) => theme.palette.grey[600],
            cursor: "pointer",
          }}
        >
          See More
        </Box>
      </Box>
    );
  }

  return (
    <>
      {Body}
      <Modal
        onClose={(e) => {
          e.stopPropagation();
          setIsModalOpen(false);
        }}
        open={isModalOpen}
      >
        <Box sx={{ padding: (theme) => theme.spacing(2), whiteSpace: "pre-wrap" }}>{content}</Box>
      </Modal>
    </>
  );
};

export const renderLongText = (params: GridCellParams): JSX.Element => {
  return <LongTextContainer content={params.value} />;
};

export const renderEllipsisOnLongText = (params: GridCellParams): JSX.Element => {
  return (
    <Box sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
      {params.value}
    </Box>
  );
};

export const RenderPatientName = ({ params, patients }: any): JSX.Element | null => {
  const patient_identity_id = params?.value;

  const getPatientFullName = (person: any) => {
    return person.first_name && person.last_name
      ? `${person.first_name} ${person.last_name}`
      : `${person.email}`;
  };

  const patient = patients.find((p) => p.identity_id === patient_identity_id);

  if (patient) {
    return (
      <Link to={`/patients/${patient?.id}`} component={RouterLink}>
        {getPatientFullName(patient)}
      </Link>
    );
  }

  return <>undefined</>;
};

export const RenderPrice = (params) => {
  const retail_price_cents = params?.value;
  const price = convertRetailPriceCentsToRetailPrice(retail_price_cents);

  return price ? `$${price}` : "N/A";
};
