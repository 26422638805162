import React from "react";
import { useAPIList, API_Type } from "../../hooks/listAll";

import SearchableSelect from "../styled/searchable-select/SearchableSelect";

const UserRaceDropdown = ({
  value,
  onChange,
}: {
  value: any;
  onChange(template): void;
}): JSX.Element | null => {
  const [races] = useAPIList(API_Type.Race, { byLevel: ["2"] });

  const raceOptions = races.map((race) => {
    return {
      ...race,
      value: race.code,
      label: `${race.display}`,
    };
  });

  if (raceOptions.length === 0) return null;

  return (
    <SearchableSelect
      options={raceOptions}
      label="Race"
      inputID="race-dropdown"
      value={value}
      setSelectedItem={onChange}
      style={{ marginTop: "20px", marginBottom: "20px" }}
      required="true"
      renderOption={(props, option) => {
        const array = [props, option?.display];
        return array;
      }}
    />
  );
};

export default UserRaceDropdown;
