/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { IntegrationsimawareImawareIntegration } from "./IntegrationsimawareImawareIntegration";

/**
 * The IntegrationsImawareBody model module.
 * @module model/IntegrationsImawareBody
 * @version v0.3
 */
export class IntegrationsImawareBody {
  /**
   * Constructs a new <code>IntegrationsImawareBody</code>.
   * @alias module:model/IntegrationsImawareBody
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>IntegrationsImawareBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/IntegrationsImawareBody} obj Optional instance to populate.
   * @return {module:model/IntegrationsImawareBody} The populated <code>IntegrationsImawareBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new IntegrationsImawareBody();
      if (data.hasOwnProperty("imaware_integration"))
        obj.imawareIntegration = IntegrationsimawareImawareIntegration.constructFromObject(
          data["imaware_integration"]
        );
    }
    return obj;
  }
}

/**
 * @member {module:model/IntegrationsimawareImawareIntegration} imawareIntegration
 */
IntegrationsImawareBody.prototype.imawareIntegration = undefined;
