import { AsyncSearchSelectProps } from "../async-search-select/AsyncSearchSelect.types";
import { DatePickerProps } from "../date-picker/DatePicker.types";
import { ImageUploadProps } from "../image-upload/ImageUpload.types";
import { MultiSelectProps } from "../multi-select/MultiSelect.types";
import { RadioGroupProps } from "../radio-group/RadioGroup.types";
import React, { Ref } from "react";
import { SearchableSelectProps } from "../searchable-select/SearchableSelect.types";
import { SelectProps } from "../select/Select.types";
import { SwitchProps } from "../switch/Switch.types";
import { TextFieldInputProps } from "../text-field-input/TextFieldInput.types";

import * as z from "zod";

export enum FormInputTypes {
  Field,
  FieldArray,
  Switch,
  DatePicker,
  ImageUpload,
  Select,
  MultiSelect,
  SearchableSelect,
  SearchableMultiSelect,
  FormSection,
  RadioGroup,
  AsyncSearchSelect,
  CustomComponent,
}

export const FormSchemaTypes = {
  FieldArray: z.any(),
  Switch: (message = "") => z.boolean(),
  Field: (message = "", type = "") => {
    const baseValidator = z.preprocess(
      (arg) => {
        if (typeof arg === "number") return arg.toString();
        else if (typeof arg === "string") return arg.trim();
        return arg;
      },
      type === "email"
        ? z.string().min(1, { message }).email({ message: "Must be a valid email" })
        : z.string().min(1, { message })
    );

    return baseValidator;
  },
  Select: (message = "") => z.string().min(1, { message }),
  DatePicker: (required = true) =>
    z.preprocess(
      (arg) => {
        if (typeof arg == "string" || arg instanceof Date) return new Date(arg);
      },
      required ? z.date() : z.any()
    ),
  MultiSelect: (message = "") =>
    z.array(z.string()).min(1, {
      message,
    }),
  SearchableSelect: (message = "") =>
    z.preprocess(
      (arg) => {
        if (typeof arg == "string") {
          return {
            label: arg,
            value: arg,
          };
        }
        return arg;
      },
      z.object(
        {
          label: z.string(),
          value: z.any(),
          parent: z.string().optional(),
        },
        {
          invalid_type_error: message,
        }
      )
    ),
  SearchableMultiSelect: (message = "") =>
    z.preprocess(
      (arg) => {
        if (typeof arg == "string") {
          return {
            label: arg,
            value: arg,
          };
        }
        return arg;
      },
      z
        .array(
          z.object(
            {
              label: z.string(),
              value: z.any(),
              parent: z.string().optional(),
            },
            {
              invalid_type_error: message,
            }
          )
        )
        .min(1, { message })
    ),
  AsyncSearchSelect: (message = "") =>
    z.preprocess(
      (arg) => {
        if (typeof arg == "string") {
          return {
            label: arg,
            value: arg,
          };
        }
        return arg;
      },
      z.object(
        {
          label: z.string(),
          value: z.string(),
          parent: z.string().optional(),
        },
        {
          invalid_type_error: message,
        }
      )
    ),

  Optional: z.any(),
};

export interface FormError<TFields> {
  name: keyof TFields;
  message: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface FormDefaultValues<TFields> {
  name: keyof TFields;
  value: any;
}

export interface ServerError {
  name: string;
  message: string;
}

export interface FormProps {
  formData: Array<FormDataType>;
  defaultValues?: any;
  onSubmit?: any;
  onError?: any;
  setWatchedFields?: any;
  forwardedRef?: Ref<HTMLFormElement>;
  serverErrors?: Array<ServerError>;
  resetPartial?: any;
  containerProps?: {
    spacing: {
      xs?: number;
      sm?: number;
      md?: number;
      lg?: number;
    };
    columns?: {
      xs?: number;
      sm?: number;
      md?: number;
      lg?: number;
    };
  };
}

export interface FieldProps {
  name: string;
  defaultValue: any;
  watch?: boolean;
  breakpoints?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
  };
  schema: z.ZodTypeAny;
}

/* eslint-enable @typescript-eslint/no-explicit-any */
export interface TextFieldInputInterface extends FieldProps {
  props: Partial<TextFieldInputProps>;
  component: FormInputTypes.Field;
}

interface SwitchInputInterface extends FieldProps {
  props: Partial<SwitchProps>;
  component: FormInputTypes.Switch;
}

interface DatePickerInputInterface extends FieldProps {
  props: Partial<DatePickerProps>;
  component: FormInputTypes.DatePicker;
}

interface SelectInterface extends FieldProps {
  props: Partial<SelectProps>;
  component: FormInputTypes.Select;
}

interface MultiSelectInterface extends FieldProps {
  props: Partial<MultiSelectProps>;
  component: FormInputTypes.MultiSelect;
}

interface SearchableSelectInterface extends FieldProps {
  props: Partial<SearchableSelectProps>;
  component: FormInputTypes.SearchableSelect;
}

interface SearchableMultiSelectInterface extends FieldProps {
  props: Partial<SearchableSelectProps>;
  component: FormInputTypes.SearchableMultiSelect;
}

interface AsyncSearchSelectInterface extends FieldProps {
  props: Partial<AsyncSearchSelectProps>;
  component: FormInputTypes.AsyncSearchSelect;
}

interface RadioGroupInterface extends FieldProps {
  props: Partial<RadioGroupProps>;
  component: FormInputTypes.RadioGroup;
}

interface ImageUploadInterface extends FieldProps {
  props: Partial<ImageUploadProps>;
  component: FormInputTypes.ImageUpload;
}

interface FormSectionInterface extends Omit<FieldProps, "defaultValue" | "schema"> {
  component: FormInputTypes.FormSection;
  name: string;
  formElements: Array<FormDataType>;
}

export interface CustomComponentInterface extends Pick<FieldProps, "breakpoints" | "name"> {
  component: FormInputTypes.CustomComponent;
  element: React.ReactElement;
}

export interface FieldArrayInterface {
  name: string;
  component: FormInputTypes.FieldArray;
  formElements: Array<FormDataType>;
}

export type FormDataType =
  | TextFieldInputInterface
  | SwitchInputInterface
  | DatePickerInputInterface
  | SelectInterface
  | MultiSelectInterface
  | SearchableSelectInterface
  | SearchableMultiSelectInterface
  | AsyncSearchSelectInterface
  | FormSectionInterface
  | RadioGroupInterface
  | ImageUploadInterface
  | CustomComponentInterface;
