/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { SurveyssubmissionsSubmissionResponses } from "./SurveyssubmissionsSubmissionResponses";

/**
 * The SurveyssubmissionsSubmissionQuestions model module.
 * @module model/SurveyssubmissionsSubmissionQuestions
 * @version v0.3
 */
export class SurveyssubmissionsSubmissionQuestions {
  /**
   * Constructs a new <code>SurveyssubmissionsSubmissionQuestions</code>.
   * @alias module:model/SurveyssubmissionsSubmissionQuestions
   * @class
   * @param id {String} ID of the question this response corresponds to
   */
  constructor(id) {
    this.id = id;
  }

  /**
   * Constructs a <code>SurveyssubmissionsSubmissionQuestions</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SurveyssubmissionsSubmissionQuestions} obj Optional instance to populate.
   * @return {module:model/SurveyssubmissionsSubmissionQuestions} The populated <code>SurveyssubmissionsSubmissionQuestions</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SurveyssubmissionsSubmissionQuestions();
      if (data.hasOwnProperty("id")) obj.id = ApiClient.convertToType(data["id"], "String");
      if (data.hasOwnProperty("responses"))
        obj.responses = ApiClient.convertToType(data["responses"], [
          SurveyssubmissionsSubmissionResponses,
        ]);
    }
    return obj;
  }
}

/**
 * ID of the question this response corresponds to
 * @member {String} id
 */
SurveyssubmissionsSubmissionQuestions.prototype.id = undefined;

/**
 * Array of responses to the question. All previous responses to the question are archived. Provide an empty array to archive all responses to the question.
 * @member {Array.<module:model/SurveyssubmissionsSubmissionResponses>} responses
 */
SurveyssubmissionsSubmissionQuestions.prototype.responses = undefined;
