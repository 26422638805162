import { MultiSelectProps } from "./MultiSelect.types";
import { SelectProps } from "../select/Select.types";
import React from "react";
import Select from "../select/Select";

const MultiSelect = (props: MultiSelectProps): JSX.Element => {
  const { values } = props;
  return <Select {...(props as unknown as SelectProps)} value={values} multiple />;
};

export default MultiSelect;
