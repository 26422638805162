import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";

import { MenuListProps } from "./MenuList.types";
import { OutlinedButton } from "../Buttons";

const MenuList = (props: MenuListProps): JSX.Element => {
  const { label, options, style, iconButton = false } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const handleClick = async (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <OutlinedButton style={style} onClick={handleClick}>
        {label}
      </OutlinedButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{ sx: { width: iconButton ? "auto" : anchorEl && anchorEl.offsetWidth } }}
      >
        {options.map((option, i) => {
          return option.href && !option.onClick ? (
            <Link to={option.href} style={{ textDecoration: "none" }} key={i}>
              <MenuItem>{option.label}</MenuItem>
            </Link>
          ) : option.href && option.onClick ? (
            <Link to={option.href} style={{ textDecoration: "none" }} key={i}>
              <MenuItem
                key={i}
                onClick={(e) => {
                  e.preventDefault();
                  if (option.onClick) {
                    option.onClick();
                    handleClose();
                  }
                }}
              >
                {option.label}
              </MenuItem>
            </Link>
          ) : (
            <MenuItem
              key={i}
              onClick={() => {
                if (option.onClick) {
                  option.onClick();
                  handleClose();
                }
              }}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default MenuList;
