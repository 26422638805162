import CircleIcon from "@mui/icons-material/Circle";
import React from "react";

import { colors, statusColors } from "styles/colors";
import { StatusDotProps } from "./StatusDot.types";

const StatusDot = (props: StatusDotProps): JSX.Element => {
  const {
    status = "info",
    color,
    size = "md",
    label,
    labelLocation = "right",
    dotMarginX = "10px",
    dotMarginY = "5px",
  } = props;

  const sizes = {
    sm: "10px",
    table: "13px",
    md: "15px",
    lg: "20px",
  };

  return (
    <span
      style={{
        display: "flex",
        flexDirection: labelLocation === "right" ? "row" : "row-reverse",
        alignItems: "center",
        width: "fit-content",
        fontSize: sizes[size],
      }}
    >
      <CircleIcon
        sx={{
          fontSize: "inherit",
          color: color ? colors[color] : statusColors[status],
          marginX: dotMarginX,
          marginY: dotMarginY,
        }}
      />
      {label}
    </span>
  );
};

export default StatusDot;
