/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { EncountersEncounterNotesAttributes } from "./EncountersEncounterNotesAttributes";

/**
 * The EncountersEncounter model module.
 * @module model/EncountersEncounter
 * @version v0.3
 */
export class EncountersEncounter {
  /**
   * Constructs a new <code>EncountersEncounter</code>.
   * @alias module:model/EncountersEncounter
   * @class
   * @param startOn {String} Date of the start of the encounter
   * @param endOn {String} Date of the end of the encounter
   * @param source {module:model/EncountersEncounter.SourceEnum} Source of how the encounter started
   * @param patientId {String} ID of the patient whom this encounter is associated with
   * @param practitionerId {String} ID of the practitioner whom this encounter is associated with
   */
  constructor(startOn, endOn, source, patientId, practitionerId) {
    this.startOn = startOn;
    this.endOn = endOn;
    this.source = source;
    this.patientId = patientId;
    this.practitionerId = practitionerId;
  }

  /**
   * Constructs a <code>EncountersEncounter</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EncountersEncounter} obj Optional instance to populate.
   * @return {module:model/EncountersEncounter} The populated <code>EncountersEncounter</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EncountersEncounter();
      if (data.hasOwnProperty("start_on"))
        obj.startOn = ApiClient.convertToType(data["start_on"], "String");
      if (data.hasOwnProperty("end_on"))
        obj.endOn = ApiClient.convertToType(data["end_on"], "String");
      if (data.hasOwnProperty("source"))
        obj.source = ApiClient.convertToType(data["source"], "String");
      if (data.hasOwnProperty("patient_id"))
        obj.patientId = ApiClient.convertToType(data["patient_id"], "String");
      if (data.hasOwnProperty("practitioner_id"))
        obj.practitionerId = ApiClient.convertToType(data["practitioner_id"], "String");
      if (data.hasOwnProperty("notes_attributes"))
        obj.notesAttributes = ApiClient.convertToType(data["notes_attributes"], [
          EncountersEncounterNotesAttributes,
        ]);
    }
    return obj;
  }
}

/**
 * Date of the start of the encounter
 * @member {String} startOn
 */
EncountersEncounter.prototype.startOn = undefined;

/**
 * Date of the end of the encounter
 * @member {String} endOn
 */
EncountersEncounter.prototype.endOn = undefined;

/**
 * Allowed values for the <code>source</code> property.
 * @enum {String}
 * @readonly
 */
EncountersEncounter.SourceEnum = {
  /**
   * value: "messaging"
   * @const
   */
  messaging: "messaging",

  /**
   * value: "video_call"
   * @const
   */
  videoCall: "video_call",

  /**
   * value: "phone_call"
   * @const
   */
  phoneCall: "phone_call",

  /**
   * value: "in_person"
   * @const
   */
  inPerson: "in_person",

  /**
   * value: "external_provider"
   * @const
   */
  externalProvider: "external_provider",
};
/**
 * Source of how the encounter started
 * @member {module:model/EncountersEncounter.SourceEnum} source
 */
EncountersEncounter.prototype.source = undefined;

/**
 * ID of the patient whom this encounter is associated with
 * @member {String} patientId
 */
EncountersEncounter.prototype.patientId = undefined;

/**
 * ID of the practitioner whom this encounter is associated with
 * @member {String} practitionerId
 */
EncountersEncounter.prototype.practitionerId = undefined;

/**
 * @member {Array.<module:model/EncountersEncounterNotesAttributes>} notesAttributes
 */
EncountersEncounter.prototype.notesAttributes = undefined;
