import React from "react";
import WizardModal from "components/styled/wizard/WizardModal";
import { FormInputTypes, FormSchemaTypes, ServerError } from "components/styled/form/Form.types";
import { WizardForm } from "components/styled/wizard/Wizard.types";
import { SearchableSelectOption } from "components/styled/searchable-select/SearchableSelect.types";

const AddOrEditObservationTypeGroupModal = ({
  open,
  onClose,
  onSubmit,
  observationTypeOptions,
  name = "",
  selectedObservationTypes = [],
  serverErrors,
}: {
  onClose: () => void;
  open: boolean;
  onSubmit: (d) => void;
  name?: string;
  selectedObservationTypes?: Record<string, string>[];
  observationTypeOptions: Array<SearchableSelectOption>;
  serverErrors: ServerError[];
}) => {
  const addObservationForm: Array<WizardForm> = [
    {
      title: selectedObservationTypes.length
        ? "Edit Observation Type Grouping"
        : "Add Observation Type Grouping",
      form: [
        {
          name: "name",
          defaultValue: name,
          schema: FormSchemaTypes.Field("Name is required"),
          component: FormInputTypes.Field,
          breakpoints: {
            xs: 12,
            sm: 12,
          },
          props: {
            label: "Name",
          },
        },
        {
          name: "observationTypes",
          defaultValue: selectedObservationTypes,
          schema: FormSchemaTypes.SearchableMultiSelect(
            "You must select at least one observation type"
          ),
          component: FormInputTypes.SearchableMultiSelect,
          breakpoints: {
            xs: 12,
            sm: 12,
          },
          props: {
            label: "Observation Types",
            options: observationTypeOptions,
          },
        },
      ],
    },
  ];

  return open ? (
    <WizardModal
      disableCloseButton
      disableCloseOnFocusOut
      serverErrors={serverErrors}
      onClose={onClose}
      open={open}
      forms={addObservationForm}
      onSubmit={onSubmit}
      setWatchedFields={undefined}
    />
  ) : null;
};

export default AddOrEditObservationTypeGroupModal;
