export const TINYMCE_PUBLIC_KEY = "svrktrwr53se5fvd5nzhqjf84qsf82ccis0itlufdn4mt75f";

// Cache keys used for react-query
export enum ReactQueryKeys {
  APPOINTMENTS = "APPOINTMENTS",
  APPOINTMENT_TYPES = "APPOINTMENT_TYPES",
  BARNARD_CONVERSATION = "BARNARD_CONVERSATION",
  BARNARD_CONVERSATIONS = "BARNARD_CONVERSATIONS",
  BARNARD_CONVERSATIONS_ALL = "BARNARD_CONVERSATIONS_ALL",
  CALENDARS = "CALENDARS",
  CONTENTFUL_CAPABLE_ENTRIES = "CONTENTFUL_CAPABLE_ENTRIES",
  CONTENTFUL_INTEGRATION = "CONTENTFUL_INTEGRATION",
  CONVERSATION_PARTICIPANTS = "CONVERSATION_PARTICIPANTS",
  CONVERSATION_PATIENT_PARTICIPANTS = "CONVERSATION_PATIENT_PARTICIPANTS",
  CONVERSATIONS = "CONVERSATIONS",
  CONVERSATIONS_ALL = "CONVERSATIONS_ALL",
  CURRENT_USER = "CURRENT_USER",
  GOALS = "GOALS",
  INBOX = "INBOX",
  INSURANCE_CLAIM = "INSURANCE_CLAIM",
  INSURANCE_CLAIMS = "INSURANCE_CLAIMS",
  INTEGRATIONS = "INTEGRATIONS",
  INTEGRATIONS_ACUITY = "INTEGRATIONS_ACUITY",
  LAB_TEST_ORDERS = "LAB_TEST_ORDERS",
  MESSAGES = "MESSAGES",
  MEDICAL_TERM = "MEDICAL_TERM",
  OBSERVATION_TYPE = "OBSERVATION_TYPE",
  OBSERVATION_TYPES = "OBSERVATION_TYPES",
  OBSERVATION_TYPE_GROUPS = "OBSERVATION_TYPE_GROUPS",
  PATIENT = "PATIENT",
  PATIENTS = "PATIENTS",
  PATIENT_ALLERGENS = "PATIENT_ALLERGENS",
  PATIENT_ATTACHMENTS = "PATIENT_ATTACHMENTS",
  PATIENT_BARNARD_CONVERSATIONS = "PATIENT_BARNARD_CONVERSATIONS",
  PATIENT_ENCOUNTER = "PATIENT_ENCOUNTER",
  PATIENT_OBSERVATIONS = "PATIENT_OBSERVATIONS",
  PATIENT_RELATED_PERSONS = "PATIENT_RELATED_PERSONS",
  PERMISSION_VIEW = "PERMISSION_VIEW",
  PRACTITIONERS = "PRACTITIONERS",
  PRACTITIONERS_AND_TENANT_ADMINS_DATA = "PRACTITIONERS_AND_TENANT_ADMINS_DATA",
  QUESTIONNAIRE = "QUESTIONNAIRE",
  QUESTIONNAIRES = "QUESTIONNAIRES",
  REFERRING_PROVIDERS = "REFERRING_PROVIDERS",
  SUBMISSIONS = "SUBMISSIONS",
  SURVEY = "SURVEY",
  TAG = "TAG",
  TAGS = "TAGS",
  TARGETS = "TARGETS",
  TASK = "TASK",
  TASKS = "TASKS",
  TASK_TEMPLATE = "TASK_TEMPLATE",
  TASK_TEMPLATES = "TASK_TEMPLATES",
  TWILIO_CLIENT = "TWILIO_CLIENT",
  TWILIO_CONVERSATION = "TWILIO_CONVERSATION",
  TWILIO_CONVERSATIONS = "TWILIO_CONVERSATIONS",
  TWILIO_TOKEN = "TWILIO_TOKEN",
  USER_CAN = "USER_CAN",
  USERS = "USERS",
  VIDEO_LINK = "VIDEO_LINK",
  VIDEO_TOKEN = "VIDEO_TOKEN",
}
