import { AddInfoContext } from "contexts/info";
import { formatError } from "utils/strings";
import { Policy } from "models/Insurance/insurance";
import { SOME_RIDICULOUS_NUMBER } from "constants/api";
import { useContext, useEffect, useState } from "react";

import api from "api";

export const usePolicies = (
  patient
): {
  policies: Policy[];
  loading: boolean;
  error: string;
  refetch: () => void;
} => {
  const addInfoAlert = useContext(AddInfoContext);
  const [policies, setPolicies] = useState<Policy[]>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const [refetchValue, setRefetchValue] = useState(0);

  const refetch = () => {
    setRefetchValue(refetchValue + 1);
  };

  useEffect(() => {
    (async () => {
      if (!patient) {
        setLoading(false);
        return;
      }

      const opts = {
        page: 1,
        size: SOME_RIDICULOUS_NUMBER,
        filtersOperator: "eq",
        filters: [
          JSON.stringify({
            field: "patient_id",
            operator: "eq",
            value: patient.id,
          }),
        ],
      };

      const policiesResponse = await api.client.InsurancePolicy.list(opts).catch((e) => {
        addInfoAlert({ status: "error", message: formatError(e) });
        setError(e);
      });

      if (policiesResponse && policiesResponse.body) {
        setPolicies(policiesResponse.body);
        setLoading(false);
      }
    })();
  }, [addInfoAlert, patient, refetchValue]);
  return { policies, loading, error, refetch };
};

export const usePolicyById = (
  policyId
): {
  policy: Policy | null;
  loading: boolean;
  error: string;
  refetch: () => void;
} => {
  const addInfoAlert = useContext(AddInfoContext);
  //ts
  const [policy, setPolicy] = useState<Policy | null>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const [refetchValue, setRefetchValue] = useState(0);

  const refetch = () => {
    setRefetchValue(refetchValue + 1);
  };

  useEffect(() => {
    (async () => {
      if (!policyId) {
        setLoading(false);
        return;
      }

      const policiesResponse = await api.client.InsurancePolicy.get(policyId).catch((e) => {
        addInfoAlert({ status: "error", message: formatError(e) });
        setError(e);
      });

      if (policiesResponse && policiesResponse.body) {
        setPolicy(policiesResponse.body);
        setLoading(false);
      }
    })();
  }, [addInfoAlert, policyId, refetchValue]);

  return { policy, loading, error, refetch };
};
