import { useQuery } from "@tanstack/react-query";
import { ReactQueryKeys } from "constants/keys";
import { userCanView } from "./PermissionsViews.helpers";
import { PermissionsViewsPermission, PermissionsViewsName } from "./PermissionsViews.type";
import { useGetPermissionsViews } from "./useGetPermissionsViews";
import { capableClient } from "services/capableClient";

export const useUserCanView = ({
  permissionsViewsName,
  permissionsViewsPermission,
}: {
  permissionsViewsName: PermissionsViewsName | PermissionsViewsName[];
  permissionsViewsPermission: PermissionsViewsPermission;
}) => {
  const { data } = useGetPermissionsViews(capableClient);
  return useQuery(
    [ReactQueryKeys.USER_CAN, permissionsViewsName, permissionsViewsPermission],
    () => userCanView(data ?? [])({ permissionsViewsName, permissionsViewsPermission }),
    {
      enabled: !!data,
    }
  );
};
