/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The ObservationsObservation model module.
 * @module model/ObservationsObservation
 * @version v0.3
 */
export class ObservationsObservation {
  /**
   * Constructs a new <code>ObservationsObservation</code>.
   * @alias module:model/ObservationsObservation
   * @class
   * @param patientId {String} ID of the patient whom this observation is associated with
   * @param observedValue {Object} Actual value of the observation
   */
  constructor(patientId, observedValue) {
    this.patientId = patientId;
    this.observedValue = observedValue;
  }

  /**
   * Constructs a <code>ObservationsObservation</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ObservationsObservation} obj Optional instance to populate.
   * @return {module:model/ObservationsObservation} The populated <code>ObservationsObservation</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ObservationsObservation();
      if (data.hasOwnProperty("patient_id"))
        obj.patientId = ApiClient.convertToType(data["patient_id"], "String");
      if (data.hasOwnProperty("target_id"))
        obj.targetId = ApiClient.convertToType(data["target_id"], "String");
      if (data.hasOwnProperty("observation_type_id"))
        obj.observationTypeId = ApiClient.convertToType(data["observation_type_id"], "String");
      if (data.hasOwnProperty("observed_value"))
        obj.observedValue = ApiClient.convertToType(data["observed_value"], Object);
      if (data.hasOwnProperty("observed_date"))
        obj.observedDate = ApiClient.convertToType(data["observed_date"], "String");
      if (data.hasOwnProperty("note")) obj.note = ApiClient.convertToType(data["note"], "String");
      if (data.hasOwnProperty("data_type"))
        obj.dataType = ApiClient.convertToType(data["data_type"], "String");
    }
    return obj;
  }
}

/**
 * ID of the patient whom this observation is associated with
 * @member {String} patientId
 */
ObservationsObservation.prototype.patientId = undefined;

/**
 * ID of the target for this observation
 * @member {String} targetId
 */
ObservationsObservation.prototype.targetId = undefined;

/**
 * ID of the observation type for this observation
 * @member {String} observationTypeId
 */
ObservationsObservation.prototype.observationTypeId = undefined;

/**
 * Actual value of the observation
 * @member {Object} observedValue
 */
ObservationsObservation.prototype.observedValue = undefined;

/**
 * Date of the observation (only necessary for post-dated observations)
 * @member {String} observedDate
 */
ObservationsObservation.prototype.observedDate = undefined;

/**
 * Note about the observed value
 * @member {String} note
 */
ObservationsObservation.prototype.note = undefined;

/**
 * Allowed values for the <code>dataType</code> property.
 * @enum {String}
 * @readonly
 */
ObservationsObservation.DataTypeEnum = {
  /**
   * value: "string"
   * @const
   */
  _string: "string",

  /**
   * value: "boolean"
   * @const
   */
  _boolean: "boolean",

  /**
   * value: "integer"
   * @const
   */
  integer: "integer",

  /**
   * value: "float"
   * @const
   */
  _float: "float",

  /**
   * value: "datetime"
   * @const
   */
  datetime: "datetime",
};
/**
 * Deprecated, this information is already present on the observation's target
 * @member {module:model/ObservationsObservation.DataTypeEnum} dataType
 */
ObservationsObservation.prototype.dataType = undefined;
