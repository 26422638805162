import { TabContainerProps } from "./TabContainer.types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useContext } from "react";

import { BreakpointContext } from "contexts/breakpoint";

const messageStyles = {
  padding: (theme) => theme.spacing(2),
  minHeight: "212px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center ",
};

const TabContainer = (props: TabContainerProps): JSX.Element => {
  const { isMobileView } = useContext(BreakpointContext);

  const {
    tabHeaders,
    tabEmptyText,
    currentTabIndex,
    currentTabContent,
    onTabClick,
    isLoading,
    button,
    contentIsComponent,
  } = props;
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: (theme) => theme.spacing(5),
          width: isMobileView ? "85vw" : "100%",
          overflow: "hidden",
          overflowX: "auto",
        }}
      >
        <Box sx={{ display: "flex" }}>
          {tabHeaders.map((t, i) => (
            <Box
              key={i}
              sx={{
                marginRight: (theme) => theme.spacing(3),
                fontWeight: i === currentTabIndex ? "bold" : "",
                ":hover": { cursor: "pointer" },
                whiteSpace: "nowrap",
              }}
              onClick={() => {
                onTabClick(i);
              }}
            >
              {t}
            </Box>
          ))}
        </Box>
        {button && <span>{button}</span>}
      </Box>
      <Box>
        {isLoading && <Box sx={messageStyles}>Loading...</Box>}
        {!isLoading && !contentIsComponent && Boolean(currentTabContent?.length) && (
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {currentTabContent?.map((c, i) => {
              return (
                <Grid key={i} item xs={12} md={6} lg={4}>
                  {c}
                </Grid>
              );
            })}
          </Grid>
        )}
        {!isLoading && contentIsComponent && (
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {currentTabContent}
          </Grid>
        )}
        {!isLoading && !contentIsComponent && !currentTabContent.length && (
          <Box sx={messageStyles}>{tabEmptyText[currentTabIndex]}</Box>
        )}
      </Box>
    </Box>
  );
};

export default TabContainer;
