import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactQueryKeys } from "constants/keys";
import { Allergen } from "./Allergen";
import { SOME_RIDICULOUS_NUMBER } from "constants/api";
import api from "api/";
import { formatError } from "utils/strings";

const fetchPatientAllergens = async (patientId: string | undefined) => {
  try {
    const response = await api.client.Allergen.list({
      page: 1,
      size: SOME_RIDICULOUS_NUMBER,
      filtersOperator: "eq",
      filters: [
        JSON.stringify({
          field: "patient_id",
          operator: "eq",
          value: patientId,
        }),
      ],
    });

    if (response.ok) {
      return response.body as Allergen[];
    }
  } catch (e) {
    console.error(formatError(e));
    throw new Error("Error fetching allergens");
  }
};

const addPatientAllergy = async ({
  allergyName,
  patientId,
}: {
  allergyName: string;
  patientId: string;
}) => {
  try {
    const response = await api.client.Allergen.create({
      body: {
        allergen: {
          patient_id: patientId,
          non_terminology_allergen: allergyName,
        },
      },
    });
    if (response.ok) {
      return response.body as Allergen;
    }
  } catch (e) {
    console.error(formatError(e));
    throw new Error("Error creating allergens for patient");
  }
};

export const useAllergens = (patientId: string | undefined) => {
  return useQuery(
    [ReactQueryKeys.PATIENT_ALLERGENS, patientId],
    () => fetchPatientAllergens(patientId),
    { enabled: !!patientId }
  );
};

export const useAddAllergy = (patientId: string) => {
  const queryClient = useQueryClient();
  return useMutation(addPatientAllergy, {
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.PATIENT_ALLERGENS, patientId]);
    },
  });
};
