/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { QuestionnairesQuestionnaireAnswersAttributes } from "./QuestionnairesQuestionnaireAnswersAttributes";

/**
 * The QuestionnairesQuestionnaireQuestionsAttributes model module.
 * @module model/QuestionnairesQuestionnaireQuestionsAttributes
 * @version v0.3
 */
export class QuestionnairesQuestionnaireQuestionsAttributes {
  /**
   * Constructs a new <code>QuestionnairesQuestionnaireQuestionsAttributes</code>.
   * @alias module:model/QuestionnairesQuestionnaireQuestionsAttributes
   * @class
   * @param title {String}
   * @param position {Number}
   */
  constructor(title, position) {
    this.title = title;
    this.position = position;
  }

  /**
   * Constructs a <code>QuestionnairesQuestionnaireQuestionsAttributes</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/QuestionnairesQuestionnaireQuestionsAttributes} obj Optional instance to populate.
   * @return {module:model/QuestionnairesQuestionnaireQuestionsAttributes} The populated <code>QuestionnairesQuestionnaireQuestionsAttributes</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new QuestionnairesQuestionnaireQuestionsAttributes();
      if (data.hasOwnProperty("title"))
        obj.title = ApiClient.convertToType(data["title"], "String");
      if (data.hasOwnProperty("position"))
        obj.position = ApiClient.convertToType(data["position"], "Number");
      if (data.hasOwnProperty("status"))
        obj.status = ApiClient.convertToType(data["status"], "String");
      if (data.hasOwnProperty("type")) obj.type = ApiClient.convertToType(data["type"], "String");
      if (data.hasOwnProperty("external_id"))
        obj.externalId = ApiClient.convertToType(data["external_id"], "String");
      if (data.hasOwnProperty("external_source"))
        obj.externalSource = ApiClient.convertToType(data["external_source"], "String");
      if (data.hasOwnProperty("required"))
        obj.required = ApiClient.convertToType(data["required"], "Boolean");
      if (data.hasOwnProperty("observation_type_id"))
        obj.observationTypeId = ApiClient.convertToType(data["observation_type_id"], "String");
      if (data.hasOwnProperty("metadata"))
        obj.metadata = ApiClient.convertToType(data["metadata"], { String: Object });
      if (data.hasOwnProperty("answers_attributes"))
        obj.answersAttributes = ApiClient.convertToType(data["answers_attributes"], [
          QuestionnairesQuestionnaireAnswersAttributes,
        ]);
    }
    return obj;
  }
}

/**
 * @member {String} title
 */
QuestionnairesQuestionnaireQuestionsAttributes.prototype.title = undefined;

/**
 * @member {Number} position
 */
QuestionnairesQuestionnaireQuestionsAttributes.prototype.position = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
QuestionnairesQuestionnaireQuestionsAttributes.StatusEnum = {
  /**
   * value: "active"
   * @const
   */
  active: "active",

  /**
   * value: "archived"
   * @const
   */
  archived: "archived",
};
/**
 * @member {module:model/QuestionnairesQuestionnaireQuestionsAttributes.StatusEnum} status
 * @default 'active'
 */
QuestionnairesQuestionnaireQuestionsAttributes.prototype.status = "active";

/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
QuestionnairesQuestionnaireQuestionsAttributes.TypeEnum = {
  /**
   * value: "CommentQuestion"
   * @const
   */
  commentQuestion: "CommentQuestion",

  /**
   * value: "MultipleChoiceQuestion"
   * @const
   */
  multipleChoiceQuestion: "MultipleChoiceQuestion",

  /**
   * value: "EmailQuestion"
   * @const
   */
  emailQuestion: "EmailQuestion",

  /**
   * value: "HiddenQuestion"
   * @const
   */
  hiddenQuestion: "HiddenQuestion",

  /**
   * value: "InformationQuestion"
   * @const
   */
  informationQuestion: "InformationQuestion",

  /**
   * value: "NumberQuestion"
   * @const
   */
  numberQuestion: "NumberQuestion",

  /**
   * value: "PhoneNumberQuestion"
   * @const
   */
  phoneNumberQuestion: "PhoneNumberQuestion",

  /**
   * value: "ScaleQuestion"
   * @const
   */
  scaleQuestion: "ScaleQuestion",

  /**
   * value: "SingleChoiceQuestion"
   * @const
   */
  singleChoiceQuestion: "SingleChoiceQuestion",

  /**
   * value: "UploadQuestion"
   * @const
   */
  uploadQuestion: "UploadQuestion",

  /**
   * value: "DateQuestion"
   * @const
   */
  dateQuestion: "DateQuestion",

  /**
   * value: "MultiTextQuestion"
   * @const
   */
  multiTextQuestion: "MultiTextQuestion",
};
/**
 * @member {module:model/QuestionnairesQuestionnaireQuestionsAttributes.TypeEnum} type
 */
QuestionnairesQuestionnaireQuestionsAttributes.prototype.type = undefined;

/**
 * @member {String} externalId
 */
QuestionnairesQuestionnaireQuestionsAttributes.prototype.externalId = undefined;

/**
 * Allowed values for the <code>externalSource</code> property.
 * @enum {String}
 * @readonly
 */
QuestionnairesQuestionnaireQuestionsAttributes.ExternalSourceEnum = {
  /**
   * value: "FormSort"
   * @const
   */
  formSort: "FormSort",

  /**
   * value: "null"
   * @const
   */
  _null: "null",
};
/**
 * @member {module:model/QuestionnairesQuestionnaireQuestionsAttributes.ExternalSourceEnum} externalSource
 */
QuestionnairesQuestionnaireQuestionsAttributes.prototype.externalSource = undefined;

/**
 * Indicates whether a response to this question is required for the submission to be completed
 * @member {Boolean} required
 * @default false
 */
QuestionnairesQuestionnaireQuestionsAttributes.prototype.required = false;

/**
 * Associate an observation type if you need the patient response to automatically populate an observation with the response value in the completed submission.
 * @member {String} observationTypeId
 */
QuestionnairesQuestionnaireQuestionsAttributes.prototype.observationTypeId = undefined;

/**
 * Any JSON-formatted data you want to associate with this object
 * @member {Object.<String, Object>} metadata
 */
QuestionnairesQuestionnaireQuestionsAttributes.prototype.metadata = undefined;

/**
 * @member {Array.<module:model/QuestionnairesQuestionnaireAnswersAttributes>} answersAttributes
 */
QuestionnairesQuestionnaireQuestionsAttributes.prototype.answersAttributes = undefined;
