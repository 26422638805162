import { useQuery } from "@tanstack/react-query";

import {
  ConversationType,
  ConversationTypes,
} from "models/Conversations/BarnardConversation.types";
import { ReactQueryKeys } from "constants/keys";
import api from "api/";
import { captureException } from "@sentry/react";

export const getOwnerClientType = (conversationType: ConversationType | ConversationClientType) => {
  if (conversationType === ConversationTypes.CHAT || conversationType === ConversationTypes.SMS) {
    return (conversationType + "Owner") as ConversationClientType;
  }
  return conversationType as ConversationClientType;
};

export const ConversationClientTypes = {
  SMS: "sms",
  SMS_OWNER: "smsOwner",
  CHAT: "chat",
  CHAT_OWNER: "chatOwner",
} as const;

export type ConversationClientType =
  (typeof ConversationClientTypes)[keyof typeof ConversationClientTypes];

type TokenType = `${ConversationClientType}Token`;

export const fetchTwilioToken = async (type: ConversationClientType) => {
  const tokenType = (type + "Token") as TokenType;

  const response = await api.client.Conversation[tokenType]();
  if (!response?.ok) {
    captureException(`Error retrieving twilio token for ${tokenType}`);
    throw new Error(`Error retrieving twilio token for ${tokenType}`);
  }

  return response.body;
};

export const useTwilioToken = (type: ConversationClientType) => {
  return useQuery([ReactQueryKeys.TWILIO_TOKEN, type], () => fetchTwilioToken(type), {
    staleTime: Infinity,
  });
};
