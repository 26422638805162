import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom";

import { BreakpointProvider } from "./contexts/breakpoint";
import { MonitoringProvider } from "./contexts/monitoring";

import { theme } from "./styles/theme";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const queryClient = new QueryClient();

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_ID || "",
  });

  ReactDOM.render(
    <LDProvider>
      <MonitoringProvider>
        <ThemeProvider theme={theme}>
          <BreakpointProvider>
            <React.StrictMode>
              <QueryClientProvider client={queryClient}>
                <CssBaseline />
                <App />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </React.StrictMode>
          </BreakpointProvider>
        </ThemeProvider>
      </MonitoringProvider>
    </LDProvider>,
    document.getElementById("root")
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
