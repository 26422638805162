import Box from "@mui/material/Box";
import React, { useContext } from "react";

import { BreakpointContext } from "../../contexts/breakpoint";
import { colors } from "../../styles/colors";
import { theme } from "../../styles/theme";
import PatientMenu from "../RightSidebar/PatientMenu";
import ExpandableBottomNav from "./ExpandableBottomNav";
import InfoAlertContainer from "../InfoAlertContainer";
import RightSidebar from "../RightSidebar/RightSidebar";
import TopNav from "../RightSidebar/TopNav";
import { Variant } from "@mui/material/styles/createTypography";
import { PatientMenuLinks } from "pages/PatientID";
//TODO: fix the type when removing callback layout pattern
// https://app.shortcut.com/capable/story/6016/update-any-other-routes-that-use-patientlayout-to-use-the-new-pattern
const RightLayout = ({
  children,
  title,
  subtitle,
  sidebarInfo,
  menuLinks,
  actionButtons,
  onBackButtonClick,
  titleVariant,
  rightContent,
  showMobile = false,
  childContainerStyles = {},
  sidebar = true,
}: {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sidebarInfo?: any;
  menuLinks?: PatientMenuLinks[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionButtons?: any;
  onBackButtonClick?: () => void;
  titleVariant?: Variant;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rightContent?: any;
  showMobile?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  childContainerStyles?: any;
  sidebar?: boolean;
}): JSX.Element => {
  const { isMobileView } = useContext(BreakpointContext);
  const topNavHeight = theme.spacing(9);
  const anchorHeight = menuLinks && Boolean(menuLinks.length) ? theme.spacing(5) : "0px";
  const bottomNavHeight = isMobileView ? "100px" : "0px";
  const widthOfSidebar = isMobileView ? "0px" : "375px";
  const widthOfContent = isMobileView ? "100vw" : `calc(100vw - ${widthOfSidebar} - 4px)`;
  const widthOfTopNav = sidebar ? widthOfContent : "100vw";

  return (
    <Box
      sx={{
        backgroundColor: colors.lightGrey1,
        overflow: "hidden",
        display: "flex",
        flexDirection: isMobileView ? "column" : "row",
        height: "100%",
      }}
    >
      <Box sx={{ width: sidebar ? widthOfContent : widthOfTopNav }}>
        <TopNav
          title={title}
          subtitle={subtitle}
          topNavHeight={topNavHeight}
          widthOfContent={widthOfTopNav}
          actionButtons={actionButtons}
          onBackButtonClick={onBackButtonClick}
          titleVariant={titleVariant}
          rightContent={rightContent}
        />
        {menuLinks && Boolean(menuLinks.length) && <PatientMenu menuLinks={menuLinks} />}
        <Box
          sx={{
            padding: (theme) =>
              isMobileView ? theme.spacing(0, 3, 3, 3) : theme.spacing(0, 9, 3, 9),
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            width: widthOfContent,
            marginTop: `calc(${topNavHeight} + ${anchorHeight} + 10px)`,
            height: `calc(100vh - ${topNavHeight} - ${anchorHeight} - 60px - ${bottomNavHeight})`,
            marginBottom: bottomNavHeight,
            overflowY: "auto",
            overflowX: "hidden",
            ...childContainerStyles,
          }}
        >
          <InfoAlertContainer displayLimit={3} />
          {children}
        </Box>
      </Box>
      {sidebarInfo && (
        <>
          {isMobileView && showMobile ? (
            <ExpandableBottomNav
              bottomNavHeight={bottomNavHeight}
              topNavHeight={topNavHeight}
              sidebarInfo={sidebarInfo}
              anchorHeight={anchorHeight}
            />
          ) : (
            <RightSidebar widthOfSidebar={widthOfSidebar} sidebarInfo={sidebarInfo} />
          )}
        </>
      )}
    </Box>
  );
};

export default RightLayout;
