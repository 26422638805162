export const getRelationshipPairs = (relationshipIDs: string[], fullRelationshipInfo: any) => {
  const relationshipIdsFullDetail = relationshipIDs.map(
    // @ts-ignore
    (rID) => fullRelationshipInfo.find((r) => r.id === rID)
  );

  // the unique keys in this dictionary is the id pairs of the two people involved in relationship, order independent
  const relDict = {};

  if (relationshipIdsFullDetail.length) {
    relationshipIdsFullDetail.forEach((r) => {
      // make sure the api returns all that is expected
      if (!r || !r.related_person || !r.patient || !r.relationship_type) {
        return;
      }
      // if we don't have that relationship pair in our dictionary, let's include it
      if (
        !relDict[`${r.patient.id}-${r.related_person.id}`] &&
        !relDict[`${r.related_person.id}-${r.patient.id}`]
      ) {
        relDict[`${r.patient.id}-${r.related_person.id}`] = [r];

        const otherSide = relationshipIdsFullDetail.find(
          (rel) =>
            rel &&
            rel.patient &&
            rel.related_person &&
            r.patient.id === rel.related_person.id &&
            r.related_person.id === rel.patient.id
        );

        if (otherSide) {
          relDict[`${r.patient.id}-${r.related_person.id}`].push(otherSide);
        }
      }
    });
  }

  Object.keys(relDict).forEach((key) => {
    if (!relDict[key]) delete relDict[key];
  });

  return relDict;
};
