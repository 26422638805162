import { GLOBAL_OPERATOR_OPTIONS } from "../components/styled/filter/Filter.constants";
import { FIELD_TYPES, OPERATOR_OPTIONS } from "../components/styled/filter/Filter.types";

export const getFilterParams = (filters, globalOperator, filterFieldOptions) => {
  const adjustedFilters: string[] = [];
  let isPractitioner = false;

  filters.forEach((filter) => {
    isPractitioner = filter.field.includes("practitioner");
    if (isPractitioner) {
      filter.field = filter.field.slice(13);
    }

    const filterMeta = filterFieldOptions.find((f) => f.value === filter.field)?.meta;
    if (filterMeta?.fieldType === FIELD_TYPES.date) {
      if (filter.operator === OPERATOR_OPTIONS.before) {
        const newDate = new Date(filter.value);
        newDate.setHours(0, 0, 0, 0);
        adjustedFilters.push({
          ...filter,
          operator: OPERATOR_OPTIONS.lesserThan,
          value: newDate.toISOString(),
        });
      }

      if (filter.operator === OPERATOR_OPTIONS.after) {
        const newDate = new Date(filter.value);
        newDate.setDate(newDate.getDate() + 1);
        newDate.setHours(0, 0, 0, 0);
        adjustedFilters.push({
          ...filter,
          operator: OPERATOR_OPTIONS.greaterThanOrEqual,
          value: newDate.toISOString(),
        });
      }
    } else {
      adjustedFilters.push(filter);
    }
  });

  return {
    filters: adjustedFilters.map((f) => JSON.stringify(f)),
    globalOperator: GLOBAL_OPERATOR_OPTIONS[globalOperator].value,
    practitioner: isPractitioner,
  };
};

export const filtersSettingsComplete = (filters) =>
  filters.every((filter) =>
    Object.values(filter).every((val) => val !== undefined && val !== null)
  );

export const filterIsReset = (filters) =>
  filters.length === 1 && Object.values(filters[0]).every((val) => val === null);
