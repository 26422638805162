export enum OPERATOR_OPTIONS {
  equal = "eq",
  notEqual = "not_eq",
  matches = "matches",
  doesNotMatch = "does_not_match",
  greaterThan = "gt",
  greaterThanOrEqual = "gteq",
  lesserThan = "lt",
  lesserThanOrEqual = "lteq",
  includes = "in",
  doesNotInclude = "not_in",
  before = "before",
  after = "after",
  on = "on",
}

export enum GLOBAL_OPERATORS {
  and = "and",
  or = "or",
}

export enum FIELD_TYPES {
  boolean = "boolean",
  number = "number",
  date = "date",
  string = "string",
  enum = "enum",
}

export interface filter {
  field: string | null;
  operator: OPERATOR_OPTIONS | null;
  value: any;
}

export type FilterProps = React.PropsWithChildren<{
  filters: filter[];
  setFilters: any;
  globalOperator: number;
  setGlobalOperator: any;
  filterFieldOptions: any;
  applyFilters: any;
}>;
