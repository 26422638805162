/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { TargetstemplatesidTargetTemplate } from "./TargetstemplatesidTargetTemplate";

/**
 * The TemplatesIdBody2 model module.
 * @module model/TemplatesIdBody2
 * @version v0.3
 */
export class TemplatesIdBody2 {
  /**
   * Constructs a new <code>TemplatesIdBody2</code>.
   * @alias module:model/TemplatesIdBody2
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>TemplatesIdBody2</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TemplatesIdBody2} obj Optional instance to populate.
   * @return {module:model/TemplatesIdBody2} The populated <code>TemplatesIdBody2</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TemplatesIdBody2();
      if (data.hasOwnProperty("target_template"))
        obj.targetTemplate = TargetstemplatesidTargetTemplate.constructFromObject(
          data["target_template"]
        );
    }
    return obj;
  }
}

/**
 * @member {module:model/TargetstemplatesidTargetTemplate} targetTemplate
 */
TemplatesIdBody2.prototype.targetTemplate = undefined;
