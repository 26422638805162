import { useQuery } from "@tanstack/react-query";
import { ReactQueryKeys } from "constants/keys";
import api from "api/";
import { ObservationTypeGroup } from "../models/ObservationTypeGroup";

const fetchObservationTypeGroups = async (params) => {
  const response = await api.client.ObservationTypeGroup.list(params);

  if (!response.ok) {
    const error = `Error retrieving Observation Types ${response.error}`;
    console.error(error);
    throw new Error(error);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return response as { body: ObservationTypeGroup[]; headers: any };
};

export const useObservationTypeGroups = (params) => {
  return useQuery(
    [ReactQueryKeys.OBSERVATION_TYPE_GROUPS, params],
    () => fetchObservationTypeGroups(params),
    {
      keepPreviousData: true,
    }
  );
};
