import { useForm, Controller } from "react-hook-form";
import React, { useContext, useState } from "react";
import {
  Alert,
  Box,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { colors } from "styles/colors";
import { BreakpointContext } from "contexts/breakpoint";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { InfoOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { useHistory, useLocation } from "react-router-dom";
import { formatError } from "../utils/strings";
import api from "../api";
import { LoadingButton } from "@mui/lab";
import { CapableLogoLong } from "components/CapableIcons";

const passwordRequirementsMessage =
  "Password must be at least 8 characters long, and contain at least one uppercase letter, one lowercase letter, one digit, and one symbol";
//from https://stackoverflow.com/questions/58767980/aws-cognito-password-regex-specific-to-aws-cognito
const passwordRegEx =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;
const validationSchema = z
  .object({
    email: z.string().min(1, { message: "Email is required" }).email({
      message: "Please provide a valid email address",
    }),
    tpass: z.string().regex(passwordRegEx, {
      message: "Please enter the temporary password you received in your email",
    }),
    password: z.string().regex(passwordRegEx, {
      message: passwordRequirementsMessage,
    }),

    confirmPassword: z.string().min(1, { message: "Please confirm your password" }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ["confirmPassword"],
    message: "Your passwords must match",
  });

type ValidationSchema = z.infer<typeof validationSchema>;

const InputAdornmentForPassword = ({
  showPassword,
  setShowPassword,
  title,
  ariaLabel,
}: {
  showPassword: boolean;
  setShowPassword: (p: boolean) => void;
  title?: string;
  ariaLabel: string;
}) => {
  return (
    <>
      <InputAdornment position="end">
        <IconButton
          aria-label={ariaLabel}
          onClick={(e) => {
            e.preventDefault();
            setShowPassword(!showPassword);
          }}
          edge="end"
          sx={{
            ":hover": { color: colors.darkGrey, backgroundColor: "transparent" },
          }}
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
      {title && (
        <InputAdornment position="end" sx={{ color: colors.lightGrey2 }}>
          <Tooltip title={title} enterDelay={300} arrow placement="top">
            <InfoOutlined />
          </Tooltip>
        </InputAdornment>
      )}
    </>
  );
};

export const AccountConfirmation = () => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const { isMobileView } = useContext(BreakpointContext);
  const theme = useTheme();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [isError, setIsError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
  } = useForm<ValidationSchema>({
    defaultValues: {
      email: urlSearchParams.get("email") ?? "",
      tpass: urlSearchParams.get("tpass") ?? "",
    },
    resolver: zodResolver(validationSchema),
  });

  const processForm = async (data: ValidationSchema) => {
    setIsError(undefined);
    setIsLoading(true);
    try {
      await api.auth.completeInviteAndSetPassword(data.email, data.tpass, data.password);
      history.push("/");
      history.go(0);
    } catch (error) {
      console.error(error);
      setIsError(formatError(error));

      try {
        await api.auth.signOut();
      } catch (signOutError) {
        setIsError(formatError(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formControlStyles = {
    margin: theme.spacing(1, 0),
    width: "100%",
  };

  return (
    <Box
      sx={{
        backgroundColor: colors.lightGrey1,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Card
        sx={{
          width: isMobileView ? "100%" : "500px",
          marginTop: "100px",
          padding: theme.spacing(5),
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        <Box sx={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
          <CapableLogoLong />
        </Box>
        {isError && (
          <Alert sx={{ marginTop: 2 }} severity="error">
            {isError}
          </Alert>
        )}

        <form onSubmit={handleSubmit(processForm)}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <>
                <TextField
                  label="Email"
                  variant="outlined"
                  style={formControlStyles}
                  {...field}
                  onBlur={() => {
                    trigger("email");
                  }}
                  helperText={errors.email?.message}
                  error={!!errors.email}
                />
              </>
            )}
          />

          <Controller
            name="tpass"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <>
                <TextField
                  label="Temporary password"
                  variant="outlined"
                  style={formControlStyles}
                  {...field}
                  onBlur={() => {
                    trigger("tpass");
                  }}
                  helperText={errors.tpass?.message}
                  error={!!errors.tpass}
                />
              </>
            )}
          />

          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <>
                <TextField
                  label="New password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  style={formControlStyles}
                  {...field}
                  onBlur={() => {
                    trigger("password");
                  }}
                  helperText={errors.password?.message}
                  error={!!errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornmentForPassword
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                        title={passwordRequirementsMessage}
                        ariaLabel="Toggle password visibility"
                      />
                    ),
                  }}
                />
              </>
            )}
          />

          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <>
                <TextField
                  label="Confirm new password"
                  variant="outlined"
                  style={formControlStyles}
                  type={showConfirmPassword ? "text" : "password"}
                  {...field}
                  onBlur={() => {
                    trigger("confirmPassword");
                  }}
                  helperText={errors.confirmPassword?.message}
                  error={!!errors.confirmPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornmentForPassword
                        showPassword={showConfirmPassword}
                        setShowPassword={setShowConfirmPassword}
                        ariaLabel="Toggle confirm password visibility"
                      />
                    ),
                  }}
                />
              </>
            )}
          />

          <LoadingButton
            loading={isLoading}
            variant="contained"
            disabled={!isValid}
            onClick={handleSubmit(processForm)}
            style={formControlStyles}
            sx={{ marginTop: (theme) => theme.spacing(3) }}
          >
            Set new password
          </LoadingButton>
        </form>
      </Card>
    </Box>
  );
};
