import React, { useEffect, useState, useMemo, useContext } from "react";

import { renderLocaleDateString } from "components/grid/gridCellRenderers";
import PortalLayout from "layout/LeftSidebar/PortalLayout";
import AddOrEditObservationTypeGroupModal from "./AddOrEditObservationTypeGroupModal";
import { useObservationTypes } from "../../observations/hooks/useObservationTypes.hook";
import { SearchableSelectOption } from "components/styled/searchable-select/SearchableSelect.types";
import { ObservationTypeGroup } from "../models/ObservationTypeGroup";
import { useCreateObservationTypeGroup } from "../hooks/useCreateObservationTypeGroup.hook";
import MenuList from "components/styled/menu-list/MenuList";
import MoreVert from "@mui/icons-material/MoreVert";
import { formatError } from "utils/strings";
import { AddInfoContext } from "contexts/info";
import ListView from "layout/LeftSidebar/ListView";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useObservationTypeGroups } from "../hooks/useObservationTypeGroups.hook";
import { Box, CircularProgress } from "@mui/material";
import { useUpdateObservationTypeGroup } from "../hooks/useUpdateObservationTypeGroup.hook";
import { ServerError } from "components/styled/form/Form.types";

const ObservationTypeGroups = () => {
  const addInfoAlert = useContext(AddInfoContext);
  const [observationTypeGroupModalOpen, setObservationTypeGroupModalOpen] =
    useState<boolean>(false);
  const { data: observationTypes, isLoading: observationTypesLoading } = useObservationTypes();
  const [observationTypeOptions, setObservationTypeOptions] = useState<SearchableSelectOption[]>(
    []
  );
  const [selectedObservationTypeGroup, setSelectedObservationTypeGroup] =
    useState<ObservationTypeGroup>();
  const { mutate: createObservationTypeGroup } = useCreateObservationTypeGroup();
  const { mutate: updateObservationTypeGroup } = useUpdateObservationTypeGroup();
  const { search } = useLocation();
  const queryParams = useMemo(() => {
    return queryString.parse(search);
  }, [search]);
  const { data: observationTypeGroupsData, isLoading: observationTypeGroupsLoading } =
    useObservationTypeGroups({
      size: 10,
      page: Number(queryParams.page ?? 1),
      sortBy: [queryParams.sortBy ?? "-created_at"],
    });
  const observationTypeGroups = observationTypeGroupsData?.body ?? [];
  const totalRowCount = parseInt(observationTypeGroupsData?.headers["total-count"], 10);
  const [serverErrors, setServerErrors] = useState<ServerError[]>([]);

  useEffect(() => {
    if (observationTypes?.length) {
      setObservationTypeOptions(
        observationTypes.map((observationType) => ({
          label: observationType.name,
          value: observationType.id,
        }))
      );
    }
  }, [observationTypes]);

  const observationTypeNameMap = useMemo(() => {
    return observationTypes?.reduce(
      (obj, observationType) =>
        Object.assign(obj, {
          [observationType.id]: observationType.name,
        }),
      {}
    );
  }, [observationTypes]);

  const columns = [
    {
      accessor: "name",
      Header: "Name",
      width: 200,
    },
    {
      accessor: "observation_types",
      Header: "Observation Types",
      width: 400,
      disableSortBy: true,
      Cell: (params) => {
        if (params.row) {
          return params.row.original.observation_type_ids
            .map((id) => {
              return observationTypeNameMap?.[id];
            })
            .join(", ");
        }
        return "N/A";
      },
    },
    {
      accessor: "created_at",
      Header: "Created At",
      width: 150,
      disableSortBy: true,
      Cell: renderLocaleDateString,
    },
    {
      accessor: "id",
      Header: "",
      width: 60,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Cell: (params): any => {
        return (
          <MenuList
            label={<MoreVert />}
            iconButton
            style={{ border: "none", width: "15px", minWidth: 0 }}
            options={[
              {
                label: "Edit",
                onClick: () => {
                  setSelectedObservationTypeGroup(params.row.original);
                  setObservationTypeGroupModalOpen(true);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  const handleSubmit = async (formData) => {
    const reqBody = {
      id: (selectedObservationTypeGroup?.id as string) ?? null,
      name: formData.name as string,
      observation_type_ids: formData.observationTypes.map((option) => option.value) as string[],
    };
    setServerErrors([]);
    if (reqBody.id) {
      updateObservationTypeGroup(reqBody, {
        onSuccess: () => {
          addInfoAlert({
            status: "success",
            message: "Observation Type Group successfully updated!",
            timeout: 1000,
            isToast: true,
          });
          setSelectedObservationTypeGroup(undefined);
          setObservationTypeGroupModalOpen(false);
        },
        onError: (error) => {
          if ((error as Error).message.includes("already exists")) {
            setServerErrors([
              { name: "name", message: "Observation type group with this name already exists" },
            ]);
          } else {
            addInfoAlert({
              status: "error",
              message: formatError(error),
            });
            setObservationTypeGroupModalOpen(false);
          }
        },
      });
    } else {
      createObservationTypeGroup(reqBody, {
        onSuccess: () => {
          addInfoAlert({
            status: "success",
            message: "Observation Type Group successfully created!",
            timeout: 1000,
            isToast: true,
          });
          setObservationTypeGroupModalOpen(false);
        },
        onError: (error) => {
          if ((error as Error).message.includes("already exists")) {
            setServerErrors([
              { name: "name", message: "Observation type group with this name already exists" },
            ]);
          } else {
            addInfoAlert({
              status: "error",
              message: formatError(error),
            });
            setObservationTypeGroupModalOpen(false);
          }
        },
      });
    }
  };

  return (
    <PortalLayout>
      {!observationTypesLoading && !observationTypeGroupsLoading ? (
        <>
          <ListView
            page={Number(queryParams?.page ?? 1)}
            createButtonLabel="Add"
            createOnClick={() => {
              setSelectedObservationTypeGroup(undefined);
              setObservationTypeGroupModalOpen(true);
            }}
            tableColumns={columns}
            rows={observationTypeGroups}
            title="Observation Type Grouping"
            disableRowClick
            path="/observation-type-groupings"
            totalRowCount={totalRowCount}
          />
          <AddOrEditObservationTypeGroupModal
            onClose={() => {
              setServerErrors([]);
              setObservationTypeGroupModalOpen(false);
            }}
            open={observationTypeGroupModalOpen}
            onSubmit={handleSubmit}
            name={selectedObservationTypeGroup?.name}
            selectedObservationTypes={selectedObservationTypeGroup?.observation_type_ids?.map(
              (obs_type_id) => ({
                value: obs_type_id,
                label: observationTypeNameMap?.[obs_type_id],
              })
            )}
            observationTypeOptions={observationTypeOptions}
            serverErrors={serverErrors}
          />
        </>
      ) : (
        <Box
          sx={{
            height: "500px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </PortalLayout>
  );
};

export default ObservationTypeGroups;
