/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The TargetsidTarget model module.
 * @module model/TargetsidTarget
 * @version v0.3
 */
export class TargetsidTarget {
  /**
   * Constructs a new <code>TargetsidTarget</code>.
   * @alias module:model/TargetsidTarget
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>TargetsidTarget</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TargetsidTarget} obj Optional instance to populate.
   * @return {module:model/TargetsidTarget} The populated <code>TargetsidTarget</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TargetsidTarget();
      if (data.hasOwnProperty("name")) obj.name = ApiClient.convertToType(data["name"], "String");
      if (data.hasOwnProperty("description"))
        obj.description = ApiClient.convertToType(data["description"], "String");
      if (data.hasOwnProperty("value"))
        obj.value = ApiClient.convertToType(data["value"], "String");
      if (data.hasOwnProperty("observation_type_id"))
        obj.observationTypeId = ApiClient.convertToType(data["observation_type_id"], "String");
      if (data.hasOwnProperty("tag_list"))
        obj.tagList = ApiClient.convertToType(data["tag_list"], ["String"]);
    }
    return obj;
  }
}

/**
 * Name of the target
 * @member {String} name
 */
TargetsidTarget.prototype.name = undefined;

/**
 * Description of the target
 * @member {String} description
 */
TargetsidTarget.prototype.description = undefined;

/**
 * The target value
 * @member {String} value
 */
TargetsidTarget.prototype.value = undefined;

/**
 * ID of the relevant observation type
 * @member {String} observationTypeId
 */
TargetsidTarget.prototype.observationTypeId = undefined;

/**
 * Add an array of strings to help manage your resources
 * @member {Array.<String>} tagList
 */
TargetsidTarget.prototype.tagList = undefined;
