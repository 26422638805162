/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";

/**
 * The AppointmentsidrescheduleAppointment model module.
 * @module model/AppointmentsidrescheduleAppointment
 * @version v0.3
 */
export class AppointmentsidrescheduleAppointment {
  /**
   * Constructs a new <code>AppointmentsidrescheduleAppointment</code>.
   * @alias module:model/AppointmentsidrescheduleAppointment
   * @class
   * @param fromDate {String} when should the appointment be rescheduled
   */
  constructor(fromDate) {
    this.fromDate = fromDate;
  }

  /**
   * Constructs a <code>AppointmentsidrescheduleAppointment</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AppointmentsidrescheduleAppointment} obj Optional instance to populate.
   * @return {module:model/AppointmentsidrescheduleAppointment} The populated <code>AppointmentsidrescheduleAppointment</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AppointmentsidrescheduleAppointment();
      if (data.hasOwnProperty("from_date"))
        obj.fromDate = ApiClient.convertToType(data["from_date"], "String");
    }
    return obj;
  }
}

/**
 * when should the appointment be rescheduled
 * @member {String} fromDate
 */
AppointmentsidrescheduleAppointment.prototype.fromDate = undefined;
