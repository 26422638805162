import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

const StatusBadge = ({
  status,
}: {
  status: { text: string; backgroundColor: string; color: string };
}) => {
  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(2),
        backgroundColor: alpha(status.backgroundColor, 0.7),
        borderRadius: "4px",
        height: "20px",
        minWidth: "75px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        marginRight: (theme) => theme.spacing(2),
      }}
    >
      <Box
        sx={{
          fontWeight: "bold",
          fontSize: "10px",
          color: status.color,
        }}
      >
        {status.text}
      </Box>
    </Box>
  );
};

export default StatusBadge;
