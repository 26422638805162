/*
 * Capable Health API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import { ApiClient } from "../ApiClient";
import { GoalstemplatesidGoalTemplate } from "./GoalstemplatesidGoalTemplate";

/**
 * The TemplatesIdBody1 model module.
 * @module model/TemplatesIdBody1
 * @version v0.3
 */
export class TemplatesIdBody1 {
  /**
   * Constructs a new <code>TemplatesIdBody1</code>.
   * @alias module:model/TemplatesIdBody1
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>TemplatesIdBody1</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TemplatesIdBody1} obj Optional instance to populate.
   * @return {module:model/TemplatesIdBody1} The populated <code>TemplatesIdBody1</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TemplatesIdBody1();
      if (data.hasOwnProperty("goal_template"))
        obj.goalTemplate = GoalstemplatesidGoalTemplate.constructFromObject(data["goal_template"]);
    }
    return obj;
  }
}

/**
 * @member {module:model/GoalstemplatesidGoalTemplate} goalTemplate
 */
TemplatesIdBody1.prototype.goalTemplate = undefined;
