import { Link as RouterLink } from "react-router-dom";
import { Theme } from "@mui/system/createTheme";
import { Typography } from "@mui/material";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { useTheme } from "@mui/system";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import React, { useEffect, useState, useContext } from "react";

import { AuthContext } from "../../contexts/auth";
import { colors } from "../../styles/colors";
import {
  BusinessIntelligence,
  CapableLogoLong,
  CapableLogoShort,
  CreditCard,
  Gear,
  Home,
  Key,
  LogsSidebarIcon,
  Patient,
  Plug,
  Team,
  Webhook,
} from "../../components/CapableIcons";
import {
  PermissionsViewsName,
  PermissionsViewsPermission,
} from "features/rbac/PermissionsViews.type";
import { ProtectedComponent } from "features/rbac/ProtectedComponent";
import { patientsCurrentPageAtom } from "features/Patients/patients.atoms";
import { useAtom } from "jotai";

const SideNavigation = ({
  width,
  isMobileView,
}: {
  width?: string;
  isMobileView?: boolean;
}): JSX.Element => {
  const theme = useTheme();
  const { lookerIntegration, tagsV1, allowObservationGrouping } = useFlags();
  const [showCapableLogo, setShowCapableLogo] = useState(false);
  const [patientsOnly, setPatientsOnly] = useState(true);

  const { isLdUserIdentified } = useContext(AuthContext);

  const ldClient = useLDClient();
  const [patientsCurrentPage] = useAtom(patientsCurrentPageAtom);

  useEffect(() => {
    if (ldClient && isLdUserIdentified) {
      // wait for user to be identified with LaunchDarkly to avoid flashing UI
      const showLogo = ldClient.variation("show-capable-logo", false);
      setShowCapableLogo(showLogo);
      const patientsOnly = ldClient.variation("patient-only-in-sidebar", false);
      setPatientsOnly(patientsOnly);
    }
  }, [isLdUserIdentified, ldClient]);

  const sidebarContainer = (Sidebar: JSX.Element): JSX.Element => (
    <Box
      sx={{
        position: "absolute",
        top: "0px",
        width: width ? width : "auto",
      }}
    >
      {Sidebar}
    </Box>
  );

  const SidebarContent: JSX.Element = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.white,
        height: "100vh",
        borderRight: "1px solid",
        borderColor: colors.lightGrey4,
        width: width ? width : "auto",
        color: theme.palette.text.primary,
        padding: theme.spacing(2),
        fontSize: "16px",
      }}
      data-sentry-unmask
    >
      {showCapableLogo ? (
        <span style={{ padding: theme.spacing(0, 0, 2, 0) }}>
          <Link to="/" sx={{ ":focus": { outline: "none" } }} component={RouterLink}>
            {isMobileView ? <CapableLogoShort /> : <CapableLogoLong />}
          </Link>
        </span>
      ) : null}

      <SideNavLinkItem linkUrl="/" linkText="Home" linkIcon={<Home />} theme={theme} />

      <Divider sx={{ margin: (theme) => theme.spacing(1, 0) }} />

      <Box sx={{ overflowY: "scroll" }}>
        <ProtectedComponent
          permissionsViewsName={PermissionsViewsName.Patient}
          permissionsViewsPermission={PermissionsViewsPermission.Index}
        >
          <SideNavLinkItem
            linkUrl={`/patients?page=${patientsCurrentPage}`}
            linkText="Patients"
            linkIcon={<Patient />}
            theme={theme}
          />
        </ProtectedComponent>
        {!patientsOnly && (
          <>
            <Divider sx={{ margin: (theme) => theme.spacing(1, 0) }} />
            <Typography sx={{ margin: (theme) => theme.spacing(2, 0), fontWeight: "bold" }}>
              CARE LIBRARY
            </Typography>

            <ProtectedComponent
              permissionsViewsName={PermissionsViewsName.Questionnaire}
              permissionsViewsPermission={PermissionsViewsPermission.Index}
            >
              <SideNavLinkItem linkUrl="/questionnaires" linkText="Questionnaires" theme={theme} />
            </ProtectedComponent>

            <ProtectedComponent
              permissionsViewsName={PermissionsViewsName.Submission}
              permissionsViewsPermission={PermissionsViewsPermission.Index}
            >
              <SideNavLinkItem linkUrl="/submissions" linkText="Submissions" theme={theme} />
            </ProtectedComponent>

            <ProtectedComponent
              permissionsViewsName={PermissionsViewsName.CarePlanTemplate}
              permissionsViewsPermission={PermissionsViewsPermission.Index}
            >
              <SideNavLinkItem
                linkUrl="/careplan-templates"
                linkText="Care Plan Templates"
                theme={theme}
              />
            </ProtectedComponent>

            <ProtectedComponent
              permissionsViewsName={PermissionsViewsName.GoalTemplate}
              permissionsViewsPermission={PermissionsViewsPermission.Index}
            >
              <SideNavLinkItem linkUrl="/goal-templates" linkText="Goal Templates" theme={theme} />
            </ProtectedComponent>

            <ProtectedComponent
              permissionsViewsName={PermissionsViewsName.TargetTemplate}
              permissionsViewsPermission={PermissionsViewsPermission.Index}
            >
              <SideNavLinkItem
                linkUrl="/target-templates"
                linkText="Target Templates"
                theme={theme}
              />
            </ProtectedComponent>

            <ProtectedComponent
              permissionsViewsName={PermissionsViewsName.ObservationType}
              permissionsViewsPermission={PermissionsViewsPermission.Index}
            >
              <SideNavLinkItem
                linkUrl="/observation-types"
                linkText="Observation Types"
                theme={theme}
              />
            </ProtectedComponent>

            {allowObservationGrouping && (
              <SideNavLinkItem
                linkUrl="/observation-type-groupings"
                linkText="Observation Type Groupings"
                theme={theme}
              />
            )}

            <ProtectedComponent
              permissionsViewsName={PermissionsViewsName.TaskTemplate}
              permissionsViewsPermission={PermissionsViewsPermission.Index}
            >
              <SideNavLinkItem linkUrl="/task-templates" linkText="Task Templates" theme={theme} />
            </ProtectedComponent>

            <ProtectedComponent
              permissionsViewsName={PermissionsViewsName.Product}
              permissionsViewsPermission={PermissionsViewsPermission.Index}
            >
              <SideNavLinkItem linkUrl="/products" linkText="Products" theme={theme} />
            </ProtectedComponent>

            <ProtectedComponent
              permissionsViewsName={PermissionsViewsName.PersonalizationRuleSet}
              permissionsViewsPermission={PermissionsViewsPermission.Index}
            >
              <SideNavLinkItem linkUrl="/workflows" linkText="Workflows" theme={theme} />
            </ProtectedComponent>
            <Divider sx={{ margin: (theme) => theme.spacing(1, 0) }} />

            {/* Team section can be view by either Tenant admins or Practitioners  */}
            <ProtectedComponent
              permissionsViewsName={[
                PermissionsViewsName.TenantAdmin,
                PermissionsViewsName.Practitioner,
              ]}
              permissionsViewsPermission={PermissionsViewsPermission.Index}
            >
              <SideNavLinkItem linkUrl="/team" linkText="Team" linkIcon={<Team />} theme={theme} />
            </ProtectedComponent>
            {tagsV1 && (
              <SideNavLinkItem
                linkUrl="/settings"
                linkText="Settings"
                linkIcon={<Gear />}
                theme={theme}
              />
            )}

            <ProtectedComponent
              permissionsViewsName={PermissionsViewsName.Integration}
              permissionsViewsPermission={PermissionsViewsPermission.Index}
            >
              <SideNavLinkItem
                linkUrl="/integrations"
                linkText="Integrations"
                linkIcon={<Plug />}
                theme={theme}
              />
            </ProtectedComponent>

            <ProtectedComponent
              permissionsViewsName={PermissionsViewsName.WebhookEndpoint}
              permissionsViewsPermission={PermissionsViewsPermission.Index}
            >
              <SideNavLinkItem
                linkUrl="/webhooks"
                linkText="Webhooks"
                linkIcon={<Webhook />}
                theme={theme}
              />
            </ProtectedComponent>
            <ProtectedComponent
              permissionsViewsName={PermissionsViewsName.ApiCredentials}
              permissionsViewsPermission={PermissionsViewsPermission.Index}
            >
              <SideNavLinkItem
                linkUrl="/credentials"
                linkText="Credentials"
                linkIcon={<Key />}
                theme={theme}
              />
            </ProtectedComponent>

            <ProtectedComponent
              permissionsViewsName={PermissionsViewsName.Payments}
              permissionsViewsPermission={PermissionsViewsPermission.Index}
            >
              <SideNavLinkItem
                linkUrl="/payments"
                linkText="Payments"
                linkIcon={<CreditCard />}
                theme={theme}
              />
            </ProtectedComponent>

            <ProtectedComponent
              permissionsViewsName={PermissionsViewsName.WorkflowLog}
              permissionsViewsPermission={PermissionsViewsPermission.Index}
            >
              <SideNavLinkItem
                linkUrl="/logs"
                linkText="Logs"
                linkIcon={<LogsSidebarIcon />}
                theme={theme}
              />
            </ProtectedComponent>

            {/* //TODO: see if we can remove this Feature flag */}
            {lookerIntegration && (
              <ProtectedComponent
                permissionsViewsName={PermissionsViewsName.BusinessIntelligence}
                permissionsViewsPermission={PermissionsViewsPermission.Index}
              >
                <SideNavLinkItem
                  linkUrl="/business-intelligence"
                  linkText="Business Intelligence"
                  linkIcon={<BusinessIntelligence />}
                  theme={theme}
                />
              </ProtectedComponent>
            )}
          </>
        )}
      </Box>
    </Box>
  );

  return isMobileView ? sidebarContainer(SidebarContent) : SidebarContent;
};

const SideNavLinkItem = ({
  theme,
  linkUrl,
  linkText,
  linkIcon,
}: {
  theme: Theme;
  linkText: string;
  linkUrl: string;
  linkIcon?: React.ReactNode;
}) => (
  <Link
    underline="none"
    to={linkUrl}
    sx={{
      display: "flex",
      alignItems: "center",
      color: (theme) => theme.palette.text.primary,
      padding: (theme) => theme.spacing(1, 0),
      fontWeight: window.location.pathname === linkUrl ? "bold" : "",
      "&:hover": {
        backgroundColor: colors.lightGrey1,
        borderRadius: (theme) => theme.spacing(0.5),
      },
    }}
    component={RouterLink}
  >
    <span style={{ display: "flex", alignItems: "center", maxWidth: "15px" }}>{linkIcon}</span>
    <span style={{ marginLeft: linkIcon ? theme.spacing(1) : theme.spacing(0) }}>{linkText}</span>
  </Link>
);

export default SideNavigation;
