import { PermissionsViews } from "./PermissionsViews.type";
import { useQuery } from "@tanstack/react-query";
import { ReactQueryKeys } from "constants/keys";
import { CapableClient } from "@capable-health/client";

export const fetchUserPermissions = async (client: CapableClient) => {
  const response = await client?.permissions.getPermissionsViews({});
  return response.data as PermissionsViews;
};

export const useGetPermissionsViews = (client: CapableClient) => {
  return useQuery([ReactQueryKeys.PERMISSION_VIEW], () => fetchUserPermissions(client));
};
