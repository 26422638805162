import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import React from "react";

import { NumberFieldInputProps } from "./NumberFieldInput.types";

const NumberFieldInput = (props: NumberFieldInputProps): JSX.Element => {
  const {
    formControlStyles,
    label,
    id,
    value,
    onChange,
    readOnly,
    disabled,
    name,
    fullWidth,
    size = "medium",
    variant = "standard",
  } = props;

  const inputComponentProps = {
    label,
    id,
    value,
    onChange,
    readOnly,
    type: "number",
    name,
    disabled,
    fullWidth,
  };

  return (
    <FormControl variant={variant} sx={formControlStyles} size={size}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      {variant === "standard" && <Input {...inputComponentProps} />}
      {variant === "outlined" && <OutlinedInput {...inputComponentProps} />}
    </FormControl>
  );
};

export default NumberFieldInput;
